/* eslint-disable */
import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import dashboard from 'containers/dashboard/dashboardReducer';
import auth from 'containers/auth/authReducer';
import common from 'containers/common/commonReducer';
import notification from 'containers/notification/notificationReducer';
import inventory from 'containers/inventory/inventoryReducer';
import itineraryMaker from 'containers/itineraryMaker/itineraryMakerReducer';
import stayDetail from 'containers/stayDetail/stayDetailReducer';
import stayList from 'containers/stayList/stayListReducer';
import supplierList from 'containers/supplierList/supplierListReducer';
import supplierDetail from 'containers/supplierDetail/supplierDetailReducer';
import experiences from 'containers/experiences/experiencesReducer';
import hotelList from 'containers/hotelList/hotelListReducer';
import hotelDetail from 'containers/hotelDetail/hotelDetailReducer';
import placeList from 'containers/placeList/placeListReducer';
import placeDetail from 'containers/placeDetail/placeDetailReducer';
import rootSaga from './rootSaga';
import {
  ADD_REQUEST_SUCCESS,
  UPDATE_REQUEST_DETAILS_SUCCESS,
  REASSIGN_REQUEST_SUCCESS,
  UPDATE_STATUS_SUCCESS,
  SEND_ITINERARY_SUCCESS,
  DELETE_ITINERARY_SUCCESS,
  STAR_REQUEST_SUCCESS,
  UPDATE_ORDER_SUCCESS,
  REMARK_UPDATE_SUCCESS,
  SYNC_STORE,
} from 'actionTypes';
import { deepClone } from 'utils/common';

const reducer = combineReducers({
  auth,
  dashboard,
  common,
  notification,
  inventory,
  itineraryMaker,
  stayDetail,
  stayList,
  experiences,
  supplierList,
  supplierDetail,
  hotelList,
  hotelDetail,
  placeList,
  placeDetail,
});

const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const sagaMiddleware = createSagaMiddleware();

const affectingActions = {
  ADD_REQUEST_SUCCESS: ADD_REQUEST_SUCCESS,
  UPDATE_REQUEST_DETAILS_SUCCESS: UPDATE_REQUEST_DETAILS_SUCCESS,
  REASSIGN_REQUEST_SUCCESS: REASSIGN_REQUEST_SUCCESS,
  UPDATE_STATUS_SUCCESS: UPDATE_STATUS_SUCCESS,
  SEND_ITINERARY_SUCCESS: SEND_ITINERARY_SUCCESS,
  DELETE_ITINERARY_SUCCESS: DELETE_ITINERARY_SUCCESS,
  STAR_REQUEST_SUCCESS: STAR_REQUEST_SUCCESS,
  UPDATE_ORDER_SUCCESS: UPDATE_ORDER_SUCCESS,
  REMARK_UPDATE_SUCCESS: REMARK_UPDATE_SUCCESS,
};

const triggerStoreUpdate = store => next => action => {
  next(action);
  if (affectingActions[action.type]) {
    localStorage.setItem('updatedStore', JSON.stringify(store.getState()));
  }
};

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware, triggerStoreUpdate),
);

const store = createStore(reducer, enhancer);

window.addEventListener('focus', () => {
  if (window.isDirty) {
    try {
      const strStore = localStorage.getItem('updatedStore');
      const newStore = JSON.parse(strStore);
      store.dispatch({ type: SYNC_STORE, data: newStore });
    } catch (err) {
      window.location.reload();
    }
  }
});

sagaMiddleware.run(rootSaga);

export default store;
