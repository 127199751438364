import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import { GET_PLACES, ADD_PLACE } from '../../actionTypes';
import {
  getPlacesFailure,
  getPlacesSuccess,
  addPlaceSuccess,
  addPlaceFailure,
} from './placeListActions';

function* getPlaces(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getPlaces}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.places) {
      yield put(getPlacesSuccess(data.places));
    } else {
      yield put(getPlacesFailure(data.message));
    }
  } catch (err) {
    yield put(getPlacesFailure(err.message));
  }
}

function* addPlace(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.addPlace}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(addPlaceSuccess());
    } else {
      yield put(addPlaceFailure(data.message));
    }
  } catch (err) {
    yield put(addPlaceFailure(err.message));
  }
}

export function* placeListSaga() {
  yield takeLatest(GET_PLACES, getPlaces);
}

export function* addPlaceSaga() {
  yield takeLatest(ADD_PLACE, addPlace);
}
