import axios from 'axios';
import { getEncodedParams, deleteCookies } from './common';
import { TIMEOUT } from './consts';

export const getRequest = async (url, params, headers, cancelToken) => {
  let queryParams = '';
  if (params && Object.keys(params).length) {
    queryParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    queryParams = `?${queryParams}`;
  }
  const finalUrl = `${url}${queryParams}`;
  const data = await axios.get(finalUrl, { headers, timeout: TIMEOUT, cancelToken })
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

export const postRequest = async (url, params, headers, encode = false, cancelToken) => {
  const data = await axios({
    method: 'post',
    url,
    data: encode ? getEncodedParams(params) : params,
    headers,
    cancelToken,
    timeout: TIMEOUT,
  })
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

export const putRequest = async (url, fileData, headers) => {
  const data = await axios({
    method: 'put',
    url,
    data: fileData,
    headers,
    timeout: TIMEOUT,
  })
    .then((resp) => {
      if (resp.status === 200) {
        if (resp.data) {
          return resp.data;
        }
        return { state: true };
      }
      return { state: false };
    })
    .catch((err) => getErrorResponse(err));
  return data;
};

export const patchRequest = async (url, fileData, headers) => {
  const data = await axios({
    method: 'PATCH',
    url,
    data: fileData,
    headers,
    timeout: TIMEOUT,
  })
    .then((resp) => {
      if (resp.status === 200) {
        return { state: true };
      }
      return { state: false };
    })
    .catch((err) => getErrorResponse(err));
  return data;
};

export const deleteRequest = async (url, params, headers) => {
  const data = await axios.delete(url, {
    data: getEncodedParams(params),
    headers,
    timeout: TIMEOUT,
  })
    .then((resp) => resp.data)
    .catch((err) => getErrorResponse(err));
  return data;
};

const getErrorResponse = (err) => {
  let errorMsg = 'Error getting response';
  if (err.response) {
    if (err.response.status === 401) {
      errorMsg = 'Unauthorized';
      deleteCookies(['token', 'expert', 'message', 'state']);
      window.onbeforeunload = undefined;
      window.location = '/login';
    } else if (err.response.status === 500) {
      errorMsg = err.response.data?.message || 'Internal server error, try again later';
    } else if (err.response.data?.message) {
      errorMsg = err.response.data.message;
    } else if (err.response.status > 500) {
      errorMsg = 'Servers are unreachable at the moment, try again later';
    }
  }
  return {
    error: true,
    message: errorMsg,
  };
};
