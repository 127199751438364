import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonBase from '@material-ui/core/ButtonBase';
import makeStyles from '@material-ui/styles/makeStyles';

const CustomCheckbox = ({
  checked,
  onChange,
  disabled,
  label,
  labelClass,
  checkboxClass,
  rootClass,
}) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={clsx(classes.button, rootClass)}
      onClick={onChange}
      disableRipple
      disabled={disabled}
    >
      <Checkbox
        disabled={disabled}
        color="primary"
        className={clsx(classes.box, checkboxClass)}
        checked={checked}
      />
      <Typography className={clsx(classes.label, labelClass, checked && classes.selectedLabel)}>
        {label}
      </Typography>
    </ButtonBase>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'inherit',
  },
  box: {
    padding: 0,
  },
  label: {
    marginLeft: 8,
    fontSize: 14,
    color: theme.colors.textLight,
  },
  selectedLabel: {
    color: theme.colors.black,
  },
}));

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  checkboxClass: PropTypes.string,
  rootClass: PropTypes.string,
};

export default CustomCheckbox;
