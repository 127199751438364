import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Header from 'UI/header';
import modificationImg from 'assets/svg/installment_modification.svg';
import { getInstallmentMap } from 'utils/common';
import { ROLES } from 'utils/consts';
import Installments from '../itineraryMaker/installments';

class OrderModification extends Component {
  constructor(props) {
    super(props);
    const { request, meta } = this.props;
    this.state = {
      refundAmount: 0,
      installments: getInstallmentMap(meta.paymentMode, request.orderInfo.orderData),
    };
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  submitHandler = () => {
    const { onInstallmentsUpdate, request } = this.props;
    const { installments } = this.state;
    const params = {
      requestId: request._id,
      installmentData: installments.map(({
        amount, dueDate, uniqueId,
        paid, paymentMode, _id,
      }) => {
        return {
          _id,
          amount: amount * 100,
          dueDate: moment(dueDate).toISOString(),
          // paid,
          uniqueId,
          paymentMode: paid ? paymentMode.item.code : 'SMART_COLLECT',
        };
      }),
    };
    onInstallmentsUpdate(params);
  };

  render() {
    const {
      classes,
      meta,
      request,
      expert,
      onDismiss,
      loading,
    } = this.props;
    const { installments, refundAmount } = this.state;
    const { itineraries, requirement } = request;
    let minDate = moment(requirement.departureDate).subtract(1, 'day');
    for (let i = 0; i < itineraries.length; i++) {
      if (itineraries[i].status === 'APPROVED') {
        const newMinDate = moment(itineraries[i].requirement.departureDate);
        if (newMinDate.isBefore(minDate)) {
          minDate = newMinDate;
        }
      }
    }
    const tripDates = { from: minDate };
    return (
      <div className={classes.container}>
        <Header
          title="Installment modification"
          onDismiss={onDismiss}
          img={modificationImg}
        />
        <Installments
          itineraryActionType="order_modify"
          shouldRelaxCheck={expert.roles.includes(ROLES.TEAM_LEAD)}
          tripDates={tripDates}
          request={request}
          refundAmount={refundAmount}
          expert={expert}
          itineraryParts={[]}
          isProcessing={loading}
          paymentModes={meta.paymentMode}
          installments={installments}
          onUpdate={this.handleChange}
          nextHandler={this.submitHandler}
        />
      </div>
    );
  }
}

OrderModification.propTypes = {
  classes: PropTypes.object,
  meta: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  expert: PropTypes.object.isRequired,
  onInstallmentsUpdate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const styles = (theme) => ({
  container: {
    width: 680,
    backgroundColor: theme.colors.white,
  },
});

export default withStyles(styles)(OrderModification);
