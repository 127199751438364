import { put, call, takeLatest } from 'redux-saga/effects';
import { GET_NOTIFICATIONS_INIT, MARK_NOTIF_READ_INIT } from '../../actionTypes';
import {
  getNotificationsSuccess,
  getNotificationsFailure,
  markNotifReadSuccess,
  markNotifReadFailure,
} from './notificationActions';
import { baseURL, endPoints } from '../../utils/apiEndpoints';
import { postRequest } from '../../utils/apiRequests';

function* getNotifications(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.notification}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(getNotificationsSuccess(data));
    } else {
      yield put(getNotificationsFailure(data.message));
    }
  } catch (err) {
    yield put(getNotificationsFailure(err.message));
  }
}

export function* getNotificationsSaga() {
  yield takeLatest(GET_NOTIFICATIONS_INIT, getNotifications);
}

function* markNotifRead(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.notificationRead}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(markNotifReadSuccess(data));
      yield put({
        type: GET_NOTIFICATIONS_INIT,
        payload: { headers, params: { limit: 15, skip: 0 } },
      });
    } else {
      yield put(markNotifReadFailure(data.message));
    }
  } catch (err) {
    yield put(markNotifReadFailure(err.message));
  }
}

export function* markNotifReadSaga() {
  yield takeLatest(MARK_NOTIF_READ_INIT, markNotifRead);
}
