import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import withStyles from '@material-ui/styles/withStyles';
import Arrow from 'UI/arrow';
import { headerGenerator } from 'utils/common';
import reminder from '../../assets/svg/reminder.svg';

class Notifications extends Component {
  constructor(props) {
    super(props);
    const { token } = this.props;
    this.headers = headerGenerator(token);
  }

  componentDidMount = () => {
    const { getNotifications } = this.props;
    getNotifications({ populate: true }, this.headers);
  };

  notifClickHandler = (path) => {
    const { origin } = window.location;
    window.open(`${origin}${path}`, '_blank');
  };

  renderNotifs = () => {
    const { classes, notifications } = this.props;
    const today = moment().format('DD/MM/YY');
    const yesterday = moment(((new Date().getTime()) / 1000) - 86400).format('DD/MM/YY');
    return Object.keys(notifications).map((date) => {
      let dispTitle = moment(date, 'DD MMM YY').format('DD/MM/YY');
      if (dispTitle === today) {
        dispTitle = 'Today';
      } else if (dispTitle === yesterday) {
        dispTitle = 'Yesterday';
      }
      const len = notifications[date].length;
      let unRead = len;
      const notifItems = notifications[date].map(({
        _id,
        title,
        body,
        status,
        path,
        created_at: createdAt,
      }) => {
        if (status === 'READ') {
          unRead--;
        }
        return (
          <button
            type="button"
            key={_id}
            className={classes.notifContainer}
            onClick={() => this.notifClickHandler(path)}
          >
            <img src={reminder} alt=" " className={classes.notifImg} />
            <div className={classes.notifBody}>
              <div className={classes.row}>
                <Typography className={classes.notifTitle}>{title}</Typography>
                <Typography className={classes.time}>
                  {moment(createdAt).format('hh:mm A')}
                </Typography>
              </div>
              <Typography className={classes.notifText}>{body}</Typography>
            </div>
          </button>
        );
      });
      return (
        <div key={date} className={classes.notifSection}>
          <div className={classes.head}>
            <Typography className={classes.notifDate}>{dispTitle}</Typography>
            <Typography className={classes.unReadText}>
              {unRead === 0 ? `${len} read` : `${unRead} unread`}
            </Typography>
          </div>
          {notifItems}
        </div>
      );
    });
  };

  markNotifRead = () => {
    const { markNotifRead } = this.props;
    markNotifRead({}, this.headers);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Arrow />
        <div className={classes.header}>
          <Typography className={classes.title}>
            Notifications
          </Typography>
          <ButtonBase
            className={classes.markReadButton}
            onClick={this.markNotifRead}
          >
            Mark all read
          </ButtonBase>
        </div>
        {this.renderNotifs()}
      </div>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object,
  notifications: PropTypes.object,
  expert: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  markNotifRead: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  container: {
    padding: '20px 0',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    width: 400,
    maxHeight: 470,
    minHeight: 200,
    borderRadius: 10,
    zIndex: 2,
    marginTop: 18,
    border: `1px solid ${theme.colors.textLight_1}`,
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.24)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 14,
    padding: '0 20px',
  },
  title: {
    color: theme.colors.black,
    fontSize: 16,
  },
  markReadButton: {
    marginLeft: 'auto',
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.primary,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    padding: '5px 10px',
    height: 30,
    marginRight: -10,
  },
  notifSection: {
    display: 'flex',
    zIndex: 4,
    flexDirection: 'column',
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.underline,
    padding: '8px 20px',
    top: -20,
    minHeight: 30,
    boxSizing: 'border-box',
    position: 'sticky',
  },
  notifDate: {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  unReadText: {
    fontWeight: 300,
    fontSize: 12,
    marginLeft: 'auto',
  },
  notifContainer: {
    padding: 20,
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    minHeight: 50,
    flexDirection: 'row',
    backgroundColor: theme.colors.white,
    border: 'none',
    borderBottom: `1px solid ${theme.colors.underline}`,
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  notifImg: {
    minWidth: 30,
    height: 30,
  },
  notifBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  notifTitle: {
    fontSize: 16,
    color: theme.colors.black,
    flexWrap: 'wrap',
    flex: 1,
    paddingRight: 16,
  },
  notifText: {
    fontSize: 14,
    flexWrap: 'wrap',
    color: theme.colors.textLight,
  },
  time: {
    color: theme.colors.primary,
    fontSize: 12,
    marginLeft: 'auto',
    alignSelf: 'flex-start',
  },
});

export default withStyles(styles)(Notifications);
