import {
  CREATE_ITINERARY_INIT,
  CREATE_ITINERARY_SUCCESS,
  CREATE_ITINERARY_FAILURE,
  SAVE_ITINERARY_DRAFT_INIT,
  SAVE_ITINERARY_DRAFT_SUCCESS,
  SAVE_ITINERARY_DRAFT_FAILURE,
  GET_STAY_ROOMS_INIT,
  GET_STAY_ROOMS_SUCCESS,
  GET_STAY_ROOMS_FAILURE,
  GET_ITINERARY_PRICE_INIT,
  GET_ITINERARY_PRICE_SUCCESS,
  GET_ITINERARY_PRICE_FAILURE,
} from '../../actionTypes';

const initialState = {
  isCreatingItinerary: false,
  createItineraryResp: {},
  createItineraryError: false,
  createItineraryErrorMsg: '',
  isSavingItineraryDraft: false,
  saveItineraryDraftResp: {},
  saveItineraryDraftError: false,
  saveItineraryDraftErrorMsg: '',
  isGettingStayRooms: false,
  getStayRoomsResp: {},
  getStayRoomsError: false,
  getStayRoomsErrorMsg: '',
  isGettingItineraryPrice: false,
  itineraryPriceResp: {},
  itineraryPriceError: false,
  itineraryPriceErrorMsg: '',
};

const itineraryMaker = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ITINERARY_INIT:
      return {
        ...state,
        isCreatingItinerary: true,
        createItineraryError: false,
      };

    case CREATE_ITINERARY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isCreatingItinerary: false,
        createItineraryResp: data,
      };
    }

    case CREATE_ITINERARY_FAILURE:
      return {
        ...state,
        isCreatingItinerary: false,
        createItineraryError: true,
        createItineraryErrorMsg: action.error,
      };

    case SAVE_ITINERARY_DRAFT_INIT:
      return {
        ...state,
        isSavingItineraryDraft: true,
        saveItineraryDraftError: false,
      };

    case SAVE_ITINERARY_DRAFT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isSavingItineraryDraft: false,
        saveItineraryDraftResp: data,
      };
    }

    case SAVE_ITINERARY_DRAFT_FAILURE:
      return {
        ...state,
        isSavingItineraryDraft: false,
        saveItineraryDraftError: true,
        saveItineraryDraftErrorMsg: action.error,
      };

    case GET_STAY_ROOMS_INIT:
      return {
        ...state,
        isGettingStayRooms: true,
        getStayRoomsError: false,
      };

    case GET_STAY_ROOMS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingStayRooms: false,
        getStayRoomsResp: data,
      };
    }

    case GET_STAY_ROOMS_FAILURE:
      return {
        ...state,
        isGettingStayRooms: false,
        getStayRoomsError: true,
        getStayRoomsErrorMsg: action.error,
      };

    case GET_ITINERARY_PRICE_INIT:
      return {
        ...state,
        isGettingItineraryPrice: true,
        itineraryPriceResp: {},
        itineraryPriceError: false,
      };

    case GET_ITINERARY_PRICE_SUCCESS: {
      const { priceAvailable, pricing } = action.data;
      return {
        ...state,
        isGettingItineraryPrice: false,
        itineraryPriceResp: { ...pricing, priceAvailable },
      };
    }

    case GET_ITINERARY_PRICE_FAILURE:
      return {
        ...state,
        isGettingItineraryPrice: false,
        itineraryPriceError: true,
        itineraryPriceErrorMsg: action.error,
      };

    default:
      return state;
  }
};

export default itineraryMaker;
