import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import fileOutline from 'assets/svg/file_dotted.svg';
import fileIcon from 'assets/svg/file.svg';

class DragnDrop extends Component {
  constructor(props) {
    super(props);
    this.dragRef = React.createRef();
    this.state = {
      dragging: false,
    };
    this.dragging = false;
    this.dragCounter = 0;
  }

  componentDidMount = () => {
    const div = this.dragRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount = () => {
    const div = this.dragRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    const { dragging } = this.state;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0 && !this.dragging && !dragging) {
      this.dragging = true;
      this.setState({ dragging: true });
    }
  }

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
      this.dragging = false;
    }
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDrop = (e) => {
    const { fileDropHandler } = this.props;
    this.setState({ dragging: false });
    this.dragCounter = 0;
    this.dragging = false;
    if (fileDropHandler) {
      fileDropHandler(e);
    }
  }

  render() {
    const { classes, extraClass, children } = this.props;
    const { dragging } = this.state;
    return (
      <div
        className={clsx(classes.container, extraClass)}
        ref={this.dragRef}
      >
        {dragging ? (
          <div className={classes.droppingZone}>
            <div className={classes.animationContainer}>
              <img src={fileIcon} alt="[]" className={classes.fileIcon} />
              <img src={fileOutline} alt="[]" className={classes.fileOutline} />
            </div>
            <Typography className={classes.dropMsg}>
              Drop files here
            </Typography>
          </div>
        ) : null}
        {children}
      </div>
    );
  }
}

/**
 * @param {import('../../../index').Theme} theme
 */
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    position: 'relative',
    minWidth: 900,
    height: 580,
    borderRadius: 10,
  },
  droppingZone: {
    position: 'absolute',
    width: '98%',
    height: '97.2%',
    margin: 8,
    zIndex: 10,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${theme.colors.primary}`,
    backgroundColor: 'rgba(58,174,169,0.2)',
  },
  animationContainer: {
    position: 'relative',
    marginBottom: 20,
  },
  dropMsg: {
    fontSize: 30,
    color: theme.colors.textDark,
    textAlign: 'center',
  },
  fileIcon: {
    position: 'absolute',
    width: 36,
    maxHeight: 46,
    animationName: 'fileDrop',
    animationDuration: '1s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },
  fileOutline: {
    width: 36,
    maxHeight: 46,
  },
});

DragnDrop.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired,
  extraClass: PropTypes.string,
  fileDropHandler: PropTypes.func.isRequired,
};

export default withStyles(styles)(DragnDrop);
