import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
// import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import LabelInput from 'UI/labelInput';
// import Autocomplete from 'UI/autocomplete';
import DragnDrop from 'UI/DragnDrop';
import Button from 'UI/button';
import pdfImg from 'assets/svg/pdf.svg';
import jpegImg from 'assets/svg/jpeg.svg';
import pngImg from 'assets/svg/png.svg';
import DocumentCards from 'UI/documentCards';
import PhoneNumber from 'UI/phoneNumber';

class ItineraryUser extends Component {
  constructor(props) {
    super(props);
    this.docTypes = {
      'application/pdf': pdfImg,
      'image/png': pngImg,
      'image/jpeg': jpegImg,
    };
    this.dropInput = React.createRef();
  }

  handleDrop = (e) => {
    const { onUpdate, documents } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const modDocuments = [...documents];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { type } = file;
        if (type && Object.keys(this.docTypes).some((d) => d === type)) {
          modDocuments.push(file);
        }
      }
      onUpdate('documents', modDocuments);
      e.dataTransfer.clearData();
    }
  };

  handleInputUpload = (e) => {
    const params = {
      preventDefault: () => { },
      stopPropagation: () => { },
      dataTransfer: {
        files: e.target.files,
        clearData: () => { },
      },
    };
    this.handleDrop(params);
    this.dropInput.current.value = '';
  };

  handleChange = (field, value) => {
    const { userDetails, onUpdate } = this.props;
    const modDetails = {
      ...userDetails,
      [field]: value,
    };
    onUpdate('userDetails', modDetails);
  };

  handleSearchChange = (field, value) => {
    const { getSuggestions, userDetails, onUpdate } = this.props;
    const modDetails = {
      ...userDetails,
      [field]: { value, valid: false },
    };
    getSuggestions('countries', value);
    onUpdate('userDetails', modDetails);
  };

  handleItemSelection = (field, accessor, item) => {
    const { userDetails, onUpdate } = this.props;
    const modDetails = {
      ...userDetails,
      [field]: { valid: true, value: item[accessor] },
    };
    onUpdate('userDetails', modDetails);
  };

  deleteDocument = (index) => {
    const { onUpdate, documents } = this.props;
    const modDocuments = [...documents];
    modDocuments.splice(index, 1);
    onUpdate('documents', modDocuments);
  };

  render() {
    const {
      classes, userDetails, errorMsg,
      suggestions, documents, nextHandler,
    } = this.props;
    const {
      firstName, lastName, email,
      phone, phoneDialCode = {},
    } = userDetails;
    return (
      <DragnDrop
        extraClass={classes.dragContainer}
        fileDropHandler={this.handleDrop}
      >
        <div className={classes.container}>
          <div className={classes.body}>
            <Typography className={classes.note}>Before closing the deal</Typography>
            <Typography
              className={classes.note}
            >
              - Upload necessary documents required to process booking eg: passports, visa, PAN card
            </Typography>
            <Typography
              className={classes.note}
            >
              - Upload flight tickets if booked by customer
            </Typography>
            <Typography
              className={classes.note}
            >
              - Do not forget to verify user and trip details
            </Typography>
            <div className={classes.spacing} />
            <div className={classes.row}>
              <LabelInput
                extraClass={classes.left}
                placeholder="John"
                label="FIRST NAME"
                value={firstName}
                onChange={(val) => this.handleChange('firstName', val)}
              />
              <LabelInput
                extraClass={classes.right}
                placeholder="Doe"
                label="LAST NAME"
                value={lastName}
                onChange={(val) => this.handleChange('lastName', val)}
              />
            </div>
            <div className={classes.row}>
              <LabelInput
                extraClass={classes.left}
                label="EMAIL"
                placeholder="example@example.com"
                value={email}
                onChange={(val) => this.handleChange('email', val)}
              />
              <PhoneNumber
                label="PHONE NO."
                suggestions={suggestions}
                extraClass={classes.right}
                number={phone}
                dialCode={phoneDialCode.value}
                handleNumberChange={(val) => this.handleChange('phone', val)}
                handleDialCodeChange={(val) => this.handleSearchChange('phoneDialCode', val)}
                handleDialCodeSelect={(item) => this.handleItemSelection('phoneDialCode', 'dialCode', item)}
              />
              {/* <LabelInput label="PHONE NO." extraClass={classes.right}>
                <div className={classes.phoneField}>
                  <div className={classes.phoneWrapper}>
                    <Autocomplete
                      data={suggestions.countries}
                      accessor="displayText"
                      variant="no-outline"
                      popupClass={classes.popupClass}
                      value={phoneDialCode.value}
                      onChange={(val) => this.handleSearchChange('phoneDialCode', val)}
                      onSelected={(item) =>
                        this.handleItemSelection('phoneDialCode', 'dialCode', item)}
                    />
                  </div>
                  <InputBase
                    type="number"
                    className={classes.bareInput}
                    autoComplete="off"
                    classes={{
                      input: classes.input,
                    }}
                    value={phone}
                    onChange={(e) => this.handleChange('phone', e.target.value)}
                  />
                </div>
              </LabelInput> */}
            </div>
            <DocumentCards
              documents={documents}
              onRemove={this.deleteDocument}
            />
            {/* <div className={classes.docsSection}>
              {documents.map(({ type, name }, i) => {
                return (
                  <div
                    key={name}
                    className={classes.documentCard}
                  >
                    <img src={this.docTypes[type]} className={classes.documentImg} alt={type} />
                    <Typography className={classes.docTitle}>
                      {name}
                    </Typography>
                    <IconButton
                      className={classes.docDelete}
                      onClick={() => this.deleteDocument(i)}
                    >
                      <Delete className={classes.deleteIcon} />
                    </IconButton>
                  </div>
                );
              })}
            </div> */}
          </div>
          <div className={classes.footer}>
            {errorMsg ? (
              <Typography className={classes.errorMsg}>{errorMsg}</Typography>
            ) : null}
            <div className={classes.uploadSection}>
              <input
                type="file"
                accept="application/pdf,image/png,image/jpeg"
                multiple="multiple"
                ref={this.dropInput}
                onChange={this.handleInputUpload}
                className={classes.uploadButton}
              />
              <Typography className={classes.uploadText}>
                Upload documents
              </Typography>
            </div>
            <Button
              className={classes.createButton}
              onClick={nextHandler}
            >
              Save & continue
            </Button>
          </div>
        </div>
      </DragnDrop>
    );
  }
}

const styles = (theme) => ({
  dragContainer: {
    width: '100%',
    boxSizing: 'border-box',
    height: '100%',
    minWidth: 0,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    padding: '20px 40px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  note: {
    fontSize: 12,
    color: theme.colors.textLight,
    fontWeight: 'bold',
  },
  spacing: {
    height: 20,
    padding: 10,
    width: 1,
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  popupClass: {
    width: 200,
    top: 30,
    left: -20,
  },
  phoneField: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    borderRadius: 4,
    height: 40,
    border: `1px solid ${theme.colors.border}`,
  },
  textField: {
    flex: 1,
  },
  phoneWrapper: {
    alignSelf: 'center',
    width: 72,
    padding: '10px 5px 10px 15px',
    marginRight: 4,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  bareInput: {
    padding: '6px 5px 6px 5px',
  },
  docsSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 100,
    overflowX: 'auto',
    padding: '4px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  errorMsg: {
    padding: '0 16px',
    color: theme.colors.red,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  uploadSection: {
    cursor: 'pointer',
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
    marginLeft: 10,
    width: 180,
    height: 40,
    position: 'relative',
    backgroundColor: theme.colors.primaryBackground,
    padding: '5px 10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadButton: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  uploadText: {
    fontWeight: 'bold',
    letterSpacing: 0.5,
    fontSize: 14,
    color: theme.colors.white,
  },
  createButton: {
    width: 180,
    color: theme.colors.white,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
    marginLeft: 10,
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
});

ItineraryUser.propTypes = {
  classes: PropTypes.object,
  userDetails: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  suggestions: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
};

export default withStyles(styles)(ItineraryUser);
