import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import ReviveIcon from '@material-ui/icons/Autorenew';
import Button from 'UI/button';
import Footer from 'UI/footer';
import LabelInput from 'UI/labelInput';
import Header from 'UI/header';

const ItineraryTemplate = ({
  itinerary,
  onSave,
  onDismiss,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const handleSubmit = () => {
    if (title.trim().length === 0) {
      setErrorMsg('Title cannot be empty');
      return;
    }
    onSave({
      ...itinerary,
      title,
    });
  };
  return (
    <div className={classes.container}>
      <Header
        title="Save as Template"
        Icon={ReviveIcon}
        onDismiss={onDismiss}
      />
      <div className={classes.body}>
        <LabelInput
          label="Title"
          placeholder="Ex. maldives-obli-3N4D-full-board-water-sports"
          inputClass={classes.input}
          inputProps={{
            multiline: true,
          }}
          value={title}
          onChange={(e) => setTitle(e)}
        />
      </div>
      <Footer errorMsg={errorMsg}>
        <Button
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: 'column',
    minWidth: 620,
  },
  body: {
    padding: 40,
    flex: 1,
    flexDirection: 'column',
  },
  input: {
    height: 64,
  },
});

ItineraryTemplate.propTypes = {
  itinerary: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default ItineraryTemplate;
