import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withStyles from '@material-ui/styles/withStyles';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import DayPicker, { DateUtils } from 'react-day-picker';
import './calendar.module.css';

class DateRangeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getFirstDates(),
      isVisible: false,
    };
    this.injectStyle();
  }

  componentDidUpdate = (prevProps) => {
    const { dates } = this.props;
    const { dates: oldDates } = prevProps;
    if (dates && oldDates && (!moment(dates.from).isSame(oldDates.from)
      || !moment(dates.to).isSame(oldDates.to))
      && dates.to && dates.from) {
      this.setState({
        from: moment(dates.from).toDate(),
        to: moment(dates.to).toDate(),
        enteredTo: moment(dates.to).toDate(),
      });
    }
  };

  injectStyle = () => {
    const { theme } = this.props;
    const style = document.getElementById('dynamicStyle');
    if (!style) {
      const styleTag = document.createElement('style');
      styleTag.id = 'dynamicStyle';
      styleTag.innerHTML = `
.Range .DayPicker-Day--selected:not(.DayPicker-Day--start)
:not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: ${theme.colors.primaryLight};
  color: ${theme.colors.primary};
}
.Range .DayPicker-Day {
  border-radius: 0 !important;
}
.Range .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  color: #fff !important;
  background-color: #2C7A77 !important;
}
.Range .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  color: #fff !important;
  background-color: #2C7A77 !important;
}`;
      document.getElementsByTagName('head')[0].appendChild(styleTag);
    }
  };

  getFirstDates = () => {
    const { dates } = this.props;
    if (dates && dates.from && dates.to) {
      return {
        enteredTo: dates.to,
        from: dates.from,
        to: dates.to,
      };
    }
    return this.getInitialState();
  };

  getInitialState = () => {
    return {
      from: undefined,
      to: undefined,
      enteredTo: null,
    };
  };

  onFocusHandler = () => {
    this.setState({ isVisible: true });
  };

  onBlurHandler = () => {
    this.setState({ isVisible: false });
  };

  handleResetClick = () => {
    this.setState(this.getInitialState());
  };

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && day && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  handleDayClick = (day) => {
    const { allowBefore, shouldDisableDate } = this.props;
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      if (!allowBefore && Date.now() >= moment(day).unix() * 1000) {
        return;
      }
      if (shouldDisableDate && shouldDisableDate(day)) {
        return;
      }
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      }, this.getDateStr);
    } else {
      if (shouldDisableDate && shouldDisableDate(day)) {
        return;
      }
      this.setState({
        to: day,
        enteredTo: day,
      }, this.getDateStr);
    }
  };

  getDateStr = (dismiss) => {
    const { onSelected } = this.props;
    const { from, to } = this.state;
    let dispStr = '';
    if (from && to) {
      dispStr = `${moment(from).format('DD MMM YY')} - ${moment(to).format('DD MMM YY')}`;
      onSelected({
        from,
        to,
      }, dispStr);
    }
    if (dismiss) {
      this.setState({ isVisible: false });
    }
  };

  handleDayMouseEnter = (day) => {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  };

  dismissHandler = () => {
    this.setState({ isVisible: false, ...this.getInitialState() });
  };

  render() {
    const {
      classes,
      numberOfMonths = 2,
      value,
      extraClass,
      popupClass,
      placeholder,
      allowBefore,
      disableFromDate,
      shouldDisableDate,
      dates = {},
    } = this.props;
    const { from, enteredTo, isVisible } = this.state;
    const Icon = isVisible ? KeyboardArrowUp : KeyboardArrowDown;
    const modifiers = {
      start: from,
      end: enteredTo,
    };
    let before = new Date();
    if (from) {
      before = from;
    }
    if (disableFromDate) {
      before = disableFromDate;
    }
    if (allowBefore) {
      before = undefined;
    }
    const disabledDays = shouldDisableDate || { before };
    const selectedDays = [from, {
      from,
      to: enteredTo,
    }];
    return (
      <div className={clsx(classes.container, extraClass)}>
        <ClickAwayListener
          onClickAway={this.onBlurHandler}
        >
          <div className={classes.root}>
            <InputBase
              value={value}
              placeholder={placeholder}
              classes={{
                root: classes.inputRoot,
                input: classes.input,
              }}
              onFocus={this.onFocusHandler}
              endAdornment={(
                <IconButton
                  className={classes.iconBtn}
                  onClick={this.onFocusHandler}
                >
                  <Icon />
                </IconButton>
              )}
            />
            {isVisible ? (
              <div className={clsx(classes.paper, popupClass)}>
                <DayPicker
                  className="Range"
                  initialMonth={from || dates.from}
                  numberOfMonths={numberOfMonths}
                  fromMonth={from}
                  selectedDays={selectedDays}
                  disabledDays={disabledDays}
                  modifiers={modifiers}
                  onDayClick={this.handleDayClick}
                  onDayMouseEnter={this.handleDayMouseEnter}
                />
                <div className={classes.footer}>
                  <button
                    type="button"
                    className={classes.dismiss}
                    onClick={this.dismissHandler}
                  >
                    Dismiss
                  </button>
                  <button
                    type="button"
                    className={classes.apply}
                    onClick={() => this.getDateStr(true)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: 40,
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: '10px 5px 10px 15px',
    border: `1px solid ${theme.colors.border}`,
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.colors.white,
    boxShadow: '0 4px 8px 1px rgba(0,0,0,0.1)',
    zIndex: 1301,
    width: 490,
    borderRadius: 4,
    padding: 15,
    top: 32,
    left: -20,
  },
  inputRoot: {
    width: '100%',
    height: '100%',
  },
  input: {
    padding: 0,
    height: '100%',
    fontSize: 16,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  dismiss: {
    width: 90,
    borderRadius: 17.5,
    border: `1px solid ${theme.colors.border}`,
    textTransform: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '5px 10px',
    marginRight: 10,
    height: 32,
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  apply: {
    width: 90,
    borderRadius: 17.5,
    border: `1px solid ${theme.colors.primary}`,
    textTransform: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '5px 10px',
    height: 32,
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
    },
  },
  iconBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

DateRangeSelection.propTypes = {
  onSelected: PropTypes.func,
  classes: PropTypes.object,
  theme: PropTypes.object,
  value: PropTypes.string,
  extraClass: PropTypes.string,
  popupClass: PropTypes.string,
  placeholder: PropTypes.string,
  numberOfMonths: PropTypes.number,
  dates: PropTypes.object,
  allowBefore: PropTypes.bool,
  disableFromDate: PropTypes.object,
  shouldDisableDate: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(DateRangeSelection);
