import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';

const DotLine = ({
  isLast, dotClass,
  lineClass, DotIcon,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.lineContainer, isLast && classes.lastDot, lineClass)}>
      {DotIcon ? <DotIcon className={clsx(classes.icon, lineClass)} />
        : <div className={clsx(classes.dot, dotClass)} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  lineContainer: {
    width: 1,
    marginRight: 10,
    position: 'relative',
    backgroundColor: theme.colors.primarySelected_1,
  },
  lastDot: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  dot: {
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    left: -5,
    backgroundColor: theme.colors.primary,
    border: `2px solid ${theme.colors.primary}`,
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  icon: {
    boxSizing: 'border-box',
    position: 'absolute',
    top: -3,
    left: -10,
    width: 20,
    height: 20,
    color: theme.colors.primary,
  },
}));

DotLine.propTypes = {
  isLast: PropTypes.bool,
  dotClass: PropTypes.string,
  lineClass: PropTypes.string,
  DotIcon: PropTypes.object,
};

export default DotLine;
