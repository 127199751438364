import React from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import DiffInput from './diffInput';

const SupplierDiff = ({ data, oldData }) => {
  const classes = useStyles();
  const newLen = data.length;
  const oldLen = oldData.length;
  const renderRow = (item, oldItem) => {
    const newConversionPrice = item?.costPrice
      ? ((item.costPrice * item.conversionRate) / 100).toFixed(2) : '-';
    const oldConversionPrice = oldItem?.costPrice
      ? ((oldItem.costPrice * oldItem.conversionRate) / 100).toFixed(2) : '-';
    const quotationSupplier = item?.supplier || oldItem?.supplier;
    const quotationLabel = `${quotationSupplier.name}'s quotation`;
    return (
      <div
        className={classes.content}
        key={item?.supplier._id || oldItem?.supplier._id}
      >
        <div
          className={classes.row}
        >
          <DiffInput
            label="Cost price"
            value={item?.costPrice || '-'}
            oldValue={oldItem?.costPrice || '-'}
            extraClass={classes.itemClass}
          />
          <DiffInput
            label="Supplier currency"
            value={item?.currency.code || '-'}
            oldValue={oldItem?.currency.code || '-'}
            extraClass={classes.middleItem}
          />
          <DiffInput
            label="Cost price (INR)"
            value={newConversionPrice || 0}
            oldValue={oldConversionPrice || 0}
            extraClass={classes.itemClass}
          />
        </div>
        <div className={classes.row}>
          <DiffInput
            label={quotationLabel}
            value={item?.quotation || '-'}
            oldValue={oldItem?.quotation || '-'}
            extraClass={classes.itemClass}
          />
        </div>
      </div>
    );
  };
  const diffSuppliers = [];
  for (let i = 0; i < data.length; i++) {
    diffSuppliers.push(renderRow(data[i], oldData[i]));
  }
  if (oldLen > newLen) {
    for (let i = newLen; i < oldLen; i++) {
      diffSuppliers.push(renderRow(undefined, oldData[i]));
    }
  }
  const newSuppliers = data?.length ? data.map((s) => s.supplier.name).join(', ') : '-';
  const oldSuppliers = oldData?.length ? oldData.map((s) => s.supplier.name).join(', ') : '-';
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DiffInput
          label="SUPPLIERS"
          value={newSuppliers}
          oldValue={oldSuppliers}
          extraClass={classes.itemClass}
        />
      </div>
      {diffSuppliers}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  content: {
    display: 'felx',
    flexDirection: 'column',
    marginBottom: 16,
    borderBottom: `1px solid ${theme.colors.border}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
    width: '100%',
  },
  itemClass: {
    flex: 1,
  },
  middleItem: {
    flex: 1,
    margin: '0 10px',
  },
  spacing: {
    height: 1,
    width: 34,
  },
}));

SupplierDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default SupplierDiff;
