import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import withStyles from '@material-ui/styles/withStyles';
import Button from 'UI/button';
import pdfImg from 'assets/svg/pdf.svg';
import jpegImg from 'assets/svg/jpeg.svg';
import pngImg from 'assets/svg/png.svg';
import Footer from 'UI/footer';
import LabelInput from 'UI/labelInput';
import { getFileExtension, uuid } from 'utils/common';

class PanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
    };
    this.docTypes = {
      'application/pdf': pdfImg,
      'image/png': pngImg,
      'image/jpeg': jpegImg,
      'image/jpg': jpegImg,
    };
    this.typeNameMap = {
      PASSPORT_FRONT: 'Passport front',
      PASSPORT_BACK: 'Passport back',
      PAN_CARD: 'Pan card',
    };
    const { requirement, travellerDetails, onUpdate } = this.props;
    if (Object.keys(travellerDetails).length === 0
      || requirement.travellers.adults !== travellerDetails.adults.length
      || requirement.travellers.children !== travellerDetails.children.length
      || requirement.travellers.infants !== travellerDetails.infants.length) {
      onUpdate('travellerDetails', this.getEmptyDetails());
    }
  }

  getEmptyDetails = () => {
    const { requirement } = this.props;
    return {
      adults: new Array(requirement.travellers.adults)
        .fill(true).map(() => this.getDetailTemplate(true)),
      children: new Array(requirement.travellers.children)
        .fill(true).map(() => this.getDetailTemplate()),
      infants: new Array(requirement.travellers.infants)
        .fill(true).map(() => this.getDetailTemplate()),
    };
  };

  getDetailTemplate = (adult) => {
    const template = {
      name: '',
      id: uuid(),
      documents: [{
        documentType: 'PASSPORT_FRONT',
        document: {},
      }, {
        documentType: 'PASSPORT_BACK',
        document: {},
      }],
    };
    if (adult) {
      template.documents.push({
        documentType: 'PAN_CARD',
        document: {},
      });
    }
    return template;
  };

  handleInputUpload = (key, index, docIndex, e) => {
    const { onUpdate, travellerDetails } = this.props;
    if (e.target.files[0]) {
      const { type: fileType } = e.target.files[0];
      if (fileType && Object.keys(this.docTypes).some((d) => d === fileType)) {
        const modDetails = { ...travellerDetails };
        modDetails[key][index].documents[docIndex].document = e.target.files[0];
        onUpdate('travellerDetails', modDetails);
      }
    }
  };

  handleDocumentInfoChange = (key, index, docIndex, property, value) => {
    const { onUpdate, travellerDetails } = this.props;
    const modDetails = { ...travellerDetails };
    if (/PASSPORT_(BACK|FRONT)/.test(modDetails[key][index].documents[docIndex].documentType)) {
      modDetails[key][index].documents.forEach((element, i) => {
        if (/PASSPORT_(BACK|FRONT)/.test(element.documentType)) {
          modDetails[key][index].documents[i][property] = value;
        }
      });
    } else {
      modDetails[key][index].documents[docIndex][property] = value.toUpperCase();
    }
    onUpdate('travellerDetails', modDetails);
  };

  handleChange = (key, index, type, value) => {
    const { onUpdate, travellerDetails } = this.props;
    const modDetails = { ...travellerDetails };
    modDetails[key][index][type] = value;
    onUpdate('travellerDetails', modDetails);
  };

  removeHandler = (ev, key, index, docIndex) => {
    const { onUpdate, travellerDetails } = this.props;
    ev.preventDefault();
    ev.stopPropagation();
    const modDetails = { ...travellerDetails };
    modDetails[key][index].documents[docIndex].document = '';
    onUpdate('travellerDetails', modDetails);
  };

  toDataURL = (url) => {
    return fetch(url).then((response) => {
      return response.blob();
    }).then((blob) => {
      return URL.createObjectURL(blob);
    });
  };

  documentPreviewHandler = async (evt, document, download) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (document.link || document.originalUrl) {
      if (download) {
        try {
          const link = window.document.createElement('a');
          link.href = await this.toDataURL(document.link || document.originalUrl);
          link.download = document.fileName || 'Download';
          window.document.body.appendChild(link);
          link.click();
          window.document.body.removeChild(link);
          return;
        } catch (ex) {
          console.error(ex);
          window.open(document.link || document.originalUrl, '_blank');
        }
      } else {
        window.open(document.link || document.originalUrl, '_blank');
      }
    }
  };

  validateData = () => {
    let valid = true;
    let errorMsg = '';
    const key = 'adults';
    const index = 0;
    const {
      travellerDetails,
    } = this.props;
    const traveller = travellerDetails[key][index];
    if (!traveller || !traveller.documents) return null;
    const docIndex = traveller.documents.findIndex((doc) => doc.documentType === 'PAN_CARD');
    const document = traveller.documents[docIndex];
    if (document?.documentNumber && !(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(document.documentNumber))) {
      valid = false;
      errorMsg = 'Pan number has invalid format';
    }
    return {
      valid,
      errorMsg,
    };
  }

  nextHandler = (...args) => {
    const { valid, errorMsg } = this.validateData();
    if (!valid) {
      this.setState({ errorMsg });
      return;
    }
    const { nextHandler: onNext } = this.props;
    onNext(...args);
  }

  renderDocument = (key, traveller, index, docIndex) => {
    const { classes } = this.props;
    let displayName = '';
    if (traveller.documents[docIndex].document?.name) {
      displayName = traveller.documents[docIndex].document.name;
    } else if (traveller.documents[docIndex].document?.fileName) {
      displayName = traveller.documents[docIndex].document.fileName;
    }
    if (displayName) {
      const imageType = this.docTypes[getFileExtension(displayName).contentType];
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <a
          className={classes.document}
          onClick={(e) => this.documentPreviewHandler(e, traveller.documents[docIndex].document, false)}
          href={traveller.documents[docIndex].document?.link}
          target="_blank"
          rel="noreferrer"
        >
          <img src={imageType} alt={imageType} className={classes.docImage} />
          <Typography
            className={classes.documentName}
          >
            {displayName}
          </Typography>
          {
            traveller.documents[docIndex].document?.link && (
              <IconButton
                className={classes.deleteButton}
                onClick={(e) => this.documentPreviewHandler(e, traveller.documents[docIndex].document, true)}
              >
                <GetAppIcon className={classes.icon} />
              </IconButton>
            )
          }
          <IconButton
            className={classes.deleteButton}
            onClick={(ev) => this.removeHandler(ev, key, index, docIndex)}
          >
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </a>
      );
    }
    return null;
  };

  render() {
    const {
      classes, travellerDetails,
    } = this.props;
    const {
      errorMsg,
    } = this.state;
    const key = 'adults';
    const index = 0;
    const traveller = travellerDetails[key][index];
    if (!traveller || !traveller.documents) return null;
    const docIndex = traveller.documents.findIndex((doc) => doc.documentType === 'PAN_CARD');
    const document = traveller.documents[docIndex];
    if (!document) return null;
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <Typography
            className={classes.travellerLabel}
          >
            Enter details for primary traveller
          </Typography>
          <div
            className={classes.nameContainer}
          >
            <div
              className={classes.detailsSection}
            >
              <div className={classes.row}>
                <Typography
                  className={classes.index}
                >
                  {`${index + 1} :`}
                </Typography>
                <LabelInput
                  label="Full name (as per documents)"
                  value={traveller.name}
                  extraClass={classes.fullName}
                  onChange={(val) => this.handleChange(key, index, 'name', val)}
                />
              </div>
              <div className={clsx(classes.row, classes.documentRow)}>
                <div className={clsx(classes.row, classes.flexCol)}>
                  <div
                    className={classes.uploadSection}
                    key={document.documentType}
                  >
                    <input
                      type="file"
                      accept="application/pdf,image/png,image/jpeg"
                      onChange={(e) => this.handleInputUpload(key, index, docIndex, e)}
                      className={classes.uploadButton}
                    />
                    <Typography className={classes.uploadText}>
                      {this.typeNameMap[document.documentType]}
                    </Typography>
                    {this.renderDocument(key, traveller, index, docIndex)}
                  </div>
                  <div>
                    <LabelInput
                      label="Pan card number"
                      value={document.documentNumber}
                      extraClass={classes.w160}
                      onChange={(val) => this.handleDocumentInfoChange(key, index, docIndex, 'documentNumber', val)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer errorMsg={errorMsg}>
          <Button
            className={classes.createButton}
            onClick={this.nextHandler}
          >
            Save & continue
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    padding: '20px 40px',
    flex: 1,
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
  spacing: {
    height: 20,
    padding: 10,
    width: 1,
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  documentRow: {
    marginLeft: 40,
  },
  travellerLabel: {
    fontSize: 14,
    marginBottom: 10,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  subHeading: {
    fontSize: 12,
    marginBottom: 10,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  index: {
    width: 32,
    marginRight: 10,
    fontSize: 12,
    color: theme.colors.textLight,
    fontWeight: 'bold',
  },
  fullName: {
    width: '100%',
  },
  uploadSection: {
    cursor: 'pointer',
    borderRadius: 4,
    marginRight: 10,
    width: 160,
    height: 80,
    position: 'relative',
    backgroundColor: theme.colors.primarySelected,
    padding: '5px 10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  uploadButton: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  uploadText: {
    fontWeight: 'bold',
    letterSpacing: 0.5,
    fontSize: 12,
    color: theme.colors.primaryBackground,
  },
  document: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 6,
    position: 'absolute',
    width: '90%',
    marginTop: 20,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
    backgroundColor: theme.colors.white,
  },
  flexCol: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  docImage: {
    width: 16,
    padding: 4,
    border: `2px solid ${theme.colors.border}`,
    borderRadius: 4,
    height: 26,
  },
  documentName: {
    fontSize: 12,
    padding: '0 6px',
    color: theme.colors.textDark,
    maxWidth: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    width: 16,
    height: 16,
  },
  deleteButton: {
    padding: 4,
    marginLeft: 'auto',
  },
  createButton: {
    width: 180,
    color: theme.colors.white,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
    marginLeft: 10,
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  w160: {
    width: 160,
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
});

PanDetails.propTypes = {
  classes: PropTypes.object,
  requirement: PropTypes.object.isRequired,
  travellerDetails: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
};

export default withStyles(styles)(PanDetails);
