import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/styles/makeStyles';

const Confirmation = ({
  heading,
  description,
  onConfirm,
  onReject,
  onDismiss,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.iconContainer}>
          <ErrorIcon className={classes.icon} />
        </div>
        <Typography className={classes.headingText}>{heading}</Typography>
        <IconButton
          className={classes.closeButton}
          onClick={onDismiss}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Typography>
        {description}
      </Typography>
      <div className={classes.footer}>
        <Button
          onClick={onConfirm}
          className={classes.confirmButton}
        >
          Yes
        </Button>
        <Button
          onClick={onReject}
          className={classes.dismissButton}
        >
          No
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    minWidth: 460,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  headingText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.black,
    paddingLeft: 10,
  },
  iconContainer: {
    color: theme.colors.red,
    backgroundColor: theme.colors.redLight,
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 18,
    height: 18,
    fontSize: 14,
  },
  description: {
    color: theme.colors.black,
    fontSize: 16,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
  },
  dismissButton: {
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBackground,
    // border: `1px solid ${theme.colors.primary}`,
    width: 120,
    borderRadius: 25,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    fontSize: 18,
    marginLeft: 16,
    padding: '5px 10px',
    minHeight: '42px',
    height: '42px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  confirmButton: {
    color: theme.colors.primary,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.primary}`,
    width: 120,
    borderRadius: 25,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    fontSize: 18,
    padding: '5px 10px',
    minHeight: '42px',
    height: '42px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  closeButton: {
    padding: 6,
    marginLeft: 'auto',
  },
}));

Confirmation.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  onDismiss: PropTypes.func.isRequired,
};

export default Confirmation;
