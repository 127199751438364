import React from 'react';
import * as PropTypes from 'prop-types';

const CancelBooking = ({
  width = 30,
  height = 30,
  className,
  fill = '#DFF2F1',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <g id="headings/ic_CANCEL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon" fill={fill}>
          <circle id="Oval" cx="15" cy="15" r="15" />
        </g>
        <g id="noun_Remove_140544" transform="translate(8.000000, 7.000000)" fill="#2C7A77" fillRule="nonzero">
          <g id="Group">
            <path
              d="M3.23076923,6.4375 L9.69230769,6.4375 L9.69230769,7.5625 L3.23076923,7.5625 L3.23076923,6.4375 Z M3.23076923,8.6875 L7.53846154,8.6875 L7.53846154,9.8125 L3.23076923,9.8125 L3.23076923,8.6875 Z M3.23076923,4.1875 L7.53846154,4.1875 L7.53846154,5.3125 L3.23076923,5.3125 L3.23076923,4.1875 Z M11.3076923,10.375 C12.7946128,10.375 14,11.6341992 14,13.1875 C14,14.7408008 12.7946128,16 11.3076923,16 C9.82077185,16 8.61538462,14.7408008 8.61538462,13.1875 C8.61538462,11.6341992 9.82077185,10.375 11.3076923,10.375 Z M9.69230769,12.625 L9.69230769,13.75 L12.9230769,13.75 L12.9230769,12.625 L9.69230769,12.625 Z M12.9230769,0.5625 C12.9230769,0 12.3842414,0 12.3842414,0 L0.53883557,0 C0.53883557,0 0,0 0,0.5625 L0,15.4375 C0,16 0.53883557,16 0.53883557,16 L9.52118725,16 C9.10651905,15.7119837 8.75919326,15.3265608 8.50914249,14.875 L1.07692308,14.875 L1.07692308,1.125 L11.8461538,1.125 L11.8461538,9.8591641 C12.232728,9.9269143 12.5960239,10.066379 12.9230769,10.2640149 L12.9230769,0.5625 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

CancelBooking.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default CancelBooking;
