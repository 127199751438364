import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import CreditCard from '@material-ui/icons/CreditCard';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import InputBase from '@material-ui/core/InputBase';
import Button from 'UI/button';
import Footer from 'UI/footer';
import Header from 'UI/header';
import Checkbox from 'UI/checkbox';
import Autocomplete from 'UI/autocomplete';
import LabelInput from 'UI/labelInput';
import DateButton from 'UI/dateButton';
import addIcon from 'assets/svg/create.svg';
import DocumentCards from 'UI/documentCards';
import Confirmation from 'UI/confirmation';
import { colors } from 'UI/styleConsts';

const validTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
const paymentModes = [{ name: 'BANK TRANSFER', id: 'BANK_TRANSFER' }, { name: 'CREDIT CARD', id: 'CREDIT_CARD' }]
const TransactionPayment = ({
  onDismiss,
  onSubmit,
  loading,
  booking,
  supplier,
  bankDetails,
  balance,
  suggestions,
  // getSuggestions,
}) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);
  const [currency, setCurrency] = useState({
    value: supplier.currency.code,
    valid: true,
    item: supplier.currency,
  });
  const [invoices, setInvoices] = useState([]);
  const [conversionRate, setConversionRate] = useState(1);
  const [amountINR, setAmountINR] = useState('');
  const [bankCharges, setBankCharges] = useState(0);
  const dropInput = useRef();
  const supplierCP = (booking.costPrice || supplier.costPrice || 0) / 100;
  const currencyCode = supplier.currency.code;
  const supplierName = supplier.supplier.name;
  const isINRCurrency = supplier.currency.code === 'INR';
  const handleAmountChange = (value) => {
    setAmount(Number(value));
    setAmountINR(Number(value) * conversionRate);
  }
  const handleConversionChange = (value) => {
    setAmountINR(Number(value * amount).toFixed(2));
    setConversionRate(Number(value));
  }
  const handleINRChange = (value) => {
    setAmountINR(Number(value));
    setConversionRate(Number(value / amount).toFixed(2));
  }
  const submit = () => {
    let finalConversionRate = conversionRate * 100;
    if (isINRCurrency) {
      finalConversionRate = 100;
    }
    onSubmit({
      amount: Number(amount * 100),
      bankCharges: Number(bankCharges * 100),
      conversionRate: finalConversionRate,
      invoices,
      currencyId: currency.item._id,
      makePayment: true,
    });
    onDismiss();
  }
  const handlePaymentRecord = () => {
    setAlertVisible(true);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const modInvoices = [...invoices];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { type } = file;
        if (type && validTypes.some((v) => v === type)) {
          modInvoices.push(file);
        }
      }
      setInvoices(modInvoices);
      e.dataTransfer.clearData();
    }
  };

  const removeFile = (index) => {
    const modInvoices = [...invoices];
    modInvoices.splice(index, 1);
    setInvoices(modInvoices);
  };

  const handleUpload = (e) => {
    const params = {
      preventDefault: () => { },
      stopPropagation: () => { },
      dataTransfer: {
        files: e.target.files,
        clearData: () => { },
      },
    };
    handleDrop(params);
    dropInput.current.value = '';
  };
  return (
    <div className={classes.container}>
      <Header
        onDismiss={onDismiss}
        title="Make Supplier Payment"
        Icon={CreditCard}
      />
      <div className={classes.body}>
        <Typography variant="body1" className={classes.supplierName}>
          {supplierName}
        </Typography>
        <Typography component="span" className={classes.row}>
          Supplier Cost price:
          <Typography className={classes.supplierInfo}>
            {`${currencyCode} ${supplierCP}`}
          </Typography>
        </Typography>
        {
          bankDetails ? (
            <>
              <Typography component="span" className={classes.row}>
                Supplier Bank Account Holder:
                <Typography className={classes.supplierInfo}>
                  {bankDetails?.accountHolderName}
                </Typography>
              </Typography>
              <Typography component="span" className={classes.row}>
                Supplier Bank Account no:
                <Typography className={classes.supplierInfo}>
                  {bankDetails?.accountNumber}
                </Typography>
              </Typography>
              <Typography component="span" className={classes.row}>
                Supplier Bank IFSC code:
                <Typography className={classes.supplierInfo}>
                  {bankDetails?.ifscCode}
                </Typography>
              </Typography>
              <Typography component="span" className={classes.row}>
                Supplier Bank Account state:
                <Typography className={classes.supplierInfo}>
                  {bankDetails?.state}
                </Typography>
              </Typography>
            </>
          ) : null
        }
        <Typography component="span" className={classes.row}>
          Balance:
          <Typography className={classes.supplierInfo}>
            {Number((balance || 0) / 100).toFixed(2)}
          </Typography>
        </Typography>
        <div className={classes.autoCompleteWrapper}>
          <Typography
            className={classes.label}
          >
            Amount
          </Typography>
          <div className={classes.toSection}>
            <Autocomplete
              extraClass={classes.toSectionAutocomplete}
              inputClass={classes.addOnInput}
              value={currency.value}
              disabled
              accessor="code"
              variant="no-outline"
              placeholder="Currency"
              popupClass={classes.popupClass}
              onSelected={(item) => setCurrency({
                valid: true,
                value: item.code,
                item,
              })}
            />
            <InputBase
              autoFocus
              placeholder="Amount"
              value={amount}
              type="number"
              onChange={(e) => handleAmountChange(Number(e.target.value))}
              classes={{
                root: classes.inputArea,
              }}
            />
          </div>
        </div>
        {!isINRCurrency ? (
          <div className={classes.autoCompleteWrapper}>
            <Typography
              className={classes.label}
            >
              Conversion Rate
            </Typography>
            <div className={classes.toSection}>
              <InputBase
                autoFocus
                placeholder="Conversion Rate"
                value={conversionRate}
                type="number"
                onChange={(e) => handleConversionChange(e.target.value)}
                classes={{
                  root: classes.inputArea,
                }}
              />
            </div>
          </div>
        ) : null}
        {!isINRCurrency ? (
          <div className={classes.autoCompleteWrapper}>
            <Typography
              className={classes.label}
            >
              Amount paid in INR
            </Typography>
            <div className={classes.toSection}>
              <Autocomplete
                extraClass={classes.toSectionAutocomplete}
                inputClass={classes.addOnInput}
                data={suggestions.currencies}
                disabled
                value="INR"
                accessor="code"
                variant="no-outline"
                placeholder="INR"
                popupClass={classes.popupClass}
                onChange={() => { }}
                onSelected={() => { }}
              />
              <InputBase
                autoFocus
                placeholder="Amount"
                value={amountINR}
                type="number"
                onChange={(e) => handleINRChange(e.target.value)}
                classes={{
                  root: classes.inputArea,
                }}
              />
            </div>
          </div>
        ) : null}
        {!isINRCurrency ? (
          <div className={classes.autoCompleteWrapper}>
            <Typography
              className={classes.label}
            >
              Total Bank Charges in INR
            </Typography>
            <div className={classes.toSection}>
              <Autocomplete
                extraClass={classes.toSectionAutocomplete}
                inputClass={classes.addOnInput}
                data={suggestions.currencies}
                value="INR"
                disabled
                accessor="code"
                variant="no-outline"
                placeholder="INR"
                popupClass={classes.popupClass}
                onSelected={(item) => setCurrency({
                  valid: true,
                  value: item.code,
                  item,
                })}
              />
              <InputBase
                autoFocus
                placeholder="Bank Charges"
                value={bankCharges}
                type="number"
                onChange={(e) => setBankCharges(Number(e.target.value))}
                classes={{
                  root: classes.inputArea,
                }}
              />
            </div>
          </div>
        ) : null}
        <div className={classes.dropArea}>
          <input
            type="file"
            accept="application/pdf,image/png,image/jpeg"
            multiple="multiple"
            ref={dropInput}
            className={classes.dropBox}
            onChange={(e) => handleUpload(e)}
          />
          <Typography className={classes.dropText}>Drag & drop anywhere</Typography>
          <Typography className={classes.dropMsgOR}>
            or
          </Typography>
          <Typography className={classes.dropMsgAdd}>
            <img src={addIcon} alt="+" className={classes.addIcon} />
            &nbsp; Add documents
          </Typography>
          <div className={classes.documentWrapper}>
            <DocumentCards
              documents={invoices}
              onRemove={removeFile}
            />
          </div>
        </div>
      </div>
      <Dialog onClose={() => setAlertVisible(false)} open={alertVisible}>
        <Confirmation
          heading="Are you sure you want to continue ?"
          description={`${Number(amount)} ${currencyCode} will be transferred`}
          onConfirm={submit}
          onReject={() => setAlertVisible(false)}
          onDismiss={() => setAlertVisible(false)}
        />
      </Dialog>
      <Footer>
        <Button
          className={classes.updateBtn}
          onClick={handlePaymentRecord}
          variant="normal"
          loading={loading}
        >
          Save
        </Button>
      </Footer>
    </div>
  );
};
TransactionPayment.propTypes = {
  // createPayment: PropTypes.bool,
  suggestions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  booking: PropTypes.object.isRequired,
  supplier: PropTypes.object.isRequired,
  balance: PropTypes.object.isRequired,
  bankDetails: PropTypes.object,
};

const TransactionBooking = ({
  onSubmit,
  onDismiss,
  supplier = {},
  suggestions,
  getSuggestions,
  loading,
  booking,
  bookingOb,
  balance,
  createPayment,
}) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);
  const [bankCharges, setBankCharges] = useState(0);
  const [conversionRate, setConversionRate] = useState(1);
  const [currency, setCurrency] = useState({
    value: supplier.currency.code,
    valid: true,
    item: supplier.currency,
  });
  const [invoices, setInvoices] = useState([]);
  const dropInput = useRef();
  const isINRCurrency = supplier.currency.code === 'INR';

  const [txnDate, setDate] = useState(new Date());
  const [paymentMode, setPaymentMode] = useState('');
  const [txnRefNo, setTxnRefNo] = useState('');
  const [amountINR, setAmountINR] = useState('');
  const [multipleUsIdPayment, setMultipleUsIdPayment] = useState(false);
  const supplierCP = (booking?.costPrice || supplier.costPrice || 0) / 100;
  const currencyCode = supplier.currency.code;
  const supplierName = supplier.supplier.name;
  const submit = () => {
    let finalConversionRate = conversionRate * 100;
    if (isINRCurrency) {
      finalConversionRate = 100;
    }
    onSubmit({
      multipleUsIdPayment,
      amount: Number(amount * 100),
      bankCharges: Number(bankCharges * 100),
      currencyId: currency.item._id,
      txnDate,
      txnRefNo,
      paymentMode,
      invoices,
      conversionRate: finalConversionRate,
    });
    onDismiss();
  };
  const handlePaymentRecord = () => {
    setAlertVisible(true);
  }
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const modInvoices = [...invoices];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { type } = file;
        if (type && validTypes.some((v) => v === type)) {
          modInvoices.push(file);
        }
      }
      setInvoices(modInvoices);
      e.dataTransfer.clearData();
    }
  };

  const removeFile = (index) => {
    const modInvoices = [...invoices];
    modInvoices.splice(index, 1);
    setInvoices(modInvoices);
  };

  const handleUpload = (e) => {
    const params = {
      preventDefault: () => { },
      stopPropagation: () => { },
      dataTransfer: {
        files: e.target.files,
        clearData: () => { },
      },
    };
    handleDrop(params);
    dropInput.current.value = '';
  };

  const handleCurrencyChange = (value) => {
    getSuggestions('currencies', value);
    setCurrency({
      value,
      valid: false,
      item: {},
    });
  };
  const handleAmountChange = (value) => {
    setAmount(Number(value));
    setAmountINR(Number(value) * conversionRate);
  }
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  }
  const handleINRChange = (value) => {
    setAmountINR(Number(value));
    setConversionRate(Number(value / amount).toFixed(2));
  };
  const handleConversionChange = (value) => {
    setAmountINR(Number(value * amount).toFixed(2));
    setConversionRate(Number(value));
  }
  if (createPayment) {
    return (
      <TransactionPayment
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        supplier={supplier}
        booking={booking}
        suggestions={suggestions}
        getSuggestions={getSuggestions}
        balance={balance}
        loading={loading}
        bankDetails={bookingOb?.supplierBankDetails}
      />
    );
  }
  return (
    <div className={classes.container}>
      <Header
        onDismiss={onDismiss}
        title="Add Supplier Transaction Record"
        Icon={CreditCard}
      />
      <div className={classes.body}>
        <Typography variant="body1" className={classes.supplierName}>
          {supplierName}
        </Typography>
        <Typography component="span" className={classes.row}>
          Supplier Cost price:
          <Typography className={classes.supplierInfo}>
            {`${currencyCode} ${supplierCP}`}
          </Typography>
        </Typography>
        <div className={classes.autoCompleteWrapper}>
          <Typography
            className={classes.label}
          >
            Amount
          </Typography>
          <div className={classes.toSection}>
            <Autocomplete
              extraClass={classes.toSectionAutocomplete}
              inputClass={classes.addOnInput}
              data={suggestions.currencies}
              value={currency.value}
              disabled
              accessor="code"
              variant="no-outline"
              placeholder="Currency"
              popupClass={classes.popupClass}
              onChange={handleCurrencyChange}
              onSelected={(item) => setCurrency({
                valid: true,
                value: item.code,
                item,
              })}
            />
            <InputBase
              autoFocus
              placeholder="Amount"
              value={amount}
              type="number"
              onChange={(e) => handleAmountChange(Number(e.target.value))}
              classes={{
                root: classes.inputArea,
              }}
            />
          </div>
        </div>
        {!isINRCurrency ? (
          <div className={classes.autoCompleteWrapper}>
            <Typography
              className={classes.label}
            >
              Conversion Rate
            </Typography>
            <div className={classes.toSection}>
              <InputBase
                autoFocus
                placeholder="Conversion Rate"
                value={conversionRate}
                type="number"
                onChange={(e) => handleConversionChange(e.target.value)}
                classes={{
                  root: classes.inputArea,
                }}
              />
            </div>
          </div>
        ) : null}
        {!isINRCurrency ? (
          <div className={classes.autoCompleteWrapper}>
            <Typography
              className={classes.label}
            >
              Amount paid in INR
            </Typography>
            <div className={classes.toSection}>
              <Autocomplete
                extraClass={classes.toSectionAutocomplete}
                inputClass={classes.addOnInput}
                data={suggestions.currencies}
                disabled
                value="INR"
                accessor="code"
                variant="no-outline"
                placeholder="INR"
                popupClass={classes.popupClass}
                onChange={() => { }}
                onSelected={() => { }}
              />
              <InputBase
                autoFocus
                placeholder="Amount"
                value={amountINR}
                type="number"
                onChange={(e) => handleINRChange(e.target.value)}
                classes={{
                  root: classes.inputArea,
                }}
              />
            </div>
          </div>
        ) : null}
        {/* <LabelInput
          label="Conversion Rate"
          placeholder="Conversion rate"
          value={conversionRate}
          onChange={(val) => setConversionRate(Number(val))}
        /> */}
        <div className={classes.autoCompleteWrapper}>
          <Typography
            className={classes.label}
          >
            Bank Charges
          </Typography>
          <div className={classes.toSection}>
            <Autocomplete
              extraClass={classes.toSectionAutocomplete}
              inputClass={classes.addOnInput}
              data={suggestions.currencies}
              value="INR"
              disabled
              accessor="code"
              variant="no-outline"
              placeholder="INR"
              popupClass={classes.popupClass}
              onChange={handleCurrencyChange}
              onSelected={(item) => setCurrency({
                valid: true,
                value: item.code,
                item,
              })}
            />
            <InputBase
              autoFocus
              placeholder="Bank Charges"
              value={bankCharges}
              type="number"
              onChange={(e) => setBankCharges(Number(e.target.value))}
              classes={{
                root: classes.inputArea,
              }}
            />
          </div>
        </div>
        <Autocomplete
          label="Payment Mode"
          inputClass={classes.addOnInput}
          value={paymentMode}
          data={paymentModes}
          accessor="name"
          onChange={handlePaymentModeChange}
          onSelected={(item) => handlePaymentModeChange(item.id)}
        />
        <LabelInput
          label="Transaction Reference number"
          placeholder="Reference number"
          value={txnRefNo}
          onChange={(val) => setTxnRefNo(val)}
        />
        <Checkbox
          rootClass={classes.checkbox}
          checked={multipleUsIdPayment}
          onChange={() => setMultipleUsIdPayment(!multipleUsIdPayment)}
          label="Multiple usId payment"
        />
        <div className={classes.wrapper}>
          <Typography className={classes.label}>Transaction date</Typography>
          <DateButton
            onChange={(date) => setDate(date)}
            value={txnDate}
            disablePast={false}
            extraClass={classes.dateContainer}
          />
        </div>
        <div className={classes.dropArea}>
          <input
            type="file"
            accept="application/pdf,image/png,image/jpeg"
            multiple="multiple"
            ref={dropInput}
            className={classes.dropBox}
            onChange={(e) => handleUpload(e)}
          />
          <Typography className={classes.dropText}>Drag & drop anywhere</Typography>
          <Typography className={classes.dropMsgOR}>
            or
          </Typography>
          <Typography className={classes.dropMsgAdd}>
            <img src={addIcon} alt="+" className={classes.addIcon} />
            &nbsp; Add documents
          </Typography>
          <div className={classes.documentWrapper}>
            <DocumentCards
              documents={invoices}
              onRemove={removeFile}
            />
          </div>
        </div>
      </div>
      <Dialog onClose={() => setAlertVisible(false)} open={alertVisible}>
        <Confirmation
          heading="Are you sure you want to continue ?"
          description={`${Number(amount)} ${currencyCode} will be marked as transferred`}
          onConfirm={submit}
          onReject={() => setAlertVisible(false)}
          onDismiss={() => setAlertVisible(false)}
        />
      </Dialog>
      <Footer>
        <Button
          className={classes.updateBtn}
          onClick={handlePaymentRecord}
          variant="normal"
          loading={loading}
        >
          Save
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    padding: '20px 40px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  supplierName: {
    fontSize: 16,
    color: theme.colors.textDark,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  supplierInfo: {
    marginVertical: 6,
    textAlign: 'left',
    fontSize: 16,
    color: theme.colors.textDark,
    fontWeight: 'bold',
    paddingLeft: 10,
  },
  updateBtn: {
    width: 140,
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBackground,
  },
  autoCompleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
    flex: 1,
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  toSection: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
  },
  toSectionAutocomplete: {
    padding: '0',
    height: 40,
    width: 110,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  wrapper: {
    margin: '10px 0',
  },
  inputArea: {
    padding: '10px 12px',
    boxSizing: 'border-box',
    height: 40,
    width: '100%',
  },
  addOnInput: {
    width: '100%',
    height: '100%',
    padding: '5px 10px',
  },
  dateContainer: {
    border: `1px solid ${theme.colors.border}`,
    height: 40,
    borderRadius: 4,
  },
  checkbox: {
    marginTop: 10,
  },
  dropArea: {
    width: '100%',
    height: 110,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 30,
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  dropText: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.colors.textDark_1,
  },
  dropMsgOR: {
    margin: '6px 0',
    textAlign: 'center',
    fontSize: 12,
    color: theme.colors.textLight_1,
  },
  dropMsgAdd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.colors.primaryBackground,
  },
  documentWrapper: {
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'absolute',
    top: 30,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

TransactionBooking.propTypes = {
  createPayment: PropTypes.bool,
  suggestions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  supplier: PropTypes.object.isRequired,
  booking: PropTypes.object,
  bookingOb: PropTypes.object,
  balance: PropTypes.object.isRequired,
};

export default TransactionBooking;
