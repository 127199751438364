import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import withStyles from '@material-ui/styles/withStyles';
import MultiSelect from 'UI/multiSelect';
import LabelInput from 'UI/labelInput';
import Button from 'UI/button';
import Switch from 'UI/switch';
import Footer from 'UI/footer';
import { shouldShowDiffToggle } from 'utils/common';
import { ITINERARY_READ_MODES } from 'utils/consts';
import SupplierDiff from './supplierDiff';

class Supplier extends Component {
  constructor(props) {
    super(props);
    const { itineraryActionType, originalSuppliers } = this.props;
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalSuppliers);
    this.state = {
      value: '',
      showDiff: false,
    };
    this.refActionTypes = {
      accept_booking: 1,
      confirm_booking: 1,
      request_booking: 1,
    };
    this.readMode = Boolean(ITINERARY_READ_MODES[itineraryActionType]);
  }

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  handleChange = (field, value, index) => {
    const {
      suppliers, places,
      getSuggestions, onUpdate,
    } = this.props;
    if (field === 'supplier') {
      const modSuppliers = [...suppliers];
      modSuppliers[field] = value;
      getSuggestions('supplier', value, { placeIds: places });
      this.setState({ value });
    } else {
      const modSuppliers = [...suppliers];
      const modSupplier = { ...modSuppliers[index] };
      modSupplier[field] = value;
      modSuppliers[index] = modSupplier;
      onUpdate('suppliers', modSuppliers);
    }
  };

  handleItemSelection = (item) => {
    const {
      suppliers, onUpdate, getConversion, conversion,
    } = this.props;
    const modSuppliers = [...suppliers, {
      supplier: item,
      quotation: '',
      costPrice: '',
      supplierRefNum: '',
      // customerRefNum: '',
      currency: item.currency,
      conversionRate: item.currency.valueInTargetCurrency,
    }];
    onUpdate('suppliers', modSuppliers);
    if (!conversion[item.currency._id]) {
      const params = {
        fromCurrency: item.currency._id,
      };
      getConversion(params);
    }
    this.setState({ value: '' });
  };

  removeHandler = (accessor, item) => {
    const { suppliers, onUpdate } = this.props;
    let modSuppliers = [...suppliers];
    modSuppliers = modSuppliers.filter((d) => d.supplier[accessor] !== item[accessor]);
    onUpdate('suppliers', modSuppliers);
  };

  render() {
    const {
      classes, suggestions, errorMsg, itineraryActionType,
      suppliers, nextHandler, originalSuppliers, isProcessing,
      pricing,
    } = this.props;
    const { value, showDiff } = this.state;
    const showRefNo = this.refActionTypes[itineraryActionType];
    const selectedSuppliers = suppliers.map((s) => s.supplier);

    return (
      <div className={classes.container}>
        <div className={classes.body}>
          {this.showDiffToggle ? (
            <div className={classes.changeHeader}>
              <Switch
                checked={showDiff}
                onToggle={this.toggleDiff}
                label="VIEW CHANGES"
              />
            </div>
          ) : null}
          {!showDiff ? (
            <>
              {this.readMode ? (
                <LabelInput
                  label="SUPPLIERS"
                  value={selectedSuppliers.map((s) => s.name).join(', ')}
                  readOnly
                  extraClass={classes.leadWrapperLabelRight}
                />
              )
                : (
                  <MultiSelect
                    label="ADD ONLY LAND SUPPLIERS ( No flights supplier)"
                    selections={selectedSuppliers}
                    value={value}
                    extraClass={classes.leadWrapperLabelRight}
                    data={suggestions.suppliers}
                    accessor="name"
                    removeHandler={(item) => this.removeHandler('name', item)}
                    onChange={(val) => this.handleChange('supplier', val)}
                    onSelected={(item) => this.handleItemSelection(item)}
                  />
                )}
              <div className={classes.supplierSection}>
                {suppliers.map((supplierEntry, i) => {
                  const {
                    costPrice,
                    supplierRefNum,
                    supplier,
                    currency,
                    conversionRate = 1,
                  } = supplierEntry;
                  return (
                    <div className={classes.row} key={supplier._id}>
                      <div className={classes.firstColumn}>{supplier.name}</div>
                      <LabelInput
                        label={i === 0 ? 'Cost price' : null}
                        inputProps={{
                          type: 'number',
                        }}
                        extraClass={classes.inputBox}
                        placeholder="Cost price"
                        value={costPrice}
                        readOnly={this.readMode}
                        onChange={(val) => this.handleChange('costPrice', val, i)}
                      />
                      <LabelInput
                        label={i === 0 ? 'Supplier currency' : null}
                        extraClass={classes.inputBox}
                        readOnly
                        value={currency.code}
                        onChange={(val) => this.handleChange('costPrice', val, i)}
                      />
                      <LabelInput
                        label={i === 0 ? 'Cost price (INR)' : null}
                        extraClass={classes.inputBox}
                        inputProps={{
                          readOnly: true,
                          type: 'number',
                        }}
                        value={(costPrice * (conversionRate / 100)).toFixed(2)}
                        onChange={(val) => this.handleChange('costPrice', val, i)}
                      />
                      {showRefNo ? (
                        <LabelInput
                          label={i === 0 ? 'Booking Ref.' : null}
                          extraClass={classes.inputBox}
                          placeholder="Booking Ref."
                          value={supplierRefNum}
                          disabledInput={itineraryActionType === 'confirm_booking'}
                          onChange={(val) => this.handleChange('supplierRefNum', val, i)}
                        />
                      ) : null}
                    </div>
                  );
                })}
                <div className={classes.row}>
                  <div className={classes.firstColumn}><b>Total</b></div>
                  <LabelInput
                    extraClass={classes.inputBox}
                    readOnly
                    value=""
                  />
                  <LabelInput
                    extraClass={classes.inputBox}
                    readOnly
                    value=""
                  />
                  <LabelInput
                    extraClass={classes.inputBox}
                    error={_.round(_.sumBy(suppliers, (s) => (s.costPrice * (s.conversionRate / 100))), 2) > _.round(pricing.landPackage.costPrice, 2)}
                    disabledInput
                    value={_.round(_.sumBy(suppliers, (s) => (s.costPrice * (s.conversionRate / 100))), 2)}
                  />
                </div>
                <div className={classes.row}>
                  <div className={classes.firstColumn}><b>Original</b></div>
                  <LabelInput
                    extraClass={classes.inputBox}
                    readOnly
                    value=""
                  />
                  <LabelInput
                    extraClass={classes.inputBox}
                    readOnly
                    value=""
                  />
                  <LabelInput
                    extraClass={classes.inputBox}
                    disabledInput
                    value={_.round(pricing.landPackage.costPrice, 2)}
                  />
                </div>
              </div>
              <div className={classes.divider} />
            </>
          ) : <SupplierDiff data={suppliers} oldData={originalSuppliers} />}
        </div>
        {nextHandler ? (
          <Footer
            errorMsg={errorMsg}
          >
            <Button
              className={classes.saveBtn}
              onClick={nextHandler}
              loading={isProcessing}
            >
              Save & continue
            </Button>
          </Footer>
        ) : null}
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    flex: 1,
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeight: 420,
  },
  changeHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  leadWrapperLabelRight: {
    height: 'auto',
    marginBottom: 20,
  },
  supplierSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 8,
  },
  divider: {
    marginBottom: 16,
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.border,
    // border: `1px solid ${theme.colors.border}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  quotation: {
    width: '100%',
    marginBottom: 10,
  },
  inputClass: {
    height: 'auto',
  },
  inputBox: {
    margin: '0 10px',
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  errorMsg: {
    padding: '0 16px',
    color: theme.colors.red,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  firstColumn: {
    width: '150px',
    height: '45px',
    display: 'flex',
    alignItems: 'end',
  },
});

Supplier.propTypes = {
  classes: PropTypes.object,
  suppliers: PropTypes.array.isRequired,
  originalSuppliers: PropTypes.array,
  suggestions: PropTypes.object.isRequired,
  places: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  nextHandler: PropTypes.func,
  getConversion: PropTypes.func.isRequired,
  conversion: PropTypes.object.isRequired,
  errorMsg: PropTypes.string,
  itineraryActionType: PropTypes.string,
  isProcessing: PropTypes.bool,
  pricing: PropTypes.object,
};

export default withStyles(styles)(Supplier);
