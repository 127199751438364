import {
  GET_PLACES,
  GET_PLACES_SUCCESS,
  GET_PLACES_FAILURE,
  ADD_PLACE,
  ADD_PLACE_FAILURE,
  ADD_PLACE_SUCCESS
} from '../../actionTypes';

export const getPlaces = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_PLACES,
    payload,
  };
};

export const getPlacesFailure = (payload) => {
  return {
    type: GET_PLACES_FAILURE,
    payload,
  };
};

export const getPlacesSuccess = (payload) => {
  return {
    type: GET_PLACES_SUCCESS,
    payload,
  };
};

export const addPlace = (params, headers) => {
  const payload = { params, headers };
  return {
    type: ADD_PLACE,
    payload,
  };
};

export const addPlaceFailure = (params, headers) => {
  const payload = { params, headers };
  return {
    type: ADD_PLACE_FAILURE,
    payload,
  };
};

export const addPlaceSuccess = (payload) => {
  return {
    type: ADD_PLACE_SUCCESS,
    payload,
  };
};
