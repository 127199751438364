import React from 'react';
import * as PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/styles/makeStyles';
import { amber } from '@material-ui/core/colors';

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

const OfflineNotifier = ({
  open, handleClose,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={SlideTransition}
      open={open}
    >
      <SnackbarContent
        classes={{
          action: classes.action,
          message: classes.messageContent,
        }}
        className={classes.warning}
        aria-describedby="client-snackbar"
        message={(
          <span id="client-snackbar" className={classes.message}>
            <WarningIcon className={classes.warningIcon} />
            You are offline
          </span>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            className={classes.iconButton}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.colors.white,
    backgroundColor: amber[700],
    width: 200,
    height: 26,
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'normal',
    width: '100%',
  },
  messageContent: {
    padding: 0,
  },
  action: {
    height: 20,
  },
  warningIcon: {
    marginRight: 10,
    fontSize: 16,
  },
  iconButton: {
    padding: 0,
  },
}));

OfflineNotifier.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OfflineNotifier;
