import React from 'react';
import * as PropTypes from 'prop-types';

const Create = ({
  width = 12,
  height = 12,
  className,
  fill = '#2c7a77',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <path
          id="A"
          d="M5.545 5.455h1.636c.3 0 .545.244.545.545s-.244.545-.545.545H5.545v1.636c0 .3-.244.545-.545.545s-.545-.244-.545-.545V6.545H2.818c-.3 0-.545-.244-.545-.545s.244-.545.545-.545h1.636V3.818c0-.3.244-.545.545-.545s.545.244.545.545v1.636zM5 12A6 6 0 1 1 5 0a6 6 0 1 1 0 12zm0-1.09A4.91 4.91 0 0 0 9.909 6 4.91 4.91 0 0 0 5 1.091 4.91 4.91 0 0 0 .091 6 4.91 4.91 0 0 0 5 10.909z"
        />
      </defs>
      <g fillRule="evenodd" transform="translate(1)">
        <mask id="B" fill="#fff">
          <use href="#A" />
        </mask>
        <use fill="#000" fillRule="nonzero" href="#A" />
        <g fill={fill} mask="url(#B)">
          <path d="M-1 0h12v12H-1z" />
        </g>
      </g>
    </svg>
  );
};

Create.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Create;
