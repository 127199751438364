import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/styles/makeStyles';
import DateButton from 'UI/dateButton';
import Button from 'UI/button';
import Header from 'UI/header';
import LabelInput from 'UI/labelInput';
import Footer from 'UI/footer';
import moment from 'moment';
import reminderImg from 'assets/svg/reminder.svg';
import { getNextMonthDate } from 'utils/common';
import { useNestedState } from 'utils/customHooks';

const TripDeferred = ({
  tripDeferredReasons,
  onSubmit,
  onDismiss,
}) => {
  const classes = useStyles();
  const [state, setState] = useNestedState({
    date: null,
    selectedReason: {},
    reason: '',
    errorMsg: '',
  });
  const reasonChange = (reason) => {
    setState('selectedReason', reason);
  };

  const submitHandler = () => {
    const { date, selectedReason } = state;
    if (!date) {
      setState('errorMsg', 'Select a valid date!');
      return;
    }
    if (!selectedReason.code) {
      setState('errorMsg', 'Select a reason!');
      return;
    }
    const params = {
      details: {
        date: moment(state.date).startOf('day').toISOString(),
        code: state.selectedReason.code,
        value: state.selectedReason.code === 'OTHER' ? state.reason : '',
      },
    };
    onSubmit(params);
  };
  return (
    <div className={classes.container}>
      <Header
        title="Defer trip"
        onDismiss={onDismiss}
        img={reminderImg}
      />
      <div className={classes.body}>
        <div className={classes.box}>
          <Typography className={classes.label}>DATE</Typography>
          <div className={classes.wrapper}>
            <DateButton
              value={state.date}
              onChange={(val) => setState('date', val)}
              minDate={getNextMonthDate()}
            />
          </div>
        </div>
        <Typography className={classes.description}>
          Select a reason
        </Typography>
        {tripDeferredReasons.map((reason) => {
          return (
            <>
              <button
                type="button"
                className={classes.radioRow}
                onClick={() => reasonChange(reason)}
              >
                <Radio
                  checked={reason.code === state.selectedReason.code}
                  color="primary"
                  className={classes.radio}
                  onClick={() => reasonChange(reason)}
                />
                <Typography
                  className={clsx(
                    classes.reason,
                    reason.code === state.selectedReason.code
                    && classes.selectedReason
                  )}
                >
                  {reason.title}
                </Typography>
              </button>
              {(state.selectedReason.code === 'OTHER' && reason.code === 'OTHER') ? (
                <LabelInput
                  value={state.reason}
                  label="REASON"
                  placeholder="Trip deferring reason"
                  onChange={(val) => setState('reason', val)}
                  extraClass={classes.reasonContainer}
                  inputClass={classes.reasonInput}
                  inputProps={{
                    multiline: true,
                    rows: 3,
                    rowsMax: 3,
                  }}
                  className={classes.reasonBox}
                />
              ) : null}
            </>
          );
        })}
      </div>
      <Footer errorMsg={state.errorMsg}>
        <Button
          onClick={submitHandler}
          className={classes.updateBtn}
        >
          Defer trip
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    flexDirection: 'column',
    minWidth: 480,
    borderRadius: 10,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 40px',
  },
  titleText: {
    fontSize: 14,
    marginBottom: 16,
    color: theme.colors.black,
  },
  box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
    letterSpacing: 0.5,
    color: theme.colors.textLight,
  },
  description: {
    fontSize: 18,
    color: theme.colors.black,
    margin: '10px 0',
  },
  wrapper: {
    display: 'flex',
    height: 40,
    width: '50%',
    borderRadius: 4,
    marginBottom: 10,
    border: `1px solid ${theme.colors.border}`,
  },
  reasonContainer: {
    width: '100%',
  },
  reasonInput: {
    height: 'auto',
  },
  radioRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    outline: 'none',
    border: 'none',
    height: 32,
    cursor: 'pointer',
    backgroundColor: 'inherit',
  },
  radio: {
    padding: 4,
  },
  reason: {
    marginLeft: 6,
    color: theme.colors.textLight,
    fontSize: 16,
  },
  selectedReason: {
    color: theme.colors.black,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20px 40px',
  },
  updtBtn: {
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    padding: '0 29px',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: '24px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    boxShadow: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
}));

TripDeferred.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tripDeferredReasons: PropTypes.array.isRequired,
};

export default TripDeferred;
