import {
  CREATE_ITINERARY_INIT,
  CREATE_ITINERARY_SUCCESS,
  CREATE_ITINERARY_FAILURE,
  SAVE_ITINERARY_DRAFT_INIT,
  SAVE_ITINERARY_DRAFT_SUCCESS,
  SAVE_ITINERARY_DRAFT_FAILURE,
  GET_STAY_ROOMS_INIT,
  GET_STAY_ROOMS_SUCCESS,
  GET_STAY_ROOMS_FAILURE,
  GET_ITINERARY_PRICE_INIT,
  GET_ITINERARY_PRICE_SUCCESS,
  GET_ITINERARY_PRICE_FAILURE,
} from '../../actionTypes';

export const createItinerarySuccess = (data) => {
  return {
    type: CREATE_ITINERARY_SUCCESS,
    data,
  };
};

export const createItineraryFailure = (error) => {
  return {
    type: CREATE_ITINERARY_FAILURE,
    error,
  };
};

export const createItinerary = (params, headers) => {
  const payload = { params, headers };
  return {
    type: CREATE_ITINERARY_INIT,
    payload,
  };
};

export const saveItineraryDraftSuccess = (data) => {
  return {
    type: SAVE_ITINERARY_DRAFT_SUCCESS,
    data,
  };
};

export const saveItineraryDraftFailure = (error) => {
  return {
    type: SAVE_ITINERARY_DRAFT_FAILURE,
    error,
  };
};

export const saveItineraryDraft = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SAVE_ITINERARY_DRAFT_INIT,
    payload,
  };
};

export const getStayRoomsSuccess = (data) => {
  return {
    type: GET_STAY_ROOMS_SUCCESS,
    data,
  };
};

export const getStayRoomsFailure = (error) => {
  return {
    type: GET_STAY_ROOMS_FAILURE,
    error,
  };
};

export const getStayRooms = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_STAY_ROOMS_INIT,
    payload,
  };
};

export const getItineraryPriceSuccess = (data) => {
  return {
    type: GET_ITINERARY_PRICE_SUCCESS,
    data,
  };
};

export const getItineraryPriceFailure = (error) => {
  return {
    type: GET_ITINERARY_PRICE_FAILURE,
    error,
  };
};

export const getItineraryPrice = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_ITINERARY_PRICE_INIT,
    payload,
  };
};
