import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Switch from '@material-ui/core/Switch';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

const CustomSwitch = ({
  checked,
  onToggle,
  label,
  extraClass,
}) => {
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={onToggle}
      className={clsx(classes.container, extraClass)}
    >
      <Switch
        checked={checked}
        // onChange={onToggle}
        color="primary"
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
      <Typography className={classes.label}>{label}</Typography>
    </ButtonBase>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 8px',
    height: 32,
    boxSizing: 'border-box',
  },
  label: {
    color: theme.colors.primary,
    fontSize: 10,
    paddingLeft: 6,
  },
  root: {
    width: 28,
    height: 14,
    padding: 0,
    display: 'flex',
    overflow: 'visible',
  },
  switchBase: {
    padding: 2,
    color: theme.colors.grey,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.colors.primary,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.colors.primarySelected,
        borderColor: theme.colors.primary,
      },
    },
  },
  checked: {},
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.colors.grey}`,
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.colors.white,
  },
}));

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string,
  extraClass: PropTypes.string,
};

export default CustomSwitch;
