import React from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';

const AutocompleteItem = ({
  item, index, renderType,
  customRender, secondaryAccessor, accessor,
}) => {
  const classes = useStyles();
  if (customRender) {
    return customRender(item, index);
  }
  if (renderType === 'place') {
    const {
      displayDetails: { alternateNames, parentName = '', addressType = '' },
    } = item;
    const extraInfoText = [];
    if (addressType) {
      extraInfoText.push(`(${addressType})`);
    }
    if (parentName) {
      extraInfoText.push(parentName);
    }
    return (
      <span className={classes.itemContainer}>
        <span className={classes.placeRow}>
          <p className={classes.primaryText}>{item[accessor]}</p>
          <p className={classes.secondaryTextLeft}>{extraInfoText.join(', ')}</p>
        </span>
        {alternateNames.length ? (
          <p className={classes.secondaryTextBottom}>{alternateNames.join(', ')}</p>
        ) : null}
      </span>
    );
  }
  return (
    <span className={classes.itemContainer}>
      <p className={classes.primaryText}>{item[accessor]}</p>
      {secondaryAccessor ? (
        <p className={classes.secondaryText}>
          {`(${item[secondaryAccessor]})`}
        </p>
      ) : null}
    </span>
  );
};

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  placeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  primaryText: {
    margin: 0,
    fontSize: 16,
    color: theme.colors.black,
  },
  secondaryTextLeft: {
    margin: '0 0 0 6px',
    fontSize: 14,
    color: theme.colors.textLight,
  },
  secondaryTextBottom: {
    margin: '6px 0 0 0',
    fontSize: 14,
    color: theme.colors.textLight,
  },
}));

AutocompleteItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  customRender: PropTypes.func,
  accessor: PropTypes.string.isRequired,
  secondaryAccessor: PropTypes.string,
  renderType: PropTypes.string,
};

export default AutocompleteItem;
