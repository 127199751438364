import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles';
import Button from 'UI/button';
import Footer from 'UI/footer';
import DraggableList from 'UI/draggableList';
import DragIndicator from '@material-ui/icons/DragIndicator';
import InputBase from '@material-ui/core/InputBase';
// import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from 'UI/autocomplete';
import { uuid } from 'utils/common';
import LabelInput from 'UI/labelInput';

const phoneTypes = [{
  id: 'MOBILE',
  name: 'MOBILE',
},
{
  id: 'LINE',
  name: 'LINE',
},
{
  id: 'OTHER',
  name: 'OTHER',
},
];

class ItineraryNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localError: '',
    };
    if (!props.groundSupport.length) {
      if (props.suppliers?.length) {
        const groundSupportDetails = [];
        props.suppliers.forEach((supplier) => {
          if (supplier.supplier.groundSupport && supplier.supplier.groundSupport.length) {
            supplier.supplier.groundSupport.forEach((groundSupport) => {
              groundSupportDetails.push({
                dialCode: groundSupport.phoneDialCode,
                phoneWoDialCode: groundSupport.phone,
                phoneType: groundSupport.phoneType,
              });
            });
          }
        });

        props.onUpdate('groundSupport', groundSupportDetails);
      } else {
        props.onUpdate('groundSupport', [{
          dialCode: '',
          phoneWoDialCode: '',
          phoneType: '',
        }]);
      }
    }
  }

  handleDragEnd = (newNotes) => {
    const { onUpdate } = this.props;
    onUpdate('notes', newNotes);
  };

  handleChange = (index, value) => {
    const { onUpdate, notes } = this.props;
    const modNotes = [...notes];
    modNotes[index].text = value;
    onUpdate('notes', modNotes);
  };

  keyPressHandler = (index, keyEvent, ref) => {
    const { onUpdate, notes } = this.props;
    if (keyEvent.key === 'Enter') {
      if (ref && (index + 1) < notes.length) {
        ref.children[index + 1].children[0].children[2].children[0].focus();
      } else if (ref && (index + 1) === notes.length) {
        const modItems = [...notes];
        modItems.push({
          id: uuid(),
          text: '',
        });
        onUpdate('notes', modItems);
        setTimeout(() => {
          if (ref.children[index + 1]) {
            ref.children[index + 1].children[0].children[2].children[0].focus();
          }
        }, 300);
      }
    }
  };

  pasteHandler = (index, pasteEvent) => {
    const { onUpdate, notes } = this.props;
    let modNotes = [...notes];
    const pastedValues = pasteEvent.clipboardData.getData('text/plain')
      .split('\n')
      .map((t) => ({
        id: uuid(),
        text: t,
      }));
    modNotes = [...modNotes.slice(0, index), ...pastedValues, ...modNotes.slice(index + 1)];
    onUpdate('notes', modNotes);
  };

  handleItemAddition = () => {
    const { onUpdate, notes } = this.props;
    const lastNote = notes[notes.length - 1];
    if (lastNote && lastNote.text.trim() === '') {
      this.setState({ localError: 'Enter previous notes' });
      return;
    }
    const modNotes = [...notes];
    modNotes.push({
      id: uuid(),
      text: '',
    });
    onUpdate('notes', modNotes);
  };

  handleItemRemoval = (index) => {
    const { onUpdate, notes } = this.props;
    let modNotes = [...notes];
    modNotes = modNotes.filter((itm, idx) => idx !== index);
    if (modNotes.length === 0) {
      modNotes.push({
        id: uuid(),
        text: '',
      });
    }
    onUpdate('notes', modNotes);
  };

  renderRow = (item, index, snapshot, ref) => {
    const { classes } = this.props;
    return (
      <div
        className={classes.row}
        key={item.id}
      >
        <DragIndicator />
        <div className={classes.dot} />
        <InputBase
          placeholder="Add notes"
          className={clsx(classes.input,
            snapshot.isDragging && classes.draggingInput)}
          classes={{
            focused: classes.focusedInput,
          }}
          value={item.text}
          onKeyPress={(e) => this.keyPressHandler(index, e, ref)}
          onPaste={(e) => this.pasteHandler(index, e)}
          onChange={(e) => this.handleChange(index, e.target.value)}
          endAdornment={(
            <IconButton
              className={classes.inputAdornment}
              onClick={() => this.handleItemRemoval(index)}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          )}
        />
      </div>
    );
  };

  handleGroundSupport = (index, field, value) => {
    const { onUpdate, groundSupport } = this.props;
    const modData = [...groundSupport];
    modData[index][field] = value;
    onUpdate('groundSupport', modData);
  }

  handleGroundSupportRemoval = (index) => {
    const { onUpdate, groundSupport } = this.props;
    const modgroundSupport = groundSupport.filter((itm, idx) => idx !== index);
    if (modgroundSupport.length === 0) {
      modgroundSupport.push({
        dialCode: '',
        phoneWoDialCode: '',
        phoneType: '',
      });
    }
    onUpdate('groundSupport', modgroundSupport);
  };

  handleGroundSupportAddition = () => {
    const { onUpdate, groundSupport } = this.props;
    const modgroundSupport = [...groundSupport];
    modgroundSupport.push({
      dialCode: '',
      phoneWoDialCode: '',
      phoneType: '',
    });
    onUpdate('groundSupport', modgroundSupport);
  }

  render() {
    const {
      notes, onSubmit,
      errorMsg, classes, isProcessing,
      groundSupport = [],
    } = this.props;

    const { localError } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <DraggableList
            label="notes"
            items={notes}
            onDragEnd={(items) => this.handleDragEnd(items)}
            renderComponent={(...params) => this.renderRow(...params)}
          />
          <Button
            onClick={this.handleItemAddition}
            variant="plain"
            className={classes.additionBtn}
          >
            Add note
          </Button>
          {/* <div className={clsx(classes.row, classes.contactRow)}>
            <Typography className={classes.label}>
              Ground Support contact:
            </Typography>
            <LabelInput
              label="Support number"
              value={groundSupportNumber}
              inputProps={{
                type: 'number',
              }}
              onChange={(val) => onUpdate('groundSupportNumber', val)}
            />
          </div> */}

          {
            groundSupport.map((obj, gsi) => (
              <div className={clsx(classes.flex)}>
                <LabelInput
                  label="Phone Dial Code"
                  value={obj.dialCode}
                  extraClass={classes.dialCode}
                  inputProps={{
                    type: 'text',
                  }}
                  onChange={(val) => this.handleGroundSupport(gsi, 'dialCode', val)}
                />
                <LabelInput
                  label="Phone number"
                  value={obj.phoneWoDialCode}
                  extraClass={classes.phone}
                  inputProps={{
                    type: 'number',
                  }}
                  onChange={(val) => this.handleGroundSupport(gsi, 'phoneWoDialCode', val)}
                />
                <Autocomplete
                  label="Phone type"
                  value={obj.phoneType}
                  extraClass={classes.phoneType}
                  inputClass={classes.leadInput}
                  data={phoneTypes}
                  accessor="name"
                  onChange={(val) => this.handleGroundSupport(gsi, 'phoneType', val)}
                  onSelected={(item) => this.handleGroundSupport(gsi, 'phoneType', item.id)}
                />
                <IconButton
                  onClick={() => this.handleGroundSupportRemoval(gsi)}
                  className={classes.deleteBtn}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </div>
            ))
          }
          <Button
            onClick={this.handleGroundSupportAddition}
            variant="plain"
            className={classes.additionBtn}
          >
            Add support number
          </Button>
        </div>
        <Footer errorMsg={errorMsg || localError}>
          <Button
            onClick={onSubmit}
            loading={isProcessing}
          >
            Save & continue
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 40px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    marginBottom: 12,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactRow: {
    margin: '12px 0 0',
    borderTop: `1px solid ${theme.colors.border}`,
    paddingTop: 24,
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    color: theme.colors.textLight,
    margin: '24px 10px 0 0',
  },
  dot: {
    width: 6,
    height: 6,
    marginLeft: 10,
    borderRadius: '50%',
    backgroundColor: theme.colors.textLight,
  },
  input: {
    borderRadius: 4,
    width: '90%',
    padding: '10px 15px',
    boxSizing: 'border-box',
    height: 40,
    marginLeft: 4,
    border: `1px solid ${theme.colors.white}`,
    '&:hover:not(:focus)': {
      border: `1px solid ${theme.colors.border}`,
    },
    '&:hover $inputAdornment': {
      display: 'flex',
    },
    '&:hover $closeIcon': {
      display: 'flex',
    },
    '&:focus-within $inputAdornment': {
      display: 'flex',
    },
    '&:focus-within $enterIcon': {
      display: 'flex',
    },
  },
  focusedInput: {
    border: `1px solid ${theme.colors.primary} !important`,
  },
  readableText: {
    width: '90%',
    padding: '10px 15px',
    boxSizing: 'border-box',
    height: 40,
    marginLeft: 4,
    color: theme.colors.textDark,
    fontSize: 14,
  },
  draggingInput: {
    border: 'none',
  },
  additionBtn: {
    fontWeight: 'bold',
    padding: '4px 8px',
    height: 40,
    width: 240,
    color: theme.colors.primary,
  },
  inputAdornment: {
    display: 'none',
    padding: 4,
  },
  closeIcon: {
    color: theme.colors.red,
    height: 16,
    width: 16,
    display: 'none',
  },
  leadInput: {
    padding: '6px 5px 6px 15px',
    height: '100%',
    width: '100%',
  },
  dialCode: {
    flex: 1,
    marginRight: 10,
  },
  phone: {
    flex: 2,
    marginLeft: 10,
  },
  phoneType: {
    flex: 1,
    marginLeft: 10,
  },
  deleteBtn: {
    paddingTop: 10,
    marginLeft: 10,
  },
});

ItineraryNotes.propTypes = {
  notes: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  classes: PropTypes.object,
  isProcessing: PropTypes.bool,
  groundSupportNumber: PropTypes.string,
  groundSupport: PropTypes.array.isRequired,
  suppliers: PropTypes.array,
};

export default withStyles(styles)(ItineraryNotes);
