import {
  GET_CONVERSION_FAILURE,
  GET_CONVERSION_INIT,
  GET_CONVERSION_SUCCESS,
  GET_PARAMS_META_FAILURE,
  GET_PARAMS_META_INIT,
  GET_PARAMS_META_SUCCESS,
  PING_USER_FAILURE,
  PING_USER_INIT,
  PING_USER_SUCCESS,
  REGISTER_NOTIF_TOKEN_FAILURE,
  REGISTER_NOTIF_TOKEN_INIT,
  REGISTER_NOTIF_TOKEN_SUCCESS,
  SUGGEST_AUTOCOMPLETE_FAILURE,
  SUGGEST_AUTOCOMPLETE_INIT,
  SUGGEST_AUTOCOMPLETE_SUCCESS,
  UPLOAD_DOCS_FAILURE,
  UPLOAD_DOCS_INIT,
  UPLOAD_DOCS_SUCCESS,
} from '../../actionTypes';

const initialState = {
  isPinging: false,
  pingUserResp: {},
  pingUserError: false,
  version: 0,
  pingUserErrorMsg: '',
  isGettingParamsMeta: false,
  paramsMeta: {},
  paramsMetaError: false,
  paramsMetaErrorMsg: '',
  isTogglingActivity: false,
  toggleActiveResp: {},
  toggleActiveError: false,
  toggleActiveErrorMsg: '',
  isFetchingSuggestions: false,
  suggestions: {
    countries: [],
    cities: [],
    destinations: [],
    currencies: [],
    addOns: [],
    suppliers: [],
    stays: [],
    experts: [],
    airports: [],
    experiences: [],
  },
  suggestionsError: false,
  suggestionsErrorMsg: '',
  isFetchingConversion: false,
  conversion: {},
  conversionError: false,
  conversionErrorMsg: '',
  isUploadingDocs: false,
  uploadDocsResp: {},
  uploadDocsError: false,
  uploadDocsErrorMsg: '',
  isRegisteringToken: true,
  registerNotifTokenError: false,
  registerNotifTokenResp: {},
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case PING_USER_INIT:
      return {
        ...state,
        isPinging: true,
        pingUserError: false,
      };

    case PING_USER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isPinging: false,
        pingUserResp: data,
        version: data.version,
      };
    }

    case PING_USER_FAILURE:
      return {
        ...state,
        isPinging: false,
        pingUserError: true,
        pingUserErrorMsg: action.error,
      };

    case GET_PARAMS_META_INIT:
      return {
        ...state,
        isGettingParamsMeta: true,
        paramsMetaError: false,
      };

    case GET_PARAMS_META_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingParamsMeta: false,
        paramsMeta: data,
      };
    }

    case GET_PARAMS_META_FAILURE:
      return {
        ...state,
        isGettingParamsMeta: false,
        paramsMetaError: true,
        paramsMetaErrorMsg: action.error,
      };

      // case TOGGLE_ACTIVE_INIT:
      //   return {
      //     ...state,
      //     isTogglingActivity: true,
      //     toggleActiveError: false,
      //   };

      // case TOGGLE_ACTIVE_SUCCESS: {
      //   const { data } = action;
      //   return {
      //     ...state,
      //     isTogglingActivity: false,
      //     toggleActiveResp: data,
      //   };
      // }

      // case TOGGLE_ACTIVE_FAILURE:
      //   return {
      //     ...state,
      //     isTogglingActivity: false,
      //     toggleActiveError: true,
      //     toggleActiveErrorMsg: action.error,
      //   };

    case SUGGEST_AUTOCOMPLETE_INIT:
      return {
        ...state,
        isFetchingSuggestions: true,
        suggestionsError: false,
      };

    case SUGGEST_AUTOCOMPLETE_SUCCESS: {
      const { data } = action;
      let modData = (data[data.type] || data.places || []);
      if (data.type === 'countries') {
        modData = modData.map((item) => ({
          ...item,
          displayText: `${item.name} (${item.dialCode || '-'})`,
        }));
      }
      // if (data.type === 'experiences') {
      //   const places = data.places || [];
      //   const placeMap = _.keyBy(places, p => p._id);
      //   modData = modData.map((item) => ({
      //     ...item,
      //     address: {
      //       place: placeMap[item.address.place],
      //     },
      //   }));
      // }
      if (data.type === 'itineraryTemplates') {
        modData = data;
      }
      return {
        ...state,
        isFetchingSuggestions: false,
        suggestions: {
          ...state.suggestions,
          [data.type]: modData,
        },
      };
    }

    case SUGGEST_AUTOCOMPLETE_FAILURE:
      return {
        ...state,
        isFetchingSuggestions: false,
        suggestionsError: true,
        suggestionsErrorMsg: action.error,
      };

    case GET_CONVERSION_INIT:
      return {
        ...state,
        isFetchingConversion: true,
        conversion: {},
        conversionError: false,
      };

    case GET_CONVERSION_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isFetchingConversion: false,
        conversion: { ...state.conversion, ...data },
      };
    }

    case GET_CONVERSION_FAILURE:
      return {
        ...state,
        isFetchingConversion: false,
        conversionError: true,
        conversionErrorMsg: action.error,
      };

    case UPLOAD_DOCS_INIT:
      return {
        ...state,
        isUploadingDocs: true,
        uploadDocsError: false,
      };

    case UPLOAD_DOCS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUploadingDocs: false,
        uploadDocsResp: data,
      };
    }

    case UPLOAD_DOCS_FAILURE:
      return {
        ...state,
        isUploadingDocs: false,
        uploadDocsError: true,
        uploadDocsErrorMsg: action.error,
      };

    case REGISTER_NOTIF_TOKEN_INIT:
      return {
        ...state,
        isRegisteringToken: true,
        registerNotifTokenError: false,
        registerNotifTokenResp: {},
      };

    case REGISTER_NOTIF_TOKEN_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isRegisteringToken: false,
        registerNotifTokenResp: data,
      };
    }

    case REGISTER_NOTIF_TOKEN_FAILURE:
      return {
        ...state,
        isRegisteringToken: false,
        registerNotifTokenError: true,
        registerNotifTokenErrorMsg: action.error,
      };

    default:
      return state;
  }
};

export default common;
