import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import Header from 'UI/header';
import LabelInput from 'UI/labelInput';
import Footer from 'UI/footer';
import Button from 'UI/button';

const RejectReason = ({
  onReject,
  onDismiss,
}) => {
  const [reason, setReason] = useState('');
  const [errorMsg, setError] = useState('');
  const classes = useStyles();
  const submitHandler = () => {
    if (reason.trim().length === 0) {
      setError('Reason for rejection is required');
      return;
    }
    onReject(reason);
  };
  return (
    <div className={classes.container}>
      <Header
        Icon={CancelIcon}
        onDismiss={onDismiss}
        title="Reason for rejecting changes"
      />
      <div className={classes.body}>
        <LabelInput
          inputProps={{
            multiline: true,
            rows: 3,
            rowsMax: 6,
          }}
          inputClass={classes.reasonClass}
          value={reason}
          onChange={(val) => setReason(val)}
        />
      </div>
      <Footer errorMsg={errorMsg}>
        <Button
          variant="normal"
          onClick={submitHandler}
        >
          Reject
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px 0 0',
    backgroundColor: theme.colors.white,
  },
  body: {
    padding: '20px 40px',
  },
  reasonClass: {
    height: 'auto',
  },
}));

RejectReason.propTypes = {
  onReject: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RejectReason;
