import React from 'react';
import * as PropTypes from 'prop-types';

const Info = ({
  width = 16,
  height = 16,
  className,
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
    >
      <path
        fill={fill}
        d="M8 15.333C3.95 15.333.667 12.05.667 8S3.95.667 8 .667 15.333 3.95 15.333 8 12.05 15.333 8 15.333zM8 14A6 6 0 1 0 8 2a6 6 0 1 0 0 12zm.667-3.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V8c0-.368.298-.667.667-.667s.667.298.667.667v2.667zM7.53 6.47c-.26-.26-.26-.682 0-.943s.682-.26.943 0 .26.682 0 .943-.682.26-.943 0z"
      />
    </svg>
  );
};

Info.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Info;
