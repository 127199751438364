import {
  put,
  call,
  takeLatest,
  all,
} from 'redux-saga/effects';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import { getRequest, postRequest, putRequest } from 'utils/apiRequests';
import { addFilesToUpload, mapImageToFile } from 'utils/common';
import {
  CREATE_ITINERARY_INIT,
  SAVE_ITINERARY_DRAFT_INIT,
  GET_STAY_ROOMS_INIT,
  GET_ITINERARY_PRICE_INIT,
} from '../../actionTypes';
import {
  createItinerarySuccess,
  createItineraryFailure,
  saveItineraryDraftSuccess,
  saveItineraryDraftFailure,
  getStayRoomsSuccess,
  getStayRoomsFailure,
  getItineraryPriceSuccess,
  getItineraryPriceFailure,
} from './itineraryMakerActions';

function* createItinerary(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.signin}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(createItinerarySuccess(data));
    } else {
      yield put(createItineraryFailure(data.message));
    }
  } catch (err) {
    yield put(createItineraryFailure(err.message));
  }
}

export function* createItinerarySaga() {
  yield takeLatest(CREATE_ITINERARY_INIT, createItinerary);
}

function* saveItineraryDraft(action) {
  try {
    const { params, headers } = action.payload;
    let uploadFiles = [];
    let partFiles = {};
    const parts = params.details.itineraryParts;
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].type === 'ACTIVITY') {
        const modData = addFilesToUpload(i, parts[i].activityData.images, uploadFiles, partFiles);
        partFiles = modData.uploadMap;
        uploadFiles = modData.uploadArr;
      }
      if (parts[i].type === 'FLIGHT') {
        const modData = addFilesToUpload(i, parts[i].flightData.documents, uploadFiles, partFiles);
        partFiles = modData.uploadMap;
        uploadFiles = modData.uploadArr;
      }
      if (parts[i].type === 'SERVICE' && parts[i].serviceData.serviceType === 'Travel Insurance') {
        const modData = addFilesToUpload(i, parts[i].serviceData.travelInsuranceData.documents,
          uploadFiles, partFiles);
        partFiles = modData.uploadMap;
        uploadFiles = modData.uploadArr;
      }
    }

    // uploading user documents as well
    const modData = addFilesToUpload(-1, params.details.documents, uploadFiles, partFiles);
    partFiles = modData.uploadMap;
    uploadFiles = modData.uploadArr;

    // checking if itinerary data was changed
    let hasItineraryLink = false;
    if (params.details.itineraryData?.modified) {
      if (params.details.itineraryData.link) {
        params.details.link = params.details.itineraryData.link;
        delete params.details.itineraryData;
      } else {
        const data = addFilesToUpload(-1, [params.details.itineraryData.file],
          uploadFiles, partFiles);
        partFiles = data.uploadMap;
        uploadFiles = data.uploadArr;
        hasItineraryLink = true;
      }
    } else if (params.details.itineraryData?._id) {
      params.details.document = params.details.itineraryData._id;
      delete params.details.itineraryData;
    } else if (params.details.itineraryData) {
      params.details.link = params.details.itineraryData.link;
      delete params.details.itineraryData;
    }
    const linkUrl = `${baseURL}${endPoints.uploadDocs}`;
    const imageUploadLinkResp = yield call(postRequest, linkUrl, { files: uploadFiles }, headers);
    if (!imageUploadLinkResp.error) {
      const uploadResp = yield all(imageUploadLinkResp.urls.map(({
        signedUrl, fileName, document,
      }) => {
        // const formData = new FormData();
        // formData.append('file', partFiles[fileName].file);
        partFiles[fileName].document = document;
        return call(putRequest, signedUrl, partFiles[fileName].file, {});
      }));
      let state = true;
      if (uploadResp) {
        for (let i = 0; i < uploadResp.length; i++) {
          if (uploadResp[i].state !== true) {
            state = false;
            break;
          }
        }
      }
      if (state) {
        // mapping user documents
        params.details.documents = mapImageToFile(params.details.documents, partFiles);
        if (hasItineraryLink) {
          const itnData = mapImageToFile([params.details.itineraryData.file], partFiles);
          params.details.document = itnData[0];
          delete params.details.itineraryData;
        }

        // mapping itinerary part documents
        for (let i = 0; i < params.details.itineraryParts.length; i++) {
          if (params.details.itineraryParts[i].type === 'ACTIVITY') {
            if (params.details.itineraryParts[i].activityData.images?.length) {
              params.details.itineraryParts[i].activityData.images = mapImageToFile(
                params.details.itineraryParts[i].activityData.images, partFiles
              );
            }
          }
          if (params.details.itineraryParts[i].type === 'FLIGHT') {
            if (params.details.itineraryParts[i].flightData.documents?.length) {
              params.details.itineraryParts[i].flightData.documents = mapImageToFile(
                params.details.itineraryParts[i].flightData.documents, partFiles
              );
            }
          }
          if (params.details.itineraryParts[i].type === 'SERVICE'
            && params.details.itineraryParts[i].serviceData.serviceType === 'Travel Insurance') {
            params.details.itineraryParts[i]
              .serviceData.travelInsuranceData.documents = mapImageToFile(
                params.details.itineraryParts[i].serviceData.travelInsuranceData.documents,
                partFiles
              );
          }
        }
        const url = `${baseURL}${endPoints.draftItinerary}`;
        const data = yield call(postRequest, url, params, headers);
        if (!data.error) {
          yield put(saveItineraryDraftSuccess(data));
        } else {
          yield put(saveItineraryDraftFailure(data.message));
        }
      }
    }
  } catch (err) {
    yield put(saveItineraryDraftFailure(err.message));
  }
}

export function* saveItineraryDraftSaga() {
  yield takeLatest(SAVE_ITINERARY_DRAFT_INIT, saveItineraryDraft);
}

function* getStayRooms(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.stayRooms}`;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(getStayRoomsSuccess(data));
    } else {
      yield put(getStayRoomsFailure(data.message));
    }
  } catch (err) {
    yield put(getStayRoomsFailure(err.message));
  }
}

export function* getStayRoomsSaga() {
  yield takeLatest(GET_STAY_ROOMS_INIT, getStayRooms);
}

function* getItineraryPrice(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getItineraryPrice.replace('ITINERARY_ID', params.itineraryId)}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getItineraryPriceSuccess(data));
    } else {
      yield put(getItineraryPriceFailure(data.message));
    }
  } catch (err) {
    yield put(getItineraryPriceFailure(err.message));
  }
}

export function* getItineraryPriceSaga() {
  yield takeLatest(GET_ITINERARY_PRICE_INIT, getItineraryPrice);
}
