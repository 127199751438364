import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import LocationIcon from '@material-ui/icons/LocationOn';
import tickImg from 'assets/svg/tick.svg';
// import starImg from 'assets/svg/star_filled.svg';
import { BLOCK_TYPES } from 'utils/consts';
import { capitalize } from 'utils/common';
import StarRating from './starRating';

const blockedText = ({ blockType, blockedTill, blockedReason = '' }) => {
  let reason = '';
  if (blockType === BLOCK_TYPES.PERMANENT) {
    reason = 'This stay is permanently blocked';
  } else if (blockType === BLOCK_TYPES.TEMPORARY) {
    reason = `Stay is blocked till ${moment(blockedTill)
      .format('DD/MM/YY')} because ${blockedReason}`;
  }
  return reason;
};

const HotelCard = ({
  image,
  name,
  rating,
  address,
  selected,
  onStaySelect,
  blockInfo = {},
  stayType,
}) => {
  const classes = useStyles();
  const { blockType = '' } = blockInfo;
  const isBlocked = blockType !== BLOCK_TYPES.UNBLOCKED;
  let reason = '';
  if (isBlocked) {
    reason = blockedText(blockInfo);
  }
  const displayAddress = address && address.length > 18 ? `${address.slice(0, 18)}...` : address;
  const content = (
    <>
      <div className={isBlocked ? classes.blocked : ''}>
        <div className={classes.imageContainer}>
          <img decoding="async" src={image} alt=" " className={classes.stayImage} />
          <StarRating
            rating={rating}
            extraClass={classes.starRating}
            stayType={capitalize(stayType)}
          />
        </div>
        <Typography className={classes.title}>{name}</Typography>
        <div className={classes.row}>
          <LocationIcon className={classes.locationIcon} />
          <Typography
            className={classes.address}
          >
            {displayAddress}
          </Typography>
        </div>
      </div>
      {isBlocked ? (
        <Typography
          className={classes.blockArea}
        >
          {reason}
        </Typography>
      ) : null}
    </>
  );
  return (
    <button
      type="button"
      onClick={onStaySelect}
      className={clsx(classes.container,
        selected && classes.selected,
        isBlocked && classes.blockedContainer)}
    >
      {content}
      {selected ? (
        <img src={tickImg} alt="selected" className={classes.selectedImg} />
      ) : null}
    </button>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: 240,
    width: 240,
    borderRadius: 10,
    outline: 'none',
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    position: 'relative',
    padding: 10,
    marginRight: 10,
    marginBottom: 10,
    backgroundColor: theme.colors.white,
    transition: 'box-shadow 0.4s ease-in-out',
    '&:hover': {
      boxShadow: '0px 8px 16px 4px rgba(0,0,0,0.06)',
    },
  },
  selected: {
    backgroundColor: theme.colors.primarySelected,
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
  },
  stayImage: {
    borderRadius: 8,
    width: 220,
    height: 120,
  },
  selectedImg: {
    padding: 6,
    borderRadius: '50%',
    position: 'absolute',
    top: -13,
    right: -13,
    boxSizing: 'border-box',
    width: 26,
    height: 26,
    backgroundColor: theme.colors.primary,
    border: `1px solid ${theme.colors.white}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '6px 0',
    color: theme.colors.black,
    textAlign: 'left',
    fontSize: 16,
  },
  starRating: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  address: {
    color: theme.colors.textLight,
    fontSize: 14,
    textAlign: 'left',
    marginTop: 'auto',
  },
  locationIcon: {
    color: theme.colors.primary,
    height: 14,
    width: 14,
    marginRight: 4,
  },
  previewAddress: {
    color: theme.colors.textLight,
    fontSize: 14,
    textAlign: 'left',
    marginTop: 0,
  },
  blocked: {
    filter: 'grayscale(100%)',
  },
  blockedContainer: {
    position: 'relative',
    overflowY: 'hidden',
    '&:hover $blockArea': {
      transform: 'translateY(-100%)',
    },
  },
  blockArea: {
    transition: 'transform .3s ease-in',
    backgroundColor: theme.colors.underline,
    color: theme.colors.red,
    padding: 20,
    left: 0,
    boxSizing: 'border-box',
    width: '100%',
    textAlign: 'left',
    position: 'absolute',
    top: '100%',
  },
}));

HotelCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.oneOf([PropTypes.number, 'N/A']),
  address: PropTypes.string,
  selected: PropTypes.bool,
  onStaySelect: PropTypes.func,
  blockInfo: PropTypes.object,
  stayType: PropTypes.string,
};

export default HotelCard;
