import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DotLine from 'UI/dotLine';
import DocumentCards from 'UI/documentCards';
import { getTravellerText } from 'utils/common';
import DiffInput from './diffInput';

const FlightDiff = ({ data, oldData }) => {
  const classes = useStyles();
  const renderFlightDetailsDiff = (type) => {
    const title = type === 'departureFlightData' ? 'One way details' : 'Return details';
    const newDetails = data[type];
    const oldDetails = oldData[type];
    return (
      <div className={classes.content}>
        <Typography
          className={classes.title}
        >
          {title}
        </Typography>
        <div className={classes.row}>
          <DiffInput
            oldValue={oldDetails?.from.value || '-'}
            label="FROM"
            value={newDetails?.from.value || '-'}
            extraClass={classes.left}
          />
          <DiffInput
            oldValue={oldDetails?.to.value || '-'}
            label="TO"
            value={newDetails?.to.value || '-'}
            extraClass={classes.right}
          />
        </div>
        <div className={classes.row}>
          <DiffInput
            oldValue={oldDetails?.flightDate
              ? moment(oldDetails?.flightDate).format('DD MMM YY hh:mm A') : '-'}
            label="FLIGHT DATE & TIME"
            value={newDetails?.flightDate
              ? moment(newDetails?.flightDate).format('DD MMM YY hh:mm A') : '-'}
            extraClass={classes.left}
          />
          <DiffInput
            oldValue={oldDetails?.flightNumber || '-'}
            label="FLIGHT NUMBER"
            value={newDetails?.flightNumber || '-'}
            extraClass={classes.right}
          />
        </div>
        <div className={classes.row}>
          <DiffInput
            oldValue={oldDetails?.terminal || '-'}
            label="TERMINAL NO."
            value={newDetails?.terminal || '-'}
            extraClass={classes.left}
          />
          <DiffInput
            oldValue={oldDetails?.noOfStops || '-'}
            label="NO. OF STOPS"
            value={newDetails?.noOfStops || '-'}
            extraClass={classes.right}
          />
        </div>
        <div className={classes.row}>
          <DiffInput
            oldValue={oldDetails?.cabinClass.value || '-'}
            label="CABIN CLASS"
            value={newDetails?.cabinClass.value || '-'}
            extraClass={classes.halfWidth}
          />
        </div>
      </div>
    );
  };
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <div className={classes.row}>
          <DiffInput
            label=""
            value={data.flightType}
            oldValue={oldData.flightType}
          />
        </div>
        <div className={classes.wrapper}>
          <DotLine />
          {renderFlightDetailsDiff('departureFlightData')}
        </div>
        {(data.flightType === 'Round trip' || oldData.flightType === 'Round trip') ? (
          <div className={classes.wrapper}>
            <DotLine />
            {renderFlightDetailsDiff('returnFlightData')}
          </div>
        ) : null}
        <div className={classes.wrapper}>
          <DotLine />
          <div className={classes.content}>
            <Typography className={classes.title}>
              Other details
            </Typography>
            <div className={classes.row}>
              <DiffInput
                oldValue={oldData.baggage.value}
                label="BAGGAGE"
                value={data.baggage.value}
                extraClass={classes.left}
              />
              <DiffInput
                oldValue={getTravellerText(oldData.travellers)}
                label="TRAVELLERS"
                value={getTravellerText(data.travellers)}
                extraClass={classes.right}
              />
            </div>
            <div className={classes.row}>
              <DiffInput
                oldValue={oldData.refundable}
                label="&nbsp;"
                value={data.refundable}
                extraClass={classes.left}
              />
              <DiffInput
                oldValue={oldData.cancellationAmount || 0}
                label="MAX CANCELLATION CHARGE"
                value={data.cancellationAmount || 0}
                extraClass={classes.right}
              />
            </div>
            <div className={classes.row}>
              <DiffInput
                oldValue={oldData.costPrice}
                label="COST PRICE"
                value={data.costPrice}
                extraClass={classes.left}
              />
              <DiffInput
                oldValue={oldData.sellingPrice || 0}
                label="SELLING PRICE"
                value={data.sellingPrice || 0}
                extraClass={classes.right}
              />
            </div>
            <div className={classes.row}>
              <DiffInput
                oldValue={oldData.supplier.value}
                label="SUPPLIER"
                value={data.supplier.value}
                extraClass={classes.fullWidth}
              />
            </div>
          </div>
        </div>
        <div className={classes.wrapper}>
          <DotLine />
          <div className={classes.content}>
            <Typography className={classes.title}>
              Uploaded documents
            </Typography>
            {data.documents.length ? (
              <DocumentCards
                documents={data.documents}
                onRemove={() => {
                }}
              />
            ) : null}
          </div>
        </div>
        <div className={classes.wrapper}>
          <DotLine />
          <div className={classes.content}>
            <DiffInput
              oldValue={oldData.notes}
              label="NOTES"
              value={data.notes}
              extraClass={classes.fullWidth}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.colors.white,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  fullWidth: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    color: theme.colors.textDark,
    marginBottom: 20,
    letterSpacing: 0.5,
  },
  halfWidth: {
    width: 'calc(50% - 10px)',
  },
}));

FlightDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default FlightDiff;
