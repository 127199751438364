import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Auth from './authIndex';
import { login } from './authActions';

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  login: (params, headers) => dispatch(login(params, headers)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth));
