import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const DiffInput = ({
  label,
  value,
  oldValue,
  extraClass,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, extraClass)}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      {value !== oldValue ? (
        <div className={classes.different}>
          <Typography className={clsx(classes.inputBase, classes.oldValue)}>
            {oldValue}
          </Typography>
          <Typography className={clsx(classes.inputBase, classes.value)}>
            {value}
          </Typography>
        </div>
      ) : (
        <Typography className={clsx(classes.inputBase, classes.value)}>
          {value}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.colors.white,
  },
  label: {
    textAlign: 'left',
    fontSize: 12,
    marginBottom: 10,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    color: theme.colors.textLight,
  },
  different: {
    backgroundColor: theme.colors.primarySelected,
    border: `1px dashed ${theme.colors.primary}`,
    borderRadius: 4,
  },
  inputBase: {
    minHeight: 36,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 16,
    padding: '8px 15px',
    boxSizing: 'border-box',
  },
  oldValue: {
    color: theme.colors.black,
    textDecoration: 'line-through',
    textDecorationColor: theme.colors.red,
    marginBottom: 4,
  },
  value: {
    color: theme.colors.black,
  },
}));

DiffInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  extraClass: PropTypes.string,
};

export default DiffInput;
