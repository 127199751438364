import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import withStyles from '@material-ui/styles/withStyles';

class TravellerSelection extends Component {
  state = {
    isVisible: false,
    focused: false,
  };

  onBlurHandler = () => {
    this.setState({ isVisible: false, focused: false });
  };

  onFocusHandler = () => {
    this.setState({ isVisible: true, focused: true });
  };

  keyPressHandler = (field, keyEvent) => {
    const { keyCode } = keyEvent;
    if (keyCode === 38) {
      this.incDecHandler(field, 'inc');
      keyEvent.preventDefault();
    } else if (keyCode === 40) {
      this.incDecHandler(field, 'dec');
      keyEvent.preventDefault();
    }
  };

  handleChange = (field, value) => {
    const { travellers, onChange } = this.props;
    if (onChange) {
      const modTravellers = { ...travellers, [field]: +value };
      onChange(modTravellers);
    }
  };

  incDecHandler = (field, type) => {
    const { travellers, onChange } = this.props;
    let newVal = Number.parseInt(travellers[field], 10);
    if (type === 'inc') {
      newVal++;
    } else {
      newVal--;
    }
    if (newVal <= 0) {
      newVal = 0;
    }
    const modTravellers = { ...travellers, [field]: newVal };
    onChange(modTravellers);
  };

  rowRenderer = (name, desc, field) => {
    const { travellers = {}, classes } = this.props;
    const isDisabled = Number.parseInt(travellers[field], 10) === 0;
    return (
      <div className={classes.row}>
        <Typography className={classes.fieldName}>{name}</Typography>
        <Typography className={classes.desc}>{desc}</Typography>
        <div className={classes.rowWidth}>
          <button
            type="button"
            disabled={isDisabled}
            className={classes.button}
            onClick={() => this.incDecHandler(field, 'dec')}
          >
            <RemoveIcon className={classes.icon} />
          </button>
          <InputBase
            classes={{
              input: classes.textInput,
            }}
            type="number"
            autoComplete="off"
            value={travellers[field]}
            onKeyDown={(e) => this.keyPressHandler(field, e)}
            onChange={(e) => this.handleChange(field, e.target.value)}
          />
          <button
            type="button"
            className={classes.button}
            onClick={() => this.incDecHandler(field, 'inc')}
          >
            <AddIcon className={classes.icon} />
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      label = '',
      travellers = {},
      popupClass = '',
      extraClass = '',
      placeholder = '',
    } = this.props;
    const { isVisible, focused } = this.state;
    const { adults = 0, children = 0, infants = 0 } = travellers;
    const displayText = [];
    if (adults) {
      displayText.push(`${adults} adults`);
    }
    if (children) {
      displayText.push(`${children} children`);
    }
    if (infants) {
      displayText.push(`${infants} infants`);
    }
    const Icon = focused ? KeyboardArrowUp : KeyboardArrowDown;
    return (
      <div className={clsx(classes.container, extraClass)}>
        {label ? (
          <Typography
            className={clsx(classes.label, focused && classes.focusedLabel)}
          >
            {label}
          </Typography>
        ) : null}
        <ClickAwayListener onClickAway={this.onBlurHandler}>
          <div
            className={clsx(classes.root, focused && classes.focusedRoot)}
          >
            <InputBase
              value={displayText.join(', ')}
              classes={{
                root: classes.inputRoot,
                input: classes.input,
              }}
              placeholder={placeholder}
              autoComplete="off"
              onChange={(e) => this.handleChange('', e.target.value)}
              onFocus={this.onFocusHandler}
              endAdornment={(
                <IconButton
                  className={classes.iconBtn}
                  onClick={this.onFocusHandler}
                >
                  <Icon />
                </IconButton>
              )}
            />
            {isVisible ? (
              <div className={clsx(classes.paper, popupClass)}>
                {this.rowRenderer('Adult', '', 'adults')}
                {this.rowRenderer('Child', '(2-12yrs)', 'children')}
                {this.rowRenderer('Infant', '(Below 2 years)', 'infants')}
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: '10px 5px 10px 15px',
    border: `1px solid ${theme.colors.border}`,
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  focusedRoot: {
    border: `1px solid ${theme.colors.primary} !important`,
  },
  focusedLabel: {
    color: theme.colors.primary,
  },
  labelRoot: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${theme.colors.border}`,
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
  },
  focusedContainer: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: '10px 5px 10px 15px',
    border: `1px solid ${theme.colors.primary}`,
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  rowWidth: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 90,
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.colors.white,
    boxShadow: '0 4px 8px 1px rgba(0,0,0,0.1)',
    zIndex: 1301,
    width: '100%',
    borderRadius: 4,
    padding: '15px 15px 0',
    top: 40,
    left: -20,
  },
  inputRoot: {
    width: '100%',
    height: '100%',
  },
  input: {
    padding: 0,
    height: '100%',
    fontSize: 16,
  },
  textInput: {
    padding: '0 0 4px',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '0 4px',
    borderBottom: `1px solid ${theme.colors.underline}`,
  },
  fieldName: {
    fontSize: 16,
    color: theme.colors.black,
  },
  desc: {
    flex: 1,
    fontSize: 12,
    color: theme.colors.textLight,
    marginLeft: 5,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: 18,
    minWidth: 18,
    height: 18,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '50%',
    outline: 'none',
    border: 'none',
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
    '&:disabled': {
      backgroundColor: theme.colors.underline,
      cursor: 'not-allowed',
    },
  },
  icon: {
    width: 10,
    height: 10,
    fontSize: 'inherit',
  },
  iconBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

TravellerSelection.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  extraClass: PropTypes.string,
  popupClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  travellers: PropTypes.object,
};

export default withStyles(styles)(TravellerSelection);
