import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

class DraggableList extends Component {
  componentDidMount = () => {
    const { label } = this.props;
    this.itemsRef = document.getElementById(label);
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { onDragEnd, items } = this.props;
    const modItems = this.reorder(
      items,
      result.source.index,
      result.destination.index
    );
    onDragEnd(modItems);
  };

  getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    margin: '0 0 8px 0',
    // change background colour if dragging
    backgroundColor: isDragging ? '#EAF2F2' : '#fff',
    border: isDragging && 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  render() {
    const {
      classes,
      items,
      renderComponent,
      label,
    } = this.props;
    return (
      <div className={classes.container}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable style={{ transform: 'none' }} droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                id={label}
                style={this.getItemStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                  >
                    {(prvded, snpsht) => (
                      <div
                        ref={prvded.innerRef}
                        {...prvded.dragHandleProps}
                        {...prvded.draggableProps}
                        className={classes.row}
                        tabIndex={-1}
                        style={this.getItemStyle(
                          prvded.draggableProps.style,
                          snpsht.isDragging
                        )}
                      >
                        {renderComponent(item, index, snpsht, this.itemsRef)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
});

DraggableList.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  renderComponent: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default withStyles(styles)(DraggableList);
