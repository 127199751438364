import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import { isEqual } from 'utils/common';
import ArrowBack from 'assets/icons/arrowBack';
import ArrowNext from 'assets/icons/arrowNext';

/* eslint-disable no-underscore-dangle */
class RenderPDF extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props;
    this.pdf = window['pdfjs-dist/build/pdf'];
    this.canvas = document.getElementById(id);
    this.state = {
      currPage: 1,
      totalPages: 0,
    };
  }

  componentDidMount = () => {
    const { id } = this.props;
    this.canvas = document.getElementById(id);
    this.renderPdf();
  };

  shouldComponentUpdate = (nextProps, prevState) => {
    const { file, show } = this.props;
    const checkProps = {
      file: file.file,
      name: file.name,
      type: file.type,
      show,
    };
    const { file: newFile, show: newShow } = nextProps;
    const newProps = {
      file: newFile.file,
      name: newFile.name,
      type: newFile.type,
      show: newShow,
    };
    return !(isEqual(newProps, checkProps) && isEqual(this.state, prevState));
  };

  renderPdf = () => {
    const {
      file,
      show,
      preview,
      id,
    } = this.props;
    const { totalPages, currPage } = this.state;
    if (!this.canvas) {
      this.canvas = document.getElementById(id);
      return; // first render
    }
    if (!show) {
      this.canvas.style.display = 'none';
      this.canvas.height = 0;
      this.canvas.width = 0;
      return;
    }
    if (!this.pdf) {
      this.pdf = window['pdfjs-dist/build/pdf'];
    }
    this.canvas.style.display = 'block';
    const ctx = this.canvas.getContext('2d');
    const scale = preview ? 1.2 : 0.4;
    const reader = new FileReader();
    reader.onload = () => {
      this.pdf.getDocument(reader.result).promise.then((pdfDoc_) => {
        this.pdfDoc = pdfDoc_;
        if (this.pdfDoc) {
          if (!totalPages) {
            this.setState({ totalPages: this.pdfDoc._pdfInfo.numPages });
          }
          this.pdfDoc.getPage(currPage).then((page) => {
            const viewport = page.getViewport({ scale });
            if (preview) {
              this.canvas.height = Math.min(viewport.height, window.innerHeight - 60);
              this.canvas.width = viewport.width;
              this.canvas.style.borderRadius = 0;
            } else {
              this.canvas.height = Math.min(viewport.height, 250);
              this.canvas.width = Math.min(viewport.width, 180);
            }
            // Render PDF page into canvas context
            const renderContext = {
              canvasContext: ctx,
              viewport,
            };
            page.render(renderContext);
          });
        }
      });
    };
    if (file) {
      reader.readAsArrayBuffer(file.file);
    }
  };

  renderDiffPage = (amt) => {
    const { totalPages, currPage } = this.state;
    const newPage = currPage + amt;
    if (newPage > 0 && newPage <= totalPages) {
      this.setState({ currPage: newPage });
    }
  };

  render() {
    this.renderPdf();
    const { id = 'pdf', preview, classes } = this.props;
    const { totalPages, currPage } = this.state;
    return (
      <>
        <canvas
          id={id}
          height="0"
          width="0"
          style={{ display: 'none', borderRadius: 8, border: '1px solid #ddd' }}
        />
        {preview ? (
          <div className={classes.navSection}>
            <button
              type="button"
              className={classes.button}
              onClick={() => this.renderDiffPage(-1)}
            >
              <ArrowBack fill="#fff" />
            </button>
            <Typography className={classes.status}>
              {`${currPage} / ${totalPages}`}
            </Typography>
            <button
              type="button"
              className={classes.button}
              onClick={() => this.renderDiffPage(1)}
            >
              <ArrowNext fill="#fff" />
            </button>
          </div>
        ) : null}
      </>
    );
  }
}

RenderPDF.propTypes = {
  classes: PropTypes.object,
  file: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  preview: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

const styles = (theme) => ({
  navSection: {
    position: 'absolute',
    bottom: -40,
    left: '50%',
    padding: 10,
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: 'rgba(0,0,0,0.75)',
    minWidth: 140,
    boxSizing: 'border-box',
  },
  button: {
    outline: 'none',
    border: 'none',
    width: 30,
    height: 30,
    padding: '5px 10px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  status: {
    color: theme.colors.white,
    fontSize: 16,
    margin: '0 10px',
  },
});

export default withStyles(styles)(RenderPDF);
