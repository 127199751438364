import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MultiSelect from 'UI/multiSelect';
import CloseIcon from 'assets/icons/close';
import filter from 'assets/svg/filter.svg';
// import starImg from 'assets/svg/star_filled.svg';
// import fireImg from 'assets/svg/burning-fire.svg';
import LabelInput from 'UI/labelInput';
import DateRangeSelection from 'UI/dateRangeSelection';
import { ROLES } from 'utils/consts';
import { getDateStr } from 'utils/common';

class Filters extends Component {
  constructor(props) {
    super(props);
    const modState = this.getAppliedFilters();
    this.state = {
      ...this.getInitialState(),
      ...modState,
    };
    const { roles } = this.props;
    this.isAdminOPTL = (roles.includes(ROLES.TEAM_LEAD)
      || roles.includes(ROLES.ADMIN) || roles.includes(ROLES.FILTRATION_TEAM_LEAD)
      || roles.includes(ROLES.OPERATION));
  }

  getInitialState = () => {
    return {
      starred: false,
      live: false,
      selectedStatus: {},
      selectedSource: {},
      places: {
        value: '',
        valid: false,
        items: [],
      },
      tripDate: '',
      tripDates: { from: '', to: '' },
      requestDate: '',
      requestDates: { from: '', to: '' },
      expert: {
        value: '',
        valid: false,
        items: [],
      },
    };
  };

  getAppliedFilters = () => {
    const { filters } = this.props;
    const modState = {};
    if (filters.starred) {
      modState.starred = true;
    }
    if (filters.interested) {
      modState.interested = true;
    }
    if (filters.live) {
      modState.live = true;
    }
    if (filters.tripDates && filters.tripDates.from) {
      modState.tripDates = filters.tripDates;
      modState.tripDate = getDateStr(filters.tripDates.from, filters.tripDates.to);
    }
    if (filters.requestDates && filters.requestDates.from) {
      modState.requestDates = filters.requestDates;
      modState.requestDate = getDateStr(filters.requestDates.from, filters.requestDates.to);
    }
    if (filters.expert && filters.expert.items.length) {
      modState.expert = filters.expert;
    }
    if (filters.places && filters.places.items.length) {
      modState.places = filters.places;
    }
    if (filters.selectedStatus) {
      modState.selectedStatus = filters.selectedStatus;
    }
    if (filters.selectedSource) {
      modState.selectedSource = filters.selectedSource;
    }
    return modState;
  };

  handleSearchChange = (field, value) => {
    const { getSuggestions } = this.props;
    const fField = field === 'places' ? 'destinations' : 'experts';
    getSuggestions(fField, value);
    const { [field]: stateField } = this.state;
    const modField = {
      ...stateField,
      value,
      valid: false,
    };
    this.setState({ [field]: modField });
  };

  handleSelection = (field, item, accessor) => {
    const { [field]: stateField } = this.state;
    const modField = { ...stateField };
    const index = modField.items.findIndex((mf) => mf[accessor] === item[accessor]);
    if (index === -1) {
      modField.items = [item, ...modField.items];
      modField.value = '';
      modField.valid = true;
      this.setState({ [field]: modField });
    }
  };

  removeHandler = (field, item, accessor) => {
    const { [field]: stateField } = this.state;
    const modField = {
      ...stateField,
      items: stateField.items.filter((itm) => itm[accessor] !== item[accessor]),
    };
    this.setState({ [field]: modField });
  };

  handleFilterSelection = (field, title, item) => {
    const { [field]: filterState } = this.state;
    if (field === 'starred') {
      this.setState((prevState) => ({ [field]: !prevState[field] }));
      return;
    }
    if (field === 'interested') {
      this.setState((prevState) => ({ [field]: !prevState[field] }));
      return;
    }
    if (field === 'live') {
      this.setState((prevState) => ({ [field]: !prevState[field], selectedStatus: {} }));
      return;
    }
    const modFilters = { ...filterState };
    if (modFilters[title]) {
      delete modFilters[title];
    } else {
      modFilters[title] = item;
    }
    this.setState({ [field]: modFilters, live: false });
  };

  handleDateSelection = (field, date, dateStr) => {
    this.setState({ [field]: dateStr, [`${field}s`]: date });
  };

  resetFilters = () => {
    this.setState({ ...this.getInitialState() });
  };

  onUpdate = () => {
    const { onUpdate } = this.props;
    const {
      places, tripDates, requestDates, starred, interested, live,
      expert, selectedSource, selectedStatus,
    } = this.state;
    const filters = {
      places,
      tripDates,
      requestDates,
      starred,
      interested,
      live,
      expert,
      selectedSource,
      selectedStatus,
    };
    onUpdate(filters);
  };

  render() {
    const {
      classes,
      onDismiss,
      requestFilters,
      suggestions,
    } = this.props;
    const {
      interested,
      live,
      places,
      requestDate,
      requestDates,
      tripDate,
      tripDates,
      expert,
      selectedSource,
      selectedStatus,
    } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <img src={filter} alt="filter" className={classes.filterImg} />
          <Typography className={classes.title}>Filters</Typography>
          <IconButton
            className={classes.closeBtn}
            onClick={onDismiss}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.body}>
          <Button
            className={classes.resetBtn}
            onClick={this.resetFilters}
          >
            RESET FILTERS
          </Button>
          <Typography className={classes.labelHeading}>CUSTOM</Typography>
          <div className={classes.filterSection}>
            <button
              type="button"
              className={clsx(classes.chipBtn, interested && classes.selectedChip)}
              onClick={() => this.handleFilterSelection('interested')}
            >
              <Typography
                className={clsx(classes.avatar, interested && classes.selectedAvatar)}
              >
                {requestFilters.interested}
              </Typography>
              <Typography className={classes.chipTitle}>Interested</Typography>
            </button>
            <button
              type="button"
              style={{ width: `${Math.max('Active Requests'.length * 10.8, 140)}px` }}
              className={clsx(classes.chipBtn, live && classes.selectedChip)}
              onClick={() => this.handleFilterSelection('live')}
            >
              <Typography
                className={clsx(classes.avatar, live && classes.selectedAvatar)}
              >
                -
              </Typography>
              <Typography className={classes.chipTitle}>Active Requests</Typography>
            </button>
          </div>
          <Typography className={classes.labelHeading}>STATUS</Typography>
          <div className={classes.filterSection}>
            {requestFilters.status && requestFilters.status.map((status) => {
              const { title, count } = status;
              const width = `${Math.max(title.length * 10.8, 140)}px`;
              const selected = selectedStatus[title];
              return (
                <button
                  type="button"
                  key={title}
                  className={clsx(classes.chipBtn, selected && classes.selectedChip)}
                  style={{ width }}
                  onClick={() => this.handleFilterSelection('selectedStatus', title, status)}
                >
                  <Typography
                    className={clsx(classes.avatar, selected && classes.selectedAvatar)}
                  >
                    {count}
                  </Typography>
                  <Typography className={classes.chipTitle}>{title}</Typography>
                </button>
              );
            })}
          </div>
          <div>
            <Typography className={classes.labelHeading}>SOURCE</Typography>
            <div className={classes.filterSection}>
              {requestFilters.source && requestFilters.source.map((source) => {
                const { title } = source;
                const width = `${Math.max(title.length * 10.8, 140)}px`;
                const selected = selectedSource[title];
                return (
                  <button
                    type="button"
                    key={title}
                    className={clsx(classes.chipBtn, selected && classes.selectedChip)}
                    style={{ width }}
                    onClick={() => this.handleFilterSelection('selectedSource', title, source)}
                  >
                    <Typography className={classes.chipTitle}>{title}</Typography>
                  </button>
                );
              })}
            </div>
          </div>
          <MultiSelect
            label="DESTINATION/S"
            placeholder="Choose destination"
            selections={places.items}
            value={places.value}
            extraClass={classes.wrapper}
            popupClass={classes.popupClass}
            data={suggestions.destinations}
            accessor="name"
            removeHandler={(item) => this.removeHandler('places', item, 'name')}
            onChange={(val) => this.handleSearchChange('places', val)}
            onSelected={(item) => this.handleSelection('places', item, 'name')}
          />
          <LabelInput label="REQUEST DATES" extraClass={classes.wrapper}>
            <DateRangeSelection
              value={requestDate}
              dates={requestDates}
              numberOfMonths={1}
              allowBefore
              popupClass={classes.calendarPopup}
              onSelected={(date, dateStr) => this.handleDateSelection('requestDate', date, dateStr)}
            />
          </LabelInput>
          <LabelInput label="TRIP DATES" extraClass={classes.wrapper}>
            <DateRangeSelection
              value={tripDate}
              dates={tripDates}
              numberOfMonths={1}
              allowBefore
              popupClass={classes.calendarPopup}
              onSelected={(date, dateStr) => this.handleDateSelection('tripDate', date, dateStr)}
            />
          </LabelInput>
          {this.isAdminOPTL ? (
            <MultiSelect
              label="OWNER"
              placeholder="Choose owner"
              extraClass={classes.wrapper}
              popupClass={classes.popupClass}
              data={suggestions.experts}
              selections={expert.items}
              value={expert.value}
              accessor="fullName"
              secondaryAccessor="type"
              uniqueId="_id"
              removeHandler={(item) => this.removeHandler('expert', item, 'fullName')}
              onChange={(val) => this.handleSearchChange('expert', val)}
              onSelected={(item) => this.handleSelection('expert', item, 'fullName')}
            />
          ) : null}
        </div>
        <div className={classes.footer}>
          <Button
            aria-label="close"
            onClick={onDismiss}
            className={classes.dismissBtn}
          >
            Close
          </Button>
          <Button
            aria-label="update"
            onClick={this.onUpdate}
            className={classes.updateBtn}
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    flexDirection: 'column',
    width: 480,
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 40px',
    height: 64,
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.colors.border}`,
  },
  filterImg: {
    width: '40px',
    height: '40px',
    backgroundColor: theme.colors.primaryLight,
    padding: 10,
    boxSizing: 'border-box',
    borderRadius: '50%',
  },
  title: {
    marginLeft: 10,
    color: theme.colors.textDark,
    fontSize: 18,
    fontWeight: 'bold',
  },
  closeBtn: {
    marginLeft: 'auto',
    padding: 4,
  },
  resetBtn: {
    marginLeft: 'auto',
    marginTop: 10,
    color: theme.colors.primary,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginBottom: 14,
  },
  labelHeading: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginBottom: 14,
  },
  body: {
    overflowY: 'auto',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '0 40px',
    maxHeight: '86vh',
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 14,
  },
  chipBtn: {
    cursor: 'pointer',
    outline: 'none',
    border: `1px solid ${theme.colors.border}`,
    height: 30,
    borderRadius: 15,
    padding: 0,
    width: 140,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
    marginBottom: 14,
    backgroundColor: theme.colors.white,
  },
  selectedChip: {
    border: `1px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.primaryLight,
  },
  selectedAvatar: {
    color: `${theme.colors.white} !important`,
    backgroundColor: `${theme.colors.primaryBackground} !important`,
  },
  starImg: {
    borderRadius: '50%',
    height: 28,
    width: 28,
    boxSizing: 'border-box',
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.underline,
    color: theme.colors.primaryBackground,
  },
  avatar: {
    borderRadius: '50%',
    height: 28,
    width: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.underline,
    color: theme.colors.primaryBackground,
    fontSize: 12,
  },
  chipTitle: {
    marginLeft: 10,
    color: theme.colors.textDark,
    fontSize: 14,
  },
  wrapper: {
    marginBottom: 14,
  },
  popupClass: {
    top: 'unset',
    bottom: 41,
  },
  calendarPopup: {
    top: -310,
    width: 245,
    left: 0,
    '& > div > div > div:last-child': {
      minWidth: 0,
    },
  },
  footer: {
    width: 480,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 40px 10px 40px',
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.colors.white,
  },
  dismissBtn: {
    padding: '5px 10px',
    borderRadius: 25,
    textAlign: 'center',
    width: 132,
    backgroundColor: theme.colors.white,
    color: theme.colors.primaryBackground,
    border: `1px solid ${theme.colors.primaryBackground}`,
    fontSize: 18,
    fontWeight: 'bold',
  },
  updateBtn: {
    marginLeft: 20,
    width: 132,
    textAlign: 'center',
    borderRadius: 25,
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    fontSize: 18,
    fontWeight: 'bold',
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
});

Filters.propTypes = {
  classes: PropTypes.object,
  getSuggestions: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  suggestions: PropTypes.object.isRequired,
  requestFilters: PropTypes.object.isRequired,
  filters: PropTypes.object,
  oldFilters: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(Filters);
