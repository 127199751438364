import React, { Component } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/styles/withStyles';
import Button from 'UI/button';
import Header from 'UI/header';
import DragnDrop from 'UI/DragnDrop';
import sendImg from 'assets/svg/send.svg';
import addIcon from 'assets/svg/create.svg';
// import deleteIcon from 'assets/svg/delete.svg';
// import pdfImg from 'assets/svg/pdf.svg';
import { validateURL } from 'utils/common';
import Footer from 'UI/footer';
import DocumentCards from 'UI/documentCards';

class SendItinerary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: '',
      fileDetails: {},
      errorMsg: '',
      valid: false,
      modified: false,
      ...this.processData(),
    };
    this.dropInput = React.createRef();
    this.validTypes = ['application/pdf'];
  }

  processData = () => {
    const { mode, itineraryData } = this.props;
    let changes = {};
    if (mode === 'itinerary_details') {
      changes = {
        valid: true,
      };
      if (itineraryData._id) {
        changes.fileDetails = {
          name: itineraryData.fileName || ' ',
          link: itineraryData.link || '',
          _id: itineraryData._id,
        };
      } else if (itineraryData.file) {
        changes.fileDetails = {
          docType: itineraryData.file.type,
          name: itineraryData.file.name,
          file: itineraryData.file,
        };
      } else {
        changes.link = itineraryData.link;
      }
    }
    return changes;
  };

  handleChange = (field, value) => {
    this.setState({
      [field]: value,
      modified: true,
    }, () => {
      this.validityChecker();
      this.updateItineraryData();
    });
  };

  // handleFileNameChange = (value) => {
  //   this.setState((prevState) => ({
  //     fileDetails: {
  //       ...prevState.fileDetails,
  //       name: value,
  //     },
  //   }));
  // };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let modDetails = {};
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { type, name } = file;
        if (type && this.validTypes.some((v) => v === type)) {
          modDetails = {
            docType: type,
            file,
            name,
          };
        }
      }
      if (modDetails.file.size > 20971520) {
        this.setState({ valid: false, errorMsg: 'Max allowed file size is 20MB' });
      } else {
        this.setState({
          fileDetails: modDetails,
          modified: true,
          link: '',
        }, () => {
          this.validityChecker();
          this.updateItineraryData();
        });
      }
      e.dataTransfer.clearData();
    }
  };

  handleUpload = (e) => {
    const params = {
      preventDefault: () => {
      },
      stopPropagation: () => {
      },
      dataTransfer: {
        files: e.target.files,
        clearData: () => {
        },
      },
    };
    this.handleDrop(params);
    this.dropInput.current.value = '';
  };

  removeFile = () => {
    this.setState({
      fileDetails: {},
      modified: true,
    }, this.validityChecker);
  };

  validityChecker = () => {
    const { link, fileDetails } = this.state;
    let valid = false;
    if (fileDetails.name) {
      valid = true;
    } else if (link && validateURL(link)) {
      valid = true;
    }
    this.setState({ valid });
  };

  updateItineraryData = () => {
    const { onUpdate, mode } = this.props;
    const { fileDetails, link, modified } = this.state;
    if (modified && mode === 'itinerary_details') {
      const params = {
        file: fileDetails.file ? fileDetails.file : undefined,
        link: link.length ? link : undefined,
        name: fileDetails.name || `${link.slice(0, 10)}...`,
        modified: true,
      };
      onUpdate('itineraryData', params);
    }
  };

  sendHandler = () => {
    const { onSubmit } = this.props;
    const { fileDetails, link } = this.state;
    if (link.length && !validateURL(link)) {
      this.setState({ errorMsg: 'Invalid url' });
      return;
    }
    // if (mode === 'itinerary_details') {
    // if (modified) {
    //   const params = {
    //     file: fileDetails.file ? fileDetails.file : undefined,
    //     link: link.length ? link : undefined,
    //     name: fileDetails.name || `${link.slice(0, 10)}...`,
    //     modified: true,
    //   };
    //   onUpdate('itineraryData', params);
    // }
    //   onSubmit();
    //   return;
    // }
    onSubmit(fileDetails, link);
  };

  render() {
    const {
      classes, onDismiss, extraClass,
      isUpdatingStatus, mode,
    } = this.props;
    const {
      link,
      fileDetails,
      errorMsg,
      valid,
    } = this.state;
    return (
      <DragnDrop
        extraClass={clsx(classes.parent, extraClass)}
        fileDropHandler={this.handleDrop}
      >
        <div className={clsx(classes.container, extraClass)}>
          {mode !== 'itinerary_details' ? (
            <Header
              title="Send Itinerary"
              img={sendImg}
              onDismiss={onDismiss}
            />
          ) : null}
          <div className={classes.body}>
            {(!fileDetails.file && !fileDetails._id) ? (
              <div className={classes.dropArea}>
                <input
                  type="file"
                  accept="application/pdf"
                  multiple="multiple"
                  ref={this.dropInput}
                  className={classes.dropBox}
                  onChange={this.handleUpload}
                />
                <Typography className={classes.dropMsgAdd}>
                  <img src={addIcon} alt="+" className={classes.addIcon} />
                  &nbsp; Upload Itinerary
                </Typography>
              </div>
            )
              : (
                // <div className={classes.previewBox}>
                //   <img alt="pdf" className={classes.pdfImg} src={pdfImg} />
                //   <InputBase
                //     value={fileDetails.name}
                //     onChange={(e) => this.handleFileNameChange(e.target.value)}
                //   />
                //   <IconButton
                //     className={classes.iconButton}
                //     onClick={this.removeFile}
                //   >
                //     <img src={deleteIcon} className={classes.trash} alt="x" />
                //   </IconButton>
                // </div>
                <DocumentCards
                  documents={[fileDetails.file || fileDetails]}
                  onRemove={this.removeFile}
                />
              )}
            <Typography className={classes.or}>OR</Typography>
            <InputBase
              placeholder="Add link"
              value={link}
              disabled={Boolean(fileDetails.file)}
              classes={{
                focused: classes.focused,
              }}
              className={classes.addLink}
              onChange={(e) => this.handleChange('link', e.target.value)}
            />
          </div>
          <Footer
            errorMsg={errorMsg}
          >
            <Button
              disabled={!valid}
              loading={isUpdatingStatus}
              className={classes.sendItineraryBtn}
              onClick={this.sendHandler}
            >
              {mode === 'itinerary_details' ? 'Continue' : 'Send'}
            </Button>
          </Footer>
        </div>
      </DragnDrop>
    );
  }
}

const styles = (theme) => ({
  parent: {
    minWidth: 520,
    height: 460,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 520,
    minHeight: 440,
    borderRadius: 10,
    backgroundColor: theme.colors.white,
  },
  body: {
    flex: 1,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  dropArea: {
    width: '100%',
    height: 70,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  dropMsgAdd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.colors.primaryBackground,
  },
  previewBox: {
    width: 200,
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    borderRadius: 10,
    border: `1px solid ${theme.colors.border}`,
    boxShadow: '0 4px 6px 0px rgba(0,0,0,0.2)',
  },
  pdfImg: {
    height: 50,
    width: 50,
    objectFit: 'contain',
    marginRight: 10,
  },
  iconButton: {
    padding: 4,
    width: 20,
    height: 20,
  },
  trash: {
    width: 14,
    height: 14,
    marginTop: -3,
  },
  or: {
    fontSize: 16,
    textAlign: 'center',
    margin: '20px 0',
    color: theme.colors.textLight,
  },
  addLink: {
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
    padding: '6px 5px 6px 15px',
  },
  focused: {
    border: `1px solid ${theme.colors.primary}`,
  },
  sendItineraryBtn: {
    marginTop: 'auto',
    width: 140,
    borderRadius: 25,
    marginLeft: 20,
    padding: '5px 10px',
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    fontSize: 16,
    letterSpacing: 0.5,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
});

SendItinerary.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  mode: PropTypes.string,
  itineraryData: PropTypes.object,
  onUpdate: PropTypes.func,
  extraClass: PropTypes.string,
  isUpdatingStatus: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SendItinerary);
