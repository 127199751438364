import {
  ACCEPT_PAYMENT_FAILURE,
  ACCEPT_PAYMENT_INIT,
  ACCEPT_PAYMENT_SUCCESS,
  ADD_REQUEST_FAILURE,
  ADD_REQUEST_INIT,
  ADD_REQUEST_SUCCESS,
  BOOKING_ACTION_FAILURE,
  BOOKING_ACTION_INIT,
  BOOKING_ACTION_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  CANCEL_BOOKING_INIT,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_ITINERARY_FAILURE,
  CANCEL_ITINERARY_INIT,
  CANCEL_ITINERARY_SUCCESS,
  DELETE_ITINERARY_FAILURE,
  DELETE_ITINERARY_INIT,
  DELETE_ITINERARY_SUCCESS,
  GENERATE_VOUCHER_FAILURE,
  GENERATE_VOUCHER_INIT,
  GENERATE_VOUCHER_SUCCESS,
  GET_B2B_WALLET_BALANCE_FAILURE,
  GET_B2B_WALLET_BALANCE_INIT,
  GET_B2B_WALLET_BALANCE_SUCCESS,
  GET_BOOKINGS_FAILURE,
  GET_BOOKINGS_INIT,
  GET_BOOKINGS_SUCCESS,
  GET_REFUND_DATA_FAILURE,
  GET_REFUND_DATA_INIT,
  GET_REFUND_DATA_SUCCESS,
  GET_REQUEST_BY_ID_FAILURE,
  GET_REQUEST_BY_ID_INIT,
  GET_REQUEST_BY_ID_SUCCESS,
  GET_REQUEST_FILTERS_FAILURE,
  GET_REQUEST_FILTERS_INIT,
  GET_REQUEST_FILTERS_SUCCESS,
  GET_VIRTUAL_ACCOUNT_DATA_FAILURE,
  GET_VIRTUAL_ACCOUNT_DATA_INIT,
  GET_VIRTUAL_ACCOUNT_DATA_SUCCESS,
  INTERESTED_REQUEST_FAILURE,
  INTERESTED_REQUEST_INIT,
  INTERESTED_REQUEST_SUCCESS,
  MARK_BOOKING_TRANSACTION_FAILURE,
  MARK_BOOKING_TRANSACTION_INIT,
  MARK_BOOKING_TRANSACTION_SUCCESS,
  MODIFY_INSTALLMENTS_FAILURE,
  MODIFY_INSTALLMENTS_INIT,
  MODIFY_INSTALLMENTS_SUCCESS,
  REASSIGN_REQUEST_FAILURE,
  REASSIGN_REQUEST_INIT,
  REASSIGN_REQUEST_SUCCESS,
  REFUND_FROM_WALLET_FAILURE,
  REFUND_FROM_WALLET_INIT,
  REFUND_FROM_WALLET_SUCCESS,
  REFUND_PAYMENT_FAILURE,
  REFUND_PAYMENT_INIT,
  REFUND_PAYMENT_SUCCESS,
  REFUND_TO_WALLET_FAILURE,
  REFUND_TO_WALLET_INIT,
  REFUND_TO_WALLET_SUCCESS,
  REMARK_UPDATE_FAILURE,
  REMARK_UPDATE_INIT,
  REMARK_UPDATE_SUCCESS,
  REQUEST_LIST_FAILURE,
  REQUEST_LIST_INIT,
  REQUEST_LIST_SUCCESS,
  REQUEST_SEARCH_FAILURE,
  REQUEST_SEARCH_INIT,
  REQUEST_SEARCH_SUCCESS,
  RESEND_CONFIRMATION_FAILURE,
  RESEND_CONFIRMATION_INIT,
  RESEND_CONFIRMATION_SUCCESS,
  RESET_DASHBOARD_RESP,
  SAVE_AS_TEMPLATE_ITINERARY_FAILURE,
  SAVE_AS_TEMPLATE_ITINERARY_INIT,
  SAVE_AS_TEMPLATE_ITINERARY_SUCCESS,
  SEND_ITINERARY_FAILURE,
  SEND_ITINERARY_INIT,
  SEND_ITINERARY_SUCCESS,
  STAR_REQUEST_FAILURE,
  STAR_REQUEST_INIT,
  STAR_REQUEST_SUCCESS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_INIT,
  UPDATE_ORDER_SUCCESS,
  UPDATE_REQUEST_DETAILS_FAILURE,
  UPDATE_REQUEST_DETAILS_INIT,
  UPDATE_REQUEST_DETAILS_SUCCESS,
  UPDATE_STATUS_FAILURE,
  UPDATE_STATUS_INIT,
  UPDATE_STATUS_SUCCESS,
} from '../../actionTypes';

export const requestListSuccess = (data) => {
  return {
    type: REQUEST_LIST_SUCCESS,
    data,
  };
};

export const requestListFailure = (error) => {
  return {
    type: REQUEST_LIST_FAILURE,
    error,
  };
};

export const getRequestList = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REQUEST_LIST_INIT,
    payload,
  };
};

export const requestSearchSuccess = (data) => {
  return {
    type: REQUEST_SEARCH_SUCCESS,
    data,
  };
};

export const requestSearchFailure = (error) => {
  return {
    type: REQUEST_SEARCH_FAILURE,
    error,
  };
};

export const requestSearch = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REQUEST_SEARCH_INIT,
    payload,
  };
};

// export const getTasksSuccess = (data) => {
//   return {
//     type: GET_TASKS_SUCCESS,
//     data,
//   };
// };

// export const getTasksFailure = (error) => {
//   return {
//     type: GET_TASKS_FAILURE,
//     error,
//   };
// };

// export const getTasks = (params, headers) => {
//   const payload = { params, headers };
//   return {
//     type: GET_TASKS_INIT,
//     payload,
//   };
// };

export const addRequestSuccess = (data) => {
  return {
    type: ADD_REQUEST_SUCCESS,
    data,
  };
};

export const addRequestFailure = (error) => {
  return {
    type: ADD_REQUEST_FAILURE,
    error,
  };
};

export const addRequest = (params, headers) => {
  const payload = { params, headers };
  return {
    type: ADD_REQUEST_INIT,
    payload,
  };
};

export const updateRequestDetailsSuccess = (data) => {
  return {
    type: UPDATE_REQUEST_DETAILS_SUCCESS,
    data,
  };
};

export const updateRequestDetailsFailure = (error) => {
  return {
    type: UPDATE_REQUEST_DETAILS_FAILURE,
    error,
  };
};

export const updateRequestDetails = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_REQUEST_DETAILS_INIT,
    payload,
  };
};

export const sendItinerarySuccess = (data) => {
  return {
    type: SEND_ITINERARY_SUCCESS,
    data,
  };
};

export const sendItineraryFailure = (error) => {
  return {
    type: SEND_ITINERARY_FAILURE,
    error,
  };
};

export const sendItinerary = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SEND_ITINERARY_INIT,
    payload,
  };
};

export const reAssignRequestSuccess = (data) => {
  return {
    type: REASSIGN_REQUEST_SUCCESS,
    data,
  };
};

export const reAssignRequestFailure = (error) => {
  return {
    type: REASSIGN_REQUEST_FAILURE,
    error,
  };
};

export const reAssignRequest = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REASSIGN_REQUEST_INIT,
    payload,
  };
};

export const updateStatusSuccess = (data) => {
  return {
    type: UPDATE_STATUS_SUCCESS,
    data,
  };
};

export const updateStatusFailure = (error) => {
  return {
    type: UPDATE_STATUS_FAILURE,
    error,
  };
};

export const updateStatus = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_STATUS_INIT,
    payload,
  };
};

// export const submitItineraryDetailsSuccess = (data) => {
//   return {
//     type: SUBMIT_ITINERARY_DETAILS_SUCCESS,
//     data,
//   };
// };
//
// export const submitItineraryDetailsFailure = (error) => {
//   return {
//     type: SUBMIT_ITINERARY_DETAILS_FAILURE,
//     error,
//   };
// };
//
// export const submitItineraryDetails = (params, headers) => {
//   const payload = { params, headers };
//   return {
//     type: SUBMIT_ITINERARY_DETAILS_INIT,
//     payload,
//   };
// };

export const deleteItinerarySuccess = (data) => {
  return {
    type: DELETE_ITINERARY_SUCCESS,
    data,
  };
};

export const deleteItineraryFailure = (error) => {
  return {
    type: DELETE_ITINERARY_FAILURE,
    error,
  };
};

export const deleteItinerary = (params, headers) => {
  const payload = { params, headers };
  return {
    type: DELETE_ITINERARY_INIT,
    payload,
  };
};

export const saveAsTemplateItinerary = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SAVE_AS_TEMPLATE_ITINERARY_INIT,
    payload,
  };
};

export const saveAsTemplateItinerarySuccess = (data) => {
  return {
    type: SAVE_AS_TEMPLATE_ITINERARY_SUCCESS,
    data,
  };
};

export const saveAsTemplateItineraryFailure = (error) => {
  return {
    type: SAVE_AS_TEMPLATE_ITINERARY_FAILURE,
    error,
  };
};

export const resetDashboardResp = () => {
  return {
    type: RESET_DASHBOARD_RESP,
  };
};

export const remarkUpdateSuccess = (data) => {
  return {
    type: REMARK_UPDATE_SUCCESS,
    data,
  };
};

export const remarkUpdateFailure = (error) => {
  return {
    type: REMARK_UPDATE_FAILURE,
    error,
  };
};

export const remarkUpdate = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REMARK_UPDATE_INIT,
    payload,
  };
};

export const getRequestFiltersSuccess = (data) => {
  return {
    type: GET_REQUEST_FILTERS_SUCCESS,
    data,
  };
};

export const getRequestFiltersFailure = (error) => {
  return {
    type: GET_REQUEST_FILTERS_FAILURE,
    error,
  };
};

export const getRequestFilters = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_REQUEST_FILTERS_INIT,
    payload,
  };
};

export const starRequestSuccess = (data) => {
  return {
    type: STAR_REQUEST_SUCCESS,
    data,
  };
};

export const starRequestFailure = (error) => {
  return {
    type: STAR_REQUEST_FAILURE,
    error,
  };
};

export const starRequest = (params, headers) => {
  const payload = { params, headers };
  return {
    type: STAR_REQUEST_INIT,
    payload,
  };
};

export const interestedRequestSuccess = (data) => {
  return {
    type: INTERESTED_REQUEST_SUCCESS,
    data,
  };
};

export const interestedRequestFailure = (error) => {
  return {
    type: INTERESTED_REQUEST_FAILURE,
    error,
  };
};

export const interestedRequest = (params, headers) => {
  const payload = { params, headers };
  return {
    type: INTERESTED_REQUEST_INIT,
    payload,
  };
};

export const updateOrderSuccess = (data) => {
  return {
    type: UPDATE_ORDER_SUCCESS,
    data,
  };
};

export const updateOrderFailure = (error) => {
  return {
    type: UPDATE_ORDER_FAILURE,
    error,
  };
};

export const updateOrder = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_ORDER_INIT,
    payload,
  };
};

export const modifyInstallmentsSuccess = (data) => {
  return {
    type: MODIFY_INSTALLMENTS_SUCCESS,
    data,
  };
};

export const modifyInstallmentsFailure = (error) => {
  return {
    type: MODIFY_INSTALLMENTS_FAILURE,
    error,
  };
};

export const modifyInstallments = (params, headers) => {
  const payload = { params, headers };
  return {
    type: MODIFY_INSTALLMENTS_INIT,
    payload,
  };
};

export const bookingActionSuccess = (data) => {
  return {
    type: BOOKING_ACTION_SUCCESS,
    data,
  };
};

export const bookingActionFailure = (error) => {
  return {
    type: BOOKING_ACTION_FAILURE,
    error,
  };
};

export const bookingAction = (params, headers) => {
  const payload = { params, headers };
  return {
    type: BOOKING_ACTION_INIT,
    payload,
  };
};

export const acceptPaymentSuccess = (data) => {
  return {
    type: ACCEPT_PAYMENT_SUCCESS,
    data,
  };
};

export const acceptPaymentFailure = (error) => {
  return {
    type: ACCEPT_PAYMENT_FAILURE,
    error,
  };
};

export const acceptPayment = (params, headers) => {
  const payload = { params, headers };
  return {
    type: ACCEPT_PAYMENT_INIT,
    payload,
  };
};

export const getRequestByIDSuccess = (data) => {
  return {
    type: GET_REQUEST_BY_ID_SUCCESS,
    data,
  };
};

export const getRequestByIDFailure = (error) => {
  return {
    type: GET_REQUEST_BY_ID_FAILURE,
    error,
  };
};

export const getRequestByID = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_REQUEST_BY_ID_INIT,
    payload,
  };
};

export const resendConfirmationSuccess = (data) => {
  return {
    type: RESEND_CONFIRMATION_SUCCESS,
    data,
  };
};

export const resendConfirmationFailure = (error) => {
  return {
    type: RESEND_CONFIRMATION_FAILURE,
    error,
  };
};

export const resendConfirmation = (params, headers) => {
  const payload = { params, headers };
  return {
    type: RESEND_CONFIRMATION_INIT,
    payload,
  };
};

export const generateVoucherSuccess = (data) => {
  return {
    type: GENERATE_VOUCHER_SUCCESS,
    data,
  };
};

export const generateVoucherFailure = (error) => {
  return {
    type: GENERATE_VOUCHER_FAILURE,
    error,
  };
};

export const generateVoucher = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GENERATE_VOUCHER_INIT,
    payload,
  };
};

export const refundPaymentSuccess = (data) => {
  return {
    type: REFUND_PAYMENT_SUCCESS,
    data,
  };
};

export const refundPaymentFailure = (error) => {
  return {
    type: REFUND_PAYMENT_FAILURE,
    error,
  };
};

export const refundPayment = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REFUND_PAYMENT_INIT,
    payload,
  };
};

export const cancelItinerarySuccess = (data) => {
  return {
    type: CANCEL_ITINERARY_SUCCESS,
    data,
  };
};

export const cancelItineraryFailure = (error) => {
  return {
    type: CANCEL_ITINERARY_FAILURE,
    error,
  };
};

export const cancelItinerary = (params, headers) => {
  const payload = { params, headers };
  return {
    type: CANCEL_ITINERARY_INIT,
    payload,
  };
};

export const refundFromWalletSuccess = (data) => {
  return {
    type: REFUND_FROM_WALLET_SUCCESS,
    data,
  };
};

export const refundFromWalletFailure = (error) => {
  return {
    type: REFUND_FROM_WALLET_FAILURE,
    error,
  };
};

export const refundFromWallet = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REFUND_FROM_WALLET_INIT,
    payload,
  };
};

export const refundToWalletSuccess = (data) => {
  return {
    type: REFUND_TO_WALLET_SUCCESS,
    data,
  };
};

export const refundToWalletFailure = (error) => {
  return {
    type: REFUND_TO_WALLET_FAILURE,
    error,
  };
};

export const refundToWallet = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REFUND_TO_WALLET_INIT,
    payload,
  };
};

export const markBookingTransactionSuccess = (data) => {
  return {
    type: MARK_BOOKING_TRANSACTION_SUCCESS,
    data,
  };
};

export const markBookingTransactionFailure = (error) => {
  return {
    type: MARK_BOOKING_TRANSACTION_FAILURE,
    error,
  };
};

export const markBookingTransaction = (params, headers, requestInfo) => {
  const payload = { params, headers, requestInfo };
  return {
    type: MARK_BOOKING_TRANSACTION_INIT,
    payload,
  };
};

export const cancelBookingSuccess = (data) => {
  return {
    type: CANCEL_BOOKING_SUCCESS,
    data,
  };
};

export const cancelBookingFailure = (error) => {
  return {
    type: CANCEL_BOOKING_FAILURE,
    error,
  };
};

export const cancelBooking = (params, headers) => {
  const payload = { params, headers };
  return {
    type: CANCEL_BOOKING_INIT,
    payload,
  };
};

export const getBookingsSuccess = (data) => {
  return {
    type: GET_BOOKINGS_SUCCESS,
    data,
  };
};

export const getBookingsFailure = (error) => {
  return {
    type: GET_BOOKINGS_FAILURE,
    error,
  };
};

export const getBookings = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_BOOKINGS_INIT,
    payload,
  };
};

export const getB2bWalletBalanceSuccess = (data) => {
  return {
    type: GET_B2B_WALLET_BALANCE_SUCCESS,
    data,
  };
};

export const getB2bWalletBalanceFailure = (error) => {
  return {
    type: GET_B2B_WALLET_BALANCE_FAILURE,
    error,
  };
};

export const getB2bWalletBalance = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_B2B_WALLET_BALANCE_INIT,
    payload,
  };
};

export const getRefundDataSuccess = (data) => {
  return {
    type: GET_REFUND_DATA_SUCCESS,
    data,
  };
};

export const getRefundDataFailure = ({ error, orderId }) => {
  return {
    type: GET_REFUND_DATA_FAILURE,
    error,
    orderId,
  };
};

export const getRefundData = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_REFUND_DATA_INIT,
    payload,
  };
};

export const getVirtualAccountDataSuccess = (data) => {
  return {
    type: GET_VIRTUAL_ACCOUNT_DATA_SUCCESS,
    data,
  };
};

export const getVirtualAccountDataFailure = ({ error, orderId }) => {
  return {
    type: GET_VIRTUAL_ACCOUNT_DATA_FAILURE,
    error,
    orderId,
  };
};

export const getVirtualAccountData = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_VIRTUAL_ACCOUNT_DATA_INIT,
    payload,
  };
};
