export const endPoints = {
  /* auth */
  login: 'expert/oauth/google/getToken',
  logOut: 'expert/oauth/logOut',
  expertDetails: 'expert/details',
  notification: 'dashboard/notification/list',
  notificationRead: 'dashboard/notification/read',

  ping: 'dashboard/v2/ping',
  addNewRequest: 'request/create',
  deleteItinerary: 'request/delete/itinerary/',
  draftItinerary: 'request/draft/itinerary/',
  updateRequest: 'request/update',
  assign: 'request/assign',
  getRequest: 'request/listV2',
  getRequestByID: 'request/',
  requestFilters: 'request/filters',
  starRequest: 'request/starred',
  interestedRequest: 'request/markInterested',
  searchRequest: 'request/searchV2',
  updateStatus: 'request/status/update',
  orderModify: 'request/order/modify',
  modifyInstallments: 'request/order/modifyInstallments',
  acceptBooking: 'request/booking/accept',
  confirmBooking: 'request/booking/confirm',
  resendConfirmation: 'request/resend/communication',
  currencyConvert: 'currency/convert',
  launch: 'dashboard/v2/expert/launch',
  destSuggest: 'destination/suggest',
  citySuggest: 'city/suggest',
  currencySuggest: 'currency/suggest',
  countrySuggest: 'country/suggest',
  placeSuggest: 'place/suggest',
  experienceSuggest: 'experience/suggest',
  itineraryTemplates: 'itinerary/templates/suggest',
  airportSuggest: 'place/suggestAirport',
  supplierSuggest: 'supplier/suggest',
  expertSuggest: 'expert/suggest',
  staySuggest: 'stay/suggest',
  registerToken: 'dashboard/notification/token/add',
  uploadDocs: 'storage/signed-urls',
  acceptPayment: 'order/generateOtherChannelPayments',
  createRemark: 'remark/create',
  updateRemark: 'remark/',
  generateVoucher: 'request/saveVoucher',
  manualRefund: 'order/manualRefund',
  cancelItinerary: 'request/cancel/itinerary',
  refundFromWallet: 'wallet/refundMoneyFromWallet',
  refundMoney: 'order/refundMoneyToWallet',
  stayRooms: 'room/suggest',
  getItineraryPrice: 'itinerary/ITINERARY_ID/price',
  itineraryToggleTemplate: 'itinerary/ITINERARY_ID/toggleTemplate',

  /* inventory apis */
  getExperiences: 'experience/getExperiences',
  getExperienceForEdit: 'experience/getExperienceForEdit',
  updateExperience: 'experience/editExperience',

  /* stay apis */
  getStays: 'stay/getStays',
  // addStayFromGoogle: 'stay/addStayFromGoogleDetails',
  addStayFromGoogle: 'stay/addStayUsingPlaceId',
  getStayForEdit: 'stay/getStayForEdit',
  updateStay: 'stay/editStay',

  /* supplier apis */
  getSuppliers: 'supplier/suggest',
  addSupplier: 'supplier/add',
  getSupplierForEdit: 'supplier/getSupplierById',
  updateSupplier: 'supplier/edit',

  /* place apis */
  getPlaces: 'place/suggest',
  getPlaceForEdit: 'place/getPlaceById',
  addPlace: 'place/addPlaceByLatLong',
  // getSupplierForEdit: 'supplier/getSupplierById',
  // updateSupplier: 'supplier/edit',

  /* hotel apis */
  getHotels: 'hotel/suggest',
  updateHotel: 'hotel/edit',
  getHotelById: 'hotel/getHotelById',

  getTemplate: 'stay/getTemplates',
  toggleApprove: 'stay/approveStay',
  bookingTransaction: 'booking/supplier/pay',
  cancelBooking: 'booking/cancel',
  getBookings: 'booking',
  b2bPartnerWallet: 'wallet/getB2bPartnerWallet',
  orderRefundData: 'order/getRefundDataForOrder/',
  virtualAccount: 'order/getUserOrderRequest/',
  travellerUpdateDoc: 'traveller',
  travellerVerifyCustomerDocument: 'traveller',
  requestTraveller: 'traveller/expert',
  travellerUpdate: 'traveller/expert',
  verifyFlightDocuments: 'traveller/verifyFlightDocuments',
  mapTravellersToRequest: 'traveller/expert/mapTravellersToRequest',
  flightUploadDocUrl: 'traveller/expert/uploadTravellerFlightDocuments',
  getRequestFlightDocuments: 'traveller/expert/getRequestFlightDocuments',
};

export const baseURL = process.env.REACT_APP_ENV === 'production'
  ? 'https://api.flynote.com/api/' : 'https://api.staging.flynote.com/api/';
// export const baseURL = 'http://localhost:5000/api/';
