import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import { GET_EXPERIENCES } from '../../actionTypes';
import { getExperiencesFailure, getExperiencesSuccess } from './experiencesActions';

function* getExperiences(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getExperiences}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(getExperiencesSuccess(data.experiences));
    } else {
      yield put(getExperiencesFailure(data.message));
    }
  } catch (err) {
    yield put(getExperiencesFailure(err.message));
  }
}
/* eslint-disable import/prefer-default-export */
export function* experiencesSaga() {
  yield takeLatest(GET_EXPERIENCES, getExperiences);
}
