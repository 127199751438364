import React, { useState } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Autocomplete from 'UI/autocomplete';

const PhoneNumber = ({
  showCounter,
  label,
  extraClass,
  bodyClass,
  suggestions,
  dialCode,
  number = '',
  handleNumberChange,
  handleDialCodeChange,
  handleDialCodeSelect,
}) => {
  const classes = useStyles();
  const [focused, toggleFocus] = useState(false);
  const numLen = number.toString().length;
  const isValidNum = numLen >= 8 && numLen <= 12;
  return (
    <div className={clsx(classes.container, extraClass)}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div
        className={clsx(
          classes.phoneField,
          focused && classes.focused,
          bodyClass
        )}
      >
        <Autocomplete
          data={suggestions.countries}
          value={dialCode}
          extraClass={classes.autocomplete}
          popupClass={classes.popupClass}
          accessor="displayText"
          variant="no-outline"
          placeholder="+91"
          onChange={handleDialCodeChange}
          onSelected={handleDialCodeSelect}
        />
        <InputBase
          placeholder="1234567890"
          type="number"
          className={classes.bareInput}
          autoComplete="off"
          onFocus={() => toggleFocus(true)}
          onBlur={() => toggleFocus(false)}
          classes={{
            input: classes.input,
          }}
          value={number}
          onChange={(e) => handleNumberChange(e.target.value)}
        />
      </div>
      {showCounter ? (
        <Typography
          className={clsx(classes.counter, isValidNum && classes.validNum)}
        >
          {`${number ? number.length : 0} / 8-12`}
        </Typography>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    marginBottom: 10,
    color: theme.colors.textLight,
  },
  phoneField: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
  },
  focused: {
    border: `1px solid ${theme.colors.primary}`,
  },
  autocomplete: {
    alignSelf: 'center',
    width: 72,
    padding: '10px 5px 10px 15px',
    marginRight: 4,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  bareInput: {
    padding: '6px 5px 6px 5px',
  },
  input: {
    padding: 0,
    height: '100%',
  },
  popupClass: {
    width: 200,
    top: 30,
    left: -20,
  },
  counter: {
    marginTop: 6,
    fontWeight: 'bold',
    fontSize: 10,
    letterSpacing: 0.5,
    color: theme.colors.red,
  },
  validNum: {
    color: theme.colors.textLight,
  },
}));

PhoneNumber.propTypes = {
  showCounter: PropTypes.bool,
  label: PropTypes.string,
  extraClass: PropTypes.string,
  bodyClass: PropTypes.string,
  suggestions: PropTypes.object.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
  handleDialCodeChange: PropTypes.func.isRequired,
  handleDialCodeSelect: PropTypes.func.isRequired,
  dialCode: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PhoneNumber;
