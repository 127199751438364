import {
  GET_HOTELS,
  GET_HOTELS_SUCCESS,
  GET_HOTELS_FAILURE,
} from '../../actionTypes';

export const getHotels = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_HOTELS,
    payload,
  };
};

export const getHotelsFailure = (payload) => {
  return {
    type: GET_HOTELS_FAILURE,
    payload,
  };
};

export const getHotelsSuccess = (payload) => {
  return {
    type: GET_HOTELS_SUCCESS,
    payload,
  };
};
