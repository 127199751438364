import {
  GET_STAYS,
  GET_STAYS_SUCCESS,
  GET_STAYS_FAILURE,
  ADD_STAY,
  ADD_STAY_FAILURE,
  ADD_STAY_SUCCESS,
} from '../../actionTypes';

const initialState = {
  stays: [],
  loading: false,
  loaded: false,
  failed: false,
  message: '',
};

const stayList = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAYS:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_STAYS_SUCCESS:
      return {
        ...state,
        stays: action.payload,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_STAYS_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    case ADD_STAY:
      return {
        ...state,
        stay: action.payload.params,
        loading: true,
        loaded: false,
        failed: false,
      };

    case ADD_STAY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        failed: false,
      };

    case ADD_STAY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        failed: true,
        message: action.payload.errorMsg,
      };

    default:
      return state;
  }
};

export default stayList;
