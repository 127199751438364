import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import { CSSTransition } from 'react-transition-group';
import DotLine from 'UI/dotLine';
import Button from 'UI/button';
import PartActionButton from 'UI/partActionButton';
import { getCardClass, getOriginalPart } from 'utils/common';
import { CHANGE_TYPES } from 'utils/consts';
import CreateFlight from './createFlight';

class FlightsPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateFlight: false,
      activeIndex: -1,
      isEdit: false,
    };
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  flightActionHandler = (action, index, flight) => {
    const { itineraryParts, onUpdate } = this.props;
    const { activeIndex } = this.state;
    if (action === 'add') {
      this.setState({
        showCreateFlight: true,
        activeIndex: -1,
        isEdit: true,
      });
    } else if (action === 'edit') {
      this.setState({
        isEdit: true,
        showCreateFlight: true,
        activeIndex: index,
      });
    } else if (action === 'remove') {
      onUpdate('remove', index);
    } else if (action === 'save') {
      const bookingDate = moment().toISOString();
      const modFlight = {
        ...itineraryParts[index],
        ...flight,
        type: 'FLIGHT',
        startTimeStamp: bookingDate,
        endTimeStamp: bookingDate,
        bookingDate,
      };
      const actionType = activeIndex === -1 ? 'add' : 'edit';
      if (actionType === 'add') {
        modFlight.bookingTimeStamp = moment().toISOString();
      }
      onUpdate(actionType, activeIndex, modFlight);
      this.setState({
        activeIndex: -1,
        showCreateFlight: false,
      });
    } else if (action === 'view') {
      this.setState({
        isEdit: false,
        showCreateFlight: true,
        activeIndex: index,
      });
    }
  };

  getFlights = () => {
    const { itineraryParts } = this.props;
    const flights = [];
    let flightsCount = 0;
    for (let i = 0; i < itineraryParts.length; i++) {
      if (itineraryParts[i].type === 'FLIGHT') {
        flightsCount++;
        flights.push({
          ...itineraryParts[i],
          itineraryIndex: i,
        });
      }
    }
    return {
      flights,
      flightsCount,
    };
  };

  render() {
    const {
      classes, suggestions, itineraryParts, originalParts, version,
      getSuggestions, nextHandler, mode, itineraryActionType,
    } = this.props;
    const { showCreateFlight, activeIndex, isEdit } = this.state;
    const { flights, flightsCount } = this.getFlights();
    const flight = activeIndex !== -1 ? itineraryParts[activeIndex] : undefined;
    const originalFlight = getOriginalPart(originalParts, flight,
      itineraryActionType, showCreateFlight);
    const createFlight = (
      <CreateFlight
        isEdit={isEdit}
        version={version}
        itineraryActionType={itineraryActionType}
        suggestions={suggestions}
        getSuggestions={getSuggestions}
        flight={flight}
        originalFlight={originalFlight}
        onSubmit={(f) => this.flightActionHandler('save', -1, f)}
        onDismiss={() => this.handleChange('showCreateFlight', false)}
      />
    );
    return (
      <div className={classes.container}>
        {version === 2 ? (
          <Drawer
            anchor="right"
            open={showCreateFlight}
            onClose={() => this.handleChange('showCreateFlight', false)}
          >
            {createFlight}
          </Drawer>
        )
          : (
            <CSSTransition
              in={showCreateFlight}
              timeout={300}
              classNames="overlay"
              unmountOnExit
            >
              <div className={classes.overlayContainer}>
                {createFlight}
              </div>
            </CSSTransition>
          )}
        <div className={classes.flightsContainer}>
          {(mode === 'view' && flightsCount === 0) ? (
            <Typography className={classes.emptyText}> No flights added</Typography>
          ) : flights.map((flght) => {
            const {
              departureFlightData, flightType = '', returnFlightData, source,
              baggage = '', isRefundable = false, itineraryIndex, proposedChange,
            } = flght;
            const arrowText = returnFlightData ? ' <=> ' : ' => ';
            let isDeleted = false;
            let extraClass = '';
            if (itineraryActionType === 'modify_itinerary' || itineraryActionType === 'approve_reject_itinerary') {
              isDeleted = proposedChange.changeType === CHANGE_TYPES.DELETE;
              extraClass = getCardClass(proposedChange.changeType);
            }
            return (
              <div key={itineraryIndex} className={classes.wrapper}>
                <DotLine />
                <div className={clsx(classes.flightCard, extraClass)}>
                  <div className={classes.row}>
                    <Typography
                      className={classes.title}
                    >
                      {departureFlightData.from ? departureFlightData.from.name : ''}
                    </Typography>
                    <Typography className={classes.arrow}>{` ${arrowText} `}</Typography>
                    <Typography
                      className={classes.title}
                    >
                      {departureFlightData.to ? departureFlightData.to.name : ''}
                    </Typography>
                    {source === 'CUSTOMER' ? (
                      <div className={classes.customerBadge}>
                        CUSTOMER BOOKED
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.cardRow}>
                    <Typography className={classes.notes}>NOTES:</Typography>
                    <Typography className={classes.description}>
                      {`${departureFlightData.flightNumber || ''} | ${departureFlightData.terminal || ''} |\
 ${flightType} | Baggage: ${baggage} | ${(isRefundable && source !== 'CUSTOMER') ? 'Refundable' : 'Non refundable'}`}
                    </Typography>
                  </div>
                  <PartActionButton
                    actionHandler={this.flightActionHandler}
                    editArgs={[itineraryIndex]}
                    removeArgs={[itineraryIndex]}
                    mode={mode}
                    isDeleted={isDeleted}
                    tab="FLIGHT"
                    itineraryActionType={itineraryActionType}
                  />
                </div>
              </div>
            );
          })}
          {(mode === 'edit' || itineraryActionType === 'generate_voucher') ? (
            <Button
              variant="outlined"
              className={classes.addFlightBtn}
              onClick={() => this.flightActionHandler('add')}
            >
              {`+ Add ${itineraryActionType === 'generate_voucher' ? 'customer' : ''} flight`}
            </Button>
          ) : null}
        </div>
        {nextHandler
          ? (
            <div className={classes.footer}>
              <Button
                className={classes.saveBtn}
                onClick={nextHandler}
              >
                {mode === 'view' ? 'Next' : 'Save & continue'}
              </Button>
            </div>
          ) : null}
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: 562,
    backgroundColor: theme.colors.white,
  },
  overlayContainer: {
    zIndex: 3,
    // position: 'absolute',
    top: 64,
    // left: 0,
    width: '100%',
    height: 'calc(100% - 64px)',
  },
  emptyText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    textAlign: 'center',
  },
  flightsContainer: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  flightCard: {
    width: '92%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
    padding: '10px 20px',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    transition: 'box-shadow 400ms',
    marginBottom: 16,
    '&:hover': {
      boxShadow: theme.extraShadows[1],
    },
  },
  title: {
    fontSize: 16,
    color: theme.colors.black,
    textAlign: 'left',
    marginBottom: 10,
  },
  arrow: {
    fontSize: 16,
    color: theme.colors.black,
    marginBottom: 10,
    padding: '0 16px',
  },
  notes: {
    fontSize: 14,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    marginRight: 10,
  },
  description: {
    fontSize: 14,
    color: theme.colors.textDark,
    textAlign: 'left',
  },
  addFlightBtn: {
    marginTop: 10,
    width: 'auto',
    maxWidth: 200,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    width: '100%',
  },
  autocomplete: {
    height: 36,
    width: 200,
    marginLeft: 'auto',
  },
  autocompleteInput: {
    padding: '5px 15px',
  },
  customerBadge: {
    textAlign: 'center',
    fontSize: 10,
    marginLeft: 10,
    marginBottom: 10,
    borderRadius: 8,
    backgroundColor: theme.colors.orange,
    color: theme.colors.white,
    padding: '6px 8px',
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  deletedCard: {
    backgroundColor: theme.colors.redLight,
  },
  editedCard: {
    backgroundColor: theme.colors.primarySelected,
  },
});

FlightsPreview.propTypes = {
  classes: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  suppliers: PropTypes.array.isRequired,
  itineraryParts: PropTypes.array.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  nextHandler: PropTypes.func,
  mode: PropTypes.string,
  itineraryActionType: PropTypes.string.isRequired,
  originalParts: PropTypes.object,
  customerBooking: PropTypes.bool,
  version: PropTypes.number,
};

export default withStyles(styles)(FlightsPreview);
