import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const EmptyScreen = ({
  img, title, description, extraClass,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, extraClass)}>
      <img className={classes.image} alt={title} src={img} />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 250,
  },
  title: {
    color: theme.colors.black,
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 40,
  },
  description: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.colors.textLight,
    maxWidth: 620,
    marginTop: 40,
  },
}));

EmptyScreen.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  extraClass: PropTypes.string.isRequired,
};

export default EmptyScreen;
