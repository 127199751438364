import {
  SET_HOTEL,
  UPDATE_HOTEL,
  UPDATE_HOTEL_SUCCESS,
  UPDATE_HOTEL_FAILURE,
  GET_HOTEL,
  GET_HOTEL_SUCCESS,
  GET_HOTEL_FAILURE,
} from '../../actionTypes';

export const setHotel = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SET_HOTEL,
    payload,
  };
};

export const updateHotel = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_HOTEL,
    payload,
  };
};

export const updateHotelFailure = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_HOTEL_FAILURE,
    payload,
  };
};

export const updateHotelSuccess = (payload) => {
  return {
    type: UPDATE_HOTEL_SUCCESS,
    payload,
  };
};

export const getHotel = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_HOTEL,
    payload,
  };
};

export const getHotelFailure = (payload) => {
  return {
    type: GET_HOTEL_FAILURE,
    payload,
  };
};

export const getHotelSuccess = (payload) => {
  return {
    type: GET_HOTEL_SUCCESS,
    payload,
  };
};
