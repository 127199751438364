import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import DiffInput from './diffInput';

const PricingDiff = ({ data, oldData }) => {
  const classes = useStyles();
  const getValue = (value) => {
    if (!value) {
      return '-';
    }
    return Number(value).toFixed(2);
  };
  const getTotal = (item, accessor) => {
    let total = 0;
    if (item.landPackage) {
      total += item.landPackage[accessor];
    }
    if (item.flights) {
      total += item.flights[accessor];
    }
    if (item.services) {
      total += item.services[accessor];
    }
    return total;
  };
  const renderDiffRow = (title, pricing = {}, oldPricing = {}) => {
    if (pricing.costPrice) {
      const newMargin = (pricing.sellPrice - pricing.costPrice).toFixed(2);
      const oldMargin = (oldPricing.sellPrice - oldPricing.costPrice).toFixed(2);
      return (
        <div className={classes.row}>
          <Typography
            className={classes.title}
          >
            {title}
          </Typography>
          <DiffInput
            extraClass={classes.pricingSection}
            label=""
            value={getValue(pricing.costPrice)}
            oldValue={getValue(oldPricing.costPrice)}
          />
          <DiffInput
            extraClass={classes.middleSection}
            label=""
            value={getValue(pricing.sellPrice)}
            oldValue={getValue(oldPricing.sellPrice)}
          />
          <DiffInput
            extraClass={classes.pricingSection}
            label=""
            value={newMargin}
            oldValue={oldMargin}
          />
        </div>
      );
    }
    return null;
  };
  const newSubTotal = data.totalSellPrice || getTotal(data, 'sellPrice');
  const oldSubTotal = oldData.totalSellPrice || getTotal(oldData, 'sellPrice');
  return (
    <div className={classes.container}>
      {renderDiffRow('Land package', data.landPackage, oldData.landPackage)}
      {renderDiffRow('Flights', data.flights, oldData.flights)}
      {renderDiffRow('Services', data.services, oldData.services)}
      <div className={classes.row}>
        <Typography
          className={classes.title}
        >
          Offer applied
        </Typography>
        <DiffInput
          label=""
          value={data.offer}
          oldValue={oldData.offer}
        />
      </div>
      <div className={classes.totalRow}>
        {renderDiffRow('Total', data.landPackage, oldData.landPackage)}
      </div>
      <div className={classes.summaryRow}>
        <Typography
          className={classes.title}
        >
          Sub total
        </Typography>
        <DiffInput
          label=""
          value={newSubTotal}
          oldValue={oldSubTotal}
          extraClass={classes.summaryInput}
        />
      </div>
      <div className={classes.summaryRow}>
        <Typography
          className={classes.title}
        >
          Discount
        </Typography>
        <DiffInput
          label=""
          value={data.discount}
          oldValue={oldData.discount}
          extraClass={classes.summaryInput}
        />
      </div>
      <div className={classes.summaryRow}>
        <Typography
          className={classes.title}
        >
          Grand total
        </Typography>
        <DiffInput
          label=""
          value={newSubTotal - data.discount}
          oldValue={oldSubTotal - oldData.discount}
          extraClass={classes.summaryInput}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
    width: '100%',
  },
  title: {
    fontSize: 16,
    color: theme.colors.black,
    width: 140,
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingTop: 10,
    borderTop: `1px solid ${theme.colors.border}`,
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    marginLeft: 'auto',
  },
  pricingSection: {
    flex: 1,
  },
  middleSection: {
    flex: 1,
    margin: '0 10px',
  },
  summaryInput: {
    width: 160,
  },
}));

PricingDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default PricingDiff;
