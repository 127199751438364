export const colors = {
  blue: '#0095b6',
  orange: '#F2B318',
  orangeLight: '#ffe554',
  yellow: '#F2B318',
  yellowLight: '#FEF7E8',
  yellowLight_1: '#FFE1B2',
  brown: '#b26500',
  green: '#43a047',
  greenLight: '#86c288',
  greenDark: '#388e3c',
  grey: '#999',
  textLight: '#666',
  textLight_1: '#9B9B9B',
  textDark: '#333',
  textDark_1: '#303030',
  background: '#f5f5f5',
  background_1: '#f2f2f2',
  white: '#fff',
  underline: '#eee',
  underline_1: '#ccc',
  border: '#ddd',
  red: '#CC3254',
  redLight: '#FAEBEE',
  redDark: '#910020',
  primary: '#2C7A77',
  primaryLight: '#DFF2F1',
  primaryBackground: '#3AAEA9',
  primarySelected: '#EAF2F2',
  primarySelected_1: '#ABCAC9',
  black: '#000',
};

export const fonts = {
  body2: 12,
  body1: 14,
  title: 16,
  heading: 18,
};
