import { all, spawn } from 'redux-saga/effects';
import * as dashboardSagas from 'containers/dashboard/dashboardSaga';
import * as authSagas from 'containers/auth/authSaga';
import * as commonSagas from 'containers/common/commonSaga';
import * as notificationSagas from 'containers/notification/notificationSaga';
import * as inventorySagas from 'containers/inventory/inventorySaga';
import * as itineraryMakerSagas from 'containers/itineraryMaker/itineraryMakerSaga';
import * as stayDetailSagas from 'containers/stayDetail/stayDetailSaga';
import * as stayListSagas from 'containers/stayList/stayListSaga';
import * as experiencesSagas from 'containers/experiences/experiencesSaga';
import * as supplierListSagas from 'containers/supplierList/supplierListSaga';
import * as supplierDetailSagas from 'containers/supplierDetail/supplierDetailSaga';
import * as hotelListSagas from 'containers/hotelList/hotelListSaga';
import * as hotelDetailSagas from 'containers/hotelDetail/hotelDetailSaga';
import * as placeListSagas from 'containers/placeList/placeListSaga';
import * as placeDetailSagas from 'containers/placeDetail/placeDetailSaga';

export default function* rootSaga() {
  yield all([
    ...Object.values(dashboardSagas),
    ...Object.values(authSagas),
    ...Object.values(commonSagas),
    ...Object.values(notificationSagas),
    ...Object.values(inventorySagas),
    ...Object.values(itineraryMakerSagas),
    ...Object.values(stayDetailSagas),
    ...Object.values(stayListSagas),
    ...Object.values(experiencesSagas),
    ...Object.values(supplierListSagas),
    ...Object.values(supplierDetailSagas),
    ...Object.values(hotelListSagas),
    ...Object.values(hotelDetailSagas),
    ...Object.values(placeListSagas),
    ...Object.values(placeDetailSagas),
  ].map(spawn));
}
