import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ItineraryMaker from './itineraryMakerIndex';
import {
  createItinerary, saveItineraryDraft,
  getStayRooms, getItineraryPrice,
} from './itineraryMakerActions';
import { suggestAutocomplete, getConversion } from '../common/commonActions';
import { getRequestByID, updateStatus, bookingAction } from '../dashboard/dashboardActions';

const mapStateToProps = (state) => ({
  ...state.auth.loginResp,
  ...state.itineraryMaker,
  requestById: state.dashboard.requestById,
  isGettingRequestByID: state.dashboard.isGettingRequestByID,
  getRequestByIDError: state.dashboard.getRequestByIDError,
  getRequestByIDErrorMsg: state.dashboard.getRequestByIDErrorMsg,
  isUpdatingStatus: state.dashboard.isUpdatingStatus,
  updateStatusResp: state.dashboard.updateStatusResp,
  updateStatusError: state.dashboard.updateStatusError,
  updateStatusErrorMsg: state.dashboard.updateStatusErrorMsg,
  suggestions: state.common.suggestions,
  isFetchingSuggestions: state.common.isFetchingSuggestions,
  meta: state.common.paramsMeta,
  conversion: state.common.conversion,
  conversionError: state.common.conversionError,
  conversionErrorMsg: state.common.conversionErrorMsg,
  isBooking: state.dashboard.isBooking,
  bookingActionResp: state.dashboard.bookingActionResp,
  bookingActionError: state.dashboard.bookingActionError,
  bookingActionErrorMsg: state.dashboard.bookingActionErrorMsg,
});

const mapDispatchToProps = (dispatch) => ({
  getRequestById: (params, headers) => dispatch(getRequestByID(params, headers)),
  createItinerary: (params, headers) => dispatch(createItinerary(params, headers)),
  getAutocompleteSuggestions: (params, headers) => dispatch(suggestAutocomplete(params, headers)),
  saveItineraryDraft: (params, headers) => dispatch(saveItineraryDraft(params, headers)),
  getStayRooms: (params, headers) => dispatch(getStayRooms(params, headers)),
  getItineraryPrice: (params, headers) => dispatch(getItineraryPrice(params, headers)),
  updateStatus: (params, headers) => dispatch(updateStatus(params, headers)),
  getConversion: (params, headers) => dispatch(getConversion(params, headers)),
  bookingAction: (params, headers) => dispatch(bookingAction(params, headers)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ItineraryMaker)
);
