import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import createStyles from '@material-ui/styles/createStyles';
import Drawer from '@material-ui/core/Drawer';
import { CSSTransition } from 'react-transition-group';
import Button from 'UI/button';
import DotLine from 'UI/dotLine';
import PartActionButton from 'UI/partActionButton';
import { getCardClass, getOriginalPart, spacedToCamelStr } from 'utils/common';
import { CHANGE_TYPES } from 'utils/consts';
import CreateService from './createService';

class ServicesPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateService: false,
      activeIndex: -1,
      isEdit: false,
    };
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  serviceActionHandler = (action, index, service) => {
    const { itineraryParts, onUpdate } = this.props;
    const { activeIndex } = this.state;
    if (action === 'add') {
      this.setState({
        showCreateService: true,
        activeIndex: -1,
        isEdit: true,
      });
    } else if (action === 'edit') {
      this.setState({
        isEdit: true,
        showCreateService: true,
        activeIndex: index,
      });
    } else if (action === 'view') {
      this.setState({
        isEdit: false,
        showCreateService: true,
        activeIndex: index,
      });
    } else if (action === 'remove') {
      onUpdate('remove', index);
    } else if (action === 'save') {
      const timeStamp = moment().toISOString();
      let modService = {
        ...service,
        type: 'SERVICE',
      };
      if (activeIndex !== -1) {
        modService = {
          ...itineraryParts[activeIndex],
          ...modService,
        };
      } else {
        modService = {
          ...modService,
          bookingDate: timeStamp,
          startTimeStamp: timeStamp,
          endTimeStamp: timeStamp,
        };
      }
      const actionType = activeIndex === -1 ? 'add' : 'edit';
      if (actionType === 'add') {
        modService.bookingTimeStamp = timeStamp;
      }
      onUpdate(actionType, activeIndex, modService);
      this.setState({
        activeIndex: -1,
        showCreateService: false,
      });
    }
  };

  getServices = () => {
    const { itineraryParts } = this.props;
    const services = [];
    let servicesCount = 0;
    for (let i = 0; i < itineraryParts.length; i++) {
      if (itineraryParts[i].type === 'SERVICE') {
        servicesCount++;
        services.push({
          ...itineraryParts[i],
          itineraryIndex: i,
        });
      }
    }
    return {
      services,
      servicesCount,
    };
  };

  getPaxText = (pax) => {
    const { adults, children, infants } = pax;
    return `${adults} adults ${children > 0 ? `${children} children` : ''} ${infants > 0 ? `${infants} infants` : ''}`;
  };

  getServiceDescription = (service) => {
    const serviceDataField = `${spacedToCamelStr(service.serviceType)}Data`;
    let description = '';
    if (serviceDataField === 'travelInsuranceData') {
      const { travelInsuranceData: { pax, description: desc = ' - ' } } = service;
      const descText = desc.length > 10 ? `${desc.slice(0, 10)}...` : desc;
      description = `PAX: ${this.getPaxText(pax)} | ${descText}`;
    } else if (serviceDataField === 'visaData') {
      const { visaData: { pax, country } } = service;
      description = `PAX: ${this.getPaxText(pax)} | Country: ${country.name}`;
    } else if (serviceDataField === 'forexData') {
      const { forexData: { targetAmount, targetCurrency, sourceCurrency } } = service;
      description = `Convert ${targetAmount} ${targetCurrency.code} from  ${sourceCurrency.code}`;
    }
    return description;
  };

  render() {
    const {
      classes, suggestions, itineraryParts, originalParts, version,
      getSuggestions, nextHandler, mode, itineraryActionType,
    } = this.props;
    const { showCreateService, activeIndex, isEdit } = this.state;
    const service = activeIndex !== -1 ? itineraryParts[activeIndex] : undefined;
    const { services, servicesCount } = this.getServices();
    const originalService = getOriginalPart(originalParts,
      service, itineraryActionType, showCreateService);
    const createService = (
      <CreateService
        isEdit={isEdit}
        version={version}
        itineraryActionType={itineraryActionType}
        suggestions={suggestions}
        getSuggestions={getSuggestions}
        service={service}
        originalService={originalService}
        onSubmit={(s) => this.serviceActionHandler('save', -1, s)}
        onDismiss={() => this.handleChange('showCreateService', false)}
      />
    );
    return (
      <div className={classes.container}>
        {version === 2 ? (
          <Drawer
            anchor="right"
            open={showCreateService}
            onClose={() => this.handleChange('showCreateService', false)}
          >
            {createService}
          </Drawer>
        ) : (
          <CSSTransition
            in={showCreateService}
            timeout={300}
            classNames="overlay"
            unmountOnExit
          >
            <div className={classes.overlayContainer}>
              {createService}
            </div>
          </CSSTransition>
        )}
        <div className={classes.serviceContainer}>
          {(mode === 'view' && servicesCount === 0) ? (
            <Typography className={classes.emptyText}> No services added</Typography>
          ) : services.map((serv) => {
            const {
              serviceType, supplier,
              itineraryIndex, proposedChange,
            } = serv;
            const description = this.getServiceDescription(serv);
            let isDeleted = false;
            let extraClass = '';
            if (itineraryActionType === 'modify_itinerary' || itineraryActionType === 'approve_reject_itinerary') {
              isDeleted = proposedChange.changeType === CHANGE_TYPES.DELETE;
              extraClass = getCardClass(proposedChange.changeType);
            }
            return (
              <div key={serviceType} className={classes.wrapper}>
                <DotLine />
                <div className={clsx(classes.serviceCard, extraClass)}>
                  <Typography className={classes.title}>{serviceType}</Typography>
                  <div className={classes.row}>
                    <Typography className={classes.notes}>NOTES:</Typography>
                    <Typography className={classes.description}>{description}</Typography>
                    <Typography className={classes.supplierName}>{supplier.name}</Typography>
                  </div>
                  <PartActionButton
                    actionHandler={this.serviceActionHandler}
                    editArgs={[itineraryIndex]}
                    removeArgs={[itineraryIndex]}
                    mode={mode}
                    isDeleted={isDeleted}
                    itineraryActionType={itineraryActionType}
                  />
                </div>
              </div>
            );
          })}
          {(mode === 'edit') ? (
            <Button
              variant="outlined"
              className={classes.addServiceBtn}
              onClick={() => this.serviceActionHandler('add')}
            >
              + Add service
            </Button>
          ) : null}
        </div>
        {nextHandler
          ? (
            <div className={classes.footer}>
              <Button
                className={classes.saveBtn}
                onClick={nextHandler}
              >
                {mode === 'view' ? 'Next' : 'Save & continue'}
              </Button>
            </div>
          ) : null}
      </div>
    );
  }
}

const styles = (theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: 562,
    backgroundColor: theme.colors.white,
  },
  overlayContainer: {
    zIndex: 3,
    position: 'absolute',
    top: 64,
    width: '100%',
    height: 'calc(100% - 64px)',
  },
  emptyText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    textAlign: 'center',
  },
  serviceContainer: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  serviceCard: {
    width: '92%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
    marginLeft: 20,
    padding: '10px 20px',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    transition: 'box-shadow 400ms',
    marginBottom: 16,
    '&:hover': {
      boxShadow: theme.extraShadows[1],
    },
  },
  title: {
    fontSize: 16,
    color: theme.colors.black,
    textAlign: 'left',
    marginBottom: 10,
  },
  notes: {
    fontSize: 14,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    marginRight: 10,
  },
  description: {
    fontSize: 14,
    color: theme.colors.textDark,
    textAlign: 'left',
  },
  supplierName: {
    marginLeft: 'auto',
    marginRight: 16,
    fontSize: 14,
    color: theme.colors.textDark,
  },
  addServiceBtn: {
    marginTop: 10,
    width: 140,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  deletedCard: {
    backgroundColor: theme.colors.redLight,
  },
  editedCard: {
    backgroundColor: theme.colors.primarySelected,
  },
});

ServicesPreview.propTypes = {
  classes: PropTypes.object,
  itineraryParts: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  nextHandler: PropTypes.func,
  mode: PropTypes.string,
  itineraryActionType: PropTypes.string.isRequired,
  originalParts: PropTypes.object,
  version: PropTypes.number,
};

export default withStyles(styles)(ServicesPreview);
