import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import Header from 'UI/header';
import Button from 'UI/button';
import Footer from 'UI/footer';
import LabelInput from 'UI/labelInput';

const CancelBookingRequest = ({
  onCancel,
  onDismiss,
  booking,
  loading,
}) => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState('');
  const [cancellationCharges, setCancellationCharges] = useState(0);
  const handleCancellation = () => {
    if (Number(cancellationCharges) < 0) {
      setErrorMsg('Please enter valid refund amount');
      return;
    }
    const params = {
      bookingId: booking._id,
      cancellationCharges: cancellationCharges * 100,
    };
    onCancel(params);
  };
  return (
    <div className={classes.container}>
      <Header
        title="Cancel booking"
        Icon={CancelIcon}
        onDismiss={onDismiss}
      />
      <div className={classes.body}>
        <LabelInput
          label="Cancellation Charge"
          placeholder="Cancellation charge"
          type="number"
          inputProps={{
            inputmode: 'numeric',
          }}
          value={cancellationCharges}
          onChange={(val) => setCancellationCharges(Number(val))}
        />
      </div>
      <Footer errorMsg={errorMsg}>
        <Button
          loading={loading}
          onClick={handleCancellation}
        >
          Cancel booking
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: '50vw',
    maxWidth: 420,
  },
  body: {
    padding: 40,
  },
}));

CancelBookingRequest.propTypes = {
  booking: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CancelBookingRequest;
