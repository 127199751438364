import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notification from './notificationIndex';
import { getNotifications, markNotifRead } from './notificationActions';

const mapStateToProps = (state) => ({
  ...state.auth.loginResp,
  ...state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (params, headers) => dispatch(getNotifications(params, headers)),
  markNotifRead: (params, headers) => dispatch(markNotifRead(params, headers)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notification)
);
