import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const Chip = ({
  label,
  selected,
  extraClass = '',
  onSelected,
  dynamicWidth = true,
}) => {
  const classes = useStyles();
  const labelLen = label.length;
  return (
    <button
      type="button"
      onClick={onSelected}
      style={{ width: dynamicWidth ? Math.max(labelLen * 10, 80) : undefined }}
      className={clsx(classes.chip, extraClass, selected && classes.selected)}
    >
      <Typography className={classes.label}>{label}</Typography>
    </button>
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {
    outline: 'none',
    borderRadius: 20,
    width: 80,
    backgroundColor: theme.colors.white,
    padding: '6px 8px',
    border: `1px solid ${theme.colors.border}`,
  },
  selected: {
    border: `1px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.primarySelected,
  },
  label: {
    lineHeight: '16px',
    fontSize: 14,
    color: theme.colors.textDark,
  },
  selectedLabel: {
    color: theme.colors.black,
  },
}));

Chip.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  dynamicWidth: PropTypes.bool,
};

export default Chip;
