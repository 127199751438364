import React from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Footer from 'UI/footer';
import Button from 'UI/button';
import CustomCheckbox from 'UI/checkbox';

const ExtraServices = ({
  onUpdate,
  nextHandler,
  errorMsg,
  tasksRequested,
}) => {
  const classes = useStyles();
  const services = [{
    title: 'Flight',
    code: 'FLIGHT',
  }, {
    title: 'Forex',
    code: 'FOREX',
  }, {
    title: 'Travel Insurance',
    code: 'TRAVEL_INSURANCE',
  }, {
    title: 'Visa',
    code: 'VISA',
  }];
  const handleChange = (item) => {
    const modTasks = { ...tasksRequested };
    if (modTasks[item.code]) {
      delete modTasks[item.code];
    } else {
      modTasks[item.code] = item.title;
    }
    onUpdate('tasksRequested', modTasks);
  };
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <Typography className={classes.title}>
          Customer&apos;s extra service requirements
        </Typography>
        {services.map((item) => {
          return (
            <CustomCheckbox
              key={item.code}
              onChange={() => handleChange(item)}
              checked={Boolean(tasksRequested[item.code])}
              label={item.title}
              rootClass={classes.checkboxContainer}
            />
          );
        })}
      </div>
      <Footer
        errorMsg={errorMsg}
      >
        <Button
          className={classes.saveBtn}
          onClick={nextHandler}
        >
          Save & continue
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: 562,
    backgroundColor: theme.colors.white,
  },
  body: {
    padding: '20px 40px',
  },
  title: {
    fontSize: 16,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  checkboxContainer: {
    marginBottom: 16,
    height: 40,
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
}));

ExtraServices.propTypes = {
  nextHandler: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  tasksRequested: PropTypes.object,
};

export default ExtraServices;
