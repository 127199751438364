import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import createStyles from '@material-ui/styles/createStyles';
import Header from 'UI/header';
import Chip from 'UI/chip';
import SearchIcon from 'assets/icons/search';
import arrowBack from 'assets/svg/arrow_left.svg';
import { capitalize } from 'utils/common';
import { BLOCK_TYPES, IMAGE_PLACEHOLDER } from 'utils/consts';
import HotelCard from './hotelCard';
import StayPreferences from './stayPreferences';
import StayPreferencesNew from './stayPreferencesNew';

class StaySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stayTypes: {},
      stayRatings: {},
      searchVal: '',
      selectedStay: {},
      errorMsg: '',
      edit: false,
      choosePreference: false,
    };
    this.stayTypes = ['RESORT', 'HOTEL', 'CRUISE', 'HOUSE_BOAT',
      'HOSTEL', 'HOME_STAY', 'APARTMENT'];
    this.stayRatings = ['3 star', '4 star', '5 star', '7 star'];
  }

  componentDidMount = () => {
    const {
      activeStay, relatedRoute,
      getSuggestions, version,
    } = this.props;
    let prevState = {};
    if (activeStay) {
      prevState = {
        selectedStay: activeStay,
        choosePreference: true,
        route: relatedRoute,
        edit: true,
      };
    }
    this.setState({ ...prevState });
    getSuggestions('stays', '', {
      includeWithoutRooms: !(version === 2),
      stayRatings: [],
      stayTypes: [],
      placeId: relatedRoute.place.item._id,
      limit: 100,
    });
  };

  handleChange = (field, value) => {
    const { relatedRoute, getSuggestions, version } = this.props;
    if (field === 'searchVal') {
      const { stayTypes, stayRatings } = this.state;
      getSuggestions('stays', value, {
        includeWithoutRooms: !(version === 2),
        placeId: relatedRoute.place.item._id,
        stayRatings: Object.keys(stayRatings),
        stayTypes: Object.keys(stayTypes),
        limit: 100,
      });
      this.setState({ searchVal: value });
    } else {
      const { [field]: item } = this.state;
      const newValue = { ...item };
      if (newValue[value]) {
        delete newValue[value];
      } else {
        newValue[value] = value;
      }
      this.setState({ [field]: newValue }, () => {
        const { searchVal, stayRatings, stayTypes } = this.state;
        getSuggestions('stays', searchVal, {
          includeWithoutRooms: !(version === 2),
          placeId: relatedRoute.place.item._id,
          stayRatings: Object.keys(stayRatings),
          stayTypes: Object.keys(stayTypes),
        });
      });
    }
  };

  handleStaySelection = (stay) => {
    if (stay.stay.blockInfo && stay.stay.blockInfo.blockType !== BLOCK_TYPES.UNBLOCKED) {
      return;
    }
    this.setState({
      selectedStay: stay,
      errorMsg: '',
    });
  };

  addStayHandler = (preferences) => {
    const { onAddStay } = this.props;
    const { selectedStay } = this.state;
    if (!selectedStay.stay) {
      this.setState({ errorMsg: 'Select an stay to continue' });
    } else if (preferences) {
      const params = {
        ...selectedStay,
        ...preferences,
      };
      this.setState({ choosePreference: false });
      onAddStay(params);
    } else {
      this.setState({ choosePreference: true });
    }
  };

  resetHandler = () => {
    const { getSuggestions, relatedRoute, version } = this.props;
    this.setState({
      stayTypes: {},
      stayRatings: {},
      searchVal: '',
      selectedStay: {},
      errorMsg: '',
    }, () => {
      getSuggestions('stays', '', {
        placeId: relatedRoute.place.item._id,
        stayRatings: [],
        stayTypes: [],
        includeWithoutRooms: !(version === 2),
      });
    });
  };

  dismissPreference = () => {
    const { onDismiss } = this.props;
    const { edit } = this.state;
    if (edit) {
      onDismiss();
    } else {
      this.setState({ choosePreference: false });
    }
  };

  render() {
    const {
      classes, onDismiss, meta: { mealPlan }, itineraryActionType,
      requirement, mode, suggestions, relatedRoute, transferModes,
      originalStay, version, isFetchingSuggestions,
      minDate,
    } = this.props;
    const {
      stayTypes,
      stayRatings,
      selectedStay,
      searchVal,
      errorMsg,
      choosePreference,
    } = this.state;
    let placeholderTxt = '';
    if (relatedRoute && relatedRoute.place) {
      placeholderTxt = relatedRoute.place.value;
    }
    if (choosePreference) {
      const props = {
        mode,
        itineraryActionType,
        stay: selectedStay,
        originalStay,
        relatedRoute,
        mealPlans: mealPlan,
        transferModes,
        minDate,
        onDismiss: this.dismissPreference,
        onSelect: this.addStayHandler,
        version,
        requirement,
      };
      const Preference = version === 2 ? StayPreferencesNew : StayPreferences;
      return (
        <div className={clsx(classes.container, version !== 2 && classes.oldContainer)}>
          <Preference {...props} />
        </div>
      );
    }
    return (
      <div className={clsx(classes.container, version !== 2 && classes.oldContainer)}>
        <Header
          className={classes.headerStrip}
          titleClass={classes.titleClass}
          variant="back"
          img={arrowBack}
          title="Select stay"
          onDismiss={onDismiss}
        />
        {isFetchingSuggestions ? <LinearProgress className={classes.loader} /> : null}
        <div className={clsx(classes.body, version !== 2 && classes.oldContainer)}>
          {version === 2 ? (
            <div className={classes.filterSection}>
              <div className={classes.row}>
                <Typography className={classes.filterText}>Filters</Typography>
                <ButtonBase
                  className={classes.resetBtn}
                  onClick={this.resetHandler}
                >
                  Reset
                </ButtonBase>
              </div>
              <Typography className={classes.filterHeading}>Stay type</Typography>
              <div className={classes.filters}>
                {this.stayTypes.map((st) => {
                  return (
                    <Chip
                      label={capitalize(st.replace('_', ' '))}
                      key={st}
                      selected={Boolean(stayTypes[st])}
                      extraClass={classes.chip}
                      onSelected={() => this.handleChange('stayTypes', st)}
                    />
                  );
                })}
              </div>
              <Typography className={classes.filterHeading}>Star ratings</Typography>
              <div className={classes.filters}>
                {this.stayRatings.map((sr) => {
                  return (
                    <Chip
                      label={sr}
                      key={sr}
                      selected={Boolean(stayRatings[sr])}
                      extraClass={classes.chip}
                      onSelected={() => this.handleChange('stayRatings', sr)}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          <div className={classes.displayBody}>
            <div className={classes.displaySection}>
              <div className={classes.topBar}>
                <InputBase
                  className={classes.searchBox}
                  classes={{
                    focused: classes.focusedBox,
                  }}
                  value={searchVal}
                  placeholder={`Search stay in ${placeholderTxt}`}
                  onChange={(e) => this.handleChange('searchVal', e.target.value)}
                  endAdornment={<SearchIcon />}
                />
              </div>
              {suggestions.stays.length === 0 ? (
                <>
                  <Typography
                    className={classes.noResultTitle}
                  >
                    Couldn&apos;t find any matching results
                  </Typography>
                  <Typography className={classes.noResult}>• Try another search</Typography>
                  <Typography className={classes.noResult}>
                    •  Try changing the filters
                  </Typography>
                </>
              ) : null}
              <div className={classes.stays}>
                {suggestions.stays.map((sty) => {
                  const {
                    stay: {
                      name, stayTypes: sTypes = [],
                      _id, blockInfo = {}, images = [], starRatingNumber = 'N/A',
                      placeName,
                    },
                  } = sty;
                  const imageLink = images.length ? images[0].url : IMAGE_PLACEHOLDER;
                  return (
                    <HotelCard
                      address={placeName}
                      stayType={sTypes[0] || 'Stay'}
                      name={name}
                      image={imageLink}
                      rating={starRatingNumber}
                      key={_id}
                      blockInfo={blockInfo}
                      selected={selectedStay.stay && selectedStay.stay._id === _id}
                      onStaySelect={() => this.handleStaySelection(sty)}
                    />
                  );
                })}
              </div>
            </div>
            <div className={classes.footer}>
              {errorMsg && <Typography className={classes.errorMsg}>{errorMsg}</Typography>}
              <Button
                className={clsx(classes.addButton, selectedStay.stay && classes.validBtn)}
                onClick={() => this.addStayHandler()}
              >
                Choose stay
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = createStyles((theme) => ({
  oldContainer: {
    width: '940px !important',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '72vw',
    borderRadius: 10,
    height: '100%',
    backgroundColor: theme.colors.white,
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  loader: {
    position: 'absolute',
    top: 40,
    width: '100%',
    left: 0,
    color: theme.colors.primary,
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  displayBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filterSection: {
    flex: 1,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '20%',
    padding: '20px 20px 20px 40px',
    borderRight: `1px solid ${theme.colors.border}`,
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  resetBtn: {
    marginLeft: 'auto',
    width: 72,
    borderRadius: 4,
    padding: '5px 10px',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    color: theme.colors.primary,
    backgroundColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  filterText: {
    color: theme.colors.black,
    fontSize: 16,
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    cursor: 'pointer',
    marginRight: 4,
    marginBottom: 10,
    '&:hover': {
      backgroundColor: theme.colors.primarySelected,
    },
  },
  filterHeading: {
    color: theme.colors.textLight,
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    textTransform: 'capitalize',
  },
  displaySection: {
    flex: 1,
    display: 'flex',
    // minWidth: 700,
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '20px 40px',
    overflowY: 'auto',
    // maxHeight: 420,
  },
  stays: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  searchBox: {
    width: '100%',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    height: '40px',
    boxSizing: 'border-box',
    padding: '10px 20px',
    marginBottom: 20,
    color: theme.colors.black,
    fontSize: 16,
  },
  focusedBox: {
    border: `1px solid ${theme.colors.primary}`,
  },
  createText: {
    color: theme.colors.primary,
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 0.5,
    marginLeft: 6,
  },
  noResultTitle: {
    fontSize: 16,
    color: theme.colors.textLight,
    marginBottom: 15,
  },
  noResult: {
    marginLeft: 30,
    fontSize: 16,
    color: theme.colors.textLight,
    marginBottom: 10,
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '20px 40px',
  },
  errorMsg: {
    padding: '0 16px',
    color: theme.colors.red,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  addButton: {
    width: 180,
    borderRadius: 25,
    backgroundColor: theme.colors.grey,
    fontSize: 16,
    color: theme.colors.white,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
  validBtn: {
    backgroundColor: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
}));

StaySelection.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onAddStay: PropTypes.func.isRequired,
  relatedRoute: PropTypes.object.isRequired,
  activeStay: PropTypes.object,
  originalStay: PropTypes.object,
  meta: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  transferModes: PropTypes.array.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  requirement: PropTypes.object.isRequired,
  version: PropTypes.number,
  mode: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  isFetchingSuggestions: PropTypes.bool.isRequired,
};

export default withStyles(styles)(StaySelection);
