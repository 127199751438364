import {
  put, call, takeEvery,
  all, takeLatest, cancelled,
} from 'redux-saga/effects';
import axios from 'axios';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import { getRequest, postRequest } from 'utils/apiRequests';
import {
  PING_USER_INIT,
  GET_PARAMS_META_INIT,
  // TOGGLE_ACTIVE_INIT,
  SUGGEST_AUTOCOMPLETE_INIT,
  GET_CONVERSION_INIT,
  UPLOAD_DOCS_INIT,
  REGISTER_NOTIF_TOKEN_INIT,
} from '../../actionTypes';
import {
  pingUserSuccess,
  pingUserFailure,
  getParamsSuccess,
  getParamsFailure,
  // toggleActiveSuccess,
  // toggleActiveFailure,
  suggestAutocompleteSuccess,
  suggestAutocompleteFailure,
  getConversionSuccess,
  getConversionFailure,
  uploadDocsSuccess,
  uploadDocsFailure,
  registerNotifTokenSuccess,
  registerNotifTokenFailure,
} from './commonActions';

function* pingUser(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.ping}`;
    const data = yield call(getRequest, url, params, headers);
    yield put(pingUserSuccess(data));
  } catch (err) {
    yield put(pingUserFailure(err.message));
  }
}

export function* pingUserSaga() {
  yield takeLatest(PING_USER_INIT, pingUser);
}

function* getParamsMeta(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.launch}`;
    const data = yield call(getRequest, url, params, headers);
    if (data.state) {
      yield put(getParamsSuccess(data.parameters));
    } else {
      yield put(getParamsFailure(data.message || 'Error getting response'));
    }
  } catch (err) {
    yield put(getParamsFailure(err.message));
  }
}

export function* getParamsMetaSaga() {
  yield takeLatest(GET_PARAMS_META_INIT, getParamsMeta);
}

// function* toggleActive(action) {
//   try {
//     const { params, headers } = action.payload;
//     const url = `${baseURL}${endPoints.launch}`;
//     const data = yield call(postRequest, url, params, headers);
//     if (data.state) {
//       yield put(toggleActiveSuccess(data));
//     } else {
//       yield put(toggleActiveFailure(data.messsage));
//     }
//   } catch (err) {
//     yield put(toggleActiveFailure(err.message));
//   }
// }

// export function* toggleActiveSaga() {
//   yield takeLatest(TOGGLE_ACTIVE_INIT, toggleActive);
// }

function* suggestAutocomplete(action) {
  const cancelSource = axios.CancelToken.source();
  try {
    const { params, headers } = action.payload;
    let endPoint = endPoints.countrySuggest;
    let suggestionType = params.type;
    if (params.type === 'cities') {
      endPoint = endPoints.placeSuggest;
    } else if (params.type === 'destinations') {
      endPoint = endPoints.placeSuggest;
    } else if (params.type === 'currencies') {
      endPoint = endPoints.currencySuggest;
    } else if (params.type === 'stays') {
      endPoint = endPoints.staySuggest;
      params.placeIds = [params.placeId];
      delete params.placeId;
    } else if (params.type === 'supplier') {
      endPoint = endPoints.supplierSuggest;
      suggestionType = 'suppliers';
    } else if (params.type === 'experts') {
      endPoint = endPoints.expertSuggest;
      suggestionType = 'experts';
    } else if (params.type === 'airports') {
      endPoint = endPoints.airportSuggest;
      suggestionType = 'airports';
    } else if (params.type === 'experiences') {
      endPoint = endPoints.experienceSuggest;
      suggestionType = 'experiences';
    } else if (params.type === 'itineraryTemplates') {
      endPoint = endPoints.itineraryTemplates;
      suggestionType = 'itineraryTemplates';
    }
    const url = `${baseURL}${endPoint}`;
    delete params.type;
    const data = yield call(postRequest, url, params, headers, false, cancelSource.token);
    if (!data.error) {
      yield put(suggestAutocompleteSuccess({ ...data, type: suggestionType }));
    } else {
      yield put(suggestAutocompleteFailure(data.message));
    }
  } catch (err) {
    yield put(suggestAutocompleteFailure(err.message));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export function* suggestAutocompleteSaga() {
  yield takeLatest(SUGGEST_AUTOCOMPLETE_INIT, suggestAutocomplete);
}

function* getConversion(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.currencyConvert}`;
    let data = {};
    if (params.constructor.name === 'Array') {
      const conversionCalls = params.map((p) => call(postRequest, url, p, headers));
      const resps = yield all(conversionCalls);
      for (let i = 0; i < params.length; i++) {
        data[params[i].fromCurrency] = resps[i].rate;
      }
    } else {
      const resp = yield call(postRequest, url, params, headers);
      data = { [params.fromCurrency]: resp.rate };
    }
    if (!data.error) {
      yield put(getConversionSuccess(data));
    } else {
      yield put(getConversionFailure(data.message));
    }
  } catch (err) {
    yield put(getConversionFailure(err.message));
  }
}

export function* getConversionSaga() {
  yield takeEvery(GET_CONVERSION_INIT, getConversion);
}

function* uploadDocs(action) {
  try {
    const { params, headers, nextAction } = action.payload;
    const url = `${baseURL}${endPoints.uploadDocs}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(uploadDocsSuccess(data));
      yield put({ ...nextAction, data });
    } else {
      yield put(uploadDocsFailure(data.message));
    }
  } catch (err) {
    yield put(uploadDocsFailure(err.message));
  }
}

export function* uploadDocsSaga() {
  yield takeLatest(UPLOAD_DOCS_INIT, uploadDocs);
}

function* registerNotifToken(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.registerToken}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(registerNotifTokenSuccess(data));
    } else {
      yield put(registerNotifTokenFailure(data.message));
    }
  } catch (err) {
    yield put(registerNotifTokenFailure(err.message));
  }
}

export function* registerNotifTokenSaga() {
  yield takeLatest(REGISTER_NOTIF_TOKEN_INIT, registerNotifToken);
}
