import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import VisibleIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/styles/makeStyles';
import pdfImg from 'assets/svg/pdf.svg';
import jpegImg from 'assets/svg/jpeg.svg';
import pngImg from 'assets/svg/png.svg';
import Button from 'UI/button';
import { getFileExtension } from 'utils/common';

const DocumentCards = ({
  documents = [],
  extraClass,
  onRemove,
}) => {
  const classes = useStyles();
  const imageMap = {
    'application/pdf': pdfImg,
    'image/png': pngImg,
    'image/jpeg': jpegImg,
    'image/jpg': jpegImg,
  };
  const [fields, setFields] = useState({});
  const togglePopover = (field, value) => {
    setFields({ [field]: value });
  };
  const handlePreview = (dynField, document) => {
    if (document.link) {
      window.open(document.link, '_blank');
    } else {
      const reader = new FileReader();
      reader.onerror = () => { };
      reader.onload = () => {
        const newWindow = window.open('', '_blank');
        newWindow.document.write(`<embed type="application/pdf" width="100%" height="100%" src=${reader.result} />`);
      };
      reader.readAsDataURL(document);
    }
    togglePopover(dynField, null);
  };
  const handleRemove = (dynField, index) => {
    onRemove(index);
    togglePopover(dynField, null);
  };
  return (
    <div className={clsx(classes.wrapper, extraClass)}>
      {documents.map((document, index) => {
        const { type, name, fileName } = document;
        const docName = name || fileName;
        const imageType = imageMap[type || getFileExtension(docName).contentType];
        const dynField = `field${index}`;
        return (
          <div key={docName} className={classes.document}>
            <img src={imageType} alt={imageType} className={classes.docImage} />
            <Typography className={classes.docName}>{docName}</Typography>
            <IconButton
              variant="plain"
              aria-describedby={docName}
              className={classes.moreIcon}
              onClick={(e) => togglePopover(dynField, e.currentTarget)}
            >
              <MoreVertIcon className={classes.optionIcon} />
            </IconButton>
            <Popover
              id={docName}
              open={Boolean(fields[dynField])}
              PaperProps={{
                className: classes.popperClass,
              }}
              variant="plain"
              anchorEl={fields[dynField]}
              onClose={() => togglePopover(dynField, null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.popoverContainer}>
                <Button
                  variant="plain"
                  className={classes.visibleButton}
                  onClick={() => handlePreview(dynField, document)}
                >
                  <VisibleIcon className={classes.icon} />
                  <Typography className={classes.optionsText}>Preview</Typography>
                </Button>
                <Button
                  variant="plain"
                  className={classes.deleteButton}
                  onClick={() => handleRemove(dynField, index)}
                >
                  <DeleteIcon className={classes.icon} />
                  <Typography className={classes.optionsText}>Delete</Typography>
                </Button>
              </div>
            </Popover>
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  document: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 140,
    marginRight: 10,
    marginBottom: 6,
    padding: 6,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
  },
  docImage: {
    width: 16,
    padding: 6,
    border: `2px solid ${theme.colors.border}`,
    borderRadius: 4,
  },
  docName: {
    fontSize: 14,
    padding: '0 6px',
    color: theme.colors.textDark,
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 140,
  },
  popperClass: {
    borderRadius: 4,
    marginTop: 6,
    marginLeft: -40,
  },
  icon: {
    width: 16,
    height: 16,
  },
  deleteButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    color: theme.colors.red,
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  visibleButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  optionsText: {
    width: '80%',
    margin: '0 auto',
    fontSize: 14,
    color: theme.colors.textDark,
  },
  deleteText: {
    color: theme.colors.red,
  },
  moreIcon: {
    marginLeft: 'auto',
    padding: 4,
  },
  deleteIcon: {
    width: 16,
    height: 16,
    color: theme.colors.textLight,
  },
}));

DocumentCards.propTypes = {
  extraClass: PropTypes.string,
  documents: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default DocumentCards;
