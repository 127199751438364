import React from 'react';
import * as PropTypes from 'prop-types';

const ArrowNext = ({
  width = 14,
  height = 10,
  className,
  fill = '#000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <path
        fill={fill}
        d="M10.418 4.286L7.352 1.22c-.28-.28-.28-.73 0-1s.73-.28 1 0l4.286 4.286c.28.28.28.73 0 1L8.362 9.79c-.28.28-.73.28-1 0s-.28-.73 0-1l3.066-3.066H.714C.32 5.714 0 5.394 0 5s.32-.714.714-.714h9.704z"
      />
    </svg>
  );
};

ArrowNext.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ArrowNext;
