const baseStyles = {
  gutterBottom: {
    marginBottom: 16,
  },
  acenter: {
    alignItems: 'center',
  },
  'aflex-start': {
    alignItems: 'flex-start',
  },
  'aflex-end': {
    alignItems: 'flex-end',
  },
  jcenter: {
    justifyContent: 'center',
  },
  'jflex-start': {
    justifyContent: 'flex-start',
  },
  'jflex-end': {
    justifyContent: 'flex-end',
  },
  'jspace-between': {
    justifyContent: 'space-between',
  },
  'jspace-around': {
    justifyContent: 'space-around',
  },
  'jspace-evenly': {
    justifyContent: 'space-evenly',
  },
};

export default baseStyles;
