import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import { GET_HOTELS } from '../../actionTypes';
import { getHotelsFailure, getHotelsSuccess } from './hotelListActions';

function* getHotels(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getHotels}`;
    const data = yield call(postRequest, url, params, headers);
    if (data) {
      yield put(getHotelsSuccess(data));
    } else {
      yield put(getHotelsFailure(data.message));
    }
  } catch (err) {
    yield put(getHotelsFailure(err.message));
  }
}
/* eslint-disable import/prefer-default-export */
export function* hotelListSaga() {
  yield takeLatest(GET_HOTELS, getHotels);
}
