import { createMuiTheme } from '@material-ui/core/styles';
import { colors, fonts } from 'UI/styleConsts';

const theme = createMuiTheme({
  colors,
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.orange,
    },
    text: {
      primary: colors.black,
      secondary: colors.textLight,
    },
  },
  extraShadows: [
    '-4px 4px 10px 4px rgba(0,0,0,0.2)',
    '0 1px 10px 0 rgba(0,0,0,0.17)',
    '0px 4px 8px 1px rgba(0,0,0,0.1)',
    '0px 6px 10px 0px rgba(0,0,0,0.2)',
  ],
  typography: {
    fontSize: 14,
    fontFamily: 'Lato',
    h3: {
      fontSize: 18,
      color: colors.black,
    },
    h2: {
      fontSize: 32,
    },
    body1: {
      fontSize: 14,
      color: colors.black,
    },
    body2: {
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.textLight,
    },
  },
  fonts,
  overrides: {
    MuiButton: {
      text: {
        textTransform: 'none',
      },
      root: {
        height: 42,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 4,
      },
      rounded: {
        borderRadius: 10,
      },
    },
    MuiIconButton: {
      root: {
        padding: 6,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        top: -16,
      },
    },
    MuiChip: {
      deleteIcon: {
        width: 16,
        height: 16,
      },
    },
  },
});

export default theme;
