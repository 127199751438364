import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Autocomplete from 'UI/autocomplete';
import LabelInput from 'UI/labelInput';
import Header from 'UI/header';
import Button from 'UI/button';
import DateButton from 'UI/dateButton';
import { getButtonText, shouldShowDiffToggle } from 'utils/common';
import arrowBack from 'assets/svg/arrow_left.svg';
import TransferDiff from './transferDiff';

class CreateTransfer extends Component {
  constructor(props) {
    super(props);
    const { transfer, itineraryActionType, originalTransfer } = this.props;
    let prevState = {};
    if (transfer) {
      prevState = {
        ...this.processTransferData(transfer),
        valid: true,
        edit: true,
      };
    }
    this.originalTransfer = {};
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalTransfer);
    if (this.showDiffToggle) {
      this.originalTransfer = this.processTransferData(originalTransfer);
    }
    this.state = {
      from: {
        value: '',
        valid: false,
        item: {},
      },
      to: {
        value: '',
        valid: false,
        item: {},
      },
      transferMode: {
        value: '',
        valid: false,
      },
      description: '',
      transferDate: new Date(),
      valid: false,
      errorMsg: '',
      edit: false,
      showDiff: false,
      ...prevState,
    };
  }

  processTransferData = (transfer) => {
    const {
      from, to, transferMode,
      transferDate, description,
    } = transfer;
    return {
      from: {
        value: from.name,
        valid: true,
        item: from,
      },
      to: {
        value: to.name,
        valid: true,
        item: to,
      },
      transferDate: moment(transferDate),
      transferMode: {
        value: transferMode,
        valid: true,
      },
      description,
    };
  };

  handleChange = (field, value) => {
    const { getSuggestions } = this.props;
    const { [field]: itm } = this.state;
    if (itm.constructor.name === 'Object') {
      this.setState({
        [field]: {
          value,
          valid: false,
        },
      });
      if (field === 'from' || field === 'to') {
        getSuggestions('destinations', value, {
          includeAddressTypes: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2'],
        });
      }
    } else {
      this.setState({ [field]: value }, this.validityChecker);
    }
  };

  handleSelection = (field, item, accessor) => {
    this.setState({
      [field]: {
        value: item[accessor],
        valid: true,
        item,
      },
    }, this.validityChecker);
  };

  validityChecker = (setError) => {
    const {
      from, to, transferMode,
    } = this.state;
    let valid = true;
    let errorMsg = '';
    if (!from.valid) {
      valid = false;
      errorMsg = 'Invalid departure place';
    } else if (!to.valid) {
      valid = false;
      errorMsg = 'Invalid arrival at place';
    } else if (!transferMode.valid) {
      valid = false;
      errorMsg = 'Invalid transfer mode';
    }
    if (setError) {
      this.setState({
        valid,
        errorMsg,
      });
    } else {
      this.setState({ valid });
    }
    return valid;
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    const {
      from, to, transferMode,
      transferDate, description, valid,
    } = this.state;
    if (!valid) {
      this.validityChecker(true);
      return;
    }
    const params = {
      from: from.item,
      to: to.item,
      transferMode: transferMode.value,
      transferDate: moment(transferDate)
        .toISOString(),
      description,
    };
    onSubmit(params);
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  render() {
    const {
      classes, onDismiss, itineraryActionType,
      suggestions, transferModes,
    } = this.props;
    const {
      from, to, transferMode, description,
      transferDate, valid, errorMsg, edit, showDiff,
    } = this.state;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.container}>
          <Header
            className={classes.headerStrip}
            titleClass={classes.titleClass}
            variant="back"
            img={arrowBack}
            title="Create transfer"
            onDismiss={onDismiss}
            showDiffToggle={this.showDiffToggle}
            checked={this.showDiffToggle && showDiff}
            label="VIEW CHANGES"
            onToggle={this.toggleDiff}
          />
          {showDiff ? (
            <TransferDiff
              data={this.state}
              oldData={this.originalTransfer}
            />
          )
            : (
              <div className={classes.body}>
                <div className={classes.row}>
                  <Autocomplete
                    label="FROM"
                    value={from.value}
                    data={suggestions.destinations}
                    accessor="name"
                    extraClass={classes.autocompleteContainerL}
                    inputClass={classes.autocompleteInput}
                    onChange={(val) => this.handleChange('from', val)}
                    onSelected={(item) => this.handleSelection('from', item, 'name')}
                  />
                  <Autocomplete
                    label="TO"
                    value={to.value}
                    data={suggestions.destinations}
                    accessor="name"
                    extraClass={classes.autocompleteContainerR}
                    inputClass={classes.autocompleteInput}
                    onChange={(val) => this.handleChange('to', val)}
                    onSelected={(item) => this.handleSelection('to', item, 'name')}
                  />
                </div>
                <div className={classes.row}>
                  <Autocomplete
                    label="MODE OF TRANSFER"
                    value={transferMode.value}
                    data={transferModes}
                    accessor="title"
                    extraClass={classes.autocompleteContainerL}
                    inputClass={classes.autocompleteInput}
                    onChange={(val) => this.handleChange('transferMode', val)}
                    onSelected={(item) => this.handleSelection('transferMode', item, 'title')}
                  />
                  <LabelInput
                    label="TRANSFER DATE"
                    extraClass={classes.labelDateContainer}
                  >
                    <DateButton
                      format="DD MMM YY - hh:mm A"
                      askTime
                      value={transferDate}
                      extraClass={classes.transferDate}
                      onChange={(date) => this.handleChange('transferDate', date)}
                    />
                  </LabelInput>
                </div>
                <LabelInput
                  value={description}
                  inputClass={classes.description}
                  label="DESCRIPTION"
                  placeholder="Transfer description"
                  inputProps={{
                    multiline: true,
                    rows: 3,
                    rowsMax: 3,
                  }}
                  onChange={(val) => this.handleChange('description', val)}
                />
              </div>
            )}
          <div className={classes.footer}>
            {errorMsg && <Typography className={classes.errorMsg}>{errorMsg}</Typography>}
            <Button
              className={clsx(classes.createButton, !valid && classes.disabled)}
              onClick={this.onSubmit}
            >
              {getButtonText(itineraryActionType, edit, 'transfer')}
            </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 940,
    height: 562,
    maxHeight: 562,
    borderRadius: 10,
    backgroundColor: theme.colors.white,
    overflowY: 'auto',
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  body: {
    padding: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  autocompleteContainerL: {
    flex: 1,
    marginRight: 10,
  },
  autocompleteContainerR: {
    flex: 1,
    marginLeft: 10,
  },
  autocompleteInput: {
    width: '100%',
    height: '100%',
    padding: '5px 15px',
  },
  labelDateContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: 10,
  },
  transferDate: {
    border: `1px solid ${theme.colors.border}`,
    borderRadius: '4px',
    height: '40px',
  },
  description: {
    height: 'auto',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  errorMsg: {
    padding: '0 16px',
    color: theme.colors.red,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  createButton: {
    width: 180,
    color: theme.colors.white,
    fontSize: 16,
    borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
});

CreateTransfer.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  transfer: PropTypes.object,
  transferModes: PropTypes.array.isRequired,
  // mode: PropTypes.string.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  originalTransfer: PropTypes.object,
};

export default withStyles(styles)(CreateTransfer);
