import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import DiffInput from './diffInput';

const ActivityDiff = ({ data, oldData }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.imageSection}>
        <Typography
          className={classes.label}
        >
          Experience Photos
        </Typography>
        <div className={classes.imageContainer}>
          {data?.images.length ? (
            data.images.map((image, i) => {
              return (
                <img
                  className={classes.image}
                  alt={i}
                  src={data.images}
                />
              );
            })
          ) : null}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.row}>
          <DiffInput
            label="TITLE"
            value={data.title}
            oldValue={oldData.title}
            extraClass={classes.fullWidth}
          />
        </div>
        <div className={classes.row}>
          <DiffInput
            label="DESCRIPTION"
            value={data.description}
            oldValue={oldData.description}
            extraClass={classes.fullWidth}
          />
        </div>
        <div className={classes.row}>
          <DiffInput
            label="LOCATION"
            value={data.location.value}
            oldValue={oldData.location.value}
            extraClass={classes.left}
          />
          <DiffInput
            label="TRANSFERS"
            value={data.transfers}
            oldValue={oldData.transfers}
            extraClass={classes.right}
          />
        </div>
        <div className={classes.row}>
          <DiffInput
            label="DURATION"
            value={data.duration.value}
            oldValue={oldData.duration.value}
            extraClass={classes.left}
          />
          <DiffInput
            label="ACTIVITY TIME"
            value={moment(data.activityTime).format('DD MMM YY - hh:mm A')}
            oldValue={moment(oldData.activityTime).format('DD MMM YY - hh:mm A')}
            extraClass={classes.right}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 40px',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.colors.white,
  },
  imageSection: {
    minWidth: '20%',
    overflowY: 'auto',
    margin: '30px 20px 30px 0px',
    height: '80%',
    boxSizing: 'border-box',
    flex: 3,
  },
  imageContainer: {
    flex: 1,
    height: '90%',
    border: `1px dashed ${theme.colors.primaryBackground}`,
  },
  body: {
    flex: 7,
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px 30px 20px',
    borderLeft: `1px solid ${theme.colors.border}`,
  },
  image: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
    width: '100%',
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  fullWidth: {
    flex: 1,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    marginBottom: 10,
  },
}));

ActivityDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default ActivityDiff;
