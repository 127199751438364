import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import Section from 'UI/section';
import { getTravellerText } from 'utils/common';
import DiffInput from './diffInput';

const ExperienceDiff = ({ data, oldData }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Section
        type="row"
        justify="flex-start"
        gutterBottom
      >
        <DiffInput
          label="No. of travellers"
          value={getTravellerText(data.travellers)}
          oldValue={getTravellerText(oldData.travellers)}
        />
      </Section>
      <Typography className={classes.transportation}>
        Transportation
      </Typography>
      <Section
        type="row"
        gutterBottom
      >
        <DiffInput
          label="From"
          value={data.from?.value || '-'}
          oldValue={oldData.from?.value || '-'}
          extraClass={classes.input}
        />
        <DiffInput
          label="To"
          value={data.to?.value || '-'}
          oldValue={oldData.to?.value || '-'}
          extraClass={classes.input}
        />
        <DiffInput
          label="Mode of transfer"
          value={data.transferMode?.value || '-'}
          oldValue={oldData.transferMode?.value || '-'}
          extraClass={classes.input}
        />
      </Section>
      <DiffInput
        label="Description"
        value={data.description}
        oldValue={oldData.description}
        extraClass={classes.input}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
  },
  transportation: {
    fontSize: 16,
    color: theme.colors.black,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  input: {
    flex: 1,
  },
}));

ExperienceDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default ExperienceDiff;
