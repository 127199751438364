import React from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import { getDateStr } from 'utils/common';
import DiffInput from './diffInput';

const RouteDiff = ({ data, oldData }) => {
  const classes = useStyles();
  const newLen = data.length;
  const oldLen = oldData.length;
  const renderRow = (item, oldItem) => {
    return (
      <div
        className={classes.row}
        key={item?._id || oldItem?._id}
      >
        <DiffInput
          value={item?.place.value || '-'}
          oldValue={oldItem?.place.value || '-'}
          extraClass={classes.left}
        />
        <DiffInput
          value={item?.dates ? getDateStr(item.dates.from, item.dates.to) : '-'}
          oldValue={oldItem?.dates ? getDateStr(oldItem.dates.from, oldItem.dates.to) : '-'}
          extraClass={classes.right}
        />
        <div className={classes.spacing} />
      </div>
    );
  };
  const diffRoutes = [];
  for (let i = 0; i < data.length; i++) {
    diffRoutes.push(renderRow(data[i], oldData[i]));
  }
  if (oldLen > newLen) {
    for (let i = newLen; i < oldLen; i++) {
      diffRoutes.push(renderRow(undefined, oldData[i]));
    }
  }
  return (
    <div className={classes.container}>
      {diffRoutes}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
    width: '100%',
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  spacing: {
    height: 1,
    width: 34,
  },
}));

RouteDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default RouteDiff;
