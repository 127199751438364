import {
  GET_EXPERIENCES,
  GET_EXPERIENCES_SUCCESS,
  GET_EXPERIENCES_FAILURE,
} from '../../actionTypes';

const initialState = {
  experiences: [],
  loading: false,
  loaded: false,
  failed: false,
};

const experiences = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPERIENCES:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_EXPERIENCES_SUCCESS:
      return {
        ...state,
        experiences: action.payload,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_EXPERIENCES_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default experiences;
