import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles';
import DragIndicator from '@material-ui/icons/DragIndicator';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DraggableList from 'UI/draggableList';
import Button from 'UI/button';
import { uuid } from 'utils/common';
import { ITINERARY_ACTIONS_TYPES } from 'utils/consts';

class InclusionExclusion extends Component {
  handleDragEnd = (field, data) => {
    const { onUpdate } = this.props;
    onUpdate([field], data);
  };

  handleChange = (field, index, value) => {
    const { [field]: items, onUpdate } = this.props;
    const modItems = [...items];
    modItems[index].text = value;
    onUpdate(field, modItems);
  };

  keyPressHandler = (field, index, keyEvent, ref) => {
    const { onUpdate } = this.props;
    if (keyEvent.key === 'Enter' && !keyEvent.shiftKey) {
      const { [field]: items } = this.props;
      if (ref && (index + 1) < items.length) {
        ref.children[index + 1].children[0].children[2].children[0].focus();
      } else if (ref && (index + 1) === items.length) {
        const modItems = [...items];
        modItems.push({
          id: uuid(),
          text: '',
        });
        onUpdate(field, modItems);
        setTimeout(() => {
          if (ref.children[index + 1]) {
            ref.children[index + 1].children[0].children[2].children[0].focus();
          }
        }, 300);
      }
    }
  };

  pasteHandler = (field, index, pasteEvent) => {
    const { [field]: items, onUpdate } = this.props;
    let modItems = [...items];
    const pastedVals = pasteEvent.clipboardData.getData('text/plain')
      .split('\n')
      .map((t) => ({
        id: uuid(),
        text: t,
      }));
    modItems = [...modItems.slice(0, index), ...pastedVals, ...modItems.slice(index + 1)];
    onUpdate(field, modItems);
  };

  handleItemAddition = (field) => {
    const { [field]: items, onUpdate } = this.props;
    const modItems = [...items];
    modItems.push({
      id: uuid(),
      text: '',
    });
    onUpdate(field, modItems);
  };

  handleItemRemoval = (field, index) => {
    const { [field]: items, onUpdate } = this.props;
    let modItems = [...items];
    modItems = modItems.filter((itm, idx) => idx !== index);
    if (modItems.length === 0) {
      modItems.push({
        id: uuid(),
        text: '',
      });
    }
    onUpdate(field, modItems);
  };

  renderRow = (field, item, index, snapshot, ref) => {
    const { classes } = this.props;
    const className = field === 'exclusions' ? 'red' : 'green';
    return (
      <div
        className={classes.row}
        key={item.id}
      >
        <DragIndicator />
        <div className={classes[className]} />
        <InputBase
          placeholder={`Add ${field}`}
          className={clsx(classes.input,
            snapshot.isDragging && classes.draggingInput)}
          classes={{
            focused: classes.focusedInput,
          }}
          multiline
          value={item.text}
          onKeyPress={(e) => this.keyPressHandler(field, index, e, ref)}
          onPaste={(e) => this.pasteHandler(field, index, e)}
          onChange={(e) => this.handleChange(field, index, e.target.value)}
          endAdornment={(
            <IconButton
              className={classes.inputAdornment}
              onClick={() => this.handleItemRemoval(field, index)}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          )}
        />
      </div>
    );
  };

  renderViewOnlyRow = (field, item) => {
    const { classes } = this.props;
    const className = field === 'exclusions' ? 'red' : 'green';
    return (
      <div
        className={classes.row}
        key={item.id}
      >
        <div className={classes[className]} />
        <Typography className={classes.readableText}>{item.text}</Typography>
      </div>
    );
  };

  render() {
    const {
      classes, inclusions, exclusions,
      nextHandler, mode, itineraryActionType,
    } = this.props;
    const editable = ((mode === 'edit' && itineraryActionType !== 'generate_voucher')
    || itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING);
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <Typography
            className={classes.title}
          >
            {mode === 'view' ? 'Inclusions' : 'Add Inclusions'}
          </Typography>
          {editable ? (
            <DraggableList
              label="inclusions"
              items={inclusions}
              onDragEnd={(items) => this.handleDragEnd('inclusions', items)}
              renderComponent={(...params) => this.renderRow('inclusions', ...params)}
            />
          ) : inclusions.map((inclusion, index) => this.renderViewOnlyRow('inclusions', inclusion, index, {}, null))}
          {editable ? (
            <Button
              variant="plain"
              className={classes.additionBtn}
              onClick={() => this.handleItemAddition('inclusions')}
            >
              + Add
            </Button>
          ) : null}
          <Typography
            className={classes.title}
          >
            {mode === 'view' ? 'Exclusions' : 'Add Exclusions'}
          </Typography>
          {editable ? (
            <DraggableList
              label="exclusions"
              items={exclusions}
              onDragEnd={(items) => this.handleDragEnd('exclusions', items)}
              renderComponent={(...params) => this.renderRow('exclusions', ...params)}
            />
          ) : exclusions.map((exclusion, index) => this.renderViewOnlyRow('exclusions', exclusion, index, {}, null))}
          {editable ? (
            <Button
              variant="plain"
              className={classes.additionBtn}
              onClick={() => this.handleItemAddition('exclusions')}
            >
              + Add
            </Button>
          ) : null}
        </div>
        {nextHandler ? (
          <div className={classes.footer}>
            <Button
              onClick={nextHandler}
              className={classes.saveBtn}
            >
              {mode === 'view' ? 'Next' : 'Save & Continue'}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 40px',
  },
  createImg: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  title: {
    fontSize: 16,
    color: theme.colors.textLight,
    padding: '10px 0',
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 40,
    marginBottom: 12,
  },
  red: {
    width: 6,
    height: 6,
    marginLeft: 10,
    borderRadius: '50%',
    backgroundColor: theme.colors.red,
  },
  green: {
    width: 6,
    height: 6,
    marginLeft: 10,
    borderRadius: '50%',
    backgroundColor: theme.colors.green,
  },
  input: {
    borderRadius: 4,
    width: '90%',
    padding: '10px 15px',
    minHeight: 46,
    marginLeft: 4,
    boxSizing: 'border-box',
    border: `1px solid ${theme.colors.white}`,
    '&:hover:not(:focus)': {
      border: `1px solid ${theme.colors.border}`,
    },
    '&:hover $inputAdornment': {
      opacity: 1,
    },
    '&:hover $closeIcon': {
      opacity: 1,
    },
    '&:focus-within $inputAdornment': {
      opacity: 1,
    },
    '&:focus-within $enterIcon': {
      opacity: 1,
    },
  },
  focusedInput: {
    border: `1px solid ${theme.colors.primary} !important`,
  },
  readableText: {
    width: '90%',
    padding: '10px 15px',
    boxSizing: 'border-box',
    minHeight: 40,
    marginLeft: 4,
    color: theme.colors.textDark,
    fontSize: 14,
  },
  draggingInput: {
    border: 'none',
  },
  additionBtn: {
    fontWeight: 'bold',
    padding: '4px 8px',
    height: 40,
    width: 160,
    color: theme.colors.primary,
  },
  inputAdornment: {
    opacity: 0,
    padding: 4,
  },
  closeIcon: {
    color: theme.colors.red,
    height: 16,
    width: 16,
    opacity: 0,
  },
  enterIcon: {
    opacity: 0,
    color: theme.colors.textDark,
    height: 16,
    width: 16,
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
});

InclusionExclusion.propTypes = {
  classes: PropTypes.object,
  inclusions: PropTypes.array.isRequired,
  exclusions: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  nextHandler: PropTypes.func,
  mode: PropTypes.string,
  itineraryActionType: PropTypes.string.isRequired,
};

export default withStyles(styles)(InclusionExclusion);
