import React from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from './styleConsts';

const Button = ({
  className = '',
  variant = 'normal',
  disabled = false,
  loading = false,
  children,
  onClick,
  action = 'normal',
  forwardedRef,
  ...extraProps
}) => {
  const classes = useStyles({ action });
  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled || loading}
      className={clsx(
        classes.base,
        classes[variant],
        className
      )}
      classes={{
        disabled: variant !== 'normal' ? classes.transparentDisabled : classes.disabled,
      }}
      ref={forwardedRef}
      {...extraProps}
    >
      {children}
      {loading && (
        <div className={classes.loaderContainer}>
          <CircularProgress
            size={12}
            className={classes.progress}
            disableShrink
            color="inherit"
          />
        </div>
      )}
    </ButtonBase>
  );
};

const getActionColor = (action) => {
  if (action === 'normal') {
    return {
      textColor: colors.white,
      backgroundColor: colors.primaryBackground,
      hoverColor: colors.primary,
      hoverLight: colors.primarySelected,
    };
  }
  if (action === 'success') {
    return {
      textColor: colors.white,
      backgroundColor: colors.green,
      hoverColor: colors.green,
      hoverLight: colors.greenLight,
    };
  }
  if (action === 'error') {
    return {
      textColor: colors.white,
      backgroundColor: colors.red,
      hoverColor: colors.red,
      hoverLight: colors.redLight,
    };
  }
  return {};
};

const useStyles = makeStyles({
  base: {
    cursor: 'pointer',
    fontSize: 14,
    height: 36,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  normal: ({ action }) => {
    const {
      textColor,
      backgroundColor,
      hoverColor,
    } = getActionColor(action);
    return {
      borderRadius: 18,
      minWidth: 90,
      padding: '5px 20px',
      color: textColor,
      backgroundColor,
      '&:hover': {
        backgroundColor: hoverColor,
      },
    };
  },
  disabled: {
    cursor: 'not-allowed !important',
    color: `${colors.white} !important`,
    backgroundColor: `${colors.grey} !important`,
    '&:hover': {
      backgroundColor: colors.grey,
    },
  },
  transparentDisabled: {
    cursor: 'not-allowed !important',
    color: `${colors.grey} !important`,
    backgroundColor: `${colors.white} !important`,
    border: `1px solid ${colors.border} !important`,
  },
  outlined: ({ action }) => {
    const {
      textColor,
      hoverColor,
      hoverLight,
    } = getActionColor(action);
    return {
      borderRadius: 18,
      padding: '5px 10px',
      color: hoverColor,
      backgroundColor: textColor,
      border: `1px solid ${hoverColor}`,
      '&:hover': {
        backgroundColor: hoverLight,
      },
    };
  },
  plain: ({ action }) => {
    const {
      backgroundColor,
      hoverColor,
    } = getActionColor(action);
    return {
      borderRadius: 4,
      padding: '5px 10px',
      color: hoverColor,
      '&:hover': {
        backgroundColor: action === 'normal' ? colors.primarySelected : backgroundColor,
      },
    };
  },
  progress: {
    marginRight: 6,
  },
  loaderContainer: {
    paddingLeft: 6,
  },
});

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['normal', 'outlined', 'plain']),
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  forwardedRef: PropTypes.any,
  action: PropTypes.oneOf(['normal', 'success', 'error']),
};

export default React.forwardRef((props, ref) => <Button {...props} forwardedRef={ref} />);
