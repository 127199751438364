import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Header from 'UI/header';
import Calendar from 'assets/svg/calendar.svg';
import Time from 'assets/svg/time.svg';
import reminderImg from 'assets/svg/reminder.svg';
import LabelInput from 'UI/labelInput';
import { DAY_MAP } from 'utils/consts';
import Footer from 'UI/footer';
import RadioOptions from 'UI/radioOptions';
import { calcNTimeSlots } from 'utils/common';

const followupDateAction = ['Today', 'Tomorrow', 'Next weekday'];
const unreachableDateSlots = ['Today', 'Tomorrow'];
const startBusiness24Hour = 9;
const endBusiness24Hour = 21;

class FollowUpRequest extends Component {
  constructor(props) {
    super(props);
    this.options = ['Follow up'];
    this.state = {
      selectedDate: '',
      selectedTime: '',
      date: new Date(),
      time: new Date(),
      notes: '',
      errorMsg: '',
      activeOption: 'follow_up',
      timeSlots: [],
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { activeOption, selectedDate } = this.state;
    if (prevState.selectedDate !== selectedDate && selectedDate) {
      if (selectedDate.toLowerCase() === 'today') {
        this.setState({ timeSlots: calcNTimeSlots(new Date().getHours()) });
      } else {
        this.setState({ timeSlots: calcNTimeSlots(7) });
      }
    }
    if (prevState.activeOption !== activeOption) {
      this.setState({ selectedDate: '', selectedTime: '', timeSlots: [] });
    }
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value, errorMsg: '' });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const {
      date,
      time,
      selectedDate,
      selectedTime,
      notes,
      errorMsg,
      activeOption,
    } = this.state;
    if (!selectedDate) {
      this.setState({ errorMsg: 'Please select follow up date' });
      return;
    }
    if (selectedDate === 'custom' && moment(date).endOf('day').unix() < moment().unix()) {
      this.setState({ errorMsg: 'Please select future date' });
      return;
    }
    if (!selectedTime) {
      this.setState({ errorMsg: 'Please select follow up time' });
      return;
    }
    const details = {
      time: selectedTime,
      notes,
    };
    if (selectedTime === 'custom') {
      details.time = moment(time).format('hh:mm A');
      const selectedTimeIn24 = +moment(time).format('H');
      if ((selectedTimeIn24 < startBusiness24Hour || selectedTimeIn24 >= endBusiness24Hour)
        && !errorMsg) {
        this.setState({ errorMsg: 'Time should be between business hours (9:00 AM - 9:00 PM' });
        return;
      }
    }
    if (((selectedDate === 'custom'
      && moment(date).format('DD/MM/YY') === moment().format('DD/MM/YY')) || selectedDate === 'Today')
      && (moment(details.time, 'hh:mm A').isBefore(moment()))) {
      this.setState({ errorMsg: 'Please select future time' });
      return;
    }
    if (selectedDate === 'custom') {
      details.date = moment(date).format('DD/MM/YY');
    } else if (selectedDate === 'Today') {
      details.date = moment().format('DD/MM/YY');
    } else if (selectedDate === 'Tomorrow') {
      details.date = moment(Date.now() + 86400000).format('DD/MM/YY');
    } else {
      details.date = moment(Date.now() + 604800000).format('DD/MM/YY');
    }

    details.action = activeOption.toUpperCase();

    onSubmit({ details });
  };

  render() {
    const {
      selectedDate,
      selectedTime,
      date,
      time,
      notes,
      errorMsg,
      activeOption,
      timeSlots,
    } = this.state;
    const { classes, onDismiss } = this.props;
    return (
      <div className={classes.container}>
        <Header
          title="Add reminder"
          img={reminderImg}
          onDismiss={onDismiss}
        />
        <div className={classes.body}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <RadioOptions
              options={this.options}
              defaultValue={this.options[0]}
              selected={activeOption === 'follow_up' ? this.options[0] : this.options[1]}
              onChange={(ev) => this.handleChange('activeOption', ev.target.value === this.options[0] ? 'follow_up' : 'mark_unreachable')}
            />
            {activeOption === 'follow_up' && (
              <div className={classes.dateSection}>
                <div className={classes.section}>
                  <Typography className={classes.titleText}>
                    Select reminder date
                  </Typography>
                  <div className={classes.dateChip}>
                    {followupDateAction.map((title) => {
                      const selected = title === selectedDate;
                      const renderTitle = title === 'Next weekday' ? `Next ${DAY_MAP[moment().day()]}` : title;
                      const width = `${Math.max(renderTitle.length * 10.25, 94)}px`;
                      return (
                        <Button
                          key={title}
                          style={{ width }}
                          className={clsx(classes.button, selected && classes.selected)}
                          onClick={() => this.handleChange('selectedDate', title)}
                        >
                          {renderTitle}
                        </Button>
                      );
                    })}
                    <Button
                      className={clsx(classes.button, selectedDate === 'custom' && classes.selected)}
                      onClick={() => this.handleChange('selectedDate', 'custom')}
                    >
                      {(selectedDate === 'custom') ? (
                        <Typography className={classes.dateTime}>{moment(date).format('DD/MM/YY')}</Typography>
                      )
                        : (
                          <>
                            <Typography className={classes.dateTime}>Choose date</Typography>
                            <img src={Calendar} alt="date" className={classes.buttonImg} />
                          </>
                        )}
                      <DatePicker
                        disablePast
                        value={date}
                        onChange={(dt) => this.handleChange('date', dt)}
                        className={classes.dateTimeInput}
                      />
                    </Button>
                  </div>
                </div>
                {selectedDate ? (
                  <div className={classes.section}>
                    <Typography className={classes.titleText}>Select reminder time</Typography>
                    <div className={classes.dateChip}>
                      {timeSlots.map((title) => {
                        const selected = title === selectedTime;
                        const width = `${Math.max(title.length * 10.25, 94)}px`;
                        return (
                          <Button
                            key={title}
                            style={{ width }}
                            className={clsx(classes.button, selected && classes.selected)}
                            onClick={() => this.handleChange('selectedTime', title)}
                          >
                            {title}
                          </Button>
                        );
                      })}
                      <Button
                        className={clsx(classes.button, selectedTime === 'custom' && classes.selected)}
                        onClick={() => this.handleChange('selectedTime', 'custom')}
                      >
                        {(selectedTime === 'custom') ? (
                          <Typography
                            className={classes.dateTime}
                          >
                            {moment(time).format('hh:mm A')}
                          </Typography>
                        )
                          : (
                            <>
                              <Typography className={classes.dateTime}>Choose time</Typography>
                              <img src={Time} alt="time" className={classes.buttonImg} />
                            </>
                          )}
                        <TimePicker
                          autoOk
                          label="12 hours"
                          minutesStep={5}
                          value={time}
                          onChange={(tm) => this.handleChange('time', tm)}
                          className={classes.dateTimeInput}
                        />
                      </Button>
                    </div>
                  </div>
                ) : null}
                <div className={classes.section}>
                  <LabelInput
                    label="NOTES (OPTIONAL)"
                    value={notes}
                    inputProps={{
                      multiline: true,
                      rows: 3,
                      rowsMax: 3,
                    }}
                    inputClass={classes.notesInput}
                    onChange={(val) => this.handleChange('notes', val)}
                  />
                </div>
              </div>
            )}
            {activeOption === 'mark_unreachable' && (
              <div className={classes.dateSection}>
                <div className={classes.section}>
                  <Typography className={classes.titleText}>
                    Select reminder date
                  </Typography>
                  <div className={classes.dateChip}>
                    {unreachableDateSlots.map((title) => {
                      const selected = title === selectedDate;
                      const width = `${Math.max(title.length * 10.25, 94)}px`;
                      return (
                        <Button
                          key={title}
                          style={{ width }}
                          className={clsx(classes.button, selected && classes.selected)}
                          onClick={() => this.handleChange('selectedDate', title)}
                        >
                          {title}
                        </Button>
                      );
                    })}
                  </div>
                </div>
                {selectedDate ? (
                  <div className={classes.section}>
                    <Typography className={classes.titleText}>Select reminder time</Typography>
                    <div className={classes.dateChip}>
                      {timeSlots.map((title) => {
                        const selected = title === selectedTime;
                        const width = `${Math.max(title.length * 10.25, 94)}px`;
                        return (
                          <Button
                            key={title}
                            style={{ width }}
                            className={clsx(classes.button, selected && classes.selected)}
                            onClick={() => this.handleChange('selectedTime', title)}
                          >
                            {title}
                          </Button>
                        );
                      })}
                      <Button
                        className={clsx(classes.button, selectedTime === 'custom' && classes.selected)}
                        onClick={() => this.handleChange('selectedTime', 'custom')}
                      >
                        {(selectedTime === 'custom') ? (
                          <Typography
                            className={classes.dateTime}
                          >
                            {moment(time).format('hh:mm A')}
                          </Typography>
                        )
                          : (
                            <>
                              <Typography className={classes.dateTime}>Choose time</Typography>
                              <img src={Time} alt="time" className={classes.buttonImg} />
                            </>
                          )}
                        <TimePicker
                          autoOk
                          label="12 hours"
                          minutesStep={5}
                          value={time}
                          onChange={(tm) => this.handleChange('time', tm)}
                          className={classes.dateTimeInput}
                        />
                      </Button>
                    </div>
                  </div>
                ) : null}
                <div className={classes.section}>
                  <LabelInput
                    label="NOTES (OPTIONAL)"
                    value={notes}
                    inputProps={{
                      multiline: true,
                      rows: 3,
                      rowsMax: 3,
                    }}
                    inputClass={classes.notesInput}
                    onChange={(val) => this.handleChange('notes', val)}
                  />
                </div>
              </div>
            )}
            <Footer errorMsg={errorMsg}>
              <Button
                aria-label="update"
                className={classes.updtBtn}
                onClick={this.handleSubmit}
              >
                Set Reminder
              </Button>
            </Footer>
            {/* <div className={classes.footer}>
              {errorMsg ? <Typography className={classes.errorMsg}>{errorMsg}</Typography> : null}
            </div> */}
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    padding: '0',
    boxSizing: 'border-box',
    flexDirection: 'column',
    minWidth: 600,
    borderRadius: 10,
  },
  body: {
    padding: '30px',
  },
  titleText: {
    fontSize: 14,
    marginBottom: 16,
    color: theme.colors.black,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  updtBtn: {
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    padding: '0 29px',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: '24px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    boxShadow: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  icon: {
    width: 15,
  },
  dateTime: {
    fontSize: 14,
    color: theme.colors.black,
  },
  button: {
    textTransform: 'none',
    borderRadius: 18,
    fontSize: 14,
    marginRight: '0.5rem',
    marginTop: '0.5rem',
    color: theme.colors.black,
    height: 32,
    padding: '0 10px',
    minHeight: 0,
    border: `1px solid ${theme.colors.border}`,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  selected: {
    border: `1px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.primaryLight,
  },
  buttonImg: {
    width: 16,
    height: 16,
    paddingLeft: 5,
  },
  inputField: {
    width: 500,
  },
  reason: {
    borderRadius: 4,
    padding: '6px 15px',
    border: `1px solid ${theme.colors.border}`,
    width: '100%',
  },
  section: {
    padding: '20px 0px 20px 0px',
  },
  dateTimeInput: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    opacity: 0,
    cursor: 'pointer',
    '& > div > input': {
      cursor: 'pointer !important',
    },
  },
  notesInput: {
    height: 'auto',
  },
  errorMsg: {
    marginRight: 10,
    fontWeight: 'bold',
    color: theme.colors.red,
    flexWrap: 'wrap',
  },
});

FollowUpRequest.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FollowUpRequest);
