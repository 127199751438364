import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from 'UI/button';
import PhoneNumber from 'UI/phoneNumber';
import Footer from 'UI/footer';
import Header from 'UI/header';
import whatsAppImg from 'assets/svg/whatsapp.svg';

const AddWhatsApp = ({
  request,
  suggestions,
  getSuggestions,
  onSubmit,
  onDismiss,
}) => {
  const classes = useStyles();
  const [number, setNumber] = useState(request.primaryCustomerData.phone || '');
  const [dialCode, setDialCode] = useState({
    valid: false,
    value: request.primaryCustomerData.phoneDialCode || '',
  });
  const handleUpdate = () => {
    onSubmit({
      whatsAppDialCode: dialCode.value,
      whatsApp: number,
    });
  };
  const dialCodeChange = (value) => {
    getSuggestions('countries', value);
    setDialCode({
      value,
      valid: false,
    });
  };
  const dialCodeSelect = (item) => {
    setDialCode({
      value: item.dialCode,
      valid: false,
    });
  };
  return (
    <div className={classes.container}>
      <Header
        onDismiss={onDismiss}
        title="Update WhatsApp"
        img={whatsAppImg}
      />
      <div className={classes.body}>
        <PhoneNumber
          dialCode={dialCode.value}
          label="WhatsApp"
          number={number}
          showCounter
          suggestions={suggestions}
          handleNumberChange={setNumber}
          handleDialCodeChange={dialCodeChange}
          handleDialCodeSelect={dialCodeSelect}
        />
      </div>
      <Footer>
        <Button
          className={classes.updateBtn}
          onClick={handleUpdate}
          variant="normal"
        >
          Update
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  // title: {
  //   fontSize: 20,
  //   color: theme.colors.black,
  //   marginBottom: 16,
  // },
  body: {
    padding: '20px 40px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  updateBtn: {
    width: 140,
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBackground,
  },
}));

AddWhatsApp.propTypes = {
  suggestions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
};

export default AddWhatsApp;
