import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import Header from 'UI/header';
import LabelInput from 'UI/labelInput';
import DateRangeSelection from 'UI/dateRangeSelection';
import Autocomplete from 'UI/autocomplete';
import MultiSelect from 'UI/multiSelect';
import TravellerSelection from 'UI/travellerSelection';
import Footer from 'UI/footer';
import StayDiff from 'components/itineraryMaker/stayDiff';
import arrowBack from 'assets/svg/arrow_left.svg';
import Checkbox from 'UI/checkbox';
import {
  getValidDates,
  uuid,
  getButtonText,
  shouldShowDiffToggle,
  getDateStr,
} from 'utils/common';

class StayPreferences extends Component {
  constructor(props) {
    super(props);
    this.roomTemplate = {
      roomType: {
        value: '',
        valid: false,
      },
      mealTypes: {
        value: '',
        valid: false,
        items: [],
      },
      stayDates: {
        from: new Date(),
        to: new Date(),
      },
      occupancy: {
        adults: 0,
        children: 0,
        infants: 0,
      },
      customerRefNum: '',
      uniqueId: uuid(),
    };
    let prevState = {};
    this.originalStay = {};
    const {
      stay = {}, relatedRoute,
      itineraryActionType, originalStay,
    } = this.props;
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalStay);
    if (stay.stay && stay.roomDetails) {
      prevState = this.processStay(stay);
      if (this.showDiffToggle) {
        this.originalStay = this.processStay(originalStay);
      }
    }
    this.state = {
      errorMsg: '',
      valid: false,
      checkinCheckout: '',
      splitStay: false,
      rooms: {
        value: '1 Room',
        valid: true,
      },
      transferMode: {
        value: '',
        valid: false,
        items: [],
      },
      roomDetails: [this.roomTemplate],
      checkinCheckoutDates: {},
      notes: '',
      edit: false,
      showDiff: false,
      ...prevState,
    };
    this.roomList = new Array(10).fill(true)
      .map((_, i) => ({ name: `${i + 1} Rooms` }));
    this.roomList[0] = { name: '1 Room' };
    // this.roomTypes = ['Deluxe', 'Deluxe Twin', 'Standard', 'Suite'].map((t) => ({ name: t }));
    this.disabledDateFn = getValidDates(relatedRoute.dates.from, relatedRoute.dates.to);
  }

  processStay = (stay) => {
    const checkinCheckoutDates = {
      from: moment(stay.checkInDate).toDate(),
      to: moment(stay.checkOutDate).toDate(),
    };
    const checkinCheckout = getDateStr(stay.checkInDate, stay.checkOutDate);
    return {
      rooms: {
        value: stay.rooms,
        valid: true,
      },
      transferMode: {
        value: '',
        valid: true,
        items: stay.transferMode,
      },
      checkinCheckoutDates,
      checkinCheckout,
      splitStay: Boolean(stay.splitStay),
      roomDetails: stay.roomDetails.map((rd) => ({
        uniqueId: uuid(),
        occupancy: rd.occupancy,
        mealTypes: {
          value: '',
          valid: true,
          items: rd.mealTypes?.map((mt) => ({ title: mt })) || [],
        },
        roomType: {
          value: rd.roomType,
          valid: true,
        },
        customerRefNum: rd.customerRefNum || '',
        stayDates: stay.splitStay ? {
          from: moment(rd.stayCheckInDate).toDate(),
          to: moment(rd.stayCheckOutDate).toDate(),
        } : checkinCheckoutDates,
        stayDate: stay.splitStay ? getDateStr(rd.stayCheckInDate, rd.stayCheckOutDate)
          : checkinCheckout,
      })),
      notes: stay.notes || '',
      valid: true,
      edit: true,
    };
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  handleDateSelection = (date, dateStr) => {
    const { from, to } = date;
    if (from && to) {
      this.setState({
        checkinCheckout: dateStr,
        checkinCheckoutDates: date,
      }, this.validityChecker);
    }
  };

  handleSelection = (field, item, accessor) => {
    this.setState({
      [field]: {
        value: item[accessor],
        valid: true,
      },
    }, this.validityChecker);
  };

  handleChange = (field, value) => {
    const { [field]: itm } = this.state;
    if (itm.constructor.name === 'Object') {
      this.setState({
        [field]: {
          value,
          valid: false,
        },
      }, this.validityChecker);
    } else {
      this.setState({ [field]: value }, this.validityChecker);
    }
  };

  handleTransferChange = (value) => {
    this.setState((prevState) => ({
      transferMode: {
        ...prevState.transferMode,
        value,
      },
    }));
  };

  handleTransferSelection = (item) => {
    this.setState((prevState) => ({
      transferMode: {
        items: [item, ...prevState.transferMode.items],
        value: '',
        valid: true,
      },
    }), this.validityChecker);
  };

  handleTransferRemoval = (item) => {
    this.setState((prevState) => ({
      transferMode: {
        items: prevState.transferMode.items.filter((i) => i.code !== item.code),
        value: '',
        valid: true,
      },
    }), this.validityChecker);
  };

  handleMealRemoval = (index, value) => {
    const { roomDetails } = this.state;
    const modDetails = [...roomDetails];
    const roomDetail = modDetails[index];
    roomDetail.mealTypes.items = roomDetail.mealTypes.items
      .filter((itm) => itm.title !== value.title);
    modDetails[index] = roomDetail;
    this.setState({ roomDetails: modDetails }, this.validityChecker);
  };

  handleRoomDetailChange = (index, field, value) => {
    const { roomDetails } = this.state;
    const modDetails = [...roomDetails];
    const roomDetail = modDetails[index];
    // TODO: fix this when we have inventory for roomType
    if (field === 'occupancy') {
      roomDetail[field] = value;
    } else if (field === 'mealTypes') {
      roomDetail[field] = {
        ...roomDetail[field],
        value,
      };
    } else if (field === 'roomType') {
      roomDetail[field] = {
        value,
        valid: true,
      };
    } else if (field === 'customerRefNum') {
      roomDetail[field] = value;
    }
    modDetails[index] = roomDetail;
    this.setState({ roomDetails: modDetails });
  };

  handleRoomDetailDateChange = (index, date, dateStr) => {
    const { roomDetails } = this.state;
    const modDetails = [...roomDetails];
    const roomDetail = modDetails[index];
    roomDetail.stayDates = date;
    roomDetail.stayDate = dateStr;
    modDetails[index] = roomDetail;
    this.setState({ roomDetails: modDetails });
  };

  handleRoomDetailSelection = (index, field, item, accessor) => {
    const { roomDetails } = this.state;
    const modDetails = [...roomDetails];
    const roomDetail = modDetails[index];
    if (field === 'mealTypes') {
      const { mealTypes } = roomDetail;
      const iIndex = mealTypes.items.findIndex((i) => i[accessor] === item[accessor]);
      if (iIndex === -1) {
        roomDetail[field] = {
          value: '',
          valid: true,
          items: [item, ...mealTypes.items],
        };
      }
    } else {
      roomDetail[field] = {
        value: item[accessor],
        valid: true,
      };
    }
    modDetails[index] = roomDetail;
    this.setState({ roomDetails: modDetails }, this.validityChecker);
  };

  handleRoomSelection = (item, accessor) => {
    const roomDetails = [];
    for (let i = 0; i < Number(item[accessor].split(' ')[0]); i++) {
      roomDetails.push({
        ...this.roomTemplate,
        uniqueId: uuid(),
      });
    }
    this.setState({
      rooms: {
        value: item[accessor],
        valid: true,
      },
      roomDetails,
    }, this.validityChecker);
  };

  validityChecker = (setError) => {
    const {
      rooms,
      roomDetails,
      checkinCheckoutDates: {
        from = '', to = '',
      },
      transferMode,
    } = this.state;
    let valid = true;
    let errorMsg = '';
    if (!rooms.valid) {
      valid = false;
      errorMsg = 'Select rooms to continue';
    }
    if (!from || !to) {
      valid = false;
      errorMsg = 'Select travel date to continue';
    }
    if (transferMode.items.length === 0) {
      valid = false;
      errorMsg = 'Please select transfer mode';
    }
    for (let i = 0; i < roomDetails.length; i++) {
      const { roomType, mealTypes, occupancy } = roomDetails[i];
      if (!roomType.valid || !mealTypes.valid || occupancy.adults === 0) {
        valid = false;
        errorMsg = 'Incomplete room details';
        break;
      }
    }
    if (setError) {
      this.setState({
        valid,
        errorMsg,
      });
    } else {
      this.setState({ valid });
    }
    return valid;
  };

  addPrefHandler = () => {
    const { onSelect } = this.props;
    const valid = this.validityChecker(true);
    if (valid) {
      const {
        checkinCheckoutDates,
        rooms,
        roomDetails,
        transferMode,
        notes,
        splitStay,
      } = this.state;
      const checkInDate = moment(checkinCheckoutDates.from).toISOString();
      const checkOutDate = moment(checkinCheckoutDates.to).toISOString();
      const params = {
        checkInDate,
        checkOutDate,
        rooms: rooms.value,
        transferMode: transferMode.items,
        splitStay,
        roomDetails: roomDetails.map((rd) => ({
          occupancy: rd.occupancy,
          mealTypes: rd.mealTypes.items.map((i) => i.title),
          roomType: rd.roomType.value,
          customerRefNum: rd.customerRefNum,
          stayCheckInDate: splitStay ? moment(rd.stayDates.from).toISOString() : checkInDate,
          stayCheckOutDate: splitStay ? moment(rd.stayDates.to).toISOString() : checkOutDate,
        })),
        notes,
      };
      onSelect(params);
    }
  };

  renderHotelPreview = () => {
    const { classes, stay: { stay } } = this.props;
    const {
      name, address = {},
    } = stay;
    const { addressText = '' } = address;
    // const arr = new Array(5).fill(true);
    return (
      <div className={classes.previewSection}>
        <div className={classes.hotelDetails}>
          <div className={classes.hotelRow}>
            <Typography className={classes.hotelTitle}>{name}</Typography>
            {/* <div className={classes.hotelRow}>
              {arr.map((_, i) => (
                // eslint-disable-next-line
                <img key={i} src={starImg} alt=" " className={classes.starImg} />
              ))}
            </div> */}
          </div>
          <Typography className={classes.hotelDescription}>{addressText}</Typography>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes, onDismiss, itineraryActionType,
      mealPlans, transferModes,
    } = this.props;
    const {
      checkinCheckout,
      checkinCheckoutDates,
      rooms,
      roomDetails,
      transferMode,
      // hotelLink,
      notes,
      errorMsg,
      valid,
      edit,
      splitStay,
      showDiff,
    } = this.state;
    return (
      <div className={classes.container}>
        <Header
          className={classes.headerStrip}
          titleClass={classes.titleClass}
          img={arrowBack}
          variant="back"
          title="Choose stay | Add preferences"
          onDismiss={onDismiss}
          showDiffToggle={this.showDiffToggle}
          checked={showDiff}
          label="VIEW CHANGES"
          onToggle={this.toggleDiff}
        />
        <div className={classes.body}>
          {this.renderHotelPreview()}
          {!showDiff ? (
            <div className={classes.displaySection}>
              <div className={classes.row}>
                <LabelInput
                  label="Check in - Check out"
                  extraClass={classes.wrapperLeft}
                >
                  <DateRangeSelection
                    placeholder="Check in - Check out"
                    value={checkinCheckout}
                    dates={checkinCheckoutDates}
                    shouldDisableDate={this.disabledDateFn}
                    onSelected={(date, dateStr) => this.handleDateSelection(date, dateStr)}
                  />
                </LabelInput>
                <Autocomplete
                  label="ROOMS"
                  value={rooms.value}
                  extraClass={classes.wrapperMid}
                  inputClass={classes.autoCompleteInput}
                  data={this.roomList}
                  accessor="name"
                  onChange={(val) => this.handleChange('rooms', val)}
                  onSelected={(item) => this.handleRoomSelection(item, 'name')}
                />
                <MultiSelect
                  label="STAY TRANSFER"
                  value={transferMode.value}
                  selections={transferMode.items}
                  placeholder="Transfer to stay"
                  extraClass={classes.wrapperRight}
                  inputClass={classes.autoCompleteInput}
                  data={transferModes}
                  accessor="title"
                  removeHandler={(item) => this.handleTransferRemoval(item)}
                  onChange={(val) => this.handleTransferChange(val)}
                  onSelected={(item) => this.handleTransferSelection(item)}
                />
              </div>
              {(rooms.value !== '1 Room' && rooms.value?.length) ? (
                <div className={classes.row}>
                  <Checkbox
                    onChange={() => this.handleChange('splitStay', !splitStay)}
                    checked={splitStay}
                    label="Split stay"
                  />
                </div>
              ) : null}
              {roomDetails.map((roomDetail, indx) => {
                const {
                  roomType, mealTypes, occupancy, stayDate,
                  uniqueId, customerRefNum = '', stayDates,
                } = roomDetail;
                return (
                  <div className={classes.col} key={uniqueId}>
                    <div className={classes.row}>
                      <Typography className={classes.index}>{`# ${indx + 1}`}</Typography>
                      {/* <Autocomplete
                    label="ROOM TYPE*"
                    value={roomType.value}
                    placeholder="eg. Deluxe Room - 7500 / night"
                    extraClass={classes.wrapper}
                    inputClass={classes.autoCompleteInput}
                    data={this.roomTypes}
                    accessor="name"
                    onChange={(val) => this.handleRoomDetailChange(indx, 'roomType', val)}
                    onSelected={(item) =>
                      this.handleRoomDetailSelection(indx, 'roomType', item, 'name')}
                  /> */}
                      <LabelInput
                        label="ROOM TYPE"
                        required
                        extraClass={classes.roomType}
                        value={roomType.value}
                        placeholder="eg. Deluxe Room - 7500 / night"
                        onChange={(val) => this.handleRoomDetailChange(indx, 'roomType', val)}
                      />
                      <TravellerSelection
                        label="OCCUPANCY"
                        travellers={occupancy}
                        extraClass={classes.occupancy}
                        onChange={(val) => this.handleRoomDetailChange(indx, 'occupancy', val)}
                      />
                      {splitStay ? (
                        <LabelInput
                          label="Stay Check in - Check out"
                          extraClass={classes.stayDates}
                        >
                          <DateRangeSelection
                            placeholder="Check in - Check out"
                            value={stayDate}
                            dates={stayDates}
                            popupClass={classes.datePopup}
                            shouldDisableDate={this.disabledDateFn}
                            onSelected={(date, dateStr) => (
                              this.handleRoomDetailDateChange(indx, date, dateStr)
                            )}
                          />
                        </LabelInput>
                      ) : null}
                      <MultiSelect
                        label="MEAL TYPE"
                        value={mealTypes.value}
                        selections={mealTypes.items}
                        placeholder="eg. Breakfast"
                        extraClass={classes.wrapper}
                        inputClass={classes.autoCompleteInput}
                        data={mealPlans}
                        accessor="title"
                        onChange={(val) => this.handleRoomDetailChange(indx, 'mealTypes', val)}
                        removeHandler={(val) => this.handleMealRemoval(indx, val)}
                        onSelected={(item) => this.handleRoomDetailSelection(indx, 'mealTypes', item, 'title')}
                      />
                    </div>
                    {itineraryActionType === 'confirm_booking' || itineraryActionType === 'generate_voucher' || itineraryActionType === 'view_booking' ? (
                      <LabelInput
                        required
                        extraClass={classes.refInput}
                        label="Confirmation No."
                        placeholder="Confirmation number"
                        disabledInput={itineraryActionType !== 'confirm_booking'}
                        value={customerRefNum}
                        onChange={(val) => this.handleRoomDetailChange(indx, 'customerRefNum', val)}
                      />
                    ) : null}
                  </div>
                );
              })}
              <div className={classes.row}>
                <LabelInput
                  value={notes}
                  inputClass={classes.notes}
                  extraClass={classes.notesContainer}
                  label="NOTES"
                  placeholder="Stay notes"
                  inputProps={{
                    multiline: true,
                    rows: 3,
                    rowsMax: 6,
                  }}
                  onChange={(val) => this.handleChange('notes', val)}
                />
              </div>
            </div>
          ) : <StayDiff oldData={this.originalStay} data={this.state} />}
        </div>
        <Footer
          errorMsg={errorMsg}
          extraClass={classes.footer}
        >
          <Button
            className={clsx(classes.addButton, valid && classes.validBtn)}
            onClick={this.addPrefHandler}
          >
            {getButtonText(itineraryActionType, edit, 'stay')}
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    backgroundColor: theme.colors.white,
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxHeight: 460,
    overflowY: 'auto',
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 30,
  },
  previewSection: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  // hotelImg: {
  //   width: 60,
  //   height: 60,
  //   borderRadius: 8,
  //   marginRight: 16,
  // },
  hotelDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  hotelRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hotelTitle: {
    fontSize: 16,
    marginBottom: 6,
    marginRight: 6,
  },
  starImg: {
    width: 12,
    height: 12,
    marginRight: 6,
  },
  hotelDescription: {
    fontSize: 12,
    color: theme.colors.textLight,
  },
  roomType: {
    flex: 1,
  },
  refInput: {
    marginBottom: 10,
  },
  displaySection: {
    // flex: 7,
    display: 'flex',
    minWidth: 700,
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '20px 40px',
  },
  index: {
    fontSize: 16,
    color: theme.colors.textLight,
    marginRight: 20,
    marginTop: 26,
  },
  wrapperRight: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
    marginLeft: 10,
  },
  wrapperLeft: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
    marginRight: 10,
  },
  wrapper: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
  },
  occupancy: {
    width: 'auto',
    flex: 1,
    margin: '0 10px',
  },
  stayDates: {
    width: 'auto',
    flex: 1,
    margin: '0 10px',
  },
  datePopup: {
    left: -260,
  },
  wrapperMid: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
    margin: '0 20px',
  },
  // hotelLink: {
  //   flex: 1,
  //   borderRadius: 4,
  //   width: '100%',
  //   marginLeft: 10,
  // },
  autoCompleteInput: {
    padding: '10px 5px 10px 15px',
    height: 40,
    width: '100%',
  },
  notesContainer: {
    width: '100%',
  },
  notes: {
    height: 'auto',
  },
  footer: {
    boxSizing: 'border-box',
  },
  addButton: {
    width: 180,
    borderRadius: 25,
    backgroundColor: theme.colors.grey,
    fontSize: 16,
    color: theme.colors.white,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
  validBtn: {
    backgroundColor: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
});

StayPreferences.propTypes = {
  classes: PropTypes.object,
  stay: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  mealPlans: PropTypes.array.isRequired,
  relatedRoute: PropTypes.object.isRequired,
  transferModes: PropTypes.array.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  originalStay: PropTypes.object,
};

export default withStyles(styles)(StayPreferences);
