import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from 'UI/button';
import Footer from 'UI/footer';
import Header from 'UI/header';
import TravellerSelection from 'UI/travellerSelection';
import Chip from 'UI/chip';
import arrowBack from 'assets/svg/arrow_left.svg';
import { IMAGE_PLACEHOLDER, ITINERARY_READ_MODES } from 'utils/consts';
import { getTravellerText, shouldShowDiffToggle } from 'utils/common';
import DiffInput from './diffInput';
import ExperienceDiff from './experienceDiff';

class CreateExperience extends Component {
  constructor(props) {
    super(props);
    const {
      activity, originalActivity, itineraryActionType, requirement,
    } = this.props;
    let changes = {};
    if (activity && activity.from) {
      changes = this.processExperience(activity);
    }
    this.originalExperience = {};
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalActivity);
    if (this.showDiffToggle) {
      this.originalExperience = this.processExperience(originalActivity);
    }
    this.state = {
      from: {
        value: '',
        valid: false,
        item: {},
      },
      to: {
        value: '',
        valid: false,
        item: {},
      },
      transferMode: {
        value: '',
        valid: false,
        item: {},
      },
      description: '',
      travellers: requirement.travellers,
      errorMsg: '',
      showDiff: false,
      experienceInfo: false,
      ...changes,
    };
  }

  /*
  componentDidMount = () => {
    this.mapEl = document.getElementById('map');
    if (!this.mapEl) {
      const scriptEl = document.createElement('script');
      scriptEl.async = true;
      scriptEl.defer = true;
      scriptEl.id = 'map';
      scriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API}`;
      scriptEl.onload = this.initMap;
      document.head.appendChild(scriptEl);
      this.mapEl = scriptEl;
    } else {
      this.initMap();
    }
  };
  */

  initMap = () => {
    /* eslint-disable */
    const { experience: { address } } = this.props;
    const { place = {} } = address;
    let name = '';
    let lat = '';
    let long = '';
    if (place.name) {
      name = place.name;
      lat = place.coordinates.lat;
      long = place.coordinates.long;
    }
    if (name && lat && long) {
      const location = new google.maps.LatLng(lat, long);
      const map = new google.maps.Map(document.getElementById('googleMap'), {
        center: location,
        zoom: 12,
      });
      new google.maps.Marker({
        position: location,
        label: name,
        map: map,
      });
    }
    /* eslint-enable */
  };

  processExperience = (experience) => {
    const {
      from, to, transferMode,
      travellers, description,
    } = experience;
    let transportationChanges = {};
    if (from) {
      transportationChanges = {
        transferMode: {
          value: transferMode.title,
          valid: true,
          item: transferMode,
        },
        from: {
          value: from.name,
          valid: true,
          item: from,
        },
        to: {
          value: to.name,
          valid: true,
          item: to,
        },
      };
    }
    return {
      description,
      travellers,
      ...transportationChanges,
    };
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  handleChange = (field, value) => {
    const { getSuggestions } = this.props;
    if (field === 'from' || field === 'to' || field === 'transferMode') {
      if (field !== 'transferMode') {
        getSuggestions('destinations', value, {
          includeAddressTypes: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2'],
        });
      }
      const { [field]: item } = this.state;
      this.setState({
        [field]: {
          ...item,
          value,
          valid: false,
        },
      }, this.validationCheck);
    } else {
      this.setState({ [field]: value }, this.validationCheck);
    }
  };

  handleSelection = (field, item, accessor) => {
    this.setState({
      [field]: {
        value: item[accessor],
        valid: true,
        item,
      },
    }, this.validationCheck);
  };

  validationCheck = (setError) => {
    const {
      from, to,
      transferMode, travellers,
    } = this.state;
    let error = '';
    let errorMsg = '';
    const hasTransportation = (from.valid || to.valid || transferMode.valid);
    if (hasTransportation) {
      if (!from.valid) {
        error = true;
        errorMsg = 'Transportation starting point is required';
      }
      if (!to.valid) {
        error = true;
        errorMsg = 'Transportation ending point is required';
      }
      if (!transferMode.valid) {
        error = true;
        errorMsg = 'Transportation mode is required';
      }
    }
    if (travellers.adults === 0) {
      error = true;
      errorMsg = 'Travellers are required';
    }
    if (setError) {
      this.setState({ errorMsg });
    }
    return {
      errorMsg,
      error,
    };
  };

  getButtonText = () => {
    const { mode, itineraryActionType } = this.props;
    if (mode === 'view' || ITINERARY_READ_MODES[itineraryActionType]) {
      return 'Close';
    }
    return 'Save & Add';
  };

  onSubmit = () => {
    const {
      onSubmit, mode,
      itineraryActionType, onDismiss,
    } = this.props;
    const {
      from, to, transferMode,
      description, travellers,
    } = this.state;
    if (mode === 'view' || ITINERARY_READ_MODES[itineraryActionType]) {
      onDismiss();
      return;
    }
    const { error } = this.validationCheck(true);
    if (error) {
      return;
    }
    const hasTransportation = (from.valid || to.valid || transferMode.valid);
    const params = {
      travellers,
      hasTransportation,
      from: hasTransportation ? from.item : undefined,
      to: hasTransportation ? to.item : undefined,
      transferMode: hasTransportation ? transferMode.item : undefined,
      description,
    };
    onSubmit(params);
  };

  toggleState = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  /*
  renderDetails = () => {
    const { classes } = this.props;
    const { experienceInfo } = this.state;
    const ArrowIcon = experienceInfo ? UpArrowIcon : DownArrowIcon;
    const experienceClass = experienceInfo ? 'expExpanded' : 'expCollapsed';
    return (
      <>
        <Button
          variant="plain"
          className={classes.viewToggleButton}
          onClick={() => this.toggleState('experienceInfo')}
        >
          {experienceInfo ? 'View less' : 'View more'}
          <ArrowIcon className={classes.arrowIcon} />
        </Button>
        <div className={clsx(classes.stayContainer, classes[experienceClass])}>
          <Typography className={classes.subTitle}>
            Location
          </Typography>
          <div id="googleMap" className={classes.googleMap} />
        </div>
      </>
    );
  };
  */

  render() {
    const {
      classes, onDismiss, experience, mode,
    } = this.props;
    const {
      travellers, errorMsg, showDiff,
    } = this.state;
    const expImg = experience?.images?.length ? experience.images[0].url : IMAGE_PLACEHOLDER;
    return (
      <div className={classes.container}>
        <Header
          className={classes.headerStrip}
          titleClass={classes.titleClass}
          variant="back"
          img={arrowBack}
          title={`Add experience details for ${experience.title}`}
          onDismiss={onDismiss}
          label="VIEW CHANGES"
          showDiffToggle={this.showDiffToggle}
          checked={this.showDiffToggle && showDiff}
          onToggle={this.toggleDiff}
        />
        <div className={classes.experienceDetails}>
          <div className={classes.row}>
            <img src={expImg} alt=" " className={classes.experienceImg} />
            <div className={classes.main}>
              <div className={classes.row}>
                <Chip
                  label={experience.category || '-'}
                  extraClass={classes.chip}
                  onSelected={() => { }}
                />
              </div>
              <Typography className={classes.title}>
                {experience.title}
              </Typography>
              <Typography className={classes.expDescription}>
                <pre style={{ whiteSpace: 'pre' }}>{experience.description || ' '}</pre>
              </Typography>
              {experience.durationInMinutes ? (
                <Typography className={classes.expDescription}>
                  {`${experience.durationInMinutes} minutes Experience`}
                </Typography>
              ) : null}
            </div>
          </div>
          {/* {this.renderDetails()} */}
        </div>
        {!showDiff ? (
          <div className={classes.body}>
            <div className={classes.row}>
              {mode !== 'view' ? (
                <TravellerSelection
                  label="No. of travellers"
                  placeholder="Add travellers"
                  extraClass={classes.travellerContainer}
                  travellers={travellers}
                  onChange={(val) => this.handleChange('travellers', val)}
                />
              )
                : (
                  <DiffInput
                    label="No. of travellers"
                    value={getTravellerText(travellers)}
                    oldValue={getTravellerText(travellers)}
                    extraClass={classes.input}
                  />
                )}
            </div>
            {/*
            <div className={classes.row}>
              <Typography className={classes.title}>
                {mode === 'view' ? 'Transportation' : 'Add transportation'}
              </Typography>
              <Typography className={classes.infoText}>
                (Optional) No transfers are included with this experience
              </Typography>
            </div>
            {mode === 'view' ? (
              <div className={classes.row}>
                <DiffInput
                  label="From"
                  value={from?.value || '-'}
                  oldValue={from?.value || '-'}
                  extraClass={classes.input}
                />
                <DiffInput
                  label="To"
                  value={to?.value || '-'}
                  oldValue={to?.value || '-'}
                  extraClass={classes.input}
                />
                <DiffInput
                  label="Mode of transfer"
                  value={transferMode?.value || '-'}
                  oldValue={transferMode?.value || '-'}
                  extraClass={classes.input}
                />
              </div>
            ) : (
              <div className={classes.row}>
                <Autocomplete
                  label="From"
                  data={suggestions.destinations}
                  value={from.value}
                  extraClass={classes.autocomplete}
                  inputClass={classes.autocompleteInput}
                  accessor="name"
                  onChange={(val) => this.handleChange('from', val)}
                  onSelected={(item) => this.handleSelection('from', item, 'name')}
                />
                <Autocomplete
                  label="To"
                  data={suggestions.destinations}
                  value={to.value}
                  extraClass={classes.autocompleteM}
                  inputClass={classes.autocompleteInput}
                  accessor="name"
                  onChange={(val) => this.handleChange('to', val)}
                  onSelected={(item) => this.handleSelection('to', item, 'name')}
                />
                <Autocomplete
                  label="Mode of transfer"
                  value={transferMode.value}
                  data={transferModes}
                  accessor="title"
                  extraClass={classes.autocomplete}
                  inputClass={classes.autocompleteInput}
                  onChange={(val) => this.handleChange('transferMode', val)}
                  onSelected={(item) => this.handleSelection('transferMode', item, 'title')}
                />
              </div>
            )}
            <div className={classes.row}>
              {mode === 'view' ? (
                <DiffInput
                  label="Description"
                  value={description}
                  oldValue={description}
                  extraClass={classes.input}
                />
              ) : (
                <LabelInput
                  value={description}
                  extraClass={classes.descriptionContainer}
                  inputClass={classes.description}
                  label="Description"
                  placeholder="Transfer description"
                  inputProps={{
                    multiline: true,
                    rows: 3,
                  }}
                  onChange={(val) => this.handleChange('description', val)}
                />
              )}
            </div>
            */}
          </div>
        )
          : (
            <ExperienceDiff data={this.state} oldData={this.originalExperience} />
          )}
        <Footer errorMsg={errorMsg}>
          <Button
            onClick={this.onSubmit}
          >
            {this.getButtonText()}
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '70vw',
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 64,
    backgroundColor: theme.colors.white,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  experienceDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
  },
  experienceImg: {
    width: 280,
    height: 160,
    borderRadius: 4,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
  },
  chip: {
    border: `1px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.primarySelected,
    padding: 4,
    '& > p': {
      fontSize: 10,
      color: theme.colors.primary,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  autocomplete: {
    flex: 1,
  },
  autocompleteM: {
    flex: 1,
    margin: '0 10px',
  },
  autocompleteInput: {
    width: '100%',
    height: '100%',
    padding: '5px 15px',
  },
  descriptionContainer: {
    width: '100%',
  },
  description: {
    height: 'auto',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
  },
  travellerContainer: {
    width: '33%',
  },
  title: {
    fontSize: 16,
    color: theme.colors.black,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  expDescription: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  input: {
    flex: 1,
  },
  infoText: {
    paddingLeft: 6,
    fontSize: 12,
    color: theme.colors.textLight,
    textAlign: 'left',
    marginBottom: 4,
    alignSelf: 'center',
  },
  googleMap: {
    width: '100%',
    height: 360,
  },
  stayContainer: {
    height: 0,
    transition: 'height 0.5s ease-in',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  expExpanded: {
    height: 420,
    maxHeight: 600,
  },
  expCollapsed: {
    height: 0,
  },
  viewToggleButton: {
    fontSize: 12,
    color: theme.colors.primary,
    marginTop: 10,
    height: 36,
    marginRight: 'auto',
    width: 120,
  },
});

CreateExperience.propTypes = {
  classes: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  experience: PropTypes.object,
  originalExperience: PropTypes.object,
  route: PropTypes.object.isRequired,
  transferModes: PropTypes.array.isRequired,
  activity: PropTypes.object,
  originalActivity: PropTypes.object,
  requirement: PropTypes.object,
  mode: PropTypes.string,
};

export default withStyles(styles)(CreateExperience);
