import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import Autocomplete from 'UI/autocomplete';
import LabelHoverInput from 'UI/labelHoverInput';
import LabelInput from 'UI/labelInput';
import Header from 'UI/header';
import { colors } from 'UI/styleConsts';
import CreateIcon from 'assets/icons/create';
import editImg from 'assets/svg/edit_lead.svg';
import { omit, validateEmail, isEqual } from '../../utils/common';

class EditRequest extends Component {
  constructor(props) {
    super(props);
    const existingDetails = this.getExistingDetails();
    this.state = {
      phoneDialCode: { value: '+91', valid: true },
      phone: '',
      whatsAppDialCode: { value: '+91', valid: true },
      whatsApp: '',
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      secondaryEmail: '',
      secondaryDialCode: { value: '', valid: false },
      secondaryNumber: '',
      usId: '',
      secondaryNumberVisible: false,
      secondaryEmailVisible: false,
      errorMsg: '',
      ...existingDetails,
    };
    this.genders = ['Male', 'Female', 'Other'].map((g) => ({ name: g }));
    this.changes = {};
  }

  componentDidUpdate = (prevProps) => {
    const { request } = this.props;
    if (!isEqual(prevProps.request, request)) {
      const details = this.getExistingDetails();
      this.setState({ ...details });
    }
  };

  getExistingDetails = () => {
    const { request } = this.props;
    const {
      primaryCustomer,
      primaryCustomerData = {},
      usId = '',
    } = request;
    const finalKey = primaryCustomer || primaryCustomerData;
    const {
      firstName = '',
      lastName = '',
      phoneDialCode = '',
      phone = '',
      whatsAppDialCode = '',
      whatsApp = '',
      email = '',
      gender = '',
      secondaryEmail = '',
      secondaryNumber = '',
      // address = {},
    } = finalKey;
    const secondaryNumberVisible = secondaryNumber && secondaryNumber.length;
    const secondaryEmailVisible = secondaryEmail && secondaryEmail.length;
    // const showAddress = Boolean(address && address.country && address.pinCode);
    return {
      firstName: firstName || '',
      lastName: lastName || '',
      phoneDialCode: { value: phoneDialCode || '', valid: true },
      phone: phone || '',
      whatsAppDialCode: { value: whatsAppDialCode || '', valid: true },
      whatsApp: whatsApp || '',
      email: email || '',
      gender,
      secondaryEmail,
      secondaryNumber,
      // address: showAddress ? address : {
      //   street: '',
      //   landmark: '',
      //   locality: '',
      //   pinCode: '',
      //   city: '',
      //   state: '',
      //   country: '',
      // },
      secondaryNumberVisible,
      secondaryEmailVisible,
      // showAddress,
      usId,
    };
  };

  handleSearchChange = (searchType, field, value) => {
    const { getSuggestions } = this.props;
    let type = 'countries';
    if (searchType === 'cities') {
      type = 'cities';
    }
    getSuggestions(type, value);
    this.setState({
      [field]: {
        value,
        valid: false,
        item: {},
      },
    });
  };

  handleSelection = (accessor, field, item) => {
    this.setState({
      [field]: {
        value: item[accessor],
        valid: true,
        item,
      },
    });
  };

  handleChange = (parent, field, value) => {
    this.changes[`${parent}_${field}`] = 1;
    if (parent === '') {
      this.setState({ [field]: value });
    } else {
      let { [parent]: modField } = this.state;
      modField = { ...modField };
      modField[field] = value;
      this.setState({ [parent]: modField });
    }
  };

  handleGenderSelection = (field, item) => {
    this.setState({ [field]: item.name });
  };

  checkValidity = () => {
    const {
      firstName, email,
      gender, whatsApp, whatsAppDialCode,
      phone, phoneDialCode,
    } = this.state;
    let valid = true;
    let errorMsg = '';
    const whatsAppStr = whatsApp.toString();
    const phoneStr = phone.toString();
    let minValid = true;
    if (firstName.length === 0) {
      errorMsg = 'Invalid first name';
    } else if (email.length && !validateEmail(email)) {
      errorMsg = 'Invalid email';
      minValid = false;
    } else if (whatsAppStr.length && (!(whatsAppStr.length >= 8 && whatsAppStr.length <= 12)
      || !whatsAppDialCode.valid)) {
      errorMsg = 'Invalid whatsApp number';
      minValid = false;
    } else if (phoneStr.length && (!(phoneStr.length >= 8 && phoneStr.length <= 12)
      || !phoneDialCode.valid)) {
      errorMsg = 'Invalid phone number';
      minValid = false;
    }
    if (!minValid) {
      valid = false;
    }
    if (gender.length && !['Male', 'Female', 'Other'].includes(gender)) {
      errorMsg = 'Invalid gender';
    }
    if (errorMsg !== '') {
      valid = false;
    }
    this.setState({ errorMsg });
    return valid;
  };

  onUpdate = () => {
    const { onUpdate } = this.props;
    const valid = this.checkValidity();
    if (!valid) {
      return;
    }
    const updatedChanges = omit(this.state, [
      'genderItem',
      // 'showAddress',
      'secondaryNumberVisible',
      'secondaryEmailVisible',
      'errorMsg',
    ]);
    updatedChanges.firstName = updatedChanges.firstName.trim();
    updatedChanges.lastName = updatedChanges.lastName.trim();
    updatedChanges.email = updatedChanges.email.trim();
    updatedChanges.phoneDialCode = updatedChanges.phoneDialCode.value;
    updatedChanges.whatsAppDialCode = updatedChanges.whatsAppDialCode.value;
    updatedChanges.secondaryDialCode = updatedChanges.secondaryDialCode.value;

    onUpdate({ primaryCustomerData: updatedChanges });
  };

  toggleField = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  render() {
    const {
      classes,
      expert,
      suggestions,
      onDismiss,
      request,
    } = this.props;
    const {
      usId,
      phoneDialCode,
      phone,
      whatsAppDialCode,
      whatsApp,
      firstName,
      lastName,
      email,
      // address,
      gender,
      secondaryEmail,
      secondaryNumber,
      secondaryDialCode,
      secondaryNumberVisible,
      secondaryEmailVisible,
      // showAddress,
      errorMsg,
    } = this.state;
    // const {
    //   street,
    //   landmark,
    //   locality,
    //   pinCode,
    //   city,
    //   state,
    //   country,
    // } = address;
    const changes = Object.keys(this.changes).length;
    const isDiffOwner = expert._id !== request.expert._id;
    const canEditContact = !(request.primaryCustomer?.firstName || request.primaryCustomer?.email);
    return (
      <div className={classes.container}>
        <Header
          title="View request details"
          img={editImg}
          onDismiss={onDismiss}
          className={classes.header}
        />
        <div className={classes.body}>
          <div className={classes.row}>
            <div className={classes.col}>
              <Typography className={classes.userLabel}>User ID</Typography>
              <Typography className={classes.userID}>{usId}</Typography>
            </div>
          </div>
          <div className={classes.row}>
            <LabelHoverInput
              extraClass={classes.left}
              placeholder="John"
              label="FIRST NAME"
              disabledInput={!canEditContact}
              value={firstName}
              onChange={(val) => this.handleChange('', 'firstName', val)}
            />
            <LabelHoverInput
              extraClass={classes.right}
              placeholder="Doe"
              label="LAST NAME"
              disabledInput={!canEditContact}
              value={lastName}
              onChange={(val) => this.handleChange('', 'lastName', val)}
            />
          </div>
          <div className={classes.row}>
            <LabelHoverInput
              label="WHATSAPP NO."
              labelClass={classes.phoneLabel}
              extraClass={classes.left}
            >
              <div className={classes.phoneField}>
                <div className={classes.phoneWrapper}>
                  <Autocomplete
                    data={suggestions.countries}
                    value={whatsAppDialCode.value}
                    popupClass={classes.popupClass}
                    disabled={!canEditContact}
                    accessor="displayText"
                    variant="no-outline"
                    onChange={(val) => this.handleSearchChange('country', 'whatsAppDialCode', val)}
                    onSelected={(item) => (
                      this.handleSelection('dialCode', 'whatsAppDialCode', item)
                    )}
                  />
                </div>
                <InputBase
                  type="number"
                  className={classes.bareInput}
                  disabled={!canEditContact}
                  autoComplete="off"
                  placeholder="WhatsApp"
                  classes={{
                    input: classes.input,
                  }}
                  value={whatsApp}
                  onChange={(e) => this.handleChange('', 'whatsApp', e.target.value)}
                />
              </div>
            </LabelHoverInput>
            <Autocomplete
              data={this.genders}
              value={gender}
              extraClass={classes.genderContainer}
              inputClass={classes.bareInput}
              bodyClass={classes.labelClass}
              label="GENDER"
              accessor="name"
              variant="no-outline"
              onChange={(val) => this.handleChange('', 'gender', val)}
              onSelected={(item) => this.handleGenderSelection('gender', item)}
            />
          </div>
          <div className={classes.row}>
            <LabelInput label="PHONE NO." labelClass={classes.phoneLabel} extraClass={classes.left}>
              <div className={classes.phoneField}>
                <div className={classes.phoneWrapper}>
                  <Autocomplete
                    data={suggestions.countries}
                    accessor="displayText"
                    variant="no-outline"
                    disabled={!canEditContact}
                    popupClass={classes.popupClass}
                    value={phoneDialCode.value}
                    onChange={(val) => this.handleSearchChange('country', 'phoneDialCode', val)}
                    onSelected={(item) => this.handleSelection('dialCode', 'phoneDialCode', item)}
                  />
                </div>
                <InputBase
                  type="number"
                  className={classes.bareInput}
                  autoComplete="off"
                  classes={{
                    input: classes.input,
                  }}
                  disabled={!canEditContact}
                  value={phone}
                  onChange={(e) => this.handleChange('', 'phone', e.target.value)}
                />
              </div>
            </LabelInput>
            <LabelHoverInput
              disabledInput={!canEditContact}
              extraClass={classes.right}
              label="EMAIL"
              placeholder="example@example.com"
              value={email}
              onChange={(val) => this.handleChange('', 'email', val)}
            />
          </div>
          <div className={classes.row}>
            {secondaryNumberVisible ? (
              <LabelInput
                label="PHONE NO."
                labelClass={classes.phoneLabel}
                extraClass={classes.left}
              >
                <div className={classes.phoneField}>
                  <div className={classes.phoneWrapper}>
                    <Autocomplete
                      data={suggestions.countries}
                      accessor="displayText"
                      variant="no-outline"
                      placeholder="+91"
                      popupClass={classes.popupClass}
                      value={secondaryDialCode.value}
                      onChange={(val) => (this.handleSearchChange('country', 'secondaryDialCode', val))}
                      onSelected={(item) => (this.handleSelection('dialCode', 'secondaryDialCode', item))}
                    />
                  </div>
                  <InputBase
                    type="number"
                    className={classes.bareInput}
                    autoComplete="off"
                    placeholder="Secondary number"
                    classes={{
                      input: classes.input,
                    }}
                    value={secondaryNumber}
                    onChange={(e) => this.handleChange('', 'secondaryNumber', e.target.value)}
                  />
                </div>
              </LabelInput>
            )
              : (
                <ButtonBase
                  className={classes.addNewButton}
                  onClick={() => this.toggleField('secondaryNumberVisible')}
                >
                  <CreateIcon fill={colors.primary} />
                  <Typography className={classes.addText}>Add number</Typography>
                </ButtonBase>
              )}
            {secondaryEmailVisible ? (
              <LabelHoverInput
                extraClass={classes.left}
                label="EMAIL"
                placeholder="example@example.com"
                value={secondaryEmail}
                onChange={(val) => this.handleChange('', 'secondaryEmail', val)}
              />
            )
              : (
                <ButtonBase
                  className={classes.addNewButton}
                  onClick={() => this.toggleField('secondaryEmailVisible')}
                >
                  <CreateIcon fill={colors.primary} />
                  <Typography className={classes.addText}>Add email</Typography>
                </ButtonBase>
              )}
          </div>
        </div>
        <div className={classes.divider} />
        {isDiffOwner && (
          <>
            <Typography
              className={classes.expertTitle}
            >
              Expert details
            </Typography>
            <div className={classes.diffOwnerRow}>
              <div className={classes.col}>
                <Typography
                  className={classes.ownerText}
                >
                  Name
                </Typography>
                <Typography
                  className={classes.ownerName}
                >
                  {request.expert.fullName}
                </Typography>
              </div>
              <div className={classes.col}>
                <Typography
                  className={classes.ownerText}
                >
                  Phone
                </Typography>
                <Typography
                  className={classes.ownerName}
                >
                  {request.expert.mobile}
                </Typography>
              </div>
            </div>
            <div className={classes.diffOwnerRow}>
              <div className={classes.col}>
                <Typography
                  className={classes.ownerText}
                >
                  Email
                </Typography>
                <Typography
                  className={classes.ownerName}
                >
                  {request.expert.email}
                </Typography>
              </div>
              <div className={classes.col}>
                <Typography
                  className={classes.ownerText}
                >
                  Is Flynote Employee ?
                </Typography>
                <Typography
                  className={classes.ownerName}
                >
                  {(request.expert.roles || []).includes('FELLOW') ? 'Yes' : 'No'}
                </Typography>
              </div>
            </div>
          </>
        )}
        {/* {showAddress ? (
          <div className={classes.addressSection}>
            <Typography className={classes.addressTitle}>Request&apos;s address</Typography>
            <div className={classes.row}>
              <LabelHoverInput
                label="Street address"
                multiline
                rows={3}
                placeholder="House no / Floor / Building, Colony / Street"
                value={street}
                extraClass={classes.left}
                inputClass={classes.multilineInput}
                onChange={(val) => this.handleChange('address', 'street', val)}
              />
              <LabelHoverInput
                label="Landmark"
                multiline
                rows={3}
                placeholder="eg: Opp. red fort"
                value={landmark}
                extraClass={classes.right}
                inputClass={classes.multilineInput}
                onChange={(val) => this.handleChange('address', 'landmark', val)}
              />
            </div>
            <div className={classes.row}>
              <LabelHoverInput
                label="Pincode"
                placeholder="Add 6 digit pin code"
                value={pinCode}
                extraClass={classes.left}
                onChange={(val) => this.handleChange('address', 'pinCode', val)}
              />
              <LabelHoverInput
                label="Locality"
                placeholder="Enter locality or area"
                value={locality}
                extraClass={classes.right}
                onChange={(val) => this.handleChange('address', 'locality', val)}
              />
            </div>
            <div className={classes.row}>
              <LabelHoverInput
                label="City / District"
                placeholder="Bengaluru"
                value={city}
                extraClass={classes.left}
                onChange={(val) => this.handleChange('address', 'city', val)}
              />
              <LabelHoverInput
                label="State"
                placeholder="Karnataka"
                value={state}
                extraClass={classes.right}
                onChange={(val) => this.handleChange('address', 'state', val)}
              />
            </div>
            <div className={classes.row}>
              <LabelHoverInput
                label="Country"
                placeholder="India"
                value={country}
                extraClass={classes.left}
                onChange={(val) => this.handleChange('address', 'country', val)}
              />
            </div>
          </div>
        )
          : (
            <>
              <ButtonBase
                className={classes.showAddressBtn}
                onClick={() => this.toggleField('showAddress')}
              >
                <CreateIcon fill={colors.primary} />
                Add request&apos;s address
              </ButtonBase>
              <div className={classes.spacing} />
            </>
          )} */}
        <div className={classes.footer}>
          {changes > 0 ? (
            <Typography className={classes.changes}>
              {`${changes} ${changes === 1 ? 'Property' : 'Properties'} changed`}
            </Typography>
          ) : null}
          {errorMsg ? (
            <Typography className={classes.errorMsg}>{errorMsg}</Typography>
          ) : null}
          <Button aria-label="close" className={classes.clsBtn} onClick={onDismiss}>
            Close
          </Button>
          <div className={classes.updateBtn}>
            <Button aria-label="update" className={classes.updtBtn} onClick={this.onUpdate}>
              Update
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    flexDirection: 'column',
    minWidth: 520,
    maxWidth: 520,
    overflowX: 'hidden',
    borderRadius: 10,
    flex: 1,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 64px)',
  },
  header: {
    maxHeight: 64,
    height: 64,
    boxSizing: 'border-box',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px 0 40px',
  },
  diffOwnerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 30,
    padding: '20px 40px 0 40px',
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  userLabel: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
  },
  userID: {
    color: theme.colors.textDark,
    fontSize: 14,
    padding: '4px 0',
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  left: {
    flex: 1,
    maxWidth: '50%',
    boxSizing: 'border-box',
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  phoneLabel: {
    marginBottom: 0,
  },
  phoneField: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 0,
    height: 32,
    borderBottom: `1px solid ${theme.colors.white}`,
    '&:hover': {
      borderBottom: `1px solid ${theme.colors.primary}`,
    },
  },
  labelClass: {
    border: 'none',
    borderRadius: 0,
    height: 32,
    borderBottom: `1px solid ${theme.colors.white}`,
    '&:hover': {
      borderBottom: `1px solid ${theme.colors.primary}`,
    },
  },
  textField: {
    flex: 1,
  },
  multilineInput: {
    minHeight: 57,
    height: 57,
  },
  phoneWrapper: {
    width: 86,
    padding: '6px 0 0 0',
    marginRight: 4,
  },
  bareInput: {
    padding: '0 5px 0 0',
  },
  input: {
    padding: 0,
    height: '100%',
  },
  popupClass: {
    width: 200,
    top: 32,
    left: -20,
  },
  genderContainer: {
    flex: 1,
    marginLeft: 10,
  },
  addNewButton: {
    height: 24,
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 0',
    marginTop: 10,
    marginRight: 'auto',
    justifyContent: 'flex-start',
    width: 100,
    alignItems: 'center',
  },
  addText: {
    marginBottom: 1,
    paddingLeft: 6,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    color: theme.colors.primary,
  },
  showAddressBtn: {
    padding: '5px 10px',
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.primary,
    letterSpacing: 0.5,
    borderRadius: 17.5,
    width: 180,
    margin: '20px 40px',
    height: 36,
    border: `1px solid ${theme.colors.primary}`,
  },
  spacing: {
    flex: 1,
  },
  divider: {
    margin: '0 40px',
    borderTop: `1px solid ${theme.colors.border}`,
  },
  addressSection: {
    margin: '0 40px 100px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  addressTitle: {
    paddingTop: 30,
    fontSize: 16,
    color: theme.colors.black,
    marginBottom: 20,
  },
  footer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px 40px',
    height: 64,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',
    position: 'fixed',
    width: 520,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.white,
  },
  changes: {
    flexWrap: 'wrap',
    maxWidth: 80,
    fontSize: 14,
    fontWeight: 300,
    marginRight: 20,
  },
  errorMsg: {
    color: theme.colors.red,
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    marginRight: 6,
  },
  clsBtn: {
    padding: '5px 10px',
    width: 120,
    borderRadius: 25,
    letterSpacing: '0.5px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.colors.primary,
    marginLeft: 'auto',
    border: `1px solid ${theme.colors.primaryBackground}`,
  },
  updtBtn: {
    padding: '5px 10px',
    width: 120,
    borderRadius: 25,
    letterSpacing: '0.5px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  updateBtn: {
    marginLeft: '20px',
  },
  ownerName: {
    fontSize: 14,
    color: theme.colors.black,
  },
  ownerText: {
    paddingRight: 6,
    fontSize: 12,
    color: theme.colors.textLight,
    fontWeight: 'bold',
  },
  expertTitle: {
    fontSize: 14,
    color: theme.colors.black,
    textAlign: 'left',
    padding: '20px 40px',
  },
});

EditRequest.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  request: PropTypes.object,
  getSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.object,
  expert: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditRequest);
