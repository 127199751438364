import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import { GOOGLE_CLIENT_ID } from 'utils/consts';
import googleImg from 'assets/svg/google.svg';
import { isEqual, headerGenerator, validateEmail } from 'utils/common';

class Auth extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   email: '',
    //   password: '',
    //   remember: false,
    // showPassword: false,
    // };
    this.headers = headerGenerator('');
  }

  componentDidUpdate = (prevProps) => {
    const {
      loginError,
      loginErrorMsg,
      showSnackbar,
    } = this.props;
    if (!isEqual(loginError, prevProps.loginError) && loginError) {
      showSnackbar(loginErrorMsg, 'error');
    }
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onSubmit = () => {
    const { login, showSnackbar } = this.props;
    const { email, password, remember } = this.state;
    if (!validateEmail(email.trim())) {
      showSnackbar('Invalid email', 'error');
    } else if (password.trim().length === 0) {
      showSnackbar('Password cannot be empty', 'error');
    }
    const params = {
      email,
      password,
      remember,
    };
    login(params, this.headers);
  };

  signIn = () => {
    const { login, showSnackbar } = this.props;
    const initLogin = () => {
      window.gapi.auth2.init({ clientId: GOOGLE_CLIENT_ID, include_granted_scopes: false })
        .then((res) => {
          res.grantOfflineAccess()
            .then((offlineAccessResp) => {
              const params = { ...offlineAccessResp };
              login(params, this.headers);
            })
            .catch((err) => {
              showSnackbar(err.message, 'error');
            });
        })
        .catch((err) => {
          showSnackbar(err.message, 'error');
        });
    };
    if (!window.gapi) {
      showSnackbar('Error initializing google login', 'error');
      return;
    }
    window.gapi.load('auth', () => {
      if (!window.gapi.auth2.getAuthInstance()) {
        initLogin();
      } else {
        initLogin();
      }
    });
  };

  render() {
    const { classes, isLogging } = this.props;
    // const {
    //   email,
    //   password,
    //   remember,
    //   showPassword,
    // } = this.state;
    return (
      <div className={classes.container}>
        <Button
          disabled={isLogging}
          variant="contained"
          className={classes.loginButton}
          onClick={this.signIn}
        >
          <img src={googleImg} alt="G" className={classes.googleImg} />
          Login with google
        </Button>
        {/* <Login
          email={email}
          password={password}
          remember={remember}
          showPassword={showPassword}
          onChange={(field, value) => this.onChange(field, value)}
          onSubmit={this.onSubmit}
        /> */}
      </div>
    );
  }
}

Auth.propTypes = {
  classes: PropTypes.object,
  login: PropTypes.func,
  isLogging: PropTypes.bool,
  loginResp: PropTypes.object,
  loginError: PropTypes.bool,
  loginErrorMsg: PropTypes.string,
  showSnackbar: PropTypes.func,
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  loginButton: {
    backgroundColor: theme.colors.white,
    margin: '40px auto',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: theme.colors.white,
    },
  },
  googleImg: {
    height: 20,
    marginRight: 10,
  },
});

export default withStyles(styles)(Auth);
