import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from 'UI/checkbox';
import { TablePagination } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

class PaginatedTable extends Component {
  render() {
    const {
      suggestions,
      templateItineraryId,
      handleClick,
      handlePageChange,
      pageSize,
      loading,
    } = this.props;
    return (
      <div>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  {' '}
                </TableCell>
                {/* <TableCell align="right">ID</TableCell> */}
                <TableCell align="left">Title</TableCell>
                {/* <TableCell align="right">Destinations</TableCell> */}
                <TableCell align="left">Route</TableCell>
                <TableCell align="center">Duration</TableCell>
                <TableCell align="left">Stays</TableCell>
                <TableCell align="left">Experiences</TableCell>
                <TableCell align="center">Pax</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (suggestions.itineraryTemplates?.itineraryTemplates || []).map((itineraryTemplate) => {
                return (
                  <TableRow
                    key={itineraryTemplate._id}
                    hover
                    onClick={(event) => handleClick(event, itineraryTemplate._id)}
                    selected={templateItineraryId === itineraryTemplate._id}
                  >
                    <TableCell padding="checkbox" component="th" scope="row">
                      <Checkbox checked={templateItineraryId === itineraryTemplate._id} />
                    </TableCell>
                    {/* <TableCell align="right">{itineraryTemplate.identifier}</TableCell> */}
                    <TableCell style={{ padding: '5px' }} align="left">
                      <a href={itineraryTemplate.previewLink} rel="noreferrer" target="_blank">
                        {itineraryTemplate.title}
                      </a>
                    </TableCell>
                    {/* <TableCell style={{ padding: '5px' }} align="right">{itineraryTemplate.destinations.map((r) => (<div>{r}<br /></div>))}</TableCell> */}
                    <TableCell style={{ padding: '5px' }} align="left">
                      <Tooltip title={(
                        <div>
                          {
                            itineraryTemplate.routes.map((r) => {
                              return (
                                <div>
                                  {r} <br />
                                </div>
                              );
                            })
                          }
                        </div>
                      )}
                      >
                        <div>
                          {`${itineraryTemplate.routes[0]?.substr(0, 15) || ''}${itineraryTemplate.routes[0]?.length > 15 ? '...' : ''}${itineraryTemplate.routes.length > 1 ? ` (+${itineraryTemplate.routes.length - 1})` : ''}`}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ padding: '5px' }} align="center">{itineraryTemplate.duration}</TableCell>
                    <TableCell style={{ padding: '5px' }} align="left">
                      <Tooltip title={(
                        <div>
                          {
                            itineraryTemplate.stays.map((r) => {
                              return (
                                <div>
                                  {r} <br />
                                </div>
                              );
                            })
                          }
                        </div>
                      )}
                      >
                        <div>
                          {`${itineraryTemplate.stays[0]?.substr(0, 15) || ''}${itineraryTemplate.stays[0]?.length > 15 ? '...' : ''}${itineraryTemplate.stays.length > 1 ? ` (+${itineraryTemplate.stays.length - 1})` : ''}`}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ padding: '5px' }} align="left">
                      <Tooltip title={(
                        <div>
                          {
                            itineraryTemplate.experiences.map((r, index) => {
                              return (
                                <div>
                                  {`${index + 1}. ${r}`} <br />
                                </div>
                              );
                            })
                          }
                        </div>
                      )}
                      >
                        <div>
                          {`${itineraryTemplate.experiences[0]?.substr(0, 15) || ''}${itineraryTemplate.experiences[0]?.length > 15 ? '...' : ''}${itineraryTemplate.experiences.length > 1 ? ` (+${itineraryTemplate.experiences.length - 1})` : ''}`}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ padding: '5px' }} align="center">{itineraryTemplate.pax}</TableCell>
                    <TableCell style={{ padding: '5px' }} align="center">{itineraryTemplate.refPrice}</TableCell>
                    <TableCell style={{ padding: '5px' }} align="center">{itineraryTemplate.createdBy}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[pageSize]}
            component="div"
            count={suggestions.itineraryTemplates?.totalCount || 0}
            rowsPerPage={pageSize}
            page={suggestions.itineraryTemplates?.page || 0}
            onChangePage={(event, newPageNumber) => {
              handlePageChange(newPageNumber * pageSize);
            }}
          />
        </TableContainer>
      </div>
    );
  }
}

const styles = () => ({
});

PaginatedTable.propTypes = {
  templateItineraryId: PropTypes.string,
  suggestions: PropTypes.object.isRequired,
  handleClick: PropTypes.object.isRequired,
  handlePageChange: PropTypes.object.isRequired,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
};

export default withStyles(styles)(PaginatedTable);
