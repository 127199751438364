import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/styles/withStyles';
import { CSSTransition } from 'react-transition-group';
import Button from 'UI/button';
import DotLine from 'UI/dotLine';
import Autocomplete from 'UI/autocomplete';
import PartActionButton from 'UI/partActionButton';
import Footer from 'UI/footer';
import RouteSection from 'UI/routeSection';
import {
  getCardClass, getOriginalPart,
  getTravellingDates, getDateStr, getTravellerText, getPartsForPreview,
} from 'utils/common';
import { CHANGE_TYPES, ITINERARY_ACTIONS_TYPES } from 'utils/consts';
import CreateTransfer from './createTransfer';
import CreateTransferNew from './createTransferNew';

class TransfersPreview extends Component {
  constructor(props) {
    super(props);
    const { routes, onUpdate, suppliers } = this.props;
    const transferDates = getTravellingDates(routes);
    onUpdate(transferDates);
    this.state = {
      showTransferCreate: false,
      activeIndex: -1,
      routeIndex: -1,
    };
    this.bookingAction = {
      [ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING]: true,
      [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY]: true,
      [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH]: true,
    };
    this.suppliers = suppliers.map((s) => s.supplier);
  }

  componentDidMount = () => {
    const { suppliers } = this.props;
    this.suppliers = suppliers.map((s) => s.supplier);
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  transferActionHandler = (action, index, transfer = {}, rIndex) => {
    const { itineraryParts, onUpdate } = this.props;
    const { activeIndex, routeIndex } = this.state;
    const timeStamp = moment(transfer.transferDate).toDate();
    const modTransfer = {
      ...transfer,
      type: 'TRANSFER',
      startTimeStamp: timeStamp,
      endTimeStamp: timeStamp,
    };
    if (action === 'add') {
      this.setState({
        routeIndex: rIndex,
        showTransferCreate: true,
        activeIndex: -1,
      });
    } else if (action === 'view') {
      this.setState({
        routeIndex: rIndex,
        mode: 'view',
        showTransferCreate: true,
        activeIndex: index,
      });
    } else if (action === 'edit') {
      this.setState({
        routeIndex: rIndex,
        mode: 'edit',
        showTransferCreate: true,
        activeIndex: index,
      });
    } else if (action === 'remove') {
      onUpdate('remove', index);
    } else if (action === 'save') {
      modTransfer.routeIndex = routeIndex;
      if (activeIndex === -1) {
        // avoid auto adding supplier
        modTransfer.supplier = {
          // value: this.suppliers[0].name,
          // valid: true,
          // item: this.suppliers[0],
        };
        onUpdate('add', index, modTransfer);
      } else {
        onUpdate('edit', index, { ...itineraryParts[activeIndex], ...modTransfer });
      }
      this.setState({
        showTransferCreate: false,
        activeIndex: -1,
        routeIndex: -1,
      });
    }
  };

  handleSupplierSelection = (action, index, value) => {
    const { itineraryParts, onUpdate } = this.props;
    const transfer = itineraryParts[index];
    const modTransfer = { ...transfer };
    if (action === 'change') {
      modTransfer.supplier = {
        value,
        valid: false,
        item: {},
      };
    } else if (action === 'select') {
      modTransfer.supplier = {
        value: value.name,
        valid: true,
        item: value,
      };
    }
    onUpdate('edit', index, modTransfer);
  };

  getDayTransfers = () => {
    const { itineraryParts, routes } = this.props;
    // const transfers = {};
    // let transfersCount = 0;
    // for (let i = 0; i < itineraryParts.length; i++) {
    //   if (itineraryParts[i].type === 'TRANSFER') {
    //     transfersCount++;
    //     const routeIndex = itineraryParts[i].routeIndex || 0;
    //     if (transfers[routeIndex]) {
    //       transfers[routeIndex].push({
    //         ...itineraryParts[i],
    //         itineraryIndex: i,
    //         routeIndex,
    //         route: routes[routeIndex],
    //       });
    //     } else {
    //       transfers[routeIndex] = [{
    //         ...itineraryParts[i],
    //         itineraryIndex: i,
    //         routeIndex,
    //         route: routes[routeIndex],
    //       }];
    //     }
    //   }
    // }
    const { count, previewParts } = getPartsForPreview(itineraryParts, 'TRANSFER', routes);
    return {
      transfers: previewParts,
      transfersCount: count,
    };
  };

  render() {
    const {
      classes, suggestions, itineraryParts, mode, originalParts, errorMsg, routes, travellers,
      getSuggestions, nextHandler, transferModes, itineraryActionType, version,
    } = this.props;
    const {
      showTransferCreate, activeIndex,
      routeIndex, mode: md,
    } = this.state;
    const { transfers, transfersCount } = this.getDayTransfers();
    const activeTransfer = activeIndex !== -1 ? itineraryParts[activeIndex] : undefined;
    const originalTransfer = getOriginalPart(originalParts, activeTransfer,
      itineraryActionType, showTransferCreate);
    return (
      <div className={classes.container}>
        {version === 2 ? (
          <Drawer
            anchor="right"
            open={showTransferCreate}
            onClose={() => this.handleChange('showTransferCreate', false)}
          >
            <CreateTransferNew
              mode={md}
              itineraryActionType={itineraryActionType}
              suggestions={suggestions}
              getSuggestions={getSuggestions}
              travellers={travellers}
              transfer={activeTransfer}
              route={routes[routeIndex]}
              transferModes={transferModes}
              originalTransfer={originalTransfer}
              onSubmit={(transfer) => this.transferActionHandler('save', activeIndex, transfer)}
              onDismiss={() => this.handleChange('showTransferCreate', false)}
            />
          </Drawer>
        )
          : (
            <CSSTransition
              in={showTransferCreate}
              timeout={300}
              classNames="overlay"
              unmountOnExit
            >
              <div className={classes.overlayContainer}>
                <CreateTransfer
                  itineraryActionType={itineraryActionType}
                  suggestions={suggestions}
                  getSuggestions={getSuggestions}
                  transfer={activeTransfer}
                  originalTransfer={originalTransfer}
                  transferModes={transferModes}
                  onSubmit={(transfer) => this.transferActionHandler('save', activeIndex, transfer)}
                  onDismiss={() => this.handleChange('showTransferCreate', false)}
                />
              </div>
            </CSSTransition>
          )}
        <div className={classes.transferContainer}>
          {(mode === 'view' && transfersCount === 0) ? (
            <Typography className={classes.emptyText}> No transfers added</Typography>
          ) : routes.map((route, index) => {
            const isLast = routes.length === index + 1;
            // if (routeTransfers.length) {
            const dateStr = getDateStr(routes[index].dates.from, routes[index].dates.to);
            return (
              <div className={classes.wrapper} key={dateStr}>
                <DotLine isLast={isLast} />
                <div className={classes.transfersContainer}>
                  <RouteSection route={routes[index]} />
                  {(transfers[index] || []).map((transfer) => {
                    const {
                      transfers: ts = [], transferDate, transferMode, travellers: transferTravellers,
                      itineraryIndex, supplier, proposedChange, description,
                    } = transfer;
                    let isDeleted = false;
                    let extraClass = '';
                    if (
                      itineraryActionType === ITINERARY_ACTIONS_TYPES.APPROVE_REJECT_ITINERARY
                      || itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
                      isDeleted = proposedChange.changeType === CHANGE_TYPES.DELETE;
                      extraClass = getCardClass(proposedChange.changeType);
                    }
                    return (
                      <div key={itineraryIndex} className={classes.wrapper}>
                        <div className={clsx(classes.transferCard, classes[extraClass])}>
                          <Typography
                            className={classes.title}
                          >
                            {ts.map((t) => t.name).join(', ')}
                          </Typography>
                          <div className={classes.row}>
                            <Typography className={classes.notes}>NOTES:</Typography>
                            <Typography className={classes.description}>
                              {`${transferMode} ${moment(transferDate).format('DD MMM YY')} ${transferTravellers ? getTravellerText(transferTravellers) : ''}`}
                            </Typography>
                            {((!isDeleted && version !== 2)
                              || this.bookingAction[itineraryActionType])
                              ? (
                                <Autocomplete
                                  data={this.suppliers}
                                  value={supplier.value}
                                  extraClass={classes.autocomplete}
                                  inputClass={classes.autocompleteInput}
                                  accessor="name"
                                  onChange={(val) => this.handleSupplierSelection('change', itineraryIndex, val)}
                                  onSelected={(item) => this.handleSupplierSelection('select', itineraryIndex, item)}
                                />
                              ) : null}
                          </div>
                          {description ? (
                            <div className={classes.row}>
                              <Typography className={classes.description}>
                                {description}
                              </Typography>
                            </div>
                          ) : null}
                          <PartActionButton
                            actionHandler={this.transferActionHandler}
                            editArgs={[itineraryIndex, {}, index]}
                            removeArgs={[itineraryIndex, {}, index]}
                            mode={mode}
                            isDeleted={isDeleted}
                            itineraryActionType={itineraryActionType}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {(mode === 'edit' && itineraryActionType !== 'generate_voucher') ? (
                    <Button
                      variant="outlined"
                      className={classes.addTransferBtn}
                      onClick={() => this.transferActionHandler('add', -1, {}, index)}
                    >
                      + Add transfer
                    </Button>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        <Footer errorMsg={errorMsg}>
          {nextHandler ? (
            <Button
              className={classes.saveBtn}
              onClick={nextHandler}
            >
              {mode === 'view' ? 'Next' : 'Save & continue'}
            </Button>
          ) : null}
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: '74vh',
    backgroundColor: theme.colors.white,
  },
  overlayContainer: {
    zIndex: 3,
    position: 'absolute',
    top: 64,
    width: '100%',
    height: 'calc(100% - 64px)',
  },
  emptyText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    textAlign: 'center',
  },
  transferContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px',
    overflowY: 'auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  transfersContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 50,
    width: '100%',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
  },
  placeIcon: {
    marginTop: 4,
    width: 26,
    height: 26,
    color: theme.colors.primary,
  },
  routeName: {
    paddingLeft: 6,
    fontSize: 24,
    color: theme.colors.black,
  },
  transferDate: {
    paddingLeft: 10,
    fontSize: 12,
    alignSelf: 'flex-end',
    marginBottom: 4,
    color: theme.colors.textLight,
  },
  transfers: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  transferCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    transition: 'box-shadow 400ms',
    marginBottom: 16,
    '&:hover': {
      boxShadow: theme.extraShadows[1],
    },
  },
  title: {
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    color: theme.colors.black,
    textAlign: 'left',
    padding: '15px 20px 10px 20px',
  },
  notes: {
    fontSize: 14,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    marginRight: 10,
  },
  description: {
    fontSize: 14,
    color: theme.colors.textDark,
    textAlign: 'left',
  },
  autocomplete: {
    position: 'absolute',
    bottom: 14,
    right: 130,
    height: 36,
    width: 200,
    // marginRight: 120,
    // marginLeft: 'auto',
  },
  autocompleteInput: {
    padding: '5px 15px',
  },
  addTransferBtn: {
    marginTop: 10,
    width: 140,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 20px 10px 20px',
    boxSizing: 'border-box',
    width: '100%',
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  deletedCard: {
    backgroundColor: theme.colors.redLight,
  },
  editedCard: {
    backgroundColor: theme.colors.primarySelected,
  },
});

TransfersPreview.propTypes = {
  classes: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  itineraryParts: PropTypes.array.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  nextHandler: PropTypes.func,
  transferModes: PropTypes.array.isRequired,
  travellers: PropTypes.array.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  originalParts: PropTypes.object,
  mode: PropTypes.string,
  errorMsg: PropTypes.string,
  version: PropTypes.number,
};

export default withStyles(styles)(TransfersPreview);
