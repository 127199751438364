import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DateRangeSelection from 'UI/dateRangeSelection';
import Autocomplete from 'UI/autocomplete';
import Switch from 'UI/switch';
import Button from 'UI/button';
import { getValidDates, uuid, shouldShowDiffToggle } from 'utils/common';
import RouteDiff from 'components/itineraryMaker/routeDiff';

class RouteSelection extends Component {
  constructor(props) {
    super(props);
    const {
      requirement: { tripDates },
      itineraryActionType,
      originalRoutes,
    } = this.props;
    const { from, to } = tripDates;
    this.disabledDateFn = getValidDates(from, to);
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalRoutes);
    this.state = {
      localError: '',
      showDiff: false,
    };
  }

  handleItemAddition = () => {
    const { routes, onUpdate } = this.props;
    const modRoutes = [...routes];
    modRoutes.push({
      id: uuid(),
      place: { value: '', valid: false },
      dates: {},
      stays: [],
    });
    onUpdate(modRoutes);
    this.setState({ localError: '' });
  };

  handleDateSelection = (index, dateRange) => {
    const { routes, onUpdate } = this.props;
    const modRoutes = [...routes];
    const route = { ...routes[index] };
    route.dates = dateRange;
    modRoutes[index] = route;
    onUpdate(modRoutes);
    this.setState({ localError: '' });
  };

  handleSelection = (index, item) => {
    const { routes, onUpdate } = this.props;
    const modRoutes = [...routes];
    const route = { ...routes[index] };
    route.place.value = item.name;
    route.place.valid = true;
    route.place.item = item;
    modRoutes[index] = route;
    onUpdate(modRoutes);
    this.setState({ localError: '' });
  };

  handleChange = (index, value) => {
    const { routes, onUpdate, getSuggestions } = this.props;
    const modRoutes = [...routes];
    const route = { ...routes[index] };
    route.place.value = value;
    route.place.valid = false;
    modRoutes[index] = route;
    onUpdate(modRoutes);
    getSuggestions('cities', value);
    this.setState({ localError: '' });
  };

  handleRouteDelete = (index) => {
    const { routes, onUpdate } = this.props;
    const modRoutes = [...routes];
    modRoutes.splice(index, 1);
    if (modRoutes.length === 0) {
      modRoutes.push({
        id: uuid(),
        place: { value: '', valid: false },
        dates: {},
        stays: [],
      });
    }
    onUpdate(modRoutes);
    this.setState({ localError: '' });
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  render() {
    const {
      classes, routes, nextHandler, itineraryActionType,
      errorMsg, suggestions, mode, originalRoutes,
    } = this.props;
    const { localError, showDiff } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          {this.showDiffToggle ? (
            <div className={classes.changeHeader}>
              <Switch
                checked={showDiff}
                onToggle={this.toggleDiff}
                label="VIEW CHANGES"
              />
            </div>
          ) : null}
          {!showDiff ? routes.map((route, index) => {
            const { dates: { from, to } } = route;
            let dateStr = '';
            if (from && to) {
              const fromDate = moment(from);
              const toDate = moment(to);
              const nights = (toDate.unix() - fromDate.unix()) / 86400;
              dateStr = `${fromDate.format('DD MMM')} - ${toDate.format('DD MMM')} | ${nights} N`;
            }
            let disableFromDate = moment().toDate();
            if (index !== 0) {
              disableFromDate = routes[index - 1].dates.to;
            }
            return (
              <div key={route.id} className={classes.row}>
                <Autocomplete
                  accessor="displayName"
                  placeholder="City"
                  value={route.place.value}
                  bodyClass={classes.labelClass}
                  extraClass={classes.inputContainer}
                  inputClass={classes.autoCompleteInput}
                  data={suggestions.cities}
                  onChange={(value) => this.handleChange(index, value)}
                  onSelected={(item) => this.handleSelection(index, item)}
                />
                <DateRangeSelection
                  extraClass={classes.dateSelection}
                  popupClass={classes.datePopup}
                  value={dateStr}
                  dates={route.dates}
                  shouldDisableDate={this.disabledDateFn}
                  disableFromDate={disableFromDate}
                  placeholder="Dates"
                  onSelected={(date) => this.handleDateSelection(index, date)}
                />
                <IconButton
                  onClick={() => this.handleRouteDelete(index)}
                  className={classes.deleteBtn}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </div>
            );
          }) : <RouteDiff data={routes} oldData={originalRoutes} />}
          {((mode === 'edit' && itineraryActionType !== 'generate_voucher') && !showDiff) ? (
            <Button
              className={classes.addButton}
              variant="plain"
              onClick={this.handleItemAddition}
            >
              + Add place
            </Button>
          ) : null}
        </div>
        <div className={classes.footer}>
          {(errorMsg || localError) && (
            <Typography className={classes.errorMsg}>{errorMsg || localError}</Typography>
          )}
          <Button
            variant="normal"
            onClick={nextHandler}
            className={classes.saveBtn}
          >
            {mode === 'view' ? 'Next' : 'Save & continue'}
          </Button>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    padding: '20px 40px',
    flex: 1,
    flexDirection: 'column',
  },
  changeHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,
  },
  indicator: {
    marginRight: 10,
  },
  inputContainer: {
    height: 40,
    flex: 1,
    maxWidth: 300,
    paddingRight: 10,
    width: '100%',
    borderRadius: 4,
  },
  labelClass: {
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
  autoCompleteInput: {
    padding: '6px 5px 6px 15px',
    height: '100%',
    width: '100%',
  },
  dateSelection: {
    flex: 1,
    marginLeft: 10,
  },
  datePopup: {
    left: -210,
  },
  addButton: {
    width: 120,
    marginRight: 'auto',
  },
  deleteBtn: {
    padding: 4,
    marginLeft: 10,
  },
  deleteIcon: {
    width: 16,
    height: 16,
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  errorMsg: {
    padding: '0 16px',
    color: theme.colors.red,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
});

RouteSelection.propTypes = {
  classes: PropTypes.object,
  routes: PropTypes.array,
  requirement: PropTypes.object.isRequired,
  errorMsg: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  mode: PropTypes.string,
  itineraryActionType: PropTypes.string.isRequired,
  originalRoutes: PropTypes.array,
};

export default withStyles(styles)(RouteSelection);
