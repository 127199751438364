import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/styles/makeStyles';
import Close from '../assets/icons/close';
import ErrorIcon from '../assets/icons/error';
import { colors } from './styleConsts';

const NotifPermission = ({ onDismiss, onAllow }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.headingRow}>
        <div className={classes.errorContainer}>
          <ErrorIcon fill={colors.red} />
        </div>
        <Typography
          className={classes.title}
        >
          Allow notifications
        </Typography>
        <IconButton
          type="button"
          className={classes.closeButton}
          onClick={onDismiss}
        >
          <Close fill={colors.red} />
        </IconButton>
      </div>
      <Typography className={classes.body}>
        Enabling Notifications will help you in staying updated with your customers &
        receiving alerts of their actions.
      </Typography>
      <div className={classes.footer}>
        <Button
          className={classes.notNowButton}
          onClick={onDismiss}
        >
          Not now
        </Button>
        <Button
          className={classes.allowButton}
          onClick={onAllow}
        >
          Allow
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px',
  },
  errorContainer: {
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.redLight,
    borderRadius: '50%',
  },
  headingRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    color: theme.colors.textDark,
    fontWeight: 'bold',
    fontSize: 18,
    paddingLeft: 10,
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 16,
    color: theme.colors.black,
    marginBottom: 20,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  closeIcon: {
    width: 30,
    height: 30,
  },
  notNowButton: {
    marginRight: 16,
    width: 140,
    height: 42,
    border: `1px solid ${theme.colors.primary}`,
    borderRadius: 25,
    backgroundColor: theme.colors.white,
    color: theme.colors.primary,
    letterSpacing: 0.5,
    textAlign: 'center',
    fontSize: 18,
  },
  allowButton: {
    width: 140,
    height: 42,
    borderRadius: 25,
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    letterSpacing: 0.5,
    textAlign: 'center',
    fontSize: 18,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
}));

NotifPermission.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onAllow: PropTypes.func.isRequired,
};

export default NotifPermission;
