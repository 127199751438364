import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

const Arrow = () => {
  const classes = useStyles();
  return (
    <div className={classes.arrow} />
  );
};

const useStyles = makeStyles((theme) => ({
  arrow: {
    top: 0,
    left: '45.8%',
    zIndex: 3,
    display: 'flex',
    position: 'absolute',
    backgroundColor: theme.colors.white,
    textAlign: 'left',
    width: '2em',
    height: '2em',
    borderTopRightRadius: '20%',
    transform: 'rotate(-45deg) skewX(0deg) scale(0.5)',
    border: `2px solid ${theme.colors.textLight_1}`,
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'inherit',
      width: '2.1em',
      height: '2.2em',
      borderTopRightRadius: '15%',
    },
    '&::before': {
      transform: 'rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%)',
    },
    '&::after': {
      transform: 'rotate(135deg) skewY(-45deg) scale(0.708, 1.414) translate(50%)',
    },
  },
}));

export default Arrow;
