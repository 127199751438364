import { useState } from 'react';

/* eslint-disable import/prefer-default-export */
export const useNestedState = (initialState) => {
  const [state, setState] = useState(initialState);
  const setNestedState = (field, value) => {
    setState({ ...state, [field]: value });
  };
  return [state, setNestedState];
};
