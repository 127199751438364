import {
  GET_PLACES,
  GET_PLACES_SUCCESS,
  GET_PLACES_FAILURE,
  ADD_PLACE,
  ADD_PLACE_SUCCESS,
  ADD_PLACE_FAILURE,
} from '../../actionTypes';

const initialState = {
  places: [],
  loading: false,
  loaded: false,
  failed: false,
};

const placeList = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLACES:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_PLACES_SUCCESS:
      return {
        ...state,
        places: action.payload,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_PLACES_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    case ADD_PLACE:
      return {
        ...state,
        place: action.payload.params,
        loading: true,
        loaded: false,
        failed: false,
      };

    case ADD_PLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        failed: false,
      };

    case ADD_PLACE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        failed: true,
      };

    default:
      return state;
  }
};

export default placeList;
