import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/styles/makeStyles';
import Switch from 'UI/switch';
import close from '../assets/svg/close.svg';

const Header = ({
  variant,
  Icon,
  iconClass,
  img,
  title,
  className = '',
  titleClass = '',
  showDiffToggle,
  onToggle,
  checked,
  label,
  onDismiss,
}) => {
  const classes = useStyles();
  const imgComp = Icon ? <Icon className={iconClass} />
    : <img src={img} alt="x" className={classes.icon} />;
  return (
    <div className={clsx(classes.header, className)}>
      {variant === 'back' ? (
        <IconButton
          aria-label="dismiss"
          className={classes.backButton}
          onClick={onDismiss}
        >
          <img src={img} alt="<-" className={classes.backImg} />
        </IconButton>
      ) : imgComp}
      <Typography className={clsx(classes.title, titleClass)}>{title}</Typography>
      {variant !== 'back' && (
        <IconButton
          aria-label="dismiss"
          className={classes.closeButton}
          onClick={onDismiss}
        >
          <img src={close} alt="x" className={classes.closeIcon} />
        </IconButton>
      )}
      {showDiffToggle && (
        <Switch
          checked={checked}
          onToggle={onToggle}
          label={label}
          extraClass={classes.switch}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px 40px',
    maxHeight: 64,
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.colors.border}`,
  },
  title: {
    color: theme.colors.textDark,
    fontWeight: 'bold',
    fontSize: 18,
    paddingLeft: 10,
  },
  closeButton: {
    marginLeft: 'auto',
  },
  backButton: {
    padding: 6,
  },
  icon: {
    width: 30,
    height: 30,
  },
  backImg: {
    width: 16,
    height: 14,
  },
  switch: {
    marginLeft: 'auto',
  },
}));

Header.propTypes = {
  variant: PropTypes.string,
  img: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  titleClass: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  iconClass: PropTypes.string,
  showDiffToggle: PropTypes.bool,
  onToggle: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

export default Header;
