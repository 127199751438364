import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import starImg from 'assets/svg/star_filled.svg';

const StarRating = ({ rating = 'N/A', stayType, extraClass }) => {
  const classes = useStyles();
  const ratingFor = stayType || ' ';
  if (rating === 'N/A') {
    return null;
  }
  return (
    <div className={clsx(classes.rating, extraClass)}>
      <img src={starImg} alt=" " className={classes.starImg} />
      <Typography className={classes.ratingText}>
        {`${rating} star ${ratingFor}`}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  ratingText: {
    fontSize: 10,
    color: theme.colors.white,
  },
  selectedImg: {
    padding: 6,
    borderRadius: '50%',
    position: 'absolute',
    top: -13,
    right: -13,
    boxSizing: 'border-box',
    width: 26,
    height: 26,
    backgroundColor: theme.colors.primary,
    border: `1px solid ${theme.colors.white}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rating: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.primary,
    borderRadius: 8,
    padding: '2px 8px',
  },
  starImg: {
    width: 10,
    height: 10,
    marginRight: 4,
  },
}));

StarRating.propTypes = {
  rating: PropTypes.number,
  extraClass: PropTypes.string,
  stayType: PropTypes.string,
};

export default StarRating;
