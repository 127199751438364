import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

const CustomerBankInfo = ({ classes, virtualAccountData, request }) => {
  const vData = virtualAccountData[request._id];
  return (
    <table className={classes.table}>
      <thead className={classes.thead}>
        <tr>
          <th><Typography variant="h6">Bank name</Typography></th>
          <th><Typography variant="h6">IFSC code</Typography></th>
          <th><Typography variant="h6">Account name</Typography></th>
          <th><Typography variant="h6">Account number</Typography></th>
          {/* <th><Typography variant="h6">UPI ID</Typography></th> */}
        </tr>
      </thead>
      <tbody className={classes.tbody}>
        <tr>
          <td><Typography>Axis Bank</Typography></td>
          <td><Typography>{vData?.userBankDetails?.ifsc}</Typography></td>
          <td><Typography>Clayo Technologies Pvt Ltd</Typography></td>
          <td><Typography>{vData?.userBankDetails?.accountNumber}</Typography></td>
          {/* <td><Typography>{vData?.userBankDetails?.vpa}</Typography></td> */}
        </tr>
      </tbody>
    </table>
  );
};
const styles = () => ({
  table: {
    width: 1000,
    margin: '10px 0 20px',
  },
  tbody: {
    textAlign: 'center',
  },
});
CustomerBankInfo.propTypes = {
  virtualAccountData: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(CustomerBankInfo);
