import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import { getDateStr, getTravellerText, uuid } from 'utils/common';
import { IMAGE_PLACEHOLDER } from 'utils/consts';
import DiffInput from './diffInput';

const StayDiffNew = ({ data, oldData }) => {
  const classes = useStyles();
  let newTransferMode = '-';
  let oldTransferMode = '-';
  if (data?.transferMode.items.length) {
    newTransferMode = data.transferMode.items
      .map((m) => m.title)
      .join(', ');
  }
  if (oldData?.transferMode?.items.length) {
    oldTransferMode = oldData.transferMode.items
      .map((m) => m.title)
      .join(', ');
  }
  const roomDetail = (Icon, text, oldText, extraClass) => {
    return (
      <div className={clsx(classes.roomDetail, extraClass)}>
        <Icon className={classes.icon} />
        <DiffInput
          label=""
          value={text || '-'}
          oldValue={oldText || '-'}
          extraClass={classes.flexSection}
        />
      </div>
    );
  };
  const renderRooms = (index, newRoom, oldRoom) => {
    let backgroundClass = '';
    let image = newRoom?.room.images?.length ? newRoom.room.images[0].url : IMAGE_PLACEHOLDER;
    if (!newRoom && oldRoom) {
      backgroundClass = 'deleted';
      if (oldRoom.images?.length) {
        image = oldRoom.room.images[0].url;
      }
    } else if (newRoom && oldRoom) {
      backgroundClass = 'edit';
    }
    return (
      <div
        className={clsx(classes.roomContainer, classes[backgroundClass])}
        key={uuid()}
      >
        <img
          src={image}
          alt=" "
          className={classes.roomImage}
          decoding="async"
        />
        <div className={classes.col}>
          <DiffInput
            label=""
            value={newRoom?.room.name || '-'}
            oldValue={oldRoom?.room.name || '-'}
          />
          <div className={classes.roomTypeRow}>
            <RoomIcon className={classes.icon} />
            <DiffInput
              label=""
              value={newRoom?.room.roomType.replace('_', ' ') || '-'}
              oldValue={oldRoom?.room.roomType.replace('_', ' ') || '-'}
              extraClass={clsx(classes.flexSection, classes.roomType)}
            />
          </div>
        </div>
        <div className={classes.roomData}>
          {roomDetail(PersonIcon, newRoom ? getTravellerText(newRoom.occupancy) : '-',
            oldRoom ? getTravellerText(oldRoom.occupancy) : '-')}
          {roomDetail(RestaurantIcon, newRoom ? newRoom.mealPlan.title : '-',
            oldRoom ? oldRoom.mealPlan.title : '-', classes.mealPlan)}
          {roomDetail(CalendarIcon, newRoom ? getDateStr(newRoom.roomCheckInDate, newRoom.roomCheckOutDate) : '-',
            oldRoom ? getDateStr(oldRoom.roomCheckInDate, oldRoom.roomCheckOutDate) : '-')}
        </div>
      </div>
    );
  };
  const newRoomLength = data.roomDetails.length;
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DiffInput
          label="Check in - Check out"
          value={data.checkinCheckout}
          oldValue={oldData.checkinCheckout}
          extraClass={classes.flexSection}
        />
        <DiffInput
          label="Transfers included"
          value={newTransferMode}
          oldValue={oldTransferMode}
          extraClass={classes.flexSection}
        />
        <DiffInput
          label="No. of travellers"
          value={getTravellerText(data.travellers)}
          oldValue={getTravellerText(oldData.travellers)}
          extraClass={classes.flexSection}
        />
      </div>
      <div className={classes.col}>
        {data.roomDetails.map((room, i) => renderRooms(i + 1, room, oldData.roomDetails[i]))}
        {data.roomDetails.length < oldData.roomDetails.length ? (
          oldData.roomDetails.slice(newRoomLength).map((oldRoom, i) => (
            renderRooms(i + newRoomLength, undefined, oldRoom)
          ))) : null}
      </div>
      <div className={classes.row}>
        <DiffInput
          label="NOTES"
          value={data.notes || '-'}
          oldValue={oldData.notes || '-'}
          extraClass={classes.flexSection}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  imageSection: {
    minWidth: '30%',
    overflowY: 'auto',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    padding: 20,
    marginBottom: 14,
    height: '80%',
  },
  roomContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    marginBottom: 16,
  },
  roomImage: {
    height: 170,
    width: 300,
    borderRadius: 4,
  },
  roomDetail: {
    display: 'flex',
    flexDirection: 'row',
    // width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    padding: '0 20px',
    color: theme.colors.primary,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  roomData: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px solid ${theme.colors.border}`,
  },
  index: {
    fontSize: 16,
    color: theme.colors.textLight,
    marginRight: 40,
    marginTop: 26,
  },
  flexSection: {
    flex: 1,
  },
  middleSection: {
    flex: 1,
    margin: '0 10px',
  },
  mealPlan: {
    border: `1px solid ${theme.colors.border}`,
    borderLeft: 'none',
    borderRight: 'none',
  },
  roomTypeRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  roomType: {
    '& > p': {
      fontSize: 12,
    },
    marginLeft: -10,
  },
}));

StayDiffNew.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default StayDiffNew;
