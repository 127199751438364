import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Footer from 'UI/footer';
import Button from 'UI/button';
import CustomCheckbox from 'UI/checkbox';
import LabelInput from 'UI/labelInput';
import clsx from 'clsx';

const ModifyConfirmation = ({
  modifyReason,
  onUpdate,
  isProcessing,
  approveRejectChanges,
  itineraryActionType,
  nextHandler,
  errorMsg,
}) => {
  const classes = useStyles();
  const [notifyOps, toggleOpsNotification] = useState(false);
  const [localError, setError] = useState('');
  const handleApproveRejection = (action) => {
    if (action === 'approve' && notifyOps) {
      approveRejectChanges('approve_with_mail');
    } else {
      approveRejectChanges(action);
    }
  };
  const submitHandler = () => {
    if (modifyReason.trim().length === 0) {
      setError('Reason cannot be empty');
    } else {
      nextHandler();
    }
  };
  const isApprovalMode = itineraryActionType === 'approve_reject_itinerary';
  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <LabelInput
          inputProps={{
            multiline: true,
            rows: 3,
            rowsMax: 6,
            readOnly: isApprovalMode,
          }}
          required
          label="Reason"
          placeholder="Reason for modifying itinerary"
          value={modifyReason}
          onChange={(val) => onUpdate('modifyReason', val)}
          inputClass={classes.reason}
          extraClass={classes.reasonContainer}
        />
        {isApprovalMode ? (
          <CustomCheckbox
            onChange={() => toggleOpsNotification(!notifyOps)}
            checked={notifyOps}
            label="Send booking mail to Operation"
          />
        ) : null}
      </div>
      <Footer
        errorMsg={errorMsg || localError}
      >
        {isApprovalMode ? (
          <>
            <Button
              variant="outlined"
              loading={isProcessing}
              onClick={() => handleApproveRejection('reject_request')}
              className={clsx(classes.rejectButton, !modifyReason.length && classes.disabled)}
            >
              REJECT
            </Button>
            <Button
              loading={isProcessing}
              onClick={() => handleApproveRejection('approve')}
              className={clsx(classes.createButton, !modifyReason.length && classes.disabled)}
            >
              APPROVE
            </Button>
          </>
        ) : (
          <Button
            loading={isProcessing}
            onClick={submitHandler}
            className={clsx(classes.createButton, !modifyReason.length && classes.disabled)}
          >
            Save & continue
          </Button>
        )}
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: 562,
    backgroundColor: theme.colors.white,
  },
  body: {
    padding: '20px 40px',
  },
  reasonContainer: {
    marginBottom: 16,
  },
  reason: {
    height: 'auto',
  },
  createButton: {
    minWidth: 150,
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  rejectButton: {
    color: theme.colors.red,
    backgroundColor: theme.colors.white,
    marginRight: 10,
    border: `1px solid ${theme.colors.red}`,
    minWidth: 120,
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    border: `1px solid ${theme.colors.grey} !important`,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
}));

ModifyConfirmation.propTypes = {
  approveRejectChanges: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  modifyReason: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool,
  expert: PropTypes.object,
  nextHandler: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  itineraryActionType: PropTypes.string.isRequired,
};

export default ModifyConfirmation;
