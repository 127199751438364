import {
  SET_STAY,
  UPDATE_STAY,
  UPDATE_STAY_SUCCESS,
  UPDATE_STAY_FAILURE,
  GET_STAY,
  GET_STAY_FAILURE,
  GET_STAY_SUCCESS,
  TOGGLE_APPROVE,
  TOGGLE_APPROVE_FAILURE,
  TOGGLE_APPROVE_SUCCESS,
  // LOAD_STAYS,
} from '../../actionTypes';

const initialState = {
  stay: {},
  editAllowed: false,
  loading: false,
  loaded: false,
  failed: false,
  updating: false,
  updateSuccess: false,
  updateFail: false,
};

const stayDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAY:
      return {
        ...state,
        stay: action.payload.params,
      };

    case UPDATE_STAY:
      return {
        ...state,
        stay: action.payload.params,
        updating: true,
        updateSuccess: false,
        updateFail: false,
      };

    case UPDATE_STAY_SUCCESS:
      return {
        ...state,
        stay: action.payload,
        updating: false,
        updateSuccess: true,
        updateFail: false,
      };

    case UPDATE_STAY_FAILURE:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        updateFail: true,
      };

    case GET_STAY:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_STAY_SUCCESS:
      return {
        ...state,
        stay: action.payload.stay,
        editAllowed: action.payload.editAllowed,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_STAY_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    case TOGGLE_APPROVE:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case TOGGLE_APPROVE_SUCCESS:
      return {
        ...state,
        stay: action.payload,
        loaded: true,
        loading: false,
        failed: false,
      };

    case TOGGLE_APPROVE_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default stayDetail;
