import {
  SET_STAY,
  UPDATE_STAY,
  UPDATE_STAY_SUCCESS,
  UPDATE_STAY_FAILURE,
  GET_STAY,
  GET_STAY_SUCCESS,
  GET_STAY_FAILURE,
  TOGGLE_APPROVE,
  TOGGLE_APPROVE_SUCCESS,
  TOGGLE_APPROVE_FAILURE,
} from '../../actionTypes';

export const setStay = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SET_STAY,
    payload,
  };
};

export const updateStay = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_STAY,
    payload,
  };
};

export const updateStayFailure = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_STAY_FAILURE,
    payload,
  };
};

export const updateStaySuccess = (payload) => {
  return {
    type: UPDATE_STAY_SUCCESS,
    payload,
  };
};

export const getStay = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_STAY,
    payload,
  };
};

export const getStayFailure = (payload) => {
  return {
    type: GET_STAY_FAILURE,
    payload,
  };
};

export const getStaySuccess = (payload) => {
  return {
    type: GET_STAY_SUCCESS,
    payload,
  };
};

export const toggleApprove = (params, headers) => {
  const payload = { params, headers };
  return {
    type: TOGGLE_APPROVE,
    payload,
  };
};

export const toggleApproveFailure = (payload) => {
  return {
    type: TOGGLE_APPROVE_FAILURE,
    payload,
  };
};

export const toggleApproveSuccess = (payload) => {
  return {
    type: TOGGLE_APPROVE_SUCCESS,
    payload,
  };
};
