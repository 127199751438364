import React from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import { getTravellerText } from 'utils/common';
import DocumentCards from 'UI/documentCards';
import DiffInput from './diffInput';

const ServiceDiff = ({ data, oldData }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DiffInput
          extraClass={classes.halfWidth}
          label="TYPE OF ADD-ON"
          value={data.service.value}
          oldValue={oldData.service.value}
        />
      </div>
      {data.service.value === 'Forex' ? (
        <div className={classes.row}>
          <DiffInput
            label="AMOUNT TO CONVERT"
            value={`${data?.forexData.targetCurrency.value || ' '} 
  ${data?.forexData.targetAmount || '-'}`}
            oldValue={`${oldData?.forexData.targetCurrency.value || ' '}
  ${oldData?.forexData.targetAmount || '-'}`}
            extraClass={classes.left}
          />
          <DiffInput
            label="CONVERT FROM"
            value={`${data?.forexData.sourceCurrency.value || '-'}`}
            oldValue={`${oldData?.forexData.sourceCurrency.value || '-'}`}
            extraClass={classes.right}
          />
        </div>
      ) : null}
      {data.service.value === 'Travel Insurance' ? (
        <>
          <div className={classes.row}>
            <DiffInput
              label="PAX"
              value={getTravellerText(data?.travelInsuranceData.pax || '-')}
              oldValue={getTravellerText(oldData?.travelInsuranceData.pax || '-')}
              extraClass={classes.halfWidth}
            />
          </div>
          <div className={classes.row}>
            <DiffInput
              label="POLICY DESCRIPTION"
              value={data?.travelInsuranceData.description || '-'}
              oldValue={oldData?.travelInsuranceData.description || '-'}
              extraClass={classes.left}
            />
            <div className={classes.documentContainer}>
              <DocumentCards
                documents={data?.travelInsuranceData.documents || []}
                onRemove={() => {}}
              />
            </div>
          </div>
        </>
      ) : null}
      {data.service.value === 'Visa' ? (
        <div className={classes.row}>
          <DiffInput
            label="PAX"
            value={getTravellerText(data.visaData.pax)}
            oldValue={getTravellerText(oldData.visaData.pax)}
            extraClass={classes.left}
          />
          <DiffInput
            label="COUNTRY"
            value={data.visaData.country.value}
            oldValue={oldData.visaData.country.value}
            extraClass={classes.right}
          />
        </div>
      ) : null}
      <div className={classes.row}>
        <DiffInput
          oldValue={oldData.costPrice}
          label="COST PRICE"
          value={data.costPrice}
          extraClass={classes.left}
        />
        <DiffInput
          oldValue={oldData.sellingPrice || 0}
          label="SELLING PRICE"
          value={data.sellingPrice || 0}
          extraClass={classes.right}
        />
      </div>
      <div className={classes.row}>
        <DiffInput
          oldValue={oldData.supplier.value}
          label="SUPPLIER"
          value={data.supplier.value}
          extraClass={classes.fullWidth}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  documentContainer: {
    flex: 1,
    marginLeft: 10,
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
  },
  halfWidth: {
    width: '50%',
    marginRight: 10,
    boxSizing: 'border-box',
  },
}));

ServiceDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default ServiceDiff;
