import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Header from 'UI/header';
import {
  getDateStr,
  uuid,
  capitalize,
  getTravellerText,
} from 'utils/common';
// import CustomCheckbox from 'UI/checkbox';
import TravellerSelection from 'UI/travellerSelection';
import DateRangeSelection from 'UI/dateRangeSelection';
import Footer from 'UI/footer';
import Button from 'UI/button';
import arrowBack from 'assets/svg/arrow_left.svg';
import Autocomplete from 'UI/autocomplete';

class RoomSelection extends Component {
  constructor(props) {
    super(props);
    const { route, requirement } = this.props;
    const rooms = this.getRoomsForSelection();
    this.state = {
      // splitStay: false,
      rooms,
      errorMsg: '',
    };
    this.checkInCheckOutDates = getDateStr(route.dates.form, route.dates.to);
    this.travellers = getTravellerText(requirement.travellers);
  }

  getRoomsForSelection = () => {
    const {
      edit, room: roomToEdit,
      rooms, route, requirement,
    } = this.props;
    if (edit && roomToEdit.room._id) {
      return [{
        isDirty: true,
        room: roomToEdit.room,
        id: uuid(),
        mealPlan: {
          value: roomToEdit.mealPlan?.title,
          valid: true,
          item: roomToEdit.mealPlan,
        },
        occupancy: roomToEdit.occupancy,
        dates: {
          from: moment(roomToEdit.roomCheckInDate).toDate(),
          to: moment(roomToEdit.roomCheckOutDate).toDate(),
        },
        dateStr: getDateStr(roomToEdit.roomCheckInDate, roomToEdit.roomCheckOutDate),
      }];
    }
    const roomsForSelection = rooms.map((room) => ({
      isDirty: false,
      room,
      id: uuid(),
      mealPlan: {
        value: '',
        valid: false,
        item: {},
      },
      occupancy: {
        adults: 0,
        children: 0,
        infants: 0,
        ...requirement.travellers,
      },
      dates: {
        from: route.dates.form,
      },
      dateStr: '',
    }));
    return roomsForSelection;
  };

  // handleSplitStayChange = () => {
  //   this.setState((prevState) => ({ splitStay: !prevState.splitStay }));
  // };

  handleRoomDetailChange = (index, field, value) => {
    const { rooms } = this.state;
    const modRooms = [...rooms];
    const modRoom = modRooms[index];
    if (field === 'occupancy') {
      modRoom.occupancy = value;
      if (value.adults || value.children || value.infants) {
        modRoom.isDirty = true;
      }
    } else if (field === 'mealPlan') {
      modRoom.mealPlan = {
        ...modRoom.mealPlan,
        value,
        valid: false,
      };
    }
    modRooms[index] = modRoom;
    this.setState({ rooms: modRooms });
  };

  handleRoomDetailSelection = (index, field, item) => {
    const { rooms } = this.state;
    const modRooms = [...rooms];
    const modRoom = modRooms[index];
    modRoom.isDirty = true;
    if (field === 'mealPlan') {
      modRoom.mealPlan = {
        value: item.title,
        valid: true,
        item,
      };
    }
    modRooms[index] = modRoom;
    this.setState({ rooms: modRooms });
  };

  handleRoomDetailDateChange = (index, dates, dateStr) => {
    const { rooms } = this.state;
    const modRooms = [...rooms];
    const modRoom = modRooms[index];
    if (dates.from && dates.to) {
      modRoom.isDirty = true;
    }
    modRoom.dates = dates;
    modRoom.dateStr = dateStr;
    modRooms[index] = modRoom;
    this.setState({ rooms: modRooms });
  };

  isRoomValid = (room) => {
    let error = false;
    let errorMsg = '';
    const { mealPlan, occupancy, dates } = room;
    if (!mealPlan.valid) {
      error = true;
      errorMsg = 'Meal type not selected';
    } else if (occupancy.adults === 0 && occupancy.children === 0) {
      error = true;
      errorMsg = 'Occupants not selected';
    } else if (!dates.from || !dates.to) {
      error = true;
      errorMsg = 'Room dates not selected';
    }
    return {
      error,
      errorMsg,
    };
  };

  onSubmit = () => {
    const { onUpdate, edit } = this.props;
    const { rooms } = this.state;
    const roomsToAdd = [];
    let error = false;
    let errorMsg = '';
    for (let i = 0; i < rooms.length; i++) {
      const { error: e, errorMsg: msg } = this.isRoomValid(rooms[i]);
      if (rooms[i].isDirty && !e) {
        const {
          mealPlan, occupancy, dates, room,
        } = rooms[i];
        roomsToAdd.push({
          room,
          mealPlan: mealPlan.item,
          occupancy,
          roomCheckInDate: moment(dates.from).toISOString(),
          roomCheckOutDate: moment(dates.to).toISOString(),
        });
      }
      if (e && rooms[i].isDirty) {
        error = e;
        errorMsg = msg;
        break;
      }
    }
    if (error) {
      this.setState({ errorMsg });
      return;
    }
    if (edit) {
      onUpdate('update', roomsToAdd[0]);
    } else {
      onUpdate('save', roomsToAdd);
    }
  };

  render() {
    const {
      mealPlans: allMealPlans, stay, onDismiss,
      classes, shouldDisableDate,
    } = this.props;
    const { rooms, errorMsg } = this.state;
    return (
      <div className={classes.container}>
        <Header
          className={classes.headerClass}
          titleClass={classes.title}
          title={`Select room in ${stay.name}`}
          onDismiss={onDismiss}
          variant="back"
          img={arrowBack}
        />
        <div className={classes.stayDetails}>
          <div className={classes.box}>
            <Typography className={classes.boxTitle}>
              CheckIn/checkout
            </Typography>
            <Typography className={classes.boxValue}>
              {this.checkInCheckOutDates}
            </Typography>
          </div>
          <div className={classes.box}>
            <Typography className={classes.boxTitle}>
              No. of travellers
            </Typography>
            <Typography className={classes.boxValue}>
              {this.travellers}
            </Typography>
          </div>
          {/* <CustomCheckbox
            onChange={this.handleSplitStayChange}
            checked={splitStay}
            label="Split stay"
          /> */}
        </div>
        <div className={classes.body}>
          {rooms.map((room, index) => {
            const {
              room: {
                name, images = [],
                roomType = '', _id,
              },
              mealPlan, occupancy, dates, dateStr,
            } = room;
            const finalImage = images[0] ? images[0].url : '';
            return (
              <div key={_id} className={classes.room}>
                <img
                  decoding="async"
                  src={finalImage}
                  alt={`${name.slice(0, 5)}...`}
                  className={classes.roomImage}
                />
                <div className={clsx(classes.roomDetails, classes.roomInfo)}>
                  <Typography className={classes.roomName}>
                    {name}
                  </Typography>
                  <Typography className={classes.roomType}>
                    {capitalize(roomType.replace('_', ' '))}
                  </Typography>
                </div>
                <div className={classes.roomDetails}>
                  <div className={classes.rowWrapper}>
                    <PersonIcon className={classes.iconClass} />
                    <TravellerSelection
                      label=""
                      placeholder="Add occupants"
                      extraClass={classes.travellerWrapper}
                      travellers={occupancy}
                      onChange={(val) => this.handleRoomDetailChange(index, 'occupancy', val)}
                    />
                  </div>
                  <div className={clsx(classes.rowWrapper, classes.mealRowWrapper)}>
                    <RestaurantIcon className={classes.iconClass} />
                    <Autocomplete
                      label=""
                      value={mealPlan.value}
                      popupClass={classes.autocompletePopup}
                      placeholder="Select meal type"
                      extraClass={classes.mealPlansWrapper}
                      inputClass={classes.autoCompleteInput}
                      variant="no-outline"
                      data={allMealPlans}
                      accessor="title"
                      onChange={(val) => this.handleRoomDetailChange(index, 'mealPlan', val)}
                      onSelected={(item) => this.handleRoomDetailSelection(index, 'mealPlan', item, 'title')}
                    />
                  </div>
                  <div className={classes.rowWrapper}>
                    <CalendarIcon className={classes.iconClass} />
                    <DateRangeSelection
                      placeholder="Check in - Check out"
                      value={dateStr}
                      dates={dates}
                      extraClass={classes.dateWrapper}
                      popupClass={classes.datePopup}
                      shouldDisableDate={shouldDisableDate}
                      onSelected={(date, str) => (
                        this.handleRoomDetailDateChange(index, date, str)
                      )}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Footer
          extraClass={classes.footer}
          errorMsg={errorMsg}
        >
          <Button
            variant="normal"
            className={classes.addButton}
            onClick={this.onSubmit}
          >
            ADD ROOM
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 64,
    width: '72vw',
    height: '100%',
    boxSizing: 'border-box',
  },
  headerClass: {
    height: 70,
  },
  title: {
    color: theme.colors.primary,
    fontSize: 24,
  },
  stayDetails: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 30px',
    backgroundColor: theme.colors.primarySelected,
  },
  body: {
    flex: 1,
    overflowY: 'auto',
    padding: '20px 30px',
  },
  room: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
  roomInfo: {
    padding: '15px 20px',
  },
  roomDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderLeft: `1px solid ${theme.colors.border}`,
  },
  rowWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '33%',
    width: '100%',
    alignItems: 'center',
  },
  travellerWrapper: {
    flex: 1,
    '& > div': {
      height: '100%',
      border: 'none',
    },
  },
  mealRowWrapper: {
    borderTop: `1px solid ${theme.colors.border}`,
    borderBottom: `1px solid ${theme.colors.border}`,
    borderLeft: 'none',
    borderRight: 'none',
  },
  autoCompleteInput: {
    width: '100%',
    border: 'none',
    padding: '10px 5px 10px 15px',
  },
  autocompletePopup: {
    top: 55,
    left: -40,
    width: '110%',
  },
  mealPlansWrapper: {
    flex: 1,
  },
  dateWrapper: {
    flex: 1,
    border: 'none',
  },
  datePopup: {
    right: 10,
    left: 'unset',
    top: 40,
  },
  roomImage: {
    height: 170,
    width: 300,
    borderRadius: 4,
  },
  roomName: {
    color: theme.colors.black,
    fontSize: 16,
    marginBottom: 10,
  },
  roomType: {
    fontSize: 12,
    color: theme.colors.black,
  },
  iconClass: {
    width: 20,
    height: 20,
    padding: '0 20px',
    color: theme.colors.primary,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
  },
  boxTitle: {
    fontSize: 12,
    color: theme.colors.textLight,
  },
  boxValue: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  footer: {
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    width: '72vw',
    height: 60,
    padding: '10px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: theme.colors.white,
    boxShadow: '0 -2px 8px 0 rgba(0,0,0,0.17)',
  },
  addButton: {
    backgroundColor: theme.colors.primary,
    borderRadius: 25,
    width: 130,
    color: theme.colors.white,
  },
});

RoomSelection.propTypes = {
  stay: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  mealPlans: PropTypes.array.isRequired,
  shouldDisableDate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  requirement: PropTypes.object.isRequired,
  room: PropTypes.object,
  edit: PropTypes.bool.isRequired,
};

export default withStyles(styles)(RoomSelection);
