import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import withStyles from '@material-ui/styles/withStyles';
import Header from 'UI/header';
import Autocomplete from 'UI/autocomplete';
import upload from 'assets/svg/upload_outline.svg';
import LabelInput from 'UI/labelInput';
import RenderPDF from 'UI/renderPDF';
import deleteIcon from 'assets/svg/delete.svg';
import pdfIcon from 'assets/svg/pdf.svg';
import pngIcon from 'assets/svg/png.svg';
import jpegIcon from 'assets/svg/jpeg.svg';
import addIcon from 'assets/svg/create.svg';
import ArrowBack from 'assets/icons/arrowBack';
import ArrowNext from 'assets/icons/arrowNext';
import { colors } from 'UI/styleConsts';
import DragnDrop from 'UI/DragnDrop';
import Warning from 'assets/icons/warning';
import PreviewDocs from './previewDocs';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewDialog: false,
      fileDetails: [],
      base64Files: [],
      activeFile: { index: -1 },
    };
    this.dropInput = React.createRef();
    const temp = ['Passport', 'Tickets', 'Pan card', 'Visa', 'Itinerary', 'Other'];
    this.docTypes = temp.map((t) => ({ name: t }));
    this.activeFile = { index: -1 };
    this.validTypes = ['application/pdf', 'image/png', 'image/jpeg'];
  }

  componentDidMount = () => {
    const pdfLib = document.getElementById('pdflib');
    if (!pdfLib || (pdfLib && pdfLib.src !== 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.min.js')) {
      const scriptEl = document.createElement('script');
      scriptEl.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.min.js';
      scriptEl.id = 'pdflib';
      document.head.appendChild(scriptEl);
    }
  }

  readFileAsBase64 = (files) => {
    const { base64Files } = this.state;
    const modFiles = [...base64Files];
    const startLength = base64Files.length;
    const filesNum = files.length;
    let done = 0;
    for (let j = 0; j < filesNum; j++) {
      modFiles[startLength + j] = '';
      (function (i, self) { // eslint-disable-line
        const reader = new FileReader();
        reader.onload = () => {
          modFiles[i] = reader.result;
          done++;
          if (done === filesNum) {
            self.setState({ base64Files: modFiles });
          }
        };
        reader.readAsDataURL(files[i - startLength]);
      }((j + startLength), this));
    }
  }

  handleDrop = (e) => {
    const { markTouched } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const { fileDetails } = this.state;
      const modDetails = [...fileDetails];
      const filesToAppend = [];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { name, size, type } = file;
        if (type && this.validTypes.some((v) => v === type)) {
          filesToAppend.push(file);
          const detail = {
            name,
            size,
            type,
            sendName: name,
            docType: '',
            file,
          };
          modDetails.push(detail);
        }
      }
      this.readFileAsBase64(filesToAppend);
      const { activeFile } = this.state;
      let newFile = activeFile;
      if (activeFile.index === -1 && modDetails.length) {
        newFile = { index: 0, ...modDetails[0] };
        this.activeFile = newFile;
      }
      this.setState({ fileDetails: modDetails, activeFile: newFile });
      markTouched();
      e.dataTransfer.clearData();
    }
  }

  handleChange = (index, field, val) => {
    const { markTouched } = this.props;
    const { fileDetails } = this.state;
    const modDetails = [...fileDetails];
    modDetails[index][field] = val;
    markTouched();
    this.setState({ fileDetails: modDetails });
  }

  uploadHandler = () => {
    const { onUpload } = this.props;
    const { fileDetails } = this.state;
    onUpload(fileDetails);
  }

  removeFile = (e, index) => {
    e.stopPropagation();
    const {
      fileDetails,
      base64Files,
    } = this.state;
    const modFileDetails = [...fileDetails];
    modFileDetails.splice(index, 1);
    const modBaseFiles = [...base64Files];
    modBaseFiles.splice(index, 1);
    let newFile = this.activeFile;
    if (this.activeFile.index === index) {
      newFile = { index: -1 };
      if (index === 0 && modFileDetails.length > 0) {
        newFile = { index: 0, ...modFileDetails[0] };
      }
    } else if (this.activeFile.index > index) {
      const { index: idx } = this.activeFile;
      const newIndex = idx - 1;
      newFile = { index: newIndex, ...modFileDetails[newIndex] };
    }
    this.activeFile = newFile;
    this.setState({
      fileDetails: modFileDetails,
      base64Files: modBaseFiles,
      activeFile: newFile,
    });
  }

  changeActivePreview = (index) => {
    const { fileDetails } = this.state;
    this.activeFile = { index, ...fileDetails[index] };
    this.setState({ activeFile: { index, ...fileDetails[index] } });
  }

  prevHandler = () => {
    const { index } = this.activeFile;
    if (index - 1 >= 0) {
      const newIndex = index - 1;
      this.changeActivePreview(newIndex);
    }
  }

  nextHandler = () => {
    const { fileDetails } = this.state;
    const len = fileDetails.length;
    const { index } = this.activeFile;
    if (index + 1 < len) {
      this.changeActivePreview(index + 1);
    }
  }

  renderActiveItem = () => {
    const { classes } = this.props;
    const { base64Files } = this.state;
    const { index, type } = this.activeFile;
    if (index === -1 || type === 'application/pdf') {
      return null;
    }
    return (
      <img className={classes.imgPreview} alt="p" src={base64Files[index]} />
    );
  }

  getFileSize = (size) => {
    if (size < 1024) {
      return '1 KB';
    }
    const kbs = size / 1024;
    if (kbs > 1024) {
      const mbs = kbs / 1024;
      return `${Math.round(mbs)} MB`;
    }
    return `${Math.round(kbs)} KB`;
  }

  handleUpload = (e) => {
    const params = {
      preventDefault: () => { },
      stopPropagation: () => { },
      dataTransfer: {
        files: e.target.files,
        clearData: () => { },
      },
    };
    this.handleDrop(params);
    this.dropInput.current.value = '';
  }

  toggleState = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  }

  render() {
    const { classes, onDismiss } = this.props;
    const {
      fileDetails,
      base64Files,
      previewDialog,
    } = this.state;
    const { index, type: fileType } = this.activeFile;
    const showPdf = index > -1 && fileDetails[index] && fileType === 'application/pdf';
    const prevDisabled = index <= 0;
    const nextDisabled = (index === -1) || index === fileDetails.length - 1;
    const dialogClass = previewDialog ? 'vDialog' : 'nvDialog';
    return (
      <DragnDrop
        fileDropHandler={(e) => this.handleDrop(e)}
      >
        <>
          <div className={classes[dialogClass]}>
            {previewDialog ? (
              <PreviewDocs
                base64Files={base64Files}
                fileDetails={fileDetails}
                index={index}
                onDismiss={() => this.toggleState('previewDialog')}
              />
            ) : null}
          </div>
          <Header
            title="Upload docs"
            img={upload}
            onDismiss={onDismiss}
          />
          <div className={classes.body}>
            <div
              className={classes.dropSection}
            >
              <div className={classes.dropArea}>
                <input
                  type="file"
                  accept="application/pdf,image/png,image/jpeg"
                  multiple="multiple"
                  ref={this.dropInput}
                  className={classes.dropBox}
                  onChange={(e) => this.handleUpload(e)}
                />
                <Typography className={classes.dropText}>Drag & drop anywhere</Typography>
                <Typography className={classes.dropMsgOR}>
                  or
                </Typography>
                <Typography className={classes.dropMsgAdd}>
                  <img src={addIcon} alt="+" className={classes.addIcon} />
                  &nbsp; Add documents
                </Typography>
              </div>
              {fileDetails.map((file, idx) => {
                const {
                  name,
                  type,
                  size,
                  docType,
                } = file;
                let imgSrc = type === 'application/pdf' ? pdfIcon : base64Files[idx];
                let imgClass = 'previewImg';
                if (!imgSrc) {
                  imgSrc = type === 'img/png' ? pngIcon : jpegIcon;
                }
                if (!imgSrc || type === 'application/pdf') {
                  imgClass = 'previewImgDef';
                }
                const fileTypeCls = docType === '' ? 'fileTypeDef' : 'fileType';
                const flTp = docType === '' ? 'File type' : docType;
                const dispName = name.length > 21 ? `${name.slice(0, 22)}...` : name;
                const exceedesFileSize = size > 20971520;
                return (
                  <div
                    role="button"
                    tabIndex="0"
                    className={clsx(
                      classes.fileToUpload,
                      {
                        [classes.fileToUploadSelected]: index === idx,
                        [classes.limitExceeds]: exceedesFileSize,
                      }
                    )}
                    key={name}
                    onClick={() => this.changeActivePreview(idx)}
                  >
                    <img className={classes[imgClass]} src={imgSrc} alt=" " />
                    <div className={classes.fileLineBody}>
                      <div className={classes.row}>
                        <Typography className={classes.fileName}>{dispName}</Typography>
                        <Typography className={classes.fileSize}>
                          {this.getFileSize(size)}
                        </Typography>
                        <IconButton
                          className={classes.iconButton}
                          onClick={(e) => this.removeFile(e, idx)}
                        >
                          <img src={deleteIcon} className={classes.trash} alt="x" />
                        </IconButton>
                      </div>
                      <Typography className={classes[fileTypeCls]}>{flTp}</Typography>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classes.previewSection}>
              <Typography className={classes.acceptedMsg}>
                Accepted file types - PDF, PNG, JPEG only, Max file size - 20MB
              </Typography>
              <div className={classes.preview}>
                <button
                  type="button"
                  className={classes.previewButton}
                  onClick={() => this.toggleState('previewDialog')}
                >
                  <RenderPDF
                    file={fileDetails[index] || {}}
                    show={showPdf}
                    id="pdf"
                  />
                  {this.renderActiveItem()}
                </button>
                {(index > -1 && fileDetails[index]) ? (
                  <div className={classes.details}>
                    <LabelInput
                      label="Document name"
                      value={fileDetails[index].sendName}
                      onChange={(val) => this.handleChange(index, 'sendName', val)}
                    />
                    <Autocomplete
                      label="Document type"
                      data={this.docTypes}
                      value={fileDetails[index].docType}
                      accessor="name"
                      extraClass={classes.docTypeContainer}
                      inputClass={classes.autoCompleteInput}
                      onChange={(val) => this.handleChange(index, 'docType', val)}
                      onSelected={(item) => this.handleChange(index, 'docType', item.name)}
                    />
                  </div>
                ) : null}
              </div>
              {(fileDetails[index] && fileDetails[index].size > 20971520) ? (
                <div className={classes.errorBox}>
                  <Warning fill={colors.red} />
                  <Typography className={classes.errorText}>
                    {`File size exceeded ${this.getFileSize(fileDetails[index].size)}`}
                  </Typography>
                  <ButtonBase
                    className={classes.removeFileButton}
                    onClick={(e) => this.removeFile(e, index)}
                  >
                    REMOVE THIS FILE
                  </ButtonBase>
                </div>
              ) : null}
              <div className={classes.footer}>
                <ButtonBase
                  disabled={prevDisabled}
                  type="button"
                  className={classes.navButtonL}
                  onClick={() => this.prevHandler()}
                >
                  <ArrowBack
                    fill={prevDisabled ? colors.textLight_1 : colors.primary}
                    className={classes.navIconL}
                  />
                  Previous
                </ButtonBase>
                <ButtonBase
                  disabled={nextDisabled}
                  type="button"
                  className={classes.navButtonR}
                  onClick={() => this.nextHandler()}
                >
                  Next
                  <ArrowNext
                    fill={nextDisabled ? colors.textLight_1 : colors.primary}
                    className={classes.navIconR}
                  />
                </ButtonBase>
                <Button
                  className={classes.uploadButton}
                  onClick={this.uploadHandler}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </>
      </DragnDrop>
    );
  }
}

/**
 * @param {import('../../../index').Theme} theme
 */
const styles = (theme) => ({
  nvDialog: {
    opacity: 0,
    widows: 0,
    height: 0,
    display: 'none',
  },
  vDialog: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1499,
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    maxHeight: 510,
    borderRadius: 10,
  },
  dropSection: {
    minWidth: '45%',
    flex: 4.5,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    borderRight: `1px solid ${theme.colors.border}`,
    padding: 30,
    maxHeight: 510,
    boxSizing: 'border-box',
  },
  dropArea: {
    width: '100%',
    height: 110,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 30,
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  dropText: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.colors.textDark_1,
  },
  dropMsgOR: {
    margin: '6px 0',
    textAlign: 'center',
    fontSize: 12,
    color: theme.colors.textLight_1,
  },
  dropMsgAdd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.colors.primaryBackground,
  },
  fileToUpload: {
    cursor: 'pointer',
    border: `1px solid ${theme.colors.border}`,
    backgroundColor: theme.colors.white,
    '&:hover': {
      boxShadow: '0 4px 6px 0 rgba(0,0,0,0.1)',
    },
    borderRadius: 6,
    padding: 4,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    outline: 'none',
  },
  fileToUploadSelected: {
    boxShadow: '0 4px 6px 0 rgba(0,0,0,0.1)',
  },
  limitExceeds: {
    border: `1px solid ${theme.colors.red}`,
  },
  fileLineBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 10,
    flex: 1,
  },
  previewImg: {
    objectFit: 'contain',
    height: 54,
    width: 54,
    borderRadius: 4,
    backgroundColor: theme.colors.underline,
  },
  previewImgDef: {
    objectFit: 'contain',
    height: 54,
    width: 54,
    borderRadius: 4,
    backgroundColor: theme.colors.underline,
    boxSizing: 'border-box',
    padding: 6,
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 15px',
    width: '100%',
    height: 34,
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.redLight,
    marginBottom: 30,
  },
  errorText: {
    paddingLeft: 10,
    color: theme.colors.black,
    fontSize: 14,
  },
  removeFileButton: {
    marginLeft: 'auto',
    letterSpacing: 0.5,
    fontSize: 12,
    fontWeight: 'bold',
    height: 28,
    boxSizing: 'border-box',
    padding: '5px 10px',
    color: theme.colors.red,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 6,
    width: '100%',
  },
  fileName: {
    color: theme.colors.black,
    fontSize: 16,
  },
  trash: {
    width: 14,
    height: 14,
    marginTop: -3,
  },
  fileType: {
    color: theme.colors.black,
    fontSize: 14,
    lineHeight: 1,
    paddingBottom: 10,
  },
  fileTypeDef: {
    color: theme.colors.textLight,
    fontSize: 14,
    lineHeight: 1,
    paddingBottom: 10,
  },
  fileSize: {
    fontSize: 12,
    textAlign: 'right',
    color: theme.colors.textLight,
    marginLeft: 'auto',
    paddingRight: 6,
  },
  iconButton: {
    padding: 4,
    width: 20,
    height: 20,
  },
  previewSection: {
    flex: 5.5,
    minWidth: '55%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    maxHeight: 510,
    overflowY: 'hidden',
  },
  preview: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  previewButton: {
    outline: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    marginBottom: 20,
    maxHeight: 280,
  },
  imgPreview: {
    flex: 1,
    minWidth: 180,
    maxWidth: 200,
    maxHeight: 260,
    borderRadius: 8,
    border: `1px solid ${theme.colors.border}`,
    objectFit: 'contain',
  },
  acceptedMsg: {
    fontSize: 14,
    color: theme.colors.textLight,
    marginBottom: 20,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  navIconL: {
    marginRight: 10,
  },
  navIconR: {
    marginLeft: 10,
  },
  navButtonL: {
    outline: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 32,
    borderRadius: 4,
    marginRight: 10,
    padding: '5px 10px',
    letterSpacing: 0.5,
    fontSize: 12,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'all',
      color: theme.colors.textLight_1,
      '&:hover': {
        backgroundColor: theme.colors.primaryLight,
      },
    },
  },
  navButtonR: {
    outline: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 32,
    borderRadius: 4,
    marginLeft: 10,
    padding: '5px 10px',
    letterSpacing: 0.5,
    fontSize: 12,
    fontFamily: 'Lato',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'all',
      color: theme.colors.textLight_1,
      '&:hover': {
        backgroundColor: theme.colors.primaryLight,
      },
    },
  },
  uploadButton: {
    marginLeft: 'auto',
    borderRadius: 25,
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    width: 170,
    height: 42,
    fontSize: 18,
    letterSpacing: 0.5,
    textAlign: 'center',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 280,
    marginLeft: 20,
  },
  docTypeContainer: {
    marginTop: 30,
    flex: 1,
    borderRadius: 4,
    width: '100%',
  },
  autoCompleteInput: {
    padding: '6px 5px 6px 15px',
    height: '100%',
    width: '100%',
  },
});

Upload.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  markTouched: PropTypes.func.isRequired,
};

export default withStyles(styles)(Upload);
