import {
  GET_STAYS,
  GET_STAYS_SUCCESS,
  GET_STAYS_FAILURE,
  ADD_STAY,
  ADD_STAY_SUCCESS,
  ADD_STAY_FAILURE,
} from '../../actionTypes';

export const getStays = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_STAYS,
    payload,
  };
};

export const getStaysFailure = (payload) => {
  return {
    type: GET_STAYS_FAILURE,
    payload,
  };
};

export const getStaysSuccess = (payload) => {
  return {
    type: GET_STAYS_SUCCESS,
    payload,
  };
};

export const addStay = (params, headers) => {
  const payload = { params, headers };
  return {
    type: ADD_STAY,
    payload,
  };
};

export const addStayFailure = (errorMsg) => {
  const payload = { errorMsg };
  return {
    type: ADD_STAY_FAILURE,
    payload,
  };
};

export const addStaySuccess = (payload) => {
  return {
    type: ADD_STAY_SUCCESS,
    payload,
  };
};
