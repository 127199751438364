import {
  SET_HOTEL,
  UPDATE_HOTEL,
  UPDATE_HOTEL_SUCCESS,
  UPDATE_HOTEL_FAILURE,
  GET_HOTEL,
  GET_HOTEL_FAILURE,
  GET_HOTEL_SUCCESS,
  // LOAD_HOTELS,
} from '../../actionTypes';

const initialState = {
  hotel: {},
  editAllowed: false,
  loading: false,
  loaded: false,
  failed: false,
  updating: false,
  updateSuccess: false,
  updateFail: false,
};

const hotelDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL:
      return {
        ...state,
        hotel: action.payload.params,
      };

    case UPDATE_HOTEL:
      return {
        ...state,
        hotel: action.payload.params,
        updating: true,
        updateSuccess: false,
        updateFail: false,
      };

    case UPDATE_HOTEL_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        updateFail: false,
      };

    case UPDATE_HOTEL_FAILURE:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        updateFail: true,
      };

    case GET_HOTEL:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_HOTEL_SUCCESS:
      return {
        ...state,
        hotel: action.payload.hotel,
        editAllowed: action.payload.editAllowed,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_HOTEL_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default hotelDetail;
