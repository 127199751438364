import React from 'react';
import * as PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import makeStyles from '@material-ui/styles/makeStyles';
import { colors } from './styleConsts';
import WarningIcon from '../assets/icons/warning';
import SuccessIcon from '../assets/icons/success';
import ErrorIcon from '../assets/icons/error';
import InfoIcon from '../assets/icons/info';
import CloseIcon from '../assets/icons/close';

const iconMap = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const colorMap = {
  success: 'green',
  warning: 'orange',
  error: 'red',
  info: 'white',
};

const CustomSnack = ({
  open,
  message,
  actions,
  variant,
  onClose,
}) => {
  const classes = useStyles();
  const Icon = iconMap[variant];
  const color = colorMap[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      TransitionComponent={Slide}
    >
      <SnackbarContent
        className={`${classes.container} ${classes[variant]}`}
        classes={{
          message: classes.messageBody,
        }}
        message={(
          <>
            <Icon fill={colors[color]} />
            <Typography className={classes.message}>
              {message}
            </Typography>
            {actions.length ? (
              actions.map(({ title, action }) => {
                return (
                  <ButtonBase
                    key={title}
                    onClick={action}
                    className={classes.actionButton}
                  >
                    {title}
                  </ButtonBase>
                );
              })
            ) : null}
            <IconButton
              onClick={onClose}
              className={classes.closeButton}
            >
              <CloseIcon fill={colors.textLight} />
            </IconButton>
          </>
        )}
      />
    </Snackbar>
  );
};

CustomSnack.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
  onClose: PropTypes.func,
  actions: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: 4,
    flexDirection: 'row',
    maxHeight: 64,
  },
  info: {
    '& > div > p': {
      color: theme.colors.white,
    },
  },
  success: {
    borderLeft: `4px solid ${theme.colors.primaryBackground}`,
    backgroundColor: theme.colors.primaryLight,
  },
  error: {
    borderLeft: `4px solid ${theme.colors.red}`,
    backgroundColor: theme.colors.redLight,
  },
  warning: {
    borderLeft: `4px solid ${theme.colors.yellow}`,
    backgroundColor: theme.colors.yellowLight,
  },
  message: {
    flex: 1,
    fontSize: 16,
    color: theme.colors.black,
    padding: '0 0 0 10px',
  },
  messageBody: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 0,
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    padding: '4px 8px',
    color: theme.colors.red,
    marginRight: 4,
  },
  closeButton: {
    padding: 4,
  },
}));

export default CustomSnack;
