import React from 'react';
import * as PropTypes from 'prop-types';

const Search = ({
  width = 12,
  height = 12,
  className,
  fill = '#000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <defs>
        <path
          fill={fill}
          id="A"
          d="M9.106 8.257l2.72 2.72a.6.6 0 1 1-.849.849l-2.72-2.72A5.08 5.08 0 0 1 5.1 10.2 5.1 5.1 0 1 1 5.1 0a5.1 5.1 0 0 1 5.1 5.1 5.08 5.08 0 0 1-1.094 3.157zm-1.2-.438C8.58 7.117 9 6.157 9 5.1a3.9 3.9 0 1 0-7.8 0A3.9 3.9 0 0 0 5.1 9c1.057 0 2.017-.42 2.72-1.104l.037-.04.04-.037z"
        />
      </defs>
      <g fillRule="evenodd">
        <mask id="B" fill="#fff">
          <use href="#A" />
        </mask>
        <use fill="#000" fillRule="nonzero" href="#A" />
        <g fill="#2c7a77" mask="url(#B)">
          <path d="M0 0h12v12H0z" />
        </g>
      </g>
    </svg>
  );
};

Search.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Search;
