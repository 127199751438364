import moment from 'moment';
import {
  GET_NOTIFICATIONS_INIT,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  MARK_NOTIF_READ_INIT,
  MARK_NOTIF_READ_SUCCESS,
  MARK_NOTIF_READ_FAILURE,
} from '../../actionTypes';

const initialState = {
  isFetchingNotifications: false,
  notifications: {},
  rawNotifications: [],
  unReadCount: 0,
  notificationsError: false,
  notificationsErrorMsg: '',
  isMarkingRead: false,
  markNotifReadResp: {},
  markNotifReadError: false,
  markNotifReadErrorMsg: '',
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_INIT:
      return {
        ...state,
        isFetchingNotifications: true,
        notificationsError: false,
      };

    case GET_NOTIFICATIONS_SUCCESS: {
      const { data: { notifications = [], unReadCount = 0 } } = action;
      const groupedNotifs = {};
      for (let i = 0; i < notifications.length; i++) {
        const notif = notifications[i];
        const date = moment(notif.created_at).format('DD MMM YY');
        if (groupedNotifs[date]) {
          groupedNotifs[date].push(notif);
        } else {
          groupedNotifs[date] = [notif];
        }
      }
      return {
        ...state,
        isFetchingNotifications: false,
        notifications: groupedNotifs,
        rawNotifications: notifications,
        unReadCount,
      };
    }

    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetchingNotifications: false,
        notificationsError: true,
        notificationsErrorMsg: action.error,
      };

    case MARK_NOTIF_READ_INIT:
      return {
        ...state,
        isMarkingRead: true,
        markNotifReadError: false,
      };

    case MARK_NOTIF_READ_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isMarkingRead: false,
        markNotifReadResp: data,
        unReadCount: 0,
      };
    }

    case MARK_NOTIF_READ_FAILURE:
      return {
        ...state,
        isMarkingRead: false,
        markNotifReadError: true,
        markNotifReadErrorMsg: action.error,
      };

    default:
      return state;
  }
};

export default notification;
