import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CreditIcon from '@material-ui/icons/CreditCardOutlined';
import MoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import Header from 'UI/header';
import editImg from 'assets/svg/edit.svg';
import { getInstallmentMap, filterList } from 'utils/common';
import Footer from 'UI/footer';
import Button from 'UI/button';
import LabelInput from 'UI/labelInput';
import Autocomplete from 'UI/autocomplete';
import DateButton from 'UI/dateButton';
import DragnDrop from 'UI/DragnDrop';
import DocumentCards from 'UI/documentCards';
import CustomCheckbox from 'UI/checkbox';

class MarkPayment extends Component {
  constructor(props) {
    super(props);
    const {
      request, meta,
      paymentType,
    } = this.props;
    const installments = getInstallmentMap(meta.paymentMode, request.orderInfo.orderData);
    const { installmentAmount } = this.getFirstUnpaidInstallment(installments,
      request.orderInfo.orderData);
    let agreed = true;
    this.showCheckbox = (paymentType === 'REFUND' || paymentType === 'NEW_REFUND')
      && request.status !== 'TRIP_CANCELLED';
    if (this.showCheckbox) {
      agreed = false;
    }
    this.state = {
      installmentAmount,
      surcharge: 0,
      paymentMode: {
        valid: true,
        value: 'Cashfree',
        item: {
          code: 'CASHFREE',
          title: 'Cashfree',
        },
      },
      referenceNumber: '',
      paymentDate: new Date(),
      referenceDocuments: [],
      errorMsg: '',
      valid: false,
      hasSelectedPaymentDate: false,
      agreed,
    };
    if (paymentType === 'NEW_REFUND') {
      this.state.paymentMode = {
        valid: false,
        value: '',
        item: {},
      };
    }
    this.dropInput = React.createRef();
    this.validFileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    const disabledModes = {
      PAYMENT_LINK: true,
      SMART_COLLECT: true,
      E_MANDATE: true,
    };
    this.paymentRefundTypes = [{
      title: 'Cash',
      code: 'REFUND_CASH',
    }, {
      title: 'Credit',
      code: 'CREDIT_CASH',
    }];
    this.allowedModes = (meta.paymentMode || []).filter((m) => !disabledModes[m.code]);
    this.date = moment().format('YYYY-MM-DD');
  }

  getFirstUnpaidInstallment = (installments, orderData) => {
    const { paymentType } = this.props;
    if (paymentType === 'REFUND' || paymentType === 'NEW_REFUND') {
      return { installmentAmount: orderData[0].pendingRefundAmount / 100 || 0 };
    }
    let installmentAmount = 0;
    for (let i = 0; i < installments.length; i++) {
      if (!installments[i].paid) {
        installmentAmount = installments[i].amountToBePaid || installments[i].amount;
        // paymentMode = installments[i].paymentMode;
        break;
      }
    }
    return {
      installmentAmount,
      // paymentMode,
    };
  };

  handleChange = (field, value) => {
    const { [field]: dynField } = this.state;
    if (dynField.constructor.name === 'Object') {
      this.setState({
        [field]: {
          value,
          valid: false,
          item: {},
        },
      });
    } else if (field === 'paymentDate') {
      this.setState({
        [field]: value,
        hasSelectedPaymentDate: true,
      }, this.validityChecker);
    } else {
      this.setState({ [field]: value }, this.validityChecker);
    }
  };

  handleSelection = (field, item) => {
    this.setState({
      [field]: {
        value: item.title,
        valid: true,
        item,
      },
    }, this.validityChecker);
  };

  dropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const { referenceDocuments } = this.state;
      const modDetails = [...referenceDocuments];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { type } = file;
        if (type && this.validFileTypes.some((v) => v === type)) {
          modDetails.push(file);
        }
      }
      this.setState({ referenceDocuments: modDetails }, this.validityChecker);
      e.dataTransfer.clearData();
    }
  };

  handleUpload = (e) => {
    const params = {
      preventDefault: () => {
      },
      stopPropagation: () => {
      },
      dataTransfer: {
        files: e.target.files,
        clearData: () => {
        },
      },
    };
    this.dropHandler(params);
    this.dropInput.current.value = '';
  };

  handleRemove = (index) => {
    const { referenceDocuments } = this.state;
    const modDocuments = [...referenceDocuments];
    modDocuments.splice(index, 1);
    this.setState({ referenceDocuments: modDocuments }, this.validityChecker);
  };

  renderDocs = () => {
    const { referenceDocuments } = this.state;
    if (referenceDocuments.length === 0) {
      return null;
    }
    return (
      <DocumentCards
        documents={referenceDocuments}
        onRemove={this.handleRemove}
      />
    );
  };

  validityChecker = (setError) => {
    const { paymentType, request } = this.props;
    const {
      installmentAmount, paymentMode, hasSelectedPaymentDate,
      referenceNumber, referenceDocuments,
    } = this.state;
    let valid = true;
    let errorMsg = '';
    if (request.b2bPartner) {
      if (Number(installmentAmount) === 0) {
        valid = false;
        errorMsg = 'Amount cannot be 0';
      }
    } else if (Number(installmentAmount) === 0) {
      valid = false;
      errorMsg = 'Amount cannot be 0';
    } else if (referenceNumber.trim().length === 0 && paymentType !== 'NEW_REFUND') {
      valid = false;
      errorMsg = 'Reference number cannot be empty';
    } else if (referenceDocuments.length === 0 && paymentType !== 'NEW_REFUND') {
      valid = false;
      errorMsg = 'Reference document is compulsory';
    } else if (!paymentMode.valid) {
      valid = false;
      errorMsg = 'Invalid Payment mode';
    } else if (!hasSelectedPaymentDate && paymentType !== 'NEW_REFUND') {
      valid = false;
      errorMsg = 'Payment date is not selected';
    }
    let totalRefundSoFar = 0;
    for (let i = 0; i < (request.orderInfo.orderData[0].paymentDetails || []).length; i++) {
      const detail = request.orderInfo.orderData[0].paymentDetails[i];
      if (detail.status === 'REFUND_SUCCESSFUL') {
        totalRefundSoFar += detail.amount;
      }
    }
    if ((paymentType === 'REFUND' || paymentType === 'NEW_REFUND')
      && (Number(installmentAmount)
        > ((request.orderInfo.orderData[0].amountPaid - totalRefundSoFar) / 100))) {
      valid = false;
      errorMsg = 'Refund amount cannot be more than paid amount';
    }
    if (setError) {
      this.setState({
        errorMsg,
        valid,
      });
    } else {
      this.setState({ valid });
    }
    return valid;
  };

  submitHandler = () => {
    const {
      onPaymentMark, expert,
      request, paymentType,
    } = this.props;
    const {
      installmentAmount, referenceNumber, surcharge,
      referenceDocuments, paymentDate, paymentMode,
    } = this.state;
    const valid = this.validityChecker(true);
    if (!valid) {
      return;
    }
    const { primaryCustomer } = request;
    const { productId, productType, orderId } = request.orderInfo.orderData[0];
    const refKey = paymentType === 'REFUND' ? 'refundReferenceNumber' : 'refNo';
    if (request.b2bPartner) {
      onPaymentMark({
        productId: productId._id,
        productType,
        amount: installmentAmount * 100,
      });
      return;
    }
    let params = {
      userId: primaryCustomer._id,
      productId: productId._id,
      productType,
      orderId,
      [refKey]: referenceNumber,
      amount: installmentAmount * 100,
      surcharge: surcharge * 100,
      expertId: expert._id,
      paymentMode: paymentMode.item.code,
      referenceDocuments,
      paymentDate: paymentDate.toISOString(),
    };
    if (paymentType === 'REFUND') {
      delete params.surcharge;
      params.user = params.userId;
      delete params.userId;
      params.refundDate = params.paymentDate;
      delete params.paymentDate;
    }
    if (paymentType === 'NEW_REFUND') {
      params = {
        productId: productId._id,
        productType,
        refundAmount: installmentAmount * 100,
        preferredWalletType: paymentMode.item.code,
      };
    }
    onPaymentMark(params);
  };

  dateHandler = () => {
    // const { paymentType } = this.props;
    // if (paymentType === 'REFUND') {
    //   return false;
    // }
    // if (moment(date).isSameOrAfter(this.date, 'D')) {
    //   return false;
    // }
    return false;
  };

  paymentTypeRenderer = ({ title }) => {
    const { classes } = this.props;
    const Icon = title === 'Credit' ? CreditIcon : MoneyIcon;
    return (
      <div className={classes.paymentRow}>
        <Icon className={classes.icon} />
        <Typography className={classes.primaryText}>
          {title}
        </Typography>
      </div>
    );
  };

  render() {
    const {
      classes,
      onDismiss,
      loading,
      paymentType,
      request,
      walletBalance,
    } = this.props;
    const {
      installmentAmount, paymentMode, referenceNumber,
      paymentDate, errorMsg, valid, surcharge, agreed,
    } = this.state;
    let paymentModes = this.allowedModes;
    if (paymentMode.value) {
      paymentModes = filterList(paymentMode.value, this.allowedModes, ['title']);
    }
    let paymentData = paymentModes;
    let renderer;
    let paymentMethodLabel = 'Payment method';
    if (paymentType === 'NEW_REFUND') {
      paymentData = this.paymentRefundTypes;
      renderer = this.paymentTypeRenderer;
      paymentMethodLabel = 'Preferred wallet';
    }
    return (
      <DragnDrop
        extraClass={classes.container}
        fileDropHandler={this.dropHandler}
      >
        <div className={classes.wrapper}>
          <Header
            title={paymentType === 'ACCEPT' ? 'Accept payment' : 'Refund payment'}
            onDismiss={onDismiss}
            img={editImg}
          />
          <div className={classes.column}>
            {
              request.b2bPartner ? (
                <div className={clsx(classes.row, classes.rowStart)}>
                  <Typography className={classes.label}>Partner available balance:&nbsp;</Typography>
                  {((walletBalance?.balance || 0) / 100).toFixed(2)}
                  &nbsp;
                  {walletBalance?.currencyCode || 'INR'}
                </div>
              ) : null
            }
            <div className={paymentType === 'ACCEPT' ? classes.row : classes.refundRow}>
              <LabelInput
                label="Amount"
                extraClass={paymentType === 'ACCEPT' ? classes.amountField : classes.amountFieldFull}
                value={installmentAmount}
                onChange={(val) => this.handleChange('installmentAmount', val)}
              />
              {paymentType === 'ACCEPT' && !request.b2bPartner ? (
                <LabelInput
                  label="Surcharge"
                  extraClass={classes.surchargeField}
                  value={surcharge}
                  onChange={(val) => this.handleChange('surcharge', val)}
                />
              ) : null}
            </div>
            {paymentType !== 'NEW_REFUND' && !request.b2bPartner ? (
              <>
                <LabelInput
                  label="Reference no."
                  extraClass={classes.inputField}
                  value={referenceNumber}
                  onChange={(val) => this.handleChange('referenceNumber', val)}
                />
                <LabelInput
                  label="Payment date"
                  extraClass={classes.paymentDate}
                >
                  <DateButton
                    extraClass={classes.dateButton}
                    value={paymentDate}
                    disabled={false}
                    disablePast={false}
                    shouldDisableDate={this.dateHandler}
                    onChange={(val) => this.handleChange('paymentDate', val)}
                  />
                </LabelInput>
              </>
            ) : null}
            {!request.b2bPartner ? (
              <Autocomplete
                label={paymentMethodLabel}
                extraClass={classes.autocomplete}
                inputClass={classes.autocompleteInput}
                data={paymentData}
                value={paymentMode.value}
                customRender={renderer}
                accessor="title"
                onSelected={(item) => this.handleSelection('paymentMode', item)}
                onChange={(val) => this.handleChange('paymentMode', val)}
              />
            ) : null}
            {paymentType !== 'NEW_REFUND' && !request.b2bPartner ? (
              <div className={classes.content}>
                <Typography className={classes.label}>Upload reference documents</Typography>
                <div className={classes.dropArea}>
                  <input
                    type="file"
                    accept="application/pdf,image/png,image/jpeg"
                    ref={this.dropInput}
                    className={classes.dropBox}
                    onChange={this.handleUpload}
                  />
                  {this.renderDocs()}
                </div>
              </div>
            ) : null}
            {this.showCheckbox ? (
              <CustomCheckbox
                onChange={() => this.handleChange('agreed', !agreed)}
                checked={agreed}
                label="I acknowledge that the customer has travelled and completed this trip. This amount is being refunded because a part of the service was not fulfilled."
              />
            ) : null}
          </div>
          <Footer errorMsg={errorMsg}>
            <Button
              className={!valid ? classes.invalidBtn : ''}
              loading={loading}
              disabled={!agreed}
              onClick={this.submitHandler}
            >
              {`${paymentType === 'ACCEPT' ? 'Accept' : 'Refund'} Payment`}
            </Button>
          </Footer>
        </div>
      </DragnDrop>
    );
  }
}

MarkPayment.propTypes = {
  classes: PropTypes.object,
  meta: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  expert: PropTypes.object.isRequired,
  onPaymentMark: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  paymentType: PropTypes.string.isRequired,
  walletBalance: PropTypes.object,
};

const styles = (theme) => ({
  container: {
    minWidth: 600,
    maxHeight: '90vh',
    overflowY: 'auto',
    height: 'auto',
    backgroundColor: theme.colors.white,
  },
  wrapper: {
    minWidth: 600,
  },
  column: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 40px',
    boxSizing: 'border-box',
  },
  paymentRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  icon: {
    width: 24,
    height: 24,
    color: theme.colors.green,
  },
  primaryText: {
    marginLeft: 10,
    fontSize: 16,
    color: theme.colors.black,
  },
  rowStart: {
    alignSelf: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  refundRow: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  amountFieldFull: {
    width: '100%',
  },
  amountField: {
    width: '60%',
  },
  surchargeField: {
    width: '40%',
    marginLeft: 20,
  },
  paymentDate: {
    width: '80%',
    marginBottom: 16,
  },
  autocomplete: {
    width: '80%',
    alignSelf: 'center',
    marginBottom: 16,
  },
  autocompleteInput: {
    width: '100%',
    padding: '6px 10px',
    height: 40,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    marginBottom: 10,
  },
  dateButton: {
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padding: '5px 10px',
    height: '40px',
  },
  inputField: {
    width: '80%',
    marginBottom: 16,
  },
  content: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  dropArea: {
    width: '100%',
    height: 140,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
    cursor: 'pointer',
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  uploadText: {
    fontSize: 14,
    color: theme.colors.primary,
    alignSelf: 'center',
  },
  invalidBtn: {
    backgroundColor: theme.colors.grey,
    color: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
});

export default withStyles(styles)(MarkPayment);
