import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import ReviveIcon from '@material-ui/icons/Autorenew';
import Button from 'UI/button';
import Footer from 'UI/footer';
import LabelInput from 'UI/labelInput';
import Header from 'UI/header';

const Revival = ({
  onRevive,
  onDismiss,
}) => {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const [errorMsg, setError] = useState('');
  const handleSubmit = () => {
    if (reason.trim().length === 0) {
      setError('Reason cannot be empty');
      return;
    }
    onRevive({ details: { reason } });
  };
  return (
    <div className={classes.container}>
      <Header
        title="Revive Request"
        Icon={ReviveIcon}
        onDismiss={onDismiss}
      />
      <div className={classes.body}>
        <LabelInput
          label="Reason for reviving"
          placeholder="Reason for reviving"
          inputClass={classes.input}
          inputProps={{
            multiline: true,
          }}
          value={reason}
          onChange={(e) => setReason(e)}
        />
      </div>
      <Footer errorMsg={errorMsg}>
        <Button
          onClick={handleSubmit}
        >
          Revive
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: 'column',
    minWidth: 420,
  },
  body: {
    padding: 40,
    flex: 1,
    flexDirection: 'column',
  },
  input: {
    height: 64,
  },
});

Revival.propTypes = {
  onRevive: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default Revival;
