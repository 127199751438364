import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import ErrorIcon from '../assets/icons/error';
import { colors } from './styleConsts';

const InactiveCard = ({
  onActive,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.errorContainer}>
          <ErrorIcon fill={colors.red} />
        </div>
        <Typography className={classes.title}>Are you still there?</Typography>
      </div>
      {/* <Typography className={classes.body}>
        You are offline; you will not receive any new request.
      </Typography> */}
      {/* <Typography className={classes.body1}>Click Yes to be online.</Typography> */}
      <Button
        className={classes.activeButton}
        onClick={onActive}
      >
        Yes
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px',
    maxWidth: 480,
  },
  errorContainer: {
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.redLight,
    borderRadius: '50%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    color: theme.colors.textDark,
    fontWeight: 'bold',
    fontSize: 24,
    paddingLeft: 10,
  },
  closeButton: {
    marginLeft: 'auto',
  },
  icon: {
    width: 30,
    height: 30,
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 16,
    color: theme.colors.black,
  },
  body1: {
    marginBottom: 15,
    fontSize: 16,
    color: theme.colors.black,
    display: 'flex',
    flexWrap: 'wrap',
  },
  activeButton: {
    margin: '0 auto',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    width: 140,
    borderRadius: 25,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
}));

InactiveCard.propTypes = {
  onActive: PropTypes.func.isRequired,
};

export default InactiveCard;
