import React from 'react';
import * as PropTypes from 'prop-types';

const Warning = ({
  width = 16,
  height = 16,
  className,
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
    >
      <path
        fill={fill}
        d="M6.586 1.3a2 2 0 0 1 3.424 0l5.652 9.436a2 2 0 0 1 .006 1.991c-.352.617-1.005 1.002-1.723 1h-11.3a2 2 0 0 1-1.716-1.01c-.352-.617-.35-1.375.01-2L6.586 1.3zM2.09 11.402c-.118.205-.12.458-.002.664s.335.334.565.336h11.286c.237-.003.455-.13.572-.336s.117-.458.004-.654L8.868 1.988c-.12-.2-.337-.32-.57-.32s-.448.12-.57.32l-5.64 9.416zM7.63 5.068c0-.368.298-.667.667-.667s.667.298.667.667v2.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667V5.068zm.195 5.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0 .26.682 0 .943-.682.26-.943 0z"
      />
    </svg>
  );
};

Warning.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Warning;
