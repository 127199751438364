import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import {
  UPDATE_SUPPLIER,
  GET_SUPPLIER,
} from '../../actionTypes';
import {
  updateSupplierSuccess,
  updateSupplierFailure,
  getSupplierSuccess,
  getSupplierFailure,
} from './supplierDetailActions';

function* updateSupplier(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.updateSupplier}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.success) {
      yield put(updateSupplierSuccess());
    } else {
      yield put(updateSupplierFailure(data.message));
    }
  } catch (err) {
    yield put(updateSupplierFailure(err.message));
  }
}

function* getSupplier(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getSupplierForEdit}`;
    const data = yield call(postRequest, url, params, headers);
    if (data) {
      yield put(getSupplierSuccess({ supplier: data, editAllowed: true }));
    } else {
      yield put(getSupplierFailure(data.message));
    }
  } catch (err) {
    yield put(getSupplierFailure(err.message));
  }
}

export function* updateSupplierSaga() {
  yield takeLatest(UPDATE_SUPPLIER, updateSupplier);
}

export function* getSupplierSaga() {
  yield takeLatest(GET_SUPPLIER, getSupplier);
}
