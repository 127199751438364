import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import baseStyles from './baseStyles';

const Section = ({
  type,
  flex,
  justify = 'flex-start',
  align = 'center',
  gutterBottom,
  extraClass,
  children,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes[type],
        gutterBottom && classes.gutterBottom,
        flex && classes.flex,
        extraClass,
        classes[`j${justify}`],
        classes[`a${align}`])}
    >
      {children}
    </div>
  );
};

Section.propTypes = {
  justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  children: PropTypes.node.isRequired,
  gutterBottom: PropTypes.bool,
  extraClass: PropTypes.string,
  flex: PropTypes.bool,
  type: PropTypes.oneOf(['row', 'column']),
};

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.white,
  },
  ...baseStyles,
}));

export default Section;
