import React from 'react';
import * as PropTypes from 'prop-types';

const DocumentSuccess = ({
  className,
  fill = '#2c7a77',
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 512 512"
    >
      <path
        fill={fill}
        d="M315,91H195c-8.284,0-15,6.716-15,15s6.716,15,15,15h120c8.284,0,15-6.716,15-15S323.284,91,315,91z"
      />
      <path
        fill={fill}
        d="M315,151H195c-8.284,0-15,6.716-15,15s6.716,15,15,15h120c8.284,0,15-6.716,15-15S323.284,151,315,151z"
      />
      <path
        fill={fill}
        d="M315,211H75c-8.284,0-15,6.716-15,15s6.716,15,15,15h240c8.284,0,15-6.716,15-15S323.284,211,315,211z"
      />
      <path
        fill={fill}
        d="M260.622,271H75c-8.284,0-15,6.716-15,15s6.716,15,15,15h185.622c8.284,0,15-6.716,15-15S268.906,271,260.622,271z"
      />
      <path
        fill={fill}
        d="M391,271V45c0-24.872-21.096-45-46-45H134.998c-3.803,0-7.715,1.504-10.648,4.438l-120,121C1.593,128.217,0,132.082,0,136
   v331c0,24.813,20.187,45,45,45c31.646,0,42.154,0,42.109,0c46.003,0,136.525,0,303.891,0c66.233,0,121-54.534,121-121
   C512,324.832,457.72,271,391,271z M120,51.427V121H51.002L120,51.427z M45,482c-8.271,0-15-6.729-15-15V151h105
   c8.284,0,15-6.716,15-15V30h195c8.523,0,16,7.009,16,15v229.735c-31.838,8.084-58.657,28.725-74.757,56.265H75
   c-8.284,0-15,6.716-15,15s6.716,15,15,15h198.828c-2.498,9.592-3.828,19.645-3.828,30H75c-8.284,0-15,6.716-15,15s6.716,15,15,15
   h198.805c6.063,23.457,19.172,44.599,37.882,61H45z M391,482c-49.327,0-91-41.673-91-91c0-49.626,40.822-90,91-90
   c50.178,0,91,40.374,91,90C482,440.327,440.327,482,391,482z"
      />
      <path
        fill={fill}
        d="M447.694,365.482c-5.808-5.906-15.306-5.985-21.213-0.176l-50.566,49.738l-20.484-19.823
   c-5.953-5.761-15.449-5.605-21.21,0.348s-5.605,15.449,0.348,21.21l31,30c5.847,5.659,15.144,5.625,20.95-0.085l61-60
   C453.425,380.885,453.503,371.388,447.694,365.482z"
      />
    </svg>
  );
};

DocumentSuccess.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default DocumentSuccess;
