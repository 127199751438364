import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import makeStyles from '@material-ui/styles/makeStyles';
import DiffInput from './diffInput';

const TransferDiff = ({ data, oldData }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DiffInput
          extraClass={classes.left}
          label="FROM"
          value={data.from.value}
          oldValue={oldData.from.value}
        />
        <DiffInput
          extraClass={classes.right}
          label="TO"
          value={data.to.value}
          oldValue={oldData.to.value}
        />
      </div>
      <div className={classes.row}>
        <DiffInput
          extraClass={classes.left}
          label="MODE OF TRANSFER"
          value={data.transferMode.value}
          oldValue={oldData.transferMode.value}
        />
        <DiffInput
          extraClass={classes.right}
          label="TRANSFER DATE"
          value={moment(data.transferDate).format('DD MMM YY - hh:mm A')}
          oldValue={moment(oldData.transferDate).format('DD MMM YY - hh:mm A')}
        />
      </div>
      <div className={classes.row}>
        <DiffInput
          extraClass={classes.left}
          label="DESCRIPTION"
          value={data.description}
          oldValue={oldData.description}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
}));

TransferDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default TransferDiff;
