import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import withTheme from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import InputBase from '@material-ui/core/InputBase';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import CreditIcon from '@material-ui/icons/CreditCardOutlined';
import MoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import AddAutocomplete from 'components/dashboard/addAutocomplete';
import Header from 'UI/header';
import Footer from 'UI/footer';
import Button from 'UI/button';
import CancelBookingIcon from 'assets/icons/cancelBooking';
// import Autocomplete from 'UI/autocomplete';
// import DateButton from 'UI/dateButton';

class CancelBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: {
        0: false,
        1: false,
      },
      step: 0,
      checked: -1,
      reasons: {
        0: '',
        1: '',
        2: '',
        3: '',
        // 4: '',
      },
      refundAmount: '',
      errorMsg: '',
      // preferredWalletType: {
      //   value: '',
      //   valid: false,
      //   item: {},
      // },
    };
    const { requestCancellationReasons } = this.props;
    this.steps = ['Cancellation reason', 'Refundable amount'];
    this.reasons = requestCancellationReasons;
    this.completions = [
      { name: 'Yatra' },
      { name: 'Clear trip' },
      { name: 'Airbnb' },
      { name: 'Goibibo' },
      { name: 'DIY' },
    ];
    this.preferredWallerTypes = [{
      title: 'Cash',
      code: 'REFUND_CASH',
    }, {
      title: 'Credit',
      code: 'CREDIT_CASH',
    }];
  }

  reasonChange = (index) => {
    this.setState({ checked: index });
  };

  stepChangeHandler = (index) => {
    const { step } = this.state;
    if (index !== step) {
      this.setState({ step: index });
    }
  };

  handleAmountChange = (refundAmount) => {
    this.setState({ refundAmount });
  };

  handleChange = (index, value) => {
    const { reasons } = this.state;
    const modReasons = { ...reasons };
    modReasons[index] = value;
    this.setState({ reasons: modReasons });
  };

  handleSelection = (item) => {
    const { reasons } = this.state;
    const modReasons = { ...reasons };
    modReasons['0'] = item.name;
    this.setState({ reasons: modReasons });
  };

  validateReason = (setError) => {
    const { request, cancellationType, itinerary } = this.props;
    const {
      checked, reasons, refundAmount,
      // preferredWalletType, step,
    } = this.state;
    let errorMsg = '';
    let valid = true;
    if (checked === -1) {
      errorMsg = 'Select reason for cancelling this booking';
      valid = false;
    } else if (checked === 0 && reasons['0'].length === 0) {
      errorMsg = 'Select the company customer went with';
      valid = false;
    } else if (checked === 3 && reasons['3'].trim().length === 0) {
      errorMsg = 'Enter the other reason for cancelling';
      valid = false;
    } else if (cancellationType === 'REQUEST'
      && (request.orderInfo.orderData[0].amountPaid / 100) < refundAmount) {
      errorMsg = 'Refund amount cannot be more than amount paid';
      valid = false;
    } else if (cancellationType === 'ITINERARY'
      && (itinerary.pricing.finalSellPrice / 100) < refundAmount) {
      errorMsg = 'Refund amount cannot be more than itinerary Final Selling price';
      valid = false;
    }
    // else if (step === 1 && cancellationType === 'REQUEST' && !preferredWalletType.valid) {
    //   errorMsg = 'Select preferred wallet for user to initiate refund to!!';
    //   valid = false;
    // }
    if (setError) {
      this.setState({ errorMsg });
    }
    return {
      errorMsg,
      valid,
    };
  };

  nextHandler = () => {
    const { cancelBooking, cancellationType, itinerary } = this.props;
    const {
      step, checked,
      refundAmount, reasons,
    } = this.state;
    const { valid } = this.validateReason(true);
    if (!valid) {
      return;
    }
    if (step === 0) {
      this.setState({
        completed: {
          0: true,
          1: false,
        },
        step: 1,
      });
    } else if (step === 1) {
      const { error } = this.validateReason(true);
      if (error) {
        return;
      }
      let params;
      if (cancellationType === 'REQUEST') {
        params = {
          details: {
            reason: {
              code: this.reasons[checked].code,
              value: reasons[checked],
            },
            refundAmount: refundAmount * 100,
            // preferredWalletType: preferredWalletType.item.code,
          },
        };
      } else {
        params = {
          reason: {
            code: this.reasons[checked].code,
            value: reasons[checked],
          },
          refundAmount: refundAmount * 100,
          itineraryId: itinerary._id,
        };
      }
      cancelBooking(params);
    }
  };

  // handleWalletSelection = (item) => {
  //   this.setState({
  //     preferredWalletType: {
  //       valid: true,
  //       item,
  //       value: item.title,
  //     },
  //   });
  // };

  // handleWalletChange = (value) => {
  //   this.setState({
  //     preferredWalletType: {
  //       valid: false,
  //       item: {},
  //       value,
  //     },
  //   });
  // };

  paymentTypeRenderer = ({ title }) => {
    const { classes } = this.props;
    const Icon = title === 'Credit' ? CreditIcon : MoneyIcon;
    return (
      <div className={classes.paymentRow}>
        <Icon className={classes.icon} />
        <Typography className={classes.primaryText}>
          {title}
        </Typography>
      </div>
    );
  };

  render() {
    const {
      classes, onDismiss,
      isLoading, cancellationType,
    } = this.props;
    const {
      checked, reasons, completed,
      refundAmount, step, errorMsg,
    } = this.state;
    const shouldDisabledTab = !(this.validateReason(false).valid);
    const displayText = cancellationType === 'REQUEST' ? 'Cancel booking' : 'Cancel itinerary';
    return (
      <div className={classes.container}>
        <Header
          title={displayText}
          onDismiss={onDismiss}
          Icon={CancelBookingIcon}
          className={classes.header}
        />
        <div className={classes.stepperRow}>
          <Stepper nonLinear activeStep={step} className={classes.stepper}>
            {this.steps.map((label, index) => (
              <Step active={index === step} key={label} className={classes.step}>
                <StepButton
                  active={index === step}
                  disabled={shouldDisabledTab}
                  completed={completed[index]}
                  className={classes.stepperButton}
                  onClick={() => this.stepChangeHandler(index)}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className={classes.body}>
          {step === 0 ? (
            <>
              <Typography className={classes.reasonTitle}>
                Select a reason for canceling booking
              </Typography>
              <button
                type="button"
                className={classes.radioRow}
                onClick={() => this.reasonChange(0)}
              >
                <Radio
                  checked={checked === 0}
                  color="primary"
                  className={classes.radio}
                  onClick={() => this.reasonChange(0)}
                />
                <Typography
                  className={clsx(classes.reason, checked === 0 && classes.selectedReason)}
                >
                  {this.reasons[0].title}
                </Typography>
              </button>
              {checked === 0 ? (
                <AddAutocomplete
                  data={this.completions}
                  accessor="name"
                  extraClass={classes.autoComplete}
                  value={reasons['0']}
                  onChange={(val) => this.handleChange(0, val)}
                  onSelected={(item) => this.handleSelection(item)}
                />
              ) : null}
              <button
                type="button"
                className={classes.radioRow}
                onClick={() => this.reasonChange(1)}
              >
                <Radio
                  checked={checked === 1}
                  color="primary"
                  className={classes.radio}
                  onClick={() => this.reasonChange(1)}
                />
                <Typography
                  className={clsx(classes.reason, checked === 1 && classes.selectedReason)}
                >
                  {this.reasons[1].title}
                </Typography>
              </button>
              {/* {checked === 1 ? (
                <div className={classes.dateButton}>
                  <Typography className={classes.label}>DATE</Typography>
                  <div className={classes.wrapper}>
                    <DateButton
                      value={reasons['1']}
                      onChange={(val) => this.handleChange('1', val)}
                    />
                  </div>
                </div>
              ) : null} */}
              <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(2)}>
                <Radio
                  checked={checked === 2}
                  color="primary"
                  className={classes.radio}
                  onClick={() => this.reasonChange(2)}
                />
                <Typography
                  className={clsx(classes.reason, checked === 2 && classes.selectedReason)}
                >
                  {this.reasons[2].title}
                </Typography>
              </button>
              <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(3)}>
                <Radio
                  checked={checked === 3}
                  color="primary"
                  className={classes.radio}
                  onClick={() => this.reasonChange(3)}
                />
                <Typography
                  className={clsx(classes.reason, checked === 3 && classes.selectedReason)}
                >
                  {this.reasons[3].title}
                </Typography>
              </button>
              {/*
              <button
                type="button" className={classes.radioRow} onClick={() => this.reasonChange(4)}
              >
                <Radio
                  checked={checked === 4}
                  color="primary"
                  className={classes.radio}
                  onClick={() => this.reasonChange(4)}
                />
                <Typography
                  className={clsx(classes.reason, checked === 4 && classes.selectedReason)}
                >
                  {this.reasons[4].title}
                </Typography>
              </button> */}
              {checked === 3 ? (
                <div className={classes.box}>
                  <InputBase
                    multiline
                    autoFocus
                    rows={3}
                    rowsMax={6}
                    value={reasons['3']}
                    onChange={(e) => this.handleChange('3', e.target.value)}
                    placeholder="Mention the reason"
                    classes={{
                      multiline: classes.inputAreaR,
                      focused: classes.focusedInput,
                    }}
                  />
                </div>
              ) : null}
            </>
          ) : null}
          {step === 1 ? (
            <>
              <Typography className={classes.reasonTitle}>
                Enter refundable amount (if any)
              </Typography>
              <InputBase
                autoFocus
                type="number"
                value={refundAmount}
                onChange={(e) => this.handleAmountChange(e.target.value)}
                placeholder="Refundable amount"
                className={classes.refundAmount}
                classes={{
                  focused: classes.focusedInput,
                }}
              />
              {/* <Autocomplete
                label="Preferred wallet type"
                extraClass={classes.autocomplete}
                inputClass={classes.autocompleteInput}
                data={this.preferredWallerTypes}
                value={preferredWalletType.value}
                customRender={this.paymentTypeRenderer}
                accessor="title"
                onSelected={(item) => this.handleWalletSelection(item)}
                onChange={(val) => this.handleWalletChange(val)}
              /> */}
            </>
          ) : null}
        </div>
        <Footer errorMsg={errorMsg}>
          <Button
            loading={isLoading}
            onClick={this.nextHandler}
            className={classes.submitButton}
          >
            {step === 0 ? 'Next' : displayText}
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    borderRadius: 4,
    backgroundColor: theme.colors.white,
  },
  header: {
    borderBottom: 'none',
  },
  body: {
    padding: '10px 40px 20px',
  },
  stepperRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.border}`,
    marginBottom: 10,
  },
  stepper: {
    width: '100%',
    padding: '20px 40px',
  },
  stepperButton: {
    padding: '10px 16px',
  },
  reasonTitle: {
    color: theme.colors.black,
    fontSize: 16,
    marginBottom: 20,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 18,
    color: theme.colors.black,
    margin: '0 0 10px 6px',
  },
  radioRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    outline: 'none',
    border: 'none',
    height: 32,
    cursor: 'pointer',
    backgroundColor: 'inherit',
  },
  radio: {
    padding: 4,
  },
  reason: {
    marginLeft: 6,
    color: theme.colors.textLight,
    fontSize: 16,
  },
  selectedReason: {
    color: theme.colors.black,
  },
  autoComplete: {
    width: '70%',
    marginLeft: 44,
    marginBottom: 10,
  },
  autocompleteInput: {
    width: '100%',
    padding: '6px 10px',
    height: 40,
  },
  paymentRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  icon: {
    width: 24,
    height: 24,
    color: theme.colors.green,
  },
  primaryText: {
    marginLeft: 10,
    fontSize: 16,
    color: theme.colors.black,
  },
  box: {
    marginLeft: 44,
  },
  dateButton: {
    marginLeft: 44,
    width: 180,
    marginBottom: 16,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.colors.textLight,
  },
  wrapper: {
    marginTop: 6,
    padding: 6,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
  },
  inputArea: {
    width: '100%',
  },
  inputAreaR: {
    borderRadius: 4,
    padding: '6px',
    border: `1px solid ${theme.colors.border}`,
    width: '100%',
  },
  popupClass: {
    width: 200,
    top: 32,
    left: -20,
  },
  refundAmount: {
    padding: '6px',
    width: '100%',
    borderRadius: 6,
    marginBottom: 16,
    border: `1px solid ${theme.colors.border}`,
  },
  focusedInput: {
    border: `1px solid ${theme.colors.primary}`,
  },
  submitButton: {
    minWidth: 160,
    borderRadius: 25,
    padding: '5px 10px',
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
});

CancelBooking.propTypes = {
  classes: PropTypes.object,
  request: PropTypes.object.isRequired,
  itinerary: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  requestCancellationReasons: PropTypes.array.isRequired,
  cancellationType: PropTypes.string.isRequired,
};

export default withTheme(styles)(CancelBooking);
