import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/styles/makeStyles';

const LabelHoverInput = ({
  label,
  value,
  placeholder = '',
  multiline = false,
  rows = 3,
  error,
  onChange,
  children,
  extraClass = '',
  inputClass = '',
  disabledInput = false,
}) => {
  const classes = useStyles();
  const [focused, toggleFocus] = useState(false);
  return (
    <div className={clsx(classes.container, extraClass)}>
      <Typography
        className={clsx(classes.label, focused && classes.focusedLabel)}
      >
        {label}
      </Typography>
      {children || (
        <InputBase
          value={value}
          multiline={multiline}
          error={error}
          rows={rows}
          rowsMax={rows}
          disabled={disabledInput}
          autoComplete="off"
          placeholder={placeholder}
          onFocus={() => toggleFocus(true)}
          onBlur={() => toggleFocus(false)}
          classes={{
            input: classes.input,
            focused: classes.focused,
            error: classes.error,
          }}
          className={clsx(classes.inputRoot, inputClass)}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
  },
  focusedLabel: {
    color: `${theme.colors.primary} !important`,
  },
  input: {
    padding: 0,
    height: '100%',
    fontSize: 16,
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  focused: {
    borderBottom: `1px solid ${theme.colors.primary} !important`,
  },
  invalid: {
    borderBottom: `1px solid ${theme.colors.red} !important`,
  },
  inputRoot: {
    height: 32,
    padding: '4px 0',
    width: '100%',
    border: `1px solid ${theme.colors.white}`,
    '&:hover': {
      borderBottom: `1px solid ${theme.colors.primary}`,
    },
  },
}));

LabelHoverInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  extraClass: PropTypes.string,
  inputClass: PropTypes.string,
  children: PropTypes.element,
  disabledInput: PropTypes.bool,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
};

export default LabelHoverInput;
