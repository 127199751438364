import {
  SET_SUPPLIER,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAILURE,
  GET_SUPPLIER,
  GET_SUPPLIER_FAILURE,
  GET_SUPPLIER_SUCCESS,
  // LOAD_SUPPLIERS,
} from '../../actionTypes';

const initialState = {
  supplier: {},
  editAllowed: false,
  loading: false,
  loaded: false,
  failed: false,
  updating: false,
  updateSuccess: false,
  updateFail: false,
};

const supplierDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPPLIER:
      return {
        ...state,
        supplier: action.payload.params,
      };

    case UPDATE_SUPPLIER:
      return {
        ...state,
        supplier: action.payload.params,
        updating: true,
        updateSuccess: false,
        updateFail: false,
      };

    case UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        updateFail: false,
      };

    case UPDATE_SUPPLIER_FAILURE:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        updateFail: true,
      };

    case GET_SUPPLIER:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplier: action.payload.supplier,
        editAllowed: action.payload.editAllowed,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_SUPPLIER_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default supplierDetail;
