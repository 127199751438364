import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import {
  GET_SUPPLIERS,
  ADD_SUPPLIER,
} from '../../actionTypes';
import {
  getSuppliersFailure,
  getSuppliersSuccess,
  addSupplierSuccess,
  addSupplierFailure,
} from './supplierListActions';

function* getSuppliers(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getSuppliers}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.suppliers) {
      yield put(getSuppliersSuccess(data.suppliers));
    } else {
      yield put(getSuppliersFailure(data.message));
    }
  } catch (err) {
    yield put(getSuppliersFailure(err.message));
  }
}

function* addSupplier(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.addSupplier}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.success) {
      yield put(addSupplierSuccess());
    } else {
      yield put(addSupplierFailure(data.message));
    }
  } catch (err) {
    yield put(addSupplierFailure(err.message));
  }
}

export function* supplierListSaga() {
  yield takeLatest(GET_SUPPLIERS, getSuppliers);
}

export function* addSupplierSaga() {
  yield takeLatest(ADD_SUPPLIER, addSupplier);
}
