import {
  GET_EXPERIENCES,
  GET_EXPERIENCES_SUCCESS,
  GET_EXPERIENCES_FAILURE,
} from '../../actionTypes';

export const getExperiences = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_EXPERIENCES,
    payload,
  };
};

export const getExperiencesFailure = (payload) => {
  return {
    type: GET_EXPERIENCES_FAILURE,
    payload,
  };
};

export const getExperiencesSuccess = (payload) => {
  return {
    type: GET_EXPERIENCES_SUCCESS,
    payload,
  };
};
