import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import { GET_STAYS, ADD_STAY } from '../../actionTypes';
import {
  getStaysFailure,
  getStaysSuccess,
  addStaySuccess,
  addStayFailure,
} from './stayListActions';

function* getStays(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getStays}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.stays) {
      yield put(getStaysSuccess(data.stays));
    } else {
      yield put(getStaysFailure(data.message));
    }
  } catch (err) {
    yield put(getStaysFailure(err.message));
  }
}

function* addStay(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.addStayFromGoogle}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.success) {
      yield put(addStaySuccess());
    } else {
      yield put(addStayFailure(data.message));
    }
  } catch (err) {
    yield put(addStayFailure(err.message));
  }
}

export function* stayListSaga() {
  yield takeLatest(GET_STAYS, getStays);
}

export function* addSupplierSaga() {
  yield takeLatest(ADD_STAY, addStay);
}
