import {
  GET_HOTELS, GET_HOTELS_SUCCESS, GET_HOTELS_FAILURE,
} from '../../actionTypes';

const initialState = {
  hotels: [],
  loading: false,
  loaded: false,
  failed: false,
};

const hotelList = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTELS:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_HOTELS_SUCCESS:
      return {
        ...state,
        hotels: action.payload,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_HOTELS_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default hotelList;
