import React from 'react';
import * as PropTypes from 'prop-types';

const ArrowBack = ({
  width = 14,
  height = 10,
  className,
  fill = '#000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <path fill={fill} id="A" d="M2.44 5.714L5.505 8.78c.28.28.28.73 0 1s-.73.28-1 0L.21 5.505c-.28-.28-.28-.73 0-1L4.495.21c.28-.28.73-.28 1 0s.28.73 0 1L2.44 4.286h9.704c.394 0 .714.32.714.714s-.32.714-.714.714H2.44z" />
    </svg>
  );
};

ArrowBack.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ArrowBack;
