import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import makeStyles from '@material-ui/styles/makeStyles';
import { getDateStr } from 'utils/common';

const RouteSection = ({ route }) => {
  const classes = useStyles();
  const dateStr = getDateStr(route.dates.from, route.dates.to);
  return (
    <div className={classes.titleRow}>
      <PlaceIcon className={classes.placeIcon} />
      <Typography
        className={classes.routeName}
      >
        {route.place.item.name}
      </Typography>
      <Typography
        className={classes.dateString}
      >
        {dateStr}
      </Typography>
    </div>
  );
};

RouteSection.propTypes = {
  route: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
  },
  placeIcon: {
    marginTop: 4,
    width: 26,
    height: 26,
    color: theme.colors.primary,
  },
  routeName: {
    paddingLeft: 6,
    fontSize: 24,
    color: theme.colors.black,
  },
  dateString: {
    paddingLeft: 10,
    fontSize: 12,
    alignSelf: 'flex-end',
    marginBottom: 4,
    color: theme.colors.textLight,
  },
}));

export default RouteSection;
