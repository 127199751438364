import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

const Footer = ({
  children, errorMsg,
  extraClass, align = 'left',
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.footer, extraClass)}>
      {(errorMsg && align === 'left')
        && <Typography className={classes.errorMsg}>{errorMsg}</Typography>}
      {children}
      {(errorMsg && align === 'right')
        && <Typography className={classes.errorMsg}>{errorMsg}</Typography>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  errorMsg: {
    padding: '0 16px',
    color: theme.colors.red,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
}));

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  errorMsg: PropTypes.string,
  extraClass: PropTypes.string,
  align: PropTypes.string,
};

export default Footer;
