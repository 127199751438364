import React from 'react';
import * as PropTypes from 'prop-types';

const ModifyBooking = ({
  width = 16,
  height = 16,
  className,
  fill = '#DFF2F1',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <g id="headings/ic_MODIFY" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon" fill={fill}>
          <circle id="Oval" cx="15" cy="15" r="15" />
        </g>
        <g id="noun_edit-article_1938461" transform="translate(7.000000, 8.000000)" fill="#2C7A77" fillRule="nonzero">
          <g id="Group">
            <path
              d="M16.5466667,2.95961538 C16.4380475,2.683241 16.2374699,2.45452799 15.98,2.31346154 L14.5803333,1.45384615 C14.3358943,1.3025454 14.0477818,1.24143556 13.7643333,1.28076923 C13.4605176,1.31950128 13.1910137,1.49816847 13.0333333,1.76538462 L7.93333333,10.5346154 C7.82162783,10.7241308 7.74660968,10.933675 7.71233333,11.1519231 L7.63866667,13.6961538 C7.63933088,13.9751264 7.7833674,14.2333941 8.01833333,14.3769231 C8.13473208,14.4481383 8.26786797,14.4860113 8.40366667,14.4865385 C8.54055433,14.4863465 8.67513098,14.4506041 8.79466667,14.3826923 L10.914,13.1134615 L10.982,13.0673077 C11.0551053,13.0055614 11.1233165,12.9380446 11.186,12.8653846 C11.2531605,12.7839177 11.3137991,12.6971013 11.3673333,12.6057692 L13.0333333,9.76153846 L16.4786667,3.83076923 C16.629192,3.56567747 16.6541776,3.2455845 16.5466667,2.95961538 L16.5466667,2.95961538 Z M12.2003333,9.26538462 L10.5513333,12.1153846 C10.52789,12.155897 10.5013644,12.1944766 10.472,12.2307692 L10.404,12.3 L8.62466667,13.3615385 L8.68133333,11.2903846 C8.68928237,11.2490941 8.70065646,11.2085643 8.71533333,11.1692308 C8.73224518,11.125067 8.75311084,11.0825803 8.77766667,11.0423077 L13.8776667,2.27884615 C13.952403,2.25224222 14.0352408,2.26526602 14.0986667,2.31346154 L15.521,3.17307692 C15.5894605,3.20717834 15.6393497,3.27066859 15.657,3.34615385 L12.2003333,9.26538462 Z"
              id="Shape"
            />
            <path
              d="M6.94733333,8.87307692 L2.414,8.87307692 C2.14798285,8.87307692 1.93233333,9.09262959 1.93233333,9.36346154 C1.93233333,9.63429348 2.14798285,9.85384615 2.414,9.85384615 L6.94733333,9.85384615 C7.21335049,9.85384615 7.429,9.63429348 7.429,9.36346154 C7.429,9.09262959 7.21335049,8.87307692 6.94733333,8.87307692 L6.94733333,8.87307692 Z"
              id="Path"
            />
            <path
              d="M2.42533333,6.92307692 C2.15931618,6.92307692 1.94366667,7.14262959 1.94366667,7.41346154 C1.94366667,7.68429348 2.15931618,7.90384615 2.42533333,7.90384615 L8.092,7.90384615 C8.35801715,7.90384615 8.57366667,7.68429348 8.57366667,7.41346154 C8.57366667,7.14262959 8.35801715,6.92307692 8.092,6.92307692 L2.42533333,6.92307692 Z"
              id="Path"
            />
            <path
              d="M6.23333333,13.35 L1.06533333,13.35 C1.04326183,13.3574788 1.01940484,13.3574788 0.997333333,13.35 C0.97543237,13.3290824 0.963107181,13.2998031 0.963333333,13.2692308 L0.963333333,1.66153846 C0.963107181,1.63096615 0.97543237,1.60168685 0.997333333,1.58076923 C1.01933276,1.57285048 1.04333391,1.57285048 1.06533333,1.58076923 L7.20233333,1.58076923 L9.53133333,3.95192308 L6.45433333,3.95192308 L6.45433333,3.02884615 C6.45433334,2.8536486 6.36252819,2.69175938 6.21350001,2.6041606 C6.06447182,2.51656182 5.88086152,2.51656182 5.73183333,2.6041606 C5.58280514,2.69175938 5.49099999,2.8536486 5.491,3.02884615 L5.491,4.41923077 C5.49118062,4.53523662 5.53128176,4.64751075 5.60433333,4.73653846 C5.69747237,4.84476589 5.83134559,4.90767186 5.97266667,4.90961538 L9.82033333,4.90961538 C9.99531454,4.90990212 10.1563424,4.81242286 10.2396667,4.65576923 L10.54,4.13653846 C10.6674812,3.94317421 10.6437998,3.68519694 10.4833333,3.51923077 L7.718,0.698076923 C7.67395027,0.644429585 7.61747377,0.602792599 7.55366667,0.576923077 C7.51607812,0.571478824 7.47792188,0.571478824 7.44033333,0.576923077 L1.06533333,0.576923077 C0.747739556,0.583866186 0.447587002,0.72619353 0.238,0.969230769 C0.0829338816,1.16532809 -0.00110709435,1.40979118 -1.07997055e-05,1.66153846 L-1.07997055e-05,13.2692308 C0.00217827116,13.5241194 0.0901893702,13.7705306 0.249333333,13.9673077 C0.457594693,14.2088873 0.755395041,14.3510731 1.071,14.3596154 L6.23333333,14.3596154 C6.49935049,14.3596154 6.715,14.1400627 6.715,13.8692308 C6.715,13.5983988 6.49935049,13.3788462 6.23333333,13.3788462 L6.23333333,13.35 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ModifyBooking.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ModifyBooking;
