/* eslint react/prop-types: 0 */
/* eslint no-nested-ternary: 0 */
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// import AddIcon from '@material-ui/icons/AddOutlined';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ErrorIcon from '@material-ui/icons/Error';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpIcon from '@material-ui/icons/Help';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import Button from 'UI/button';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import {
  postRequest
} from 'utils/apiRequests';
import { getFileExtension } from 'utils/common';

const uploadUrl = `${baseURL}${endPoints.uploadDocs}`;
const travellerUpdateDocUrl = `${baseURL}${endPoints.travellerUpdateDoc}`;

const upload = async (signedUrl, file) => {
  try {
    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
    });
    return true;
  } catch (err) {
    return false;
  }
};

const toDataURL = (url) => {
  return fetch(url).then((response) => {
    return response.blob();
  }).then((blob) => {
    return URL.createObjectURL(blob);
  });
};

const documentPreviewHandler = async (doc, download) => {
  if (doc.documentData instanceof File) {
    if (download) {
      try {
        const link = window.document.createElement('a');
        link.href = URL.createObjectURL(doc.documentData);
        link.download = doc.documentData.fileName || 'Download';
        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
        return;
      } catch (ex) {
        console.error(ex);
        window.open(URL.createObjectURL(doc.documentData), '_blank');
      }
    } else {
      window.open(URL.createObjectURL(doc.documentData), '_blank');
    }
  } else if (doc.documentImagePreview) {
    if (download) {
      try {
        const link = window.document.createElement('a');
        link.href = await toDataURL(doc.documentImagePreview);
        link.download = doc.documentData.fileName || 'Download';
        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
        return;
      } catch (ex) {
        console.error(ex);
        window.open(doc.documentImagePreview, '_blank');
      }
    } else {
      window.open(doc.documentImagePreview, '_blank');
    }
  }
};

const TravellerDocumentUpload = ({
  editable,
  caption,
  classes,
  request: { uuId },
  document: documentProps,
  documentExpiryDate,
  traveller,
  showSnackbar,
  headers,
  onChange,
}) => {
  const [document, setDocument] = useState(documentProps);
  const [preview, setPreview] = useState(documentProps.documentImagePreview)
  const [anchorEl, setAnchorEl] = useState(null);
  const dropInput = useRef();
  const [busy, setBusy] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? `popover-${document.documentType}` : undefined;
  const openPopover = (ev) => {
    if (busy) return;
    setAnchorEl(ev.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!editable) {
      setDocument(documentProps);
    }
  }, [editable])
  const handleUpload = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const files = dropInput.current.files;
    setBusy(true);
    setDocument({
      ...document,
      documentData: files[0],
    })
    closePopover();
    const uploadParams = {
      files: [{
        fileName: files[0].name,
        fileCategory: 'USER_DOCUMENTS',
        ttlMinutes: 60,
        entityId: traveller._id,
        entityType: 'USER',
        extension: files[0].name.match(/\w+$/g)[0],
      }],
    };
    try {
      const uploadEntries = await postRequest(uploadUrl, uploadParams, headers);
      if (uploadEntries.error) throw uploadEntries;
      const awsUpload = await Promise.all(
        uploadEntries.urls.map(async (uploadEntry, index) => {
          const file = files[index];
          const isUploaded = await upload(uploadEntry.signedUrl, file);
          if (!isUploaded) {
            throw new Error();
          }
          return uploadEntry;
        })
      );
      const data = awsUpload
        .filter((entry) => !!entry)
        .map((entry) => ({
          url: entry.signedUrl.substr(0, entry.signedUrl.indexOf('?')),
          title: entry.fileName,
          document: entry.document,
        }));
      const doc = await postRequest(`${travellerUpdateDocUrl}/${traveller._id}/documents`, {
        uuId,
        documentType: document.documentType,
        document: data[0].document,
        documentNumber: documentProps.documentNumber,
        documentExpiryDate,
        verificationStatus: 'APPROVED',
      }, headers);
      if (doc.error) throw doc;
      setDocument({
        ...document,
        ...doc,
      });
      setBusy(false);
      onChange({
        ...document,
        ...doc,
      });
      showSnackbar('Document uploaded succesfully', 'success');
    } catch (ex) {
      setDocument({
        ...documentProps,
      });
      setBusy(false);
      showSnackbar(ex.message || 'Document uploaded failed', 'error');
    }
  };

  useEffect(() => {
    if (document.documentData instanceof File) {
      setPreview(URL.createObjectURL(document.documentData));
    }
  }, [document, setPreview])

  const handleReject = async () => {
    const travellerVerifyCustomerDocument = `${baseURL}${endPoints.travellerVerifyCustomerDocument}/${traveller._id}/verifyDocument`;
    const travellerParams = {
      documentId: document.document,
      status: 'REJECTED',
    }
    closePopover();
    setBusy(true);
    const data = await postRequest(travellerVerifyCustomerDocument, travellerParams, headers);
    if (data.error) {
      showSnackbar(data.message || 'Error while rejecting', 'error');
      setBusy(false);
      return;
    }
    setDocument({ ...document, verificationStatus: data.verificationStatus || 'REJECTED' });
    onChange({ ...document, verificationStatus: data.verificationStatus || 'REJECTED' });
    showSnackbar('Status updated succesfully', 'success');
    setBusy(false);
  }

  const handleApprove = async () => {
    const travellerVerifyCustomerDocument = `${baseURL}${endPoints.travellerVerifyCustomerDocument}/${traveller._id}/verifyDocument`;
    const travellerParams = {
      documentId: document.document,
      status: 'APPROVED',
    }
    closePopover();
    setBusy(true);
    const data = await postRequest(travellerVerifyCustomerDocument, travellerParams, headers);
    if (data.error) {
      showSnackbar(data.message || 'Error while approving', 'error');
      setBusy(false);
      return;
    }
    setDocument({ ...document, verificationStatus: data.verificationStatus || 'APPROVED' });
    onChange({ ...document, verificationStatus: data.verificationStatus || 'APPROVED' })
    showSnackbar('Status updated succesfully', 'success');
    setBusy(false);
  }

  const handlePreview = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    closePopover(ev);
    setBusy(true);
    await documentPreviewHandler(document, false);
    setBusy(false);
  };
  const handleDownload = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    closePopover(ev);
    setBusy(true);
    await documentPreviewHandler(document, true);
    setBusy(false);
  };
  const handleRemove = () => { closePopover(); };
  const { type, name, fileName } = document.documentData || {};
  const docName = name || fileName || '';
  const contentType = getFileExtension(docName).contentType || type;

  let VerificationIcon = HelpIcon;
  let verificationTitle = 'Not verified'
  if (document.verificationStatus === 'APPROVED') {
    VerificationIcon = VerifiedUser;
    verificationTitle = 'Verified'
  } else if (document.verificationStatus === 'REJECTED') {
    VerificationIcon = ErrorIcon;
    verificationTitle = 'Rejected'
  }

  return (
    <div key={docName} className={classes.uploadContainer}>
      <div className={classes.filePreview}>
        {
          contentType ? (
            <object style={{ objectFit: 'cover' }} width="100%" height="100%" type={contentType} data={preview}>
              <p>Cant render the object.</p>
            </object>
          ) : null
        }
      </div>
      <div className={classes.captionContainer}>
        {
          busy ? (
            <CircularProgress size={20} className={classes.busy} />
          ) : null
        }
        <Typography variant="caption" className={classes.caption}>{caption}</Typography>
        <Tooltip
          title={verificationTitle}
          tabIndex="-1"
          className={classes.tooltip}
        >
          <VerificationIcon
            className={clsx(classes.verificationIcon, {
              [classes.approvedIcon]: document.verificationStatus === 'APPROVED',
              [classes.rejectButton]: document.verificationStatus === 'REJECTED',
            })}
          />
        </Tooltip>

        <div className={classes.documentWrapper}>
          {/*
          <img
            src={imageType}
            alt={imageType}
            className={classes.docImage}
          />
          */}
          <IconButton
            variant="plain"
            aria-describedby={id}
            className={classes.moreIcon}
            onClick={openPopover}
          >
            <MoreVertIcon className={classes.optionIcon} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            PaperProps={{
              className: classes.popperClass,
            }}
            variant="plain"
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.popoverContainer}>
              {
                !editable && document.documentData ? (
                  <Button
                    variant="plain"
                    className={classes.visibleButton}
                    onClick={handlePreview}
                  >
                    <VisibilityIcon className={classes.icon} />
                    <Typography className={classes.optionsText}>Preview</Typography>
                  </Button>
                ) : null
              }
              {
                !editable && document.documentData ? (
                  <Button
                    variant="plain"
                    className={classes.deleteButton}
                    onClick={handleDownload}
                  >
                    <GetAppIcon className={classes.icon} />
                    <Typography className={classes.optionsText}>Download</Typography>
                  </Button>
                ) : null
              }
              {
                !editable && document.verificationStatus !== 'APPROVED' && document.documentData ? (
                  <Button
                    variant="plain"
                    className={classes.visibleButton}
                    onClick={handleApprove}
                  >
                    <CheckCircleIcon className={classes.icon} />
                    <Typography className={classes.optionsText}>Approve</Typography>
                  </Button>
                ) : null
              }
              {
                !editable && document.verificationStatus !== 'REJECTED' && document.documentData ? (
                  <Button
                    variant="plain"
                    className={classes.visibleButton}
                    onClick={handleReject}
                  >
                    <BlockIcon className={clsx(classes.icon, classes.rejectButton)} />
                    <Typography className={classes.optionsText}>Reject</Typography>
                  </Button>
                ) : null
              }
              {
                editable && document.verificationStatus !== 'APPROVED' && document.documentData ? (
                  <Button
                    variant="plain"
                    className={classes.deleteButton}
                    onClick={handleRemove}
                  >
                    <DeleteIcon className={classes.icon} />
                    <Typography className={classes.optionsText}>Delete</Typography>
                  </Button>
                ) : null
              }
              {
                editable && document.verificationStatus !== 'APPROVED' ? (
                  <Button
                    variant="plain"
                    className={classes.deleteButton}
                  >
                    <CloudUploadIcon className={classes.icon} />
                    <Typography className={classes.optionsText}>
                      {!document.documentData ? 'Upload' : 'Replace'}
                    </Typography>
                    <input
                      type="file"
                      accept="application/pdf,image/png,image/jpeg"
                      ref={dropInput}
                      className={classes.dropBox}
                      onChange={handleUpload}
                    />
                  </Button>
                ) : null
              }
            </div>
          </Popover>
        </div>
      </div>
    </div>
  )
}

export default TravellerDocumentUpload;
