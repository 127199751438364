import {
  GET_NOTIFICATIONS_INIT,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  MARK_NOTIF_READ_INIT,
  MARK_NOTIF_READ_SUCCESS,
  MARK_NOTIF_READ_FAILURE,
} from '../../actionTypes';

export const getNotificationsSuccess = (data) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    data,
  };
};

export const getNotificationsFailure = (error) => {
  return {
    type: GET_NOTIFICATIONS_FAILURE,
    error,
  };
};

export const getNotifications = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_NOTIFICATIONS_INIT,
    payload,
  };
};

export const markNotifReadSuccess = (data) => {
  return {
    type: MARK_NOTIF_READ_SUCCESS,
    data,
  };
};

export const markNotifReadFailure = (error) => {
  return {
    type: MARK_NOTIF_READ_FAILURE,
    error,
  };
};

export const markNotifRead = (params, headers) => {
  const payload = { params, headers };
  return {
    type: MARK_NOTIF_READ_INIT,
    payload,
  };
};
