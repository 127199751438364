import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import { UPDATE_STAY, GET_STAY, TOGGLE_APPROVE } from '../../actionTypes';
import {
  updateStaySuccess,
  updateStayFailure,
  getStaySuccess,
  getStayFailure,
  toggleApproveSuccess,
  toggleApproveFailure,
} from './stayDetailActions';

function* updateStay(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.updateStay}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.stay) {
      yield put(updateStaySuccess(data.saveData));
    } else {
      yield put(updateStayFailure(data.message));
    }
  } catch (err) {
    yield put(updateStayFailure(err.message));
  }
}

function* toggleApprove(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.toggleApprove}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.status) {
      yield put(toggleApproveSuccess(data.editStayResult));
    } else {
      yield put(toggleApproveFailure(data.message));
    }
  } catch (err) {
    yield put(toggleApproveFailure(err.message));
  }
}

function* getStay(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getStayForEdit}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.stay) {
      yield put(getStaySuccess({ stay: data.stay, editAllowed: data.editAllowed }));
    } else {
      yield put(getStayFailure(data.message));
    }
  } catch (err) {
    yield put(getStayFailure(err.message));
  }
}

export function* updateStaySaga() {
  yield takeLatest(UPDATE_STAY, updateStay);
}

export function* getStaySaga() {
  yield takeLatest(GET_STAY, getStay);
}

export function* toggleApproveSaga() {
  yield takeLatest(TOGGLE_APPROVE, toggleApprove);
}
