import * as _ from 'lodash';
import { REMOVING_REQUEST_STATUS } from 'utils/consts';
import {
  REQUEST_LIST_INIT,
  REQUEST_LIST_SUCCESS,
  REQUEST_LIST_FAILURE,
  REQUEST_SEARCH_INIT,
  REQUEST_SEARCH_SUCCESS,
  REQUEST_SEARCH_FAILURE,
  ADD_REQUEST_INIT,
  ADD_REQUEST_SUCCESS,
  ADD_REQUEST_FAILURE,
  UPDATE_REQUEST_DETAILS_INIT,
  UPDATE_REQUEST_DETAILS_SUCCESS,
  UPDATE_REQUEST_DETAILS_FAILURE,
  REASSIGN_REQUEST_INIT,
  REASSIGN_REQUEST_SUCCESS,
  REASSIGN_REQUEST_FAILURE,
  UPDATE_STATUS_INIT,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE,
  // SUBMIT_ITINERARY_DETAILS_INIT,
  // SUBMIT_ITINERARY_DETAILS_SUCCESS,
  // SUBMIT_ITINERARY_DETAILS_FAILURE,
  DELETE_ITINERARY_INIT,
  DELETE_ITINERARY_SUCCESS,
  DELETE_ITINERARY_FAILURE,
  SEND_ITINERARY_INIT,
  RESET_DASHBOARD_RESP,
  REMARK_UPDATE_INIT,
  REMARK_UPDATE_SUCCESS,
  REMARK_UPDATE_FAILURE,
  GET_REQUEST_FILTERS_INIT,
  GET_REQUEST_FILTERS_SUCCESS,
  GET_REQUEST_FILTERS_FAILURE,
  STAR_REQUEST_INIT,
  STAR_REQUEST_SUCCESS,
  STAR_REQUEST_FAILURE,
  INTERESTED_REQUEST_INIT,
  INTERESTED_REQUEST_SUCCESS,
  INTERESTED_REQUEST_FAILURE,
  UPDATE_ORDER_INIT,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  MODIFY_INSTALLMENTS_INIT,
  MODIFY_INSTALLMENTS_SUCCESS,
  MODIFY_INSTALLMENTS_FAILURE,
  BOOKING_ACTION_INIT,
  BOOKING_ACTION_SUCCESS,
  BOOKING_ACTION_FAILURE,
  ACCEPT_PAYMENT_INIT,
  ACCEPT_PAYMENT_SUCCESS,
  ACCEPT_PAYMENT_FAILURE,
  GET_REQUEST_BY_ID_INIT,
  GET_REQUEST_BY_ID_SUCCESS,
  GET_REQUEST_BY_ID_FAILURE,
  RESEND_CONFIRMATION_INIT,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_FAILURE,
  GENERATE_VOUCHER_INIT,
  GENERATE_VOUCHER_SUCCESS,
  GENERATE_VOUCHER_FAILURE,
  REFUND_PAYMENT_INIT,
  REFUND_PAYMENT_SUCCESS,
  REFUND_PAYMENT_FAILURE,
  SYNC_STORE,
  CANCEL_ITINERARY_INIT,
  CANCEL_ITINERARY_SUCCESS,
  CANCEL_ITINERARY_FAILURE,
  REFUND_FROM_WALLET_INIT,
  REFUND_FROM_WALLET_SUCCESS,
  REFUND_FROM_WALLET_FAILURE,
  REFUND_TO_WALLET_INIT,
  REFUND_TO_WALLET_SUCCESS,
  REFUND_TO_WALLET_FAILURE,
  MARK_BOOKING_TRANSACTION_INIT,
  MARK_BOOKING_TRANSACTION_SUCCESS,
  MARK_BOOKING_TRANSACTION_FAILURE,
  CANCEL_BOOKING_INIT,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  GET_BOOKINGS_INIT,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE,
  GET_B2B_WALLET_BALANCE_INIT,
  GET_B2B_WALLET_BALANCE_SUCCESS,
  GET_B2B_WALLET_BALANCE_FAILURE,
  GET_REFUND_DATA_INIT,
  GET_REFUND_DATA_SUCCESS,
  GET_REFUND_DATA_FAILURE,
  GET_VIRTUAL_ACCOUNT_DATA_INIT,
  GET_VIRTUAL_ACCOUNT_DATA_SUCCESS,
  GET_VIRTUAL_ACCOUNT_DATA_FAILURE,
  SAVE_AS_TEMPLATE_ITINERARY_SUCCESS,
  SAVE_AS_TEMPLATE_ITINERARY_FAILURE,
} from '../../actionTypes';

const initialState = {
  isFetchingRequests: false,
  requestList: [],
  requestListError: false,
  requestListErrorMsg: '',
  isSearchingRequest: false,
  requestSearchResp: [],
  requestSearchError: false,
  requestSearchErrorMsg: '',
  isAddingRequest: false,
  addRequestResp: {},
  addRequestError: false,
  addRequestErrorMsg: '',
  isUpdatingDetails: false,
  updateRequestDetailsResp: {},
  updateRequestDetailsError: false,
  updateRequestDetailsErrorMsg: '',
  isReAssigning: false,
  reAssignRequestResp: {},
  reAssignRequestError: false,
  reAssignRequestErrorMsg: '',
  isUpdatingStatus: false,
  updateStatusResp: {},
  updateStatusError: false,
  updateStatusErrorMsg: '',
  // isSubmittingItineraryDetails: false,
  // submitItineraryDetails: {},
  // submitItineraryDetailsError: false,
  // submitItineraryDetailsErrorMsg: '',
  isDeletingItinerary: false,
  deleteItineraryResp: {},
  deleteItineraryError: false,
  deleteItineraryErrorMsg: '',
  isUpdatingRemark: false,
  updateRemarkResp: {},
  updateRemarkError: false,
  updateRemarkErrorMsg: '',
  isGettingRequestFilters: false,
  requestFiltersResp: {},
  getRequestFiltersError: false,
  getRequestFiltersErrorMsg: '',
  isStarringRequest: false,
  starRequestResp: {},
  starRequestError: false,
  starRequestErrorMsg: '',
  isUpdatingOrder: false,
  updateOrderResp: {},
  updateOrderError: false,
  updateOrderErrorMsg: '',
  isModifyingInstallments: false,
  modifyInstallmentsResp: {},
  modifyInstallmentsError: false,
  modifyInstallmentsErrorMsg: '',
  isBooking: false,
  bookingActionResp: {},
  bookingActionError: false,
  bookingActionErrorMsg: '',
  isAcceptingPayment: false,
  acceptPaymentResp: {},
  acceptPaymentError: false,
  acceptPaymentErrorMsg: '',
  isGettingRequestByID: false,
  getRequestByIDError: false,
  getRequestByIDErrorMsg: '',
  isResendingConfirmation: false,
  resendConfirmationResp: {},
  resendConfirmationError: false,
  resendConfirmationErrorMsg: '',
  isGeneratingVoucher: false,
  generateVoucherResp: {},
  generateVoucherError: false,
  generateVoucherErrorMsg: '',
  isRefundingPayment: false,
  refundPaymentResp: {},
  refundPaymentError: false,
  refundPaymentErrorMsg: '',
  isCancellingItinerary: false,
  cancelItineraryResp: {},
  cancelItineraryError: false,
  cancelItineraryErrorMsg: '',
  isRefundingFromWallet: false,
  refundFromWalletResp: {},
  refundFromWalletError: false,
  refundFromWalletErrorMsg: '',
  isRefundingToWallet: false,
  refundToWalletResp: {},
  refundToWalletError: false,
  refundToWalletErrorMsg: '',
  isMarkingBookingTransaction: false,
  markBookingTransactionResp: {},
  markBookingTransactionError: false,
  markBookingTransactionErrorMsg: '',
  isCancellingBooking: false,
  cancelBookingResp: {},
  cancelBookingError: false,
  cancelBookingErrorMsg: '',
  isGettingBookings: false,
  bookings: {},
  balances: {},
  getBookingsError: false,
  getBookingsErrorMsg: '',
  isGettingB2bWalletBalance: false,
  getB2bWalletBalanceResp: {},
  getB2bWalletBalanceError: false,
  getB2bWalletBalanceErrorMsg: '',
  isGettingRefundData: false,
  refundData: {},
  getRefundDataError: false,
  getRefundDataErrorMsg: '',
  isGettingVirtualAccountData: false,
  virtualAccountData: {},
  getVirtualAccountDataError: false,
  getVirtualAccountDataErrorMsg: '',
};

const dashboard = (state = initialState, action) => {
  console.log('action ', action.type);
  switch (action.type) {
    case REQUEST_LIST_INIT:
      return {
        ...state,
        isFetchingRequests: true,
        requestListError: false,
      };

    case REQUEST_LIST_SUCCESS: {
      const { data } = action;
      let { requests } = data;
      const {
        kind,
        users,
        places,
        experts,
      } = data;
      const userMap = _.keyBy(users, (u) => u._id);
      const placeMap = _.keyBy(places, (p) => p._id);
      const expertMap = _.keyBy(experts, (p) => p._id);
      requests = requests.map((request) => {
        const r = request;
        r.primaryCustomer = userMap[r.primaryCustomer];
        r.requirement.places = (r.requirement.places || []).map((p) => {
          return placeMap[p];
        });
        r.expert = expertMap[r.expert];
        return r;
      });

      if (kind === 'append') {
        requests = _.concat(state.requestList, requests);
      }

      return {
        ...state,
        isFetchingRequests: false,
        requestList: requests,
      };
    }

    case REQUEST_LIST_FAILURE:
      return {
        ...state,
        isFetchingRequests: false,
        requestListError: true,
        requestListErrorMsg: action.error,
      };

    case REQUEST_SEARCH_INIT:
      return {
        ...state,
        isSearchingRequest: true,
        requestSearchError: false,
      };

    case REQUEST_SEARCH_SUCCESS: {
      const { data } = action;
      let { requests } = data;
      const { users, places, experts } = data;
      const userMap = _.keyBy(users, (u) => u._id);
      const placeMap = _.keyBy(places, (p) => p._id);
      const expertMap = _.keyBy(experts, (p) => p._id);
      requests = requests.map((request) => {
        const r = request;
        r.primaryCustomer = userMap[r.primaryCustomer];
        r.requirement.places = (r.requirement.places || []).map((p) => {
          return placeMap[p];
        });
        r.expert = expertMap[r.expert];
        return r;
      });
      return {
        ...state,
        isSearchingRequest: false,
        requestSearchResp: requests || [],
      };
    }

    case REQUEST_SEARCH_FAILURE:
      return {
        ...state,
        isSearchingRequest: false,
        requestSearchError: true,
        requestSearchErrorMsg: action.error,
      };

    case ADD_REQUEST_INIT:
      return {
        ...state,
        isAddingRequest: true,
        addRequestError: false,
        addRequestResp: {},
      };

    case ADD_REQUEST_SUCCESS: {
      const { data } = action;
      const modRequestList = [...state.requestList];
      let changes = {};
      if (!data.isReAssigning) {
        const modRequests = [data.request[0], ...modRequestList];
        changes = { requestList: modRequests };
      }
      return {
        ...state,
        isAddingRequest: false,
        addRequestResp: {
          ...data.request[0],
          message: data.message,
        },
        ...changes,
      };
    }

    case ADD_REQUEST_FAILURE:
      return {
        ...state,
        isAddingRequest: false,
        addRequestError: true,
        addRequestErrorMsg: action.error,
      };

    case UPDATE_REQUEST_DETAILS_INIT:
      return {
        ...state,
        isUpdatingDetails: true,
        updateRequestDetailsResp: {},
        updateRequestDetailsError: false,
      };

    case UPDATE_REQUEST_DETAILS_SUCCESS: {
      const { data } = action;
      const { request } = data;
      let modRequests = [...state.requestList];
      let requestIndex = modRequests.findIndex((req) => req._id === request[0]._id);
      const changes = {};
      if (requestIndex === -1) { // update in requestSearch resp
        modRequests = [...state.requestSearchResp];
        requestIndex = modRequests.findIndex((req) => req._id === request[0]._id);
        modRequests[requestIndex] = { ...request[0] };
        changes.requestSearchResp = modRequests;
      } else {
        modRequests[requestIndex] = { ...request[0] };
        changes.requestList = modRequests;
      }
      return {
        ...state,
        ...changes,
        isUpdatingDetails: false,
        updateRequestDetailsResp: data,
      };
    }

    case UPDATE_REQUEST_DETAILS_FAILURE:
      return {
        ...state,
        isUpdatingDetails: false,
        updateRequestDetailsError: true,
        updateRequestDetailsErrorMsg: action.error,
      };

    case REASSIGN_REQUEST_INIT:
      return {
        ...state,
        isReAssigning: true,
        reAssignRequestResp: {},
        reAssignRequestError: false,
      };

    case REASSIGN_REQUEST_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isReAssigning: false,
        reAssignRequestResp: data,
      };
    }

    case REASSIGN_REQUEST_FAILURE:
      return {
        ...state,
        isReAssigning: false,
        reAssignRequestError: true,
        reAssignRequestErrorMsg: action.error,
      };

    case SEND_ITINERARY_INIT:
      return {
        ...state,
        isUpdatingStatus: true,
        updateStatusResp: {},
        updateStatusError: false,
      };

    case UPDATE_STATUS_INIT:
      return {
        ...state,
        isUpdatingStatus: true,
        updateStatusResp: {},
        updateStatusError: false,
      };

    case UPDATE_STATUS_SUCCESS: {
      const { data } = action;
      const { requestList, requestSearchResp } = state;
      let modRequests = [...requestList];
      const request = data.request[0];
      let requestIndex = modRequests.findIndex((r) => r._id === request._id);
      const changes = {};
      if (requestIndex !== -1) {
        if (REMOVING_REQUEST_STATUS[request.status] || data.requestAction === 'REASSIGN') {
          if (!data.skip) {
            modRequests.splice(requestIndex, 1);
          }
        } else {
          modRequests[requestIndex] = { ...request };
        }
        changes.requestList = modRequests;
      }
      modRequests = [...requestSearchResp];
      requestIndex = modRequests.findIndex((r) => r._id === request._id);
      // update in search resp
      if (requestIndex !== -1) {
        if (REMOVING_REQUEST_STATUS[request.status] || data.requestAction === 'REASSIGN') {
          if (!data.skip) {
            modRequests.splice(requestIndex, 1);
          }
        } else {
          modRequests[requestIndex] = { ...request };
        }
        changes.requestSearchResp = modRequests;
      }
      return {
        ...state,
        ...changes,
        isUpdatingStatus: false,
        updateStatusResp: {
          request,
          message: data.message,
        },
      };
    }

    case UPDATE_STATUS_FAILURE:
      return {
        ...state,
        isUpdatingStatus: false,
        updateStatusError: true,
        updateStatusErrorMsg: action.error,
      };

    case DELETE_ITINERARY_INIT:
      return {
        ...state,
        deleteItineraryResp: {},
        isDeletingItinerary: true,
        deleteItineraryError: false,
      };

    case DELETE_ITINERARY_SUCCESS: {
      const { data } = action;
      const changes = {};
      const updateKey = data.requestType === 'SEARCH' ? 'requestSearchResp' : 'requestList';
      const modList = [...state[updateKey]];
      const requestIndex = modList.findIndex((req) => req._id === data.requestId);
      // const modRequest = { ...modList[requestIndex] };
      // modRequest.itineraries = modRequest.itineraries.filter((itn) => itn._id !== data.id);
      modList[requestIndex] = data.request[0];
      changes[updateKey] = modList;
      return {
        ...state,
        ...changes,
        isDeletingItinerary: false,
        deleteItineraryResp: data,
      };
    }

    case DELETE_ITINERARY_FAILURE:
      return {
        ...state,
        isDeletingItinerary: false,
        deleteItineraryError: true,
        deleteItineraryErrorMsg: action.error,
      };

    case SAVE_AS_TEMPLATE_ITINERARY_SUCCESS: {
      const { data } = action;
      const { itinerary } = data;
      const updateKey = data.requestType === 'SEARCH' ? 'requestSearchResp' : 'requestList';
      const list = [...state[updateKey]];
      const requestIndex = list.findIndex((req) => req._id === itinerary.request);
      const request = list.find((req) => req._id === itinerary.request);
      request.itineraries = request.itineraries.map((i) => {
        if (i._id === itinerary._id) {
          return itinerary;
        }
        return i;
      });
      list[requestIndex] = request;
      return {
        ...state,
        [updateKey]: list,
      };
    }

    case SAVE_AS_TEMPLATE_ITINERARY_FAILURE:
      return {
        ...state,
      };

    case RESET_DASHBOARD_RESP:
      return {
        ...state,
        addRequestResp: {},
        addRequestError: false,
        addRequestErrorMsg: '',
        isUpdatingStatus: false,
        updateStatusResp: {},
        updateStatusError: false,
        resendConfirmationResp: {},
        resendConfirmationError: false,
      };

    case REMARK_UPDATE_INIT:
      return {
        ...state,
        isUpdatingRemark: true,
        updateRemarkResp: {},
        updateRemarkError: false,
        updateRemarkErrorMsg: '',
      };

    case REMARK_UPDATE_SUCCESS: {
      const { data } = action;
      const { remark } = data;
      const requestById = _.cloneDeep(state.requestById);
      const remarkIndex = (requestById.remarks || []).findIndex((r) => r._id === remark._id);
      if (remarkIndex === -1) {
        requestById.remarks.push(remark);
      } else {
        requestById.remarks[remarkIndex] = remark;
      }
      return {
        ...state,
        requestById,
        isUpdatingRemark: false,
        updateRemarkResp: data,
      };
    }

    case REMARK_UPDATE_FAILURE:
      return {
        ...state,
        isUpdatingRemark: false,
        updateRemarkError: true,
        updateRemarkErrorMsg: action.error,
      };

    case GET_REQUEST_FILTERS_INIT:
      return {
        ...state,
        isGettingRequestFilters: false,
        getRequestFiltersError: false,
        getRequestFiltersErrorMsg: '',
      };

    case GET_REQUEST_FILTERS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingRequestFilters: false,
        requestFiltersResp: data,
      };
    }

    case GET_REQUEST_FILTERS_FAILURE:
      return {
        ...state,
        isGettingRequestFilters: false,
        getRequestFiltersError: true,
        getRequestFiltersErrorMsg: action.error,
      };

    case STAR_REQUEST_INIT:
      return {
        ...state,
        isStarringRequest: true,
        starRequestError: false,
      };

    case STAR_REQUEST_SUCCESS: {
      const { data } = action;
      let changes = {};
      if (data.searchUpdate) {
        const modRequests = [...state.requestSearchResp];
        const index = modRequests.findIndex((req) => req._id === data.requestId);
        modRequests[index] = {
          ...modRequests[index],
          starred: data.starred,
        };
        changes = { requestSearchResp: modRequests };
      } else {
        const modRequests = [...state.requestList];
        const index = modRequests.findIndex((req) => req._id === data.requestId);
        modRequests[index] = {
          ...modRequests[index],
          starred: data.starred,
        };
        changes = { requestList: modRequests };
      }
      return {
        ...state,
        isStarringRequest: false,
        starRequestResp: data,
        ...changes,
      };
    }
    case STAR_REQUEST_FAILURE: {
      return {
        ...state,
        isStarringRequest: false,
        starRequestError: true,
        starRequestErrorMsg: action.error,
      };
    }

    case INTERESTED_REQUEST_INIT:
      return state;

    case INTERESTED_REQUEST_SUCCESS: {
      const { data } = action;
      let changes = {};
      if (data.searchUpdate) {
        const modRequests = [...state.requestSearchResp];
        const index = modRequests.findIndex((req) => req._id === data.requestId);
        modRequests[index] = {
          ...modRequests[index],
          interested: data.interested,
        };
        changes = { requestSearchResp: modRequests };
      } else {
        const modRequests = [...state.requestList];
        const index = modRequests.findIndex((req) => req._id === data.requestId);
        modRequests[index] = {
          ...modRequests[index],
          interested: data.interested,
        };
        changes = { requestList: modRequests };
      }
      return {
        ...state,
        // isStarringRequest: false,
        // starRequestResp: data,
        ...changes,
      };
    }

    case INTERESTED_REQUEST_FAILURE: {
      return state;
    }

    case UPDATE_ORDER_INIT:
      return {
        ...state,
        isUpdatingOrder: true,
        updateOrderError: false,
      };

    case UPDATE_ORDER_SUCCESS: {
      const { data } = action;
      const { _id, orderInfo } = data;
      let modRequests = [...state.requestList];
      let requestIndex = modRequests.findIndex((rq) => rq._id === _id);
      const changes = {};
      if (requestIndex !== -1) {
        modRequests[requestIndex].orderInfo.orderData[0] = {
          ...orderInfo.orderData[0],
          installments: [...orderInfo.orderData[0].installments],
        };
        changes.requestList = modRequests;
      } else {
        modRequests = [...state.requestSearchResp];
        requestIndex = modRequests.findIndex((req) => req._id === _id);
        modRequests[requestIndex].orderInfo.orderData[0] = {
          ...orderInfo.orderData[0],
          installments: [...orderInfo.orderData[0].installments],
        };
        changes.requestSearchResp = modRequests;
      }
      return {
        ...state,
        ...changes,
        isUpdatingOrder: false,
        updateOrderResp: data,
      };
    }

    case UPDATE_ORDER_FAILURE:
      return {
        ...state,
        isUpdatingOrder: false,
        updateOrderError: true,
        updateOrderErrorMsg: action.error,
      };

    case MODIFY_INSTALLMENTS_INIT:
      return {
        ...state,
        isModifyingInstallments: true,
        modifyInstallmentsError: false,
      };

    case MODIFY_INSTALLMENTS_SUCCESS: {
      const { data } = action;
      const { _id, orderInfo } = data;
      let modRequests = [...state.requestList];
      let requestIndex = modRequests.findIndex((rq) => rq._id === _id);
      const changes = {};
      if (requestIndex !== -1) {
        modRequests[requestIndex].orderInfo.orderData[0] = {
          ...orderInfo.orderData[0],
          installments: [...orderInfo.orderData[0].installments],
        };
        changes.requestList = modRequests;
      } else {
        modRequests = [...state.requestSearchResp];
        requestIndex = modRequests.findIndex((req) => req._id === _id);
        modRequests[requestIndex].orderInfo.orderData[0] = {
          ...orderInfo.orderData[0],
          installments: [...orderInfo.orderData[0].installments],
        };
        changes.requestSearchResp = modRequests;
      }
      return {
        ...state,
        ...changes,
        isModifyingInstallments: false,
        modifyInstallmentsResp: data,
      };
    }

    case MODIFY_INSTALLMENTS_FAILURE:
      return {
        ...state,
        isModifyingInstallments: false,
        modifyInstallmentsError: true,
        modifyInstallmentsErrorMsg: action.error,
      };

    case BOOKING_ACTION_INIT:
      return {
        ...state,
        isBooking: true,
        bookingActionError: false,
      };

    case BOOKING_ACTION_SUCCESS: {
      const { data } = action;
      const { request, requestId } = data;
      let modRequests = [...state.requestList];
      let requestIndex = modRequests.findIndex((rq) => rq._id === requestId);
      const changes = {};
      if (requestIndex === -1) {
        requestIndex = state.requestSearchResp.findIndex((r) => r._id === requestId);
        modRequests = [...state.requestSearchResp];
        // const bookingIndex = modRequests[requestIndex]
        //   .bookings.findIndex((b) => b._id === booking._id);
        // const modBookings = [...modRequests[requestIndex].bookings];
        // modBookings[bookingIndex] = booking;
        modRequests[requestIndex] = request[0];
        changes.requestSearchResp = [...modRequests];
      } else {
        // const bookingIndex = modRequests[requestIndex]
        //   .bookings.findIndex((b) => b._id === booking._id);
        // const modBookings = [...modRequests[requestIndex].bookings];
        // modBookings[bookingIndex] = booking;
        modRequests[requestIndex] = request[0];
        changes.requestList = [...modRequests];
      }
      return {
        ...state,
        isBooking: false,
        bookingActionResp: data,
        ...changes,
      };
    }

    case BOOKING_ACTION_FAILURE:
      return {
        ...state,
        isBooking: false,
        bookingActionError: true,
        bookingActionErrorMsg: action.error,
      };

    case ACCEPT_PAYMENT_INIT:
      return {
        ...state,
        isAcceptingPayment: true,
        acceptPaymentError: false,
      };

    case ACCEPT_PAYMENT_SUCCESS: {
      const { data } = action;
      const { refData: { type, requestId }, orderInfo } = data;
      const changes = {};
      const updateKey = type === 'SEARCH' ? 'requestSearchResp' : 'requestList';
      const modRequests = [...state[updateKey]];
      const requestIndex = modRequests.findIndex((rq) => rq._id === requestId);
      modRequests[requestIndex].orderInfo.orderData[0] = {
        ...orderInfo.orderData[0],
        installments: [...orderInfo.orderData[0].installments],
      };
      modRequests[requestIndex].status = orderInfo.orderData[0].request.status;
      changes[updateKey] = modRequests;
      return {
        ...state,
        ...changes,
        isAcceptingPayment: false,
        acceptPaymentResp: data,
      };
    }

    case ACCEPT_PAYMENT_FAILURE:
      return {
        ...state,
        isAcceptingPayment: false,
        acceptPaymentError: true,
        acceptPaymentErrorMsg: action.error,
      };

    case GET_REQUEST_BY_ID_INIT:
      return {
        ...state,
        isFetchingRequests: true,
        isGettingRequestByID: true,
        getRequestByIDError: false,
      };

    case GET_REQUEST_BY_ID_SUCCESS: {
      const { data } = action;
      const { request } = data;
      // const requestList = _.isEmpty(state.requestList)
      //   ? [request[0]]
      //   : _.cloneDeep(state.requestList)?.map((r) => (r._id.toString() === request[0]._id.toString() ? request[0] : r));
      // const requestSearchResp = _.isEmpty(state.requestSearchResp)
      //   ? [request[0]]
      //   :_.cloneDeep(state.requestSearchResp)?.map((r) => (r._id.toString() === request[0]._id.toString() ? request[0] : r));
      return {
        ...state,
        isFetchingRequests: false,
        isGettingRequestByID: false,
        // requestList,
        // requestSearchResp,
        requestById: request[0],
      };
    }

    case GET_REQUEST_BY_ID_FAILURE:
      return {
        ...state,
        isGettingRequestByID: false,
        isFetchingRequests: false,
        getRequestByIDError: true,
        getRequestByIDErrorMsg: action.error,
      };

    case RESEND_CONFIRMATION_INIT:
      return {
        ...state,
        isResendingConfirmation: true,
        resendConfirmationError: false,
      };

    case RESEND_CONFIRMATION_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isResendingConfirmation: false,
        resendConfirmationResp: data,
      };
    }

    case RESEND_CONFIRMATION_FAILURE:
      return {
        ...state,
        isResendingConfirmation: false,
        resendConfirmationError: true,
        resendConfirmationErrorMsg: action.error,
      };

    case GENERATE_VOUCHER_INIT:
      return {
        ...state,
        isGeneratingVoucher: true,
        generateVoucherError: false,
        isUpdatingStatus: true,
      };

    case GENERATE_VOUCHER_SUCCESS: {
      const { data } = action;
      const changes = {};
      const updateKey = data.updateKey === 'SEARCH' ? 'requestSearchResp' : 'requestList';
      const modRequests = [...state[updateKey]];
      const requestIndex = modRequests.findIndex((rq) => rq._id === data.request[0]._id);
      modRequests[requestIndex] = data.request[0];
      changes[updateKey] = modRequests;
      return {
        ...state,
        isGeneratingVoucher: false,
        isUpdatingStatus: false,
        generateVoucherResp: data,
        ...changes,
      };
    }

    case GENERATE_VOUCHER_FAILURE:
      return {
        ...state,
        isGeneratingVoucher: false,
        generateVoucherError: true,
        isUpdatingStatus: false,
        generateVoucherErrorMsg: action.error,
      };

    case REFUND_PAYMENT_INIT:
      return {
        ...state,
        isRefundingPayment: true,
        refundPaymentError: false,
      };

    case REFUND_PAYMENT_SUCCESS: {
      const { data } = action;
      const { refData: { type, requestId }, orderInfo } = data;
      const changes = {};
      const updateKey = type === 'SEARCH' ? 'requestSearchResp' : 'requestList';
      const modRequests = [...state[updateKey]];
      const requestIndex = modRequests.findIndex((rq) => rq._id === requestId);
      modRequests[requestIndex].orderInfo.orderData[0] = {
        ...orderInfo.orderData[0],
        installments: [...orderInfo.orderData[0].installments],
      };
      modRequests[requestIndex].status = orderInfo.orderData[0].request.status;
      changes[updateKey] = modRequests;
      return {
        ...state,
        ...changes,
        isRefundingPayment: false,
        refundPaymentResp: data,
      };
    }

    case REFUND_PAYMENT_FAILURE:
      return {
        ...state,
        isRefundingPayment: false,
        refundPaymentError: true,
        refundPaymentErrorMsg: action.error,
      };

    case CANCEL_ITINERARY_INIT:
      return {
        ...state,
        isCancellingItinerary: true,
        cancelItineraryError: false,
      };

    case CANCEL_ITINERARY_SUCCESS: {
      const { data } = action;
      const { request, searchKey } = data;
      const changes = {};
      const updateKey = searchKey === 'SEARCH' ? 'requestSearchResp' : 'requestList';
      const modRequests = [...state[updateKey]];
      const requestIndex = modRequests.findIndex((rq) => rq._id === request[0]._id);
      modRequests[requestIndex] = request[0];
      changes[updateKey] = modRequests;
      return {
        ...state,
        isCancellingItinerary: false,
        cancelItineraryResp: data,
        ...changes,
      };
    }

    case CANCEL_ITINERARY_FAILURE:
      return {
        ...state,
        isCancellingItinerary: false,
        cancelItineraryError: true,
        cancelItineraryErrorMsg: action.error,
      };

    case REFUND_FROM_WALLET_INIT:
      return {
        ...state,
        isRefundingFromWallet: true,
        refundFromWalletResp: {},
        refundFromWalletError: false,
      };

    case REFUND_FROM_WALLET_SUCCESS: {
      const { data } = action;
      // const { refData: { type, requestId } } = data;
      // const changes = {};
      // const updateKey = type === 'SEARCH' ? 'requestSearchResp' : 'requestList';
      // const modRequests = [...state[updateKey]];
      // const requestIndex = modRequests.findIndex((rq) => rq._id === requestId);
      // modRequests[requestIndex].orderInfo.orderData[0] = {
      //   ...orderInfo.orderData[0],
      //   installments: [...orderInfo.orderData[0].installments],
      // };
      // modRequests[requestIndex].status = orderInfo.orderData[0].request.status;
      // changes[updateKey] = modRequests;
      return {
        ...state,
        // ...changes,
        isRefundingFromWallet: false,
        refundFromWalletResp: data,
      };
    }

    case REFUND_FROM_WALLET_FAILURE:
      return {
        ...state,
        isRefundingFromWallet: false,
        refundFromWalletError: true,
        refundFromWalletErrorMsg: action.error,
      };

    case REFUND_TO_WALLET_INIT:
      return {
        ...state,
        isRefundingToWallet: true,
        refundToWalletError: false,
      };

    case REFUND_TO_WALLET_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isRefundingToWallet: false,
        refundToWalletResp: data,
      };
    }

    case REFUND_TO_WALLET_FAILURE:
      return {
        ...state,
        isRefundingToWallet: false,
        refundToWalletError: true,
        refundToWalletErrorMsg: action.error,
      };

    case MARK_BOOKING_TRANSACTION_INIT:
      return {
        ...state,
        isMarkingBookingTransaction: true,
        markBookingTransactionError: false,
      };

    case MARK_BOOKING_TRANSACTION_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isMarkingBookingTransaction: false,
        markBookingTransactionResp: data,
      };
    }

    case MARK_BOOKING_TRANSACTION_FAILURE:
      return {
        ...state,
        isMarkingBookingTransaction: false,
        markBookingTransactionError: true,
        markBookingTransactionErrorMsg: action.error,
      };

    case CANCEL_BOOKING_INIT:
      return {
        ...state,
        isCancellingBooking: true,
        cancelBookingError: false,
      };

    case CANCEL_BOOKING_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isCancellingBooking: false,
        cancelBookingResp: data,
      };
    }

    case CANCEL_BOOKING_FAILURE:
      return {
        ...state,
        isCancellingBooking: false,
        cancelBookingError: true,
        cancelBookingErrorMsg: action.error,
      };

    case GET_BOOKINGS_INIT: {
      const { payload: { params } } = action;
      return {
        ...state,
        isGettingBookings: true,
        getBookingsError: false,
        bookings: {
          ...state.bookings,
          [params.requestId]: [],
        },
      };
    }

    case GET_BOOKINGS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingBookings: false,
        bookings: {
          ...state.bookings,
          [data.requestId]: data.bookings || [],
        },
        balances: {
          ...state.balances,
          [data.requestId]: data.balance,
        },
      };
    }

    case GET_BOOKINGS_FAILURE: {
      const { error: { error, requestId } } = action;
      return {
        ...state,
        isGettingBookings: false,
        getBookingsError: true,
        getBookingsErrorMsg: error,
        bookings: {
          ...state.bookings,
          [requestId]: [],
        },
      };
    }

    case GET_B2B_WALLET_BALANCE_INIT:
      return {
        ...state,
        isGettingB2bWalletBalance: true,
        getB2bWalletBalanceResp: {},
        getB2bWalletBalanceError: false,
      };

    case GET_B2B_WALLET_BALANCE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingB2bWalletBalance: false,
        getB2bWalletBalanceResp: data,
      };
    }

    case GET_B2B_WALLET_BALANCE_FAILURE:
      return {
        ...state,
        isGettingB2bWalletBalance: false,
        getB2bWalletBalanceError: true,
        getB2bWalletBalanceErrorMsg: action.error,
      };

    case GET_REFUND_DATA_INIT: {
      const { productId } = action.payload.params;
      return {
        ...state,
        isGettingRefundData: true,
        getRefundDataError: false,
        refundData: {
          ...state.refundData,
          [productId]: {},
        },
      };
    }

    case GET_REFUND_DATA_SUCCESS: {
      const { data } = action;
      const modRequest = [...state.requestList];
      const requestIndex = modRequest.findIndex((r) => r._id === data.productId);
      if (requestIndex !== -1) {
        modRequest[requestIndex] = {
          ...modRequest[requestIndex],
          refundData: data,
        };
      }
      return {
        ...state,
        isGettingRefundData: false,
        refundData: {
          ...state.refundData,
          [data.productId]: data,
        },
      };
    }

    case GET_REFUND_DATA_FAILURE: {
      const { error, productId } = action;
      return {
        ...state,
        isGettingRefundData: false,
        getRefundDataError: true,
        getRefundDataErrorMsg: error,
        refundData: {
          ...state.refundData,
          [productId]: {
            error: true,
          },
        },
      };
    }

    case GET_VIRTUAL_ACCOUNT_DATA_INIT: {
      const { requestId } = action.payload.params;
      return {
        ...state,
        isGettingRefundData: true,
        getRefundDataError: false,
        virtualAccountData: {
          ...state.virtualAccountData,
          [requestId]: {},
        },
      };
    }

    case GET_VIRTUAL_ACCOUNT_DATA_SUCCESS: {
      const { data } = action;
      const modRequest = [...state.requestList];
      const requestIndex = modRequest.findIndex((r) => r._id === data.requestId);
      if (requestIndex !== -1) {
        modRequest[requestIndex] = {
          ...modRequest[requestIndex],
          virtualAccountData: data,
        };
      }
      return {
        ...state,
        isGettingVirtualAccountData: false,
        virtualAccountData: {
          ...state.virtualAccountData,
          [data.requestId]: data,
        },
      };
    }

    case GET_VIRTUAL_ACCOUNT_DATA_FAILURE: {
      const { error, requestId } = action;
      alert('Fail');
      return {
        ...state,
        isGettingVirtualAccountData: false,
        getVirtualAccountDataError: true,
        getVirtualAccountDataErrorMsg: error,
        virtualAccountData: {
          ...state.virtualAccountData,
          [requestId]: {
            error: true,
          },
        },
      };
    }

    case SYNC_STORE: {
      return _.merge(_.cloneDeep(state), action.data.dashboard);
    }

    default:
      return state;
  }
};

export default dashboard;
