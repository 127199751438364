import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'UI/button';

const PartActionButton = ({
  tab,
  mode,
  actionHandler,
  removeArgs,
  editArgs,
  isDeleted,
  itineraryActionType,
}) => {
  const classes = useStyles();
  const isEditable = !(mode === 'view' || itineraryActionType === 'view'
    || isDeleted || (itineraryActionType === 'generate_voucher' && tab !== 'FLIGHT'));
  return (
    <>
      <div className={classes.actionArea}>
        {isEditable ? (
          <Tooltip
            arrow
            placement="top"
            title="Edit"
          >
            <Button
              variant="plain"
              className={classes.actionBtn}
              onClick={() => actionHandler('edit', ...editArgs)}
            >
              <EditIcon className={classes.arrowIcon} />
            </Button>
          </Tooltip>
        ) : null}
        {(mode === 'edit' && !isDeleted && itineraryActionType !== 'generate_voucher') ? (
          <Tooltip
            arrow
            placement="top"
            title="Delete"
          >
            <Button
              variant="plain"
              className={clsx(classes.actionBtn, classes.removeBtn)}
              onClick={() => actionHandler('remove', ...removeArgs)}
            >
              <DeleteIcon className={classes.deleteIcon} />
            </Button>
          </Tooltip>
        ) : null}
      </div>
      <Button
        variant="plain"
        className={classes.viewBtn}
        onClick={() => actionHandler('view', ...editArgs)}
      >
        View details
        <RightIcon className={classes.arrowIcon} />
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionArea: {
    position: 'absolute',
    right: 10,
    top: 6,
    display: 'flex',
    flexDirection: 'row',
  },
  removeBtn: {
    color: theme.colors.red,
  },
  actionBtn: {
    fontSize: 10,
    height: 30,
    boxSizing: 'border-box',
    padding: '4px 8px',
    marginRight: 8,
  },
  viewBtn: {
    fontSize: 12,
    height: 30,
    position: 'absolute',
    bottom: 6,
    right: 10,
  },
  arrowIcon: {
    height: 16,
    width: 16,
    color: theme.colors.primary,
  },
  deleteIcon: {
    height: 16,
    width: 16,
    color: theme.colors.red,
  },
}));

PartActionButton.propTypes = {
  mode: PropTypes.string,
  actionHandler: PropTypes.func.isRequired,
  removeArgs: PropTypes.array,
  editArgs: PropTypes.array,
  isDeleted: PropTypes.bool,
  itineraryActionType: PropTypes.string.isRequired,
  tab: PropTypes.string,
};

export default PartActionButton;
