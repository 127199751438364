import {
  SET_PLACE,
  UPDATE_PLACE,
  UPDATE_PLACE_SUCCESS,
  UPDATE_PLACE_FAILURE,
  GET_PLACE,
  GET_PLACE_SUCCESS,
  GET_PLACE_FAILURE,
} from '../../actionTypes';

export const setPlace = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SET_PLACE,
    payload,
  };
};

export const updatePlace = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_PLACE,
    payload,
  };
};

export const updatePlaceFailure = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_PLACE_FAILURE,
    payload,
  };
};

export const updatePlaceSuccess = (payload) => {
  return {
    type: UPDATE_PLACE_SUCCESS,
    payload,
  };
};

export const getPlace = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_PLACE,
    payload,
  };
};

export const getPlaceFailure = (payload) => {
  return {
    type: GET_PLACE_FAILURE,
    payload,
  };
};

export const getPlaceSuccess = (payload) => {
  return {
    type: GET_PLACE_SUCCESS,
    payload,
  };
};
