import {
  SET_PLACE,
  UPDATE_PLACE,
  UPDATE_PLACE_SUCCESS,
  UPDATE_PLACE_FAILURE,
  GET_PLACE,
  GET_PLACE_FAILURE,
  GET_PLACE_SUCCESS,
  // LOAD_PLACES,
} from '../../actionTypes';

const initialState = {
  place: {},
  editAllowed: false,
  loading: false,
  loaded: false,
  failed: false,
  updating: false,
  updateSuccess: false,
  updateFail: false,
};

const placeDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLACE:
      return {
        ...state,
        place: action.payload.params,
      };

    case UPDATE_PLACE:
      return {
        ...state,
        place: action.payload.params,
        updating: true,
        updateSuccess: false,
        updateFail: false,
      };

    case UPDATE_PLACE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        updateFail: false,
      };

    case UPDATE_PLACE_FAILURE:
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        updateFail: true,
      };

    case GET_PLACE:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_PLACE_SUCCESS:
      return {
        ...state,
        place: action.payload.place,
        editAllowed: action.payload.editAllowed,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_PLACE_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default placeDetail;
