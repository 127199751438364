import {
  GET_SUPPLIERS,
  GET_SUPPLIERS_SUCCESS,
  GET_SUPPLIERS_FAILURE,
  ADD_SUPPLIER,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAILURE,
} from '../../actionTypes';

const initialState = {
  suppliers: [],
  loading: false,
  loaded: false,
  failed: false,
};

const supplierList = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERS:
      return {
        ...state,
        loading: true,
        loaded: false,
        failed: false,
      };

    case GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.payload,
        loaded: true,
        loading: false,
        failed: false,
      };

    case GET_SUPPLIERS_FAILURE:
      return {
        ...state,
        failed: true,
        loaded: false,
        loading: false,
      };

    case ADD_SUPPLIER:
      return {
        ...state,
        supplier: action.payload.params,
        loading: true,
        loaded: false,
        failed: false,
      };

    case ADD_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        failed: false,
      };

    case ADD_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        failed: true,
      };

    default:
      return state;
  }
};

export default supplierList;
