import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { saveItineraryDraft } from 'containers/itineraryMaker/itineraryMakerActions';
import Dashboard from './dashboardIndex';
import {
  getRequestList,
  addRequest,
  requestSearch,
  updateRequestDetails,
  reAssignRequest,
  updateStatus,
  sendItinerary,
  deleteItinerary,
  resetDashboardResp,
  remarkUpdate,
  getRequestFilters,
  starRequest,
  updateOrder,
  modifyInstallments,
  bookingAction,
  acceptPayment,
  getRequestByID,
  resendConfirmation,
  generateVoucher,
  refundPayment,
  cancelItinerary,
  refundFromWallet,
  refundToWallet,
  markBookingTransaction,
  cancelBooking,
  getBookings,
  getB2bWalletBalance,
  getRefundData,
  getVirtualAccountData, interestedRequest, saveAsTemplateItinerary,
} from './dashboardActions';
import { suggestAutocomplete, getConversion, uploadDocs } from '../common/commonActions';

const mapStateToProps = (state) => ({
  ...state.auth.loginResp,
  ...state.dashboard,
  version: state.common.version,
  isFetchingSuggestions: state.common.isFetchingSuggestions,
  suggestions: state.common.suggestions,
  suggestionsError: state.common.suggestionsError,
  suggestionsErrorMsg: state.common.suggestionsErrorMsg,
  isFetchingConversion: state.common.isFetchingConversion,
  conversion: state.common.conversion,
  conversionError: state.common.conversionError,
  conversionErrorMsg: state.common.conversionErrorMsg,
  meta: state.common.paramsMeta,
  isUploadingDocs: state.common.isUploadingDocs,
  uploadDocsResp: state.common.uploadDocsResp,
  uploadDocsError: state.common.uploadDocsError,
  uploadDocsErrorMsg: state.common.uploadDocsErrorMsg,
  isSavingItineraryDraft: state.itineraryMaker.isSavingItineraryDraft,
  saveItineraryDraftResp: state.itineraryMaker.saveItineraryDraftResp,
  saveItineraryDraftError: state.itineraryMaker.saveItineraryDraftError,
  saveItineraryDraftErrorMsg: state.itineraryMaker.saveItineraryDraftErrorMsg,
});

const mapDispatchToProps = (dispatch) => ({
  getRequestList: (params, headers) => dispatch(getRequestList(params, headers)),
  requestSearch: (params, headers) => dispatch(requestSearch(params, headers)),
  getAutocompleteSuggestion: (params, headers) => dispatch(suggestAutocomplete(params, headers)),
  getConversion: (params, headers) => dispatch(getConversion(params, headers)),
  addRequest: (params, headers) => dispatch(addRequest(params, headers)),
  updateRequestDetails: (params, headers) => dispatch(updateRequestDetails(params, headers)),
  reAssignRequest: (params, headers) => dispatch(reAssignRequest(params, headers)),
  updateStatus: (params, headers) => dispatch(updateStatus(params, headers)),
  sendItinerary: (params, headers) => dispatch(sendItinerary(params, headers)),
  uploadDocs: (params, headers) => dispatch(uploadDocs(params, headers)),
  deleteItinerary: (params, headers) => dispatch(deleteItinerary(params, headers)),
  saveAsTemplateItinerary: (params, headers) => dispatch(saveAsTemplateItinerary(params, headers)),
  resetDashboardResp: () => dispatch(resetDashboardResp()),
  remarkUpdate: (params, headers) => dispatch(remarkUpdate(params, headers)),
  getRequestFilters: (params, headers) => dispatch(getRequestFilters(params, headers)),
  starRequest: (params, headers) => dispatch(starRequest(params, headers)),
  interestedRequest: (params, headers) => dispatch(interestedRequest(params, headers)),
  updateOrder: (params, headers) => dispatch(updateOrder(params, headers)),
  modifyInstallments: (params, headers) => dispatch(modifyInstallments(params, headers)),
  bookingAction: (params, headers) => dispatch(bookingAction(params, headers)),
  acceptPayment: (params, headers) => dispatch(acceptPayment(params, headers)),
  getRequestByID: (params, headers) => dispatch(getRequestByID(params, headers)),
  resendConfirmation: (params, headers) => dispatch(resendConfirmation(params, headers)),
  generateVoucher: (params, headers) => dispatch(generateVoucher(params, headers)),
  refundPayment: (params, headers) => dispatch(refundPayment(params, headers)),
  cancelItinerary: (params, headers) => dispatch(cancelItinerary(params, headers)),
  refundFromWallet: (params, headers) => dispatch(refundFromWallet(params, headers)),
  refundToWallet: (params, headers) => dispatch(refundToWallet(params, headers)),
  saveItineraryDraft: (params, headers) => dispatch(saveItineraryDraft(params, headers)),
  markBookingTransaction: (params, headers, requestInfo) => dispatch(markBookingTransaction(params, headers, requestInfo)),
  cancelBooking: (params, headers) => dispatch(cancelBooking(params, headers)),
  getBookings: (params, headers) => dispatch(getBookings(params, headers)),
  getB2bWalletBalance: (params, headers) => dispatch(getB2bWalletBalance(params, headers)),
  getRefundData: (params, headers) => dispatch(getRefundData(params, headers)),
  getVirtualAccountData: (params, headers) => dispatch(getVirtualAccountData(params, headers)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
