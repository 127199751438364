import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import DragnDrop from 'UI/DragnDrop';
import Header from 'UI/header';
import Autocomplete from 'UI/autocomplete';
import LabelInput from 'UI/labelInput';
import arrowBack from 'assets/svg/arrow_left.svg';
import addIcon from 'assets/svg/create.svg';
import Warning from 'assets/icons/warning';
import { colors } from 'UI/styleConsts';
import RadioOptions from 'UI/radioOptions';
import DateButton from 'UI/dateButton';
import ActivityDiff from 'components/itineraryMaker/activityDiff';
import { getButtonText, shouldShowDiffToggle } from 'utils/common';
import { ITINERARY_READ_MODES } from 'utils/consts';

class CreateActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      location: {
        value: '',
        valid: false,
      },
      transfers: 'Not included',
      duration: {
        value: '',
        valid: false,
      },
      activityTime: new Date(),
      hasSelectedTime: false,
      images: [],
      base64Files: [],
      valid: false,
      fileSizeExceeds: false,
      errorMsg: '',
      edit: false,
      showDiff: false,
    };
    const { itineraryActionType, originalActivity } = this.props;
    this.originalActivity = {};
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalActivity);
    if (this.showDiffToggle) {
      this.originalActivity = this.processActivity(originalActivity);
    }
    this.errorFields = {};
    this.dropInput = React.createRef();
    this.transfers = ['Included', 'Not included'];
    this.durations = ['1-4 hours', 'Half day', 'Full day', 'Multi day']
      .map((d) => ({ name: d }));
    this.validTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  }

  componentDidMount = () => {
    const { activity = {} } = this.props;
    if (activity.title) {
      const prevState = {
        ...this.processActivity(activity),
        hasSelectedTime: true,
        valid: true,
        edit: true,
      };
      this.setState({ ...prevState });
      if (activity.images.length && activity.images[0].size) {
        // we still haven't saved the itinerary
        this.readFileAsBase64(activity.images);
      }
    }
  };

  processActivity = (activity) => {
    return {
      title: activity.title,
      description: activity.description,
      images: activity.images,
      duration: {
        value: activity.duration,
        valid: true,
      },
      location: {
        value: activity.location.name,
        valid: true,
        item: activity.location,
      },
      transfers: activity.transfers,
      activityTime: activity.activityTime ? moment(activity.activityTime) : '',
    };
  };

  readFileAsBase64 = (files) => {
    const { base64Files } = this.state;
    const modFiles = [...base64Files];
    const startLength = base64Files.length;
    const filesNum = files.length;
    let done = 0;
    for (let j = 0; j < filesNum; j++) {
      modFiles[startLength + j] = '';
      (function (i, self) { // eslint-disable-line
        const reader = new FileReader();
        reader.onload = () => {
          modFiles[i] = reader.result;
          done++;
          if (done === filesNum) {
            self.setState({ base64Files: modFiles });
          }
        };
        reader.readAsDataURL(files[i - startLength]);
      }((j + startLength), this));
    }
  };

  dropHandler = (e) => {
    // const { markTouched } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const { images } = this.state;
      const modDetails = [...images];
      const filesToAppend = [];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { type } = file;
        if (type && this.validTypes.some((v) => v === type)) {
          filesToAppend.push(file);
          modDetails.push(file);
        }
      }
      let fileSizeExceeds = false;
      for (let i = 0; i < modDetails.length; i++) {
        if (modDetails[i].size > 20971520) {
          fileSizeExceeds = true;
          break;
        }
      }
      this.readFileAsBase64(filesToAppend);
      this.setState({
        images: modDetails,
        fileSizeExceeds,
      }, this.validityChecker);
      // markTouched();
      e.dataTransfer.clearData();
    }
  };

  handleUpload = (e) => {
    const params = {
      preventDefault: () => {
      },
      stopPropagation: () => {
      },
      dataTransfer: {
        files: e.target.files,
        clearData: () => {
        },
      },
    };
    this.dropHandler(params);
    this.dropInput.current.value = '';
  };

  removeFileHandler = (e, index) => {
    e.stopPropagation();
    const {
      images,
      base64Files,
    } = this.state;
    const modFileDetails = [...images];
    modFileDetails.splice(index, 1);
    const modBaseFiles = [...base64Files];
    modBaseFiles.splice(index, 1);
    this.setState({
      images: modFileDetails,
      base64Files: modBaseFiles,
    });
  };

  handleChange = (field, value) => {
    // const { markTouched } = this.props;
    const { [field]: itm } = this.state;
    if (itm.constructor.name === 'Object') {
      this.setState({
        [field]: {
          value,
          valid: false,
        },
      }, this.validityChecker);
    } else {
      this.setState({ [field]: value }, this.validityChecker);
    }
    // markTouched();
  };

  handleDateTimeChange = (time) => {
    this.setState({
      activityTime: time,
      hasSelectedTime: true,
    }, this.validityChecker);
  };

  handleSearchChange = (field, value) => {
    const { getSuggestions } = this.props;
    getSuggestions('destinations', value);
    this.setState({
      [field]: {
        value,
        valid: false,
      },
    }, this.validityChecker);
  };

  handleSelection = (field, item, accessor) => {
    this.setState({
      [field]: {
        value: item[accessor],
        valid: true,
        item,
      },
    }, this.validityChecker);
  };

  inputFocusHandler = () => {
    if (this.dropInput.current) {
      this.dropInput.current.click();
    }
  };

  validityChecker = () => {
    const {
      title,
      // description,
      location,
      duration,
      hasSelectedTime,
    } = this.state;
    let valid = true;
    if (title.trim().length === 0) {
      valid = false;
    }
    if (!location.valid || !duration.valid) {
      valid = false;
    }
    if (!hasSelectedTime) {
      valid = false;
    }
    this.setState({ valid });
  };

  getErrorMsg = () => {
    const {
      title,
      // description,
      images,
      location,
      duration,
      hasSelectedTime,
    } = this.state;
    let errorMsg = '';
    this.errorFields = {};
    // if (images.length === 0) {
    //   errorMsg = 'Experience Images are missing';
    // }
    if (title.trim().length === 0) {
      this.errorFields.title = 1;
      errorMsg = 'Title is missing';
    }
    // if (description.trim().length === 0) {
    //   this.errorFields.description = 1;
    //   errorMsg = 'Description is empty';
    // }
    if (!location.valid) {
      this.errorFields.location = 1;
      errorMsg = 'Location is missing';
    }
    if (!duration.valid) {
      this.errorFields.duration = 1;
      errorMsg = 'Experience duration is missing';
    }
    if (!hasSelectedTime) {
      this.errorFields.activityTime = 1;
      errorMsg = 'Activity time not selected';
    }
    let fileSizeExceeds = false;
    for (let i = 0; i < images.length; i++) {
      if (images[i].size > 20971520) {
        fileSizeExceeds = true;
        break;
      }
    }
    return {
      errorMsg,
      fileSizeExceeds,
    };
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  removeSizeExtraFiles = () => {
    const { images, base64Files } = this.state;
    const modFileDetails = [];
    const modBaseFiles = [];
    for (let i = 0; i < images.length; i++) {
      if (images[i].size <= 20971520) {
        modFileDetails.push(images[i]);
        modBaseFiles.push(base64Files[i]);
      }
    }
    this.setState({
      images: modFileDetails,
      base64Files: modBaseFiles,
    }, this.validityChecker);
  };

  submitHandler = () => {
    const { onSubmit } = this.props;
    const {
      valid,
      title,
      description,
      images,
      location,
      transfers,
      duration,
      activityTime,
    } = this.state;
    if (!valid) {
      const { errorMsg, fileSizeExceeds } = this.getErrorMsg();
      this.setState({
        errorMsg,
        fileSizeExceeds,
      });
      return;
    }
    const params = {
      title,
      description,
      images,
      location: location.item,
      transfers,
      duration: duration.value,
      activityTime: moment(activityTime)
        .toISOString(),
    };
    onSubmit(params);
  };

  render() {
    const {
      classes,
      suggestions = {},
      onDismiss,
      itineraryActionType,
    } = this.props;
    const {
      title,
      description,
      location,
      transfers,
      duration,
      base64Files,
      images,
      activityTime,
      valid,
      fileSizeExceeds,
      errorMsg,
      edit,
      showDiff,
    } = this.state;
    const photosLen = base64Files.length;
    const imagesToRender = images;
    return (
      <DragnDrop
        extraClass={classes.container}
        fileDropHandler={this.dropHandler}
      >
        <>
          <Header
            className={classes.headerStrip}
            titleClass={classes.titleClass}
            img={arrowBack}
            variant="back"
            title="Create a new Experience"
            onDismiss={onDismiss}
            showDiffToggle={this.showDiffToggle}
            checked={showDiff}
            label="VIEW CHANGES"
            onToggle={this.toggleDiff}
          />
          {!showDiff ? (
            <div className={classes.body}>
              <div className={classes.dropSection}>
                <Typography className={classes.dropTitle}>
                  {`Experience Photos ${photosLen}/5`}
                </Typography>
                <div className={classes.dropArea}>
                  <input
                    type="file"
                    accept="image/png,image/jpeg,image/jpg"
                    multiple="multiple"
                    ref={this.dropInput}
                    className={classes.dropBox}
                    onChange={(e) => this.handleUpload(e)}
                  />
                  {imagesToRender.length ? (
                    <div className={classes.droppedImages}>
                      {imagesToRender.map((img, i) => {
                        const name = images[i].name || images[i].fileName;
                        const src = img.link ? img.link : base64Files[i];
                        return (
                          <div
                            key={name}
                            className={classes.droppedImgContainer}
                          >
                            <IconButton
                              className={classes.droppedImgBtn}
                              onClick={(e) => this.removeFileHandler(e, i)}
                            >
                              <Close className={classes.closeIcon} />
                            </IconButton>
                            <img src={src} className={classes.droppedImg} alt={i} />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <button
                    type="button"
                    className={classes.dropContainer}
                    onClick={this.inputFocusHandler}
                  >
                    <Typography className={classes.dropText}>Drag & drop photos</Typography>
                    <Typography className={classes.dropMsgOR}>
                      or
                    </Typography>
                    <Typography className={classes.dropMsgAdd}>
                      <img src={addIcon} alt="+" className={classes.addIcon} />
                      &nbsp; Choose photos
                    </Typography>
                  </button>
                </div>
                <Typography className={classes.note}>
                  (max file size - 20MB, file format - .png .jpg, upto 5)
                </Typography>
              </div>
              <div className={classes.detailsSection}>
                <LabelInput
                  label="TITLE"
                  placeholder="eg. Snorkeling In Maldives: Explore The World Under Water"
                  value={title}
                  error={Boolean(this.errorFields.title)}
                  extraClass={classes.extraClass}
                  onChange={(val) => this.handleChange('title', val)}
                />
                <LabelInput
                  label="DESCRIPTION"
                  placeholder="When you visit Maldives, you simply must experience one of its most popular activities – snorkeling. You can explore crystal clear, blue waters at Maldives"
                  value={description}
                  error={Boolean(this.errorFields.description)}
                  onChange={(val) => this.handleChange('description', val)}
                  extraClass={classes.extraClass}
                  inputClass={classes.multilineInput}
                  inputProps={{
                    multiline: true,
                    rows: 3,
                    rowsMax: 6,
                  }}
                />
                <div className={classes.row}>
                  <Autocomplete
                    label="LOCATION"
                    value={location.value}
                    error={Boolean(this.errorFields.location)}
                    extraClass={classes.wrapperLeft}
                    inputClass={classes.autoCompleteInput}
                    data={suggestions.destinations}
                    accessor="name"
                    onChange={(val) => this.handleSearchChange('location', val)}
                    onSelected={(item) => this.handleSelection('location', item, 'name')}
                  />
                  <LabelInput
                    label="TRANSFERS"
                    extraClass={classes.radioWrapper}
                  >
                    <RadioOptions
                      options={this.transfers}
                      selected={transfers}
                      extraClass={classes.radioOptions}
                      onChange={(e) => this.handleChange('transfers', e.target.value)}
                    />
                  </LabelInput>
                  {/* <Autocomplete
                  label="TRANSFERS"
                  value={transfers.value}
                  error={Boolean(this.errorFields.transfers)}
                  extraClass={classes.wrapperRight}
                  inputClass={classes.autoCompleteInput}
                  data={filteredTransfers}
                  accessor="name"
                  onChange={(val) => this.handleChange('transfers', val)}
                  onSelected={(item) => this.handleSelection('transfers', item, 'name')}
                /> */}
                </div>
                <div className={classes.row}>
                  <Autocomplete
                    data={this.durations}
                    accessor="name"
                    label="DURATION"
                    extraClass={classes.durationWrapper}
                    inputClass={classes.autoCompleteInput}
                    value={duration.value}
                    onChange={(val) => this.handleChange('duration', val)}
                    onSelected={(item) => this.handleSelection('duration', item, 'name')}
                  />
                  <LabelInput
                    label="Activity time"
                    error={Boolean(this.errorFields.activityTime)}
                    extraClass={classes.dateButtonContainer}
                  >
                    {(activityTime || !ITINERARY_READ_MODES[itineraryActionType]) ? (
                      <DateButton
                        askTime
                        format="DD MMM YY - hh:mm A"
                        extraClass={classes.dateButton}
                        value={activityTime}
                        onChange={this.handleDateTimeChange}
                      />
                    )
                      : (
                        <Typography
                          className={clsx(classes.dateButton, classes.naField)}
                        >
                          N/A
                        </Typography>
                      )}
                  </LabelInput>
                </div>
                {fileSizeExceeds ? (
                  <div className={classes.errorBox}>
                    <Warning fill={colors.red} />
                    <Typography className={classes.errorText}>
                      File size exceeds
                    </Typography>
                    <ButtonBase
                      className={classes.removeFileButton}
                      onClick={this.removeSizeExtraFiles}
                    >
                      REMOVE FILES
                    </ButtonBase>
                  </div>
                ) : null}
                <div className={classes.footer}>
                  {errorMsg && (
                    <Typography
                      className={classes.errorMsg}
                    >
                      {errorMsg}
                    </Typography>
                  )}
                  <Button
                    className={clsx(classes.createButton, !valid && classes.disabled)}
                    onClick={this.submitHandler}
                  >
                    {getButtonText(itineraryActionType, edit, 'activity')}
                  </Button>
                </div>
              </div>
            </div>
          )
            : (
              <ActivityDiff
                oldData={this.originalActivity}
                data={this.state}
              />
            )}
        </>
      </DragnDrop>
    );
  }
}

const styles = (theme) => ({
  container: {
    height: '100%',
    maxWidth: 940,
    borderRadius: 10,
  },
  body: {
    height: 520,
    borderRadius: 10,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.colors.white,
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  dropSection: {
    flex: 3,
    minWidth: '30%',
    padding: '30px 20px 40px 40px',
    boxSizing: 'border-box',
    borderRight: `1px solid ${theme.colors.border}`,
  },
  dropTitle: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    marginBottom: 10,
  },
  dropArea: {
    flex: 1,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 14,
    height: '80%',
  },
  droppedImages: {
    position: 'absolute',
    backgroundColor: 'rgba(234,242,242,0.4)',
    top: 10,
    left: 10,
    maxHeight: '95%',
    width: '98%',
    overflowY: 'auto',
  },
  droppedImgContainer: {
    zIndex: 2,
    position: 'relative',
    width: '94%',
    marginTop: 10,
    objectFit: 'fill',
    margin: 0,
    padding: 0,
    borderRadius: 4,
  },
  droppedImgBtn: {
    position: 'absolute',
    top: -8,
    right: -9,
    width: 20,
    height: 20,
    padding: 0,
    boxSizing: 'border-box',
    color: theme.colors.white,
    backgroundColor: theme.colors.red,
    border: `1px solid ${theme.colors.white}`,
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.colors.red,
    },
  },
  droppedImg: {
    width: '100%',
  },
  closeIcon: {
    height: 12,
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  dropContainer: {
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    backgroundColor: 'transparent',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  dropText: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.colors.textDark_1,
  },
  dropMsgOR: {
    margin: '6px 0',
    textAlign: 'center',
    fontSize: 12,
    color: theme.colors.textLight_1,
  },
  durationWrapper: {
    width: '49%',
    marginRight: 10,
    justifyContent: 'center',
    flex: 1,
  },
  wrapperLeft: {
    flex: 1,
    marginRight: 10,
  },
  wrapperRight: {
    flex: 1,
    marginLeft: 10,
  },
  dropMsgAdd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.colors.primaryBackground,
  },
  radioWrapper: {
    flex: 1,
    marginLeft: 10,
    marginBottom: 20,
  },
  radioOptions: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  dateButtonContainer: {
    flex: 1,
    marginLeft: 10,
  },
  dateButton: {
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padding: '5px 10px',
    height: '40px',
  },
  naField: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  note: {
    fontSize: 12,
    flexWrap: 'wrap',
    color: theme.colors.textLight,
  },
  detailsSection: {
    flex: 7,
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px 30px 20px',
  },
  extraClass: {
    marginBottom: 26,
  },
  autoCompleteInput: {
    width: '100%',
    height: 40,
    cursor: 'pointer',
    padding: '10px 5px 10px 15px',
  },
  multiSelectInput: {
    width: '100%',
    height: '100%',
  },
  multilineInput: {
    minHeight: 84,
    height: 'unset',
    overflowY: 'auto !important',
  },
  row: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 26,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 15px',
    width: '100%',
    height: 34,
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.redLight,
  },
  errorText: {
    paddingLeft: 10,
    color: theme.colors.black,
    fontSize: 14,
  },
  errorMsg: {
    color: theme.colors.red,
    fontWeight: 'bold',
    fontSize: 14,
    paddingRight: 10,
  },
  removeFileButton: {
    marginLeft: 'auto',
    letterSpacing: 0.5,
    fontSize: 12,
    fontWeight: 'bold',
    height: 28,
    boxSizing: 'border-box',
    padding: '5px 10px',
    color: theme.colors.red,
  },
  createButton: {
    width: 244,
    color: theme.colors.white,
    borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.primary}`,
    padding: '5px 10px',
    marginRight: 20,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  selectedChip: {
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    '&:active': {
      backgroundColor: theme.colors.primaryBackground,
    },
    '&:focus': {
      backgroundColor: theme.colors.primaryBackground,
    },
  },
});

CreateActivity.propTypes = {
  classes: PropTypes.object,
  activity: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  markTouched: PropTypes.func,
  suggestions: PropTypes.object.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  originalActivity: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateActivity);
