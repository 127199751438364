import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import CreateIcon from '@material-ui/icons/CreateNewFolder';
import Header from 'UI/header';
import Footer from 'UI/footer';
import Button from 'UI/button';
import MultiSelect from 'UI/multiSelect';
import { filterList, processRequirement } from 'utils/common';
import DateRangeSelection from 'UI/dateRangeSelection';
import TravellerSelection from 'UI/travellerSelection';
import Checkbox from 'UI/checkbox';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase/InputBase';
import PaginatedTable from 'components/dashboard/paginatedTable';
import LabelInput from 'UI/labelInput';

class ConfirmRequirement extends Component {
  constructor(props) {
    super(props);
    const { request: { tags }, requirement, meta } = this.props;
    this.state = {
      templateItineraryId: null,
      itineraryTemplateSearchValue: '',
      skip: 0,
      limit: 8,
      origin: {
        value: '',
        valid: false,
        items: [],
      },
      places: {
        value: '',
        valid: false,
        items: [],
      },
      tripDate: '',
      tripDates: {
        from: '',
        to: '',
      },
      duration: 0,
      flightsBooked: false,
      isFlexible: false,
      travellers: {
        adults: 0,
        children: 0,
        infants: 0,
      },
      tripTypes: {
        value: '',
        valid: false,
        items: [],
      },
      errorMsg: '',
      activeTab: 'Enter Trip Details',
      tags: (tags || []).filter((x) => x === 'DATES_NOT_FINAL'),
      allPlaces: false,
      ...processRequirement(requirement, meta.tripTypes),
    };
  }

  handleChange = (field, value) => {
    const { [field]: itm } = this.state;
    const changes = {};
    if (itm.constructor.name === 'Object' && field !== 'travellers') {
      changes[field] = {
        ...itm,
        value,
        valid: false,
      };
    } else {
      changes[field] = value;
    }
    if (field === 'flightsBooked' && value) {
      changes.isFlexible = false;
    } else if (field === 'isFlexible' && value) {
      changes.flightsBooked = false;
    }
    this.setState({ ...changes });
  };

  handleDateSelection = (date, dateStr) => {
    const { from, to } = date;
    let changes = {};
    if (from && to) {
      changes = {
        duration: `${moment(to)
          .diff(moment(from)) / 86400000}`,
      };
    }
    this.setState({
      tripDate: dateStr,
      ...changes,
      tripDates: date,
    });
  };

  handleItemSelection = (field, accessor, item) => {
    const { [field]: stateField } = this.state;
    let modField;
    if (stateField.items) {
      const iIndex = stateField.items.findIndex((i) => i[accessor] === item[accessor]);
      if (iIndex === -1) {
        modField = {
          value: '',
          valid: true,
          items: [item, ...stateField.items],
        };
      }
    } else {
      modField = {
        value: item[accessor],
        valid: true,
        item,
      };
    }
    this.setState({ [field]: modField });
  };

  addTentative = () => {
    const { tags } = this.state;
    const index = tags.indexOf('DATES_NOT_FINAL');
    if (index === -1) {
      this.setState({
        tags: [...tags, 'DATES_NOT_FINAL'],
      });
    } else {
      const modData = [...tags];
      modData.splice(index, 1);
      this.setState({ tags: modData });
    }
  };

  toggleAllPlaces = () => {
    const { allPlaces } = this.state;
    this.setState({
      allPlaces: !allPlaces,
    });
    this.refreshItineraryTemplates();
  };

  handlePageChange = async (value) => {
    await this.setState({
      skip: value,
    });
    this.refreshItineraryTemplates();
  };

  handleSearchChange = (field, searchType, value, itineraryTemplatesExtras) => {
    const { getSuggestions } = this.props;
    let type;
    let extras = {};
    if (searchType === 'cities') {
      type = 'cities';
      extras = { includeAddressTypes: ['locality'] };
    } else if (searchType === 'places') {
      type = 'destinations';
      extras = {
        includeAddressTypes: ['country'],
        includeIndianPlaces: true,
      };
    } else if (searchType === 'itineraryTemplates') {
      type = 'itineraryTemplates';
      extras = itineraryTemplatesExtras;
    }
    getSuggestions(type, value, extras);
    const { [field]: stateField } = this.state;
    this.setState({
      [field]: {
        ...stateField,
        value,
      },
    });
  };

  handleSearchValueChange = async (value) => {
    await this.setState({
      itineraryTemplateSearchValue: value,
      skip: 0,
    });
    this.refreshItineraryTemplates();
  };

  refreshItineraryTemplates = () => {
    const {
      places,
      allPlaces,
      skip,
      limit,
      tripDates,
    } = this.state;
    const { itineraryTemplateSearchValue } = this.state;
    this.handleSearchChange('itineraryTemplates', 'itineraryTemplates', itineraryTemplateSearchValue, {
      placeIds: allPlaces ? [] : places?.items?.map((i) => i._id),
      skip,
      limit,
      nights: moment(tripDates.to).diff(moment(tripDates.from), 'days'),
    });
  };

  componentDidUpdate = () => {
    const { isFetchingSuggestions: isFetchingSuggestionsState } = this.state;
    const { isFetchingSuggestions: isFetchingSuggestionsProps } = this.props;
    if (isFetchingSuggestionsState !== isFetchingSuggestionsProps) {
      this.setState({
        isFetchingSuggestions: isFetchingSuggestionsProps,
      });
    }
  };

  removeHandler = (field, accessor, item) => {
    const { [field]: varField } = this.state;
    const modField = { ...varField };
    modField.items = modField.items.filter((d) => d[accessor] !== item[accessor]);
    this.setState({ [field]: modField });
  };

  handleTabChange = (tab) => {
    const { activeTab } = this.state;
    const newState = {};
    if (tab !== activeTab) {
      if (tab === 'Select Template') {
        newState.isFetchingSuggestions = true;
        this.refreshItineraryTemplates();
      }
      newState.activeTab = tab;
      this.setState(newState);
    }
  };

  validityChecker = (setError) => {
    const {
      origin, places, tripDates,
      travellers, tripTypes,
    } = this.state;
    let error = false;
    let errorMsg = '';
    if (origin.items.length === 0) {
      error = true;
      errorMsg = 'Origin cannot be empty';
    } else if (places.items.length === 0) {
      error = true;
      errorMsg = 'Destination cannot be empty';
    } else if (!tripDates.from || !tripDates.to) {
      error = true;
      errorMsg = 'Trip dates not selected';
    } else if (tripTypes.items.length === 0) {
      error = true;
      errorMsg = 'Trip type not selected';
    } else if (travellers.adults === 0) {
      error = true;
      errorMsg = 'No adults selected for the trip';
    }
    if (setError) {
      this.setState({ errorMsg });
    }
    return { error, errorMsg };
  };

  handleClick = (event, templateItineraryId) => {
    const { templateItineraryId: selectedTemplateItineraryId } = this.state;
    this.setState({ templateItineraryId: templateItineraryId === selectedTemplateItineraryId ? null : templateItineraryId });
  };

  submitHandler = (ignoreTemplate) => {
    const { error } = this.validityChecker(true);
    if (error) {
      return;
    }
    const { onSubmit } = this.props;
    const {
      origin, places, tripDates: { from, to }, isFlexible,
      travellers, tripTypes, duration, flightsBooked,
      tags, templateItineraryId,
    } = this.state;
    const params = {
      origin: origin.items.map((o) => o._id),
      places: places.items.map((d) => d._id),
      departureDate: moment(from).toISOString(),
      arrivalDate: moment(to).toISOString(),
      duration,
      travellers,
      tripTypes: tripTypes.items.map((t) => t.code),
      flightsBooked,
      flexible: isFlexible,
      tags,
      templateItineraryId: ignoreTemplate ? null : templateItineraryId,
    };
    onSubmit(params);
  };

  render() {
    const {
      onDismiss,
      suggestions,
      classes,
      meta,
      isLoading,
    } = this.props;
    const {
      origin, places, tripDates, travellers, tripTypes,
      flightsBooked, errorMsg, tripDate,
      activeTab, templateItineraryId,
      allPlaces, limit,
      isFetchingSuggestions,
    } = this.state;
    const tripTypesList = filterList(tripTypes.value, meta.tripTypes, ['title']);
    return (
      <div className={classes.container}>
        <Header
          onDismiss={onDismiss}
          title="Create itinerary"
          Icon={CreateIcon}
          iconClass={classes.iconClass}
        />
        <div className={classes.stepContainer}>
          <div className={classes.stepNumberBar}>
            {['Enter Trip Details', 'Select Template'].map((tab) => {
              return (
                <ButtonBase
                  className={clsx(classes.tabButton,
                    activeTab === tab && classes.activeTab)}
                  key={tab}
                  onClick={() => this.handleTabChange(tab)}
                >
                  <Typography
                    className={clsx(classes.tabNames,
                      activeTab === tab && classes.activeTab)}
                    align="left"
                  >
                    {tab}
                  </Typography>
                </ButtonBase>
              );
            })}
          </div>
          {activeTab === 'Enter Trip Details' ? (
            <div className={classes.body}>
              <div className={classes.row}>
                <MultiSelect
                  extraClass={classes.wrapperLeft}
                  inputClass={classes.input}
                  label="Origin city"
                  selections={origin.items}
                  value={origin.value}
                  accessor="name"
                  renderType="place"
                  data={suggestions.cities}
                  removeHandler={(item) => this.removeHandler('origin', 'displayName', item)}
                  onChange={(val) => this.handleSearchChange('origin', 'cities', val)}
                  onSelected={(item) => this.handleItemSelection('origin', 'displayName', item)}
                />
                <MultiSelect
                  extraClass={classes.wrapperRight}
                  inputClass={classes.input}
                  label="Destination"
                  selections={places.items}
                  value={places.value}
                  accessor="name"
                  renderType="place"
                  data={places.value ? suggestions.destinations : []}
                  removeHandler={(item) => this.removeHandler('places', 'displayName', item)}
                  onChange={(val) => this.handleSearchChange('places', 'places', val)}
                  onSelected={(item) => this.handleItemSelection('places', 'displayName', item)}
                />
              </div>
              <div className={classes.row}>
                <div className={classes.itemRow}>
                  <Checkbox
                    disabled={false}
                    checked={flightsBooked}
                    label="Flights booked"
                    onChange={() => this.handleChange('flightsBooked', !flightsBooked)}
                  />
                  {
                    /*
                    <Checkbox
                      disabled={flightsBooked}
                      checked={(tags || []).includes('DATES_NOT_FINAL')}
                      label="Dates not final"
                      onChange={this.addTentative}
                    />
                    */
                  }
                </div>
                <LabelInput
                  label="Trip Dates"
                  extraClass={classes.wrapperRight}
                  containerStyle={{ marginLeft: 0 }}
                >
                  <DateRangeSelection
                    value={`${tripDate}${tripDates.from && tripDates.to ? ` (${moment(tripDates.to).diff(tripDates.from, 'days')}N)` : ''}`}
                    dates={tripDates}
                    onSelected={(date, dateStr) => this.handleDateSelection(date, dateStr)}
                  />
                </LabelInput>
              </div>
              <div className={classes.row}>
                <TravellerSelection
                  label="NO. OF TRAVELLERS"
                  extraClass={classes.wrapperLeft}
                  travellers={travellers}
                  onChange={(val) => this.handleChange('travellers', val)}
                />
                <MultiSelect
                  label="TRIP TYPE"
                  selections={tripTypes.items}
                  value={tripTypes.value}
                  extraClass={classes.wrapperRight}
                  inputClass={classes.input}
                  data={tripTypesList}
                  accessor="title"
                  removeHandler={(item) => this.removeHandler('tripTypes', 'title', item)}
                  onChange={(val) => this.handleChange('tripTypes', val)}
                  onSelected={(item) => this.handleItemSelection('tripTypes', 'title', item)}
                />
              </div>
              <Footer errorMsg={errorMsg}>
                <Button
                  loading={isLoading}
                  onClick={() => this.handleTabChange('Select Template')}
                  className={classes.submitButton}
                >
                  Next
                </Button>
              </Footer>
            </div>
          ) : null }
          {activeTab === 'Select Template' ? (
            <div className={classes.body} style={{ width: '100%', margin: '5px', padding: '5px 20px' }}>
              <div className={classes.row} style={{ width: '100%', margin: '5px 5px' }}>
                <InputBase
                  className={classes.itineraryTemplateInput}
                  onChange={(e) => this.handleSearchValueChange(e.target.value)}
                  placeholder="filter results by id, title, destination, duration, CP"
                  style={{ width: '80%', margin: '5px 10px' }}
                />
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    color="primary"
                    checked={allPlaces}
                    label="All Places"
                    onChange={this.toggleAllPlaces}
                  />
                </div>
              </div>
              <PaginatedTable
                pageSize={limit}
                loading={isFetchingSuggestions}
                suggestions={suggestions}
                templateItineraryId={templateItineraryId}
                handleClick={this.handleClick}
                handlePageChange={this.handlePageChange}
              />
              <br />
              <Footer errorMsg={errorMsg} extraClass={classes.spaceBetween}>
                <Button
                  variant="outlined"
                  loading={isLoading}
                  onClick={() => this.submitHandler(true)}
                >Create from Scratch
                </Button>
                <Button
                  disabled={templateItineraryId === null}
                  loading={isLoading}
                  onClick={() => this.submitHandler(false)}
                >Copy & Create
                </Button>
              </Footer>
            </div>
          ) : null }
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    width: 1300,
    backgroundColor: theme.colors.white,
  },
  spaceBetween: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '20%',
  },
  iconClass: {
    color: theme.colors.primary,
  },
  body: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    alignItems: 'center',
  },
  itemRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingRight: 20,
  },
  input: {
    padding: '0px 5px 0px 15px',
    height: '100%',
    width: '100%',
  },
  wrapperRight: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
    maxWidth: '50%',
    marginLeft: 10,
  },
  wrapperLeft: {
    flex: 1,
    borderRadius: 4,
    maxWidth: '50%',
    width: '100%',
    marginRight: 10,
  },
  stepNumberBar: {
    paddingTop: 8,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderRight: `1px solid ${theme.colors.underline}`,
  },
  tabButton: {
    width: '50%',
    borderRadius: '20px',
    height: 36,
    padding: '5px 10px',
    fontSize: 14,
    letterSpacing: 0.5,
    color: theme.colors.textDark_1,
    backgroundColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  stepContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  activeTab: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    backgroundColor: theme.colors.primaryLight,
  },
  itineraryTemplateInput: {
    padding: '5px 15px',
    marginTop: '20px',
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
});

ConfirmRequirement.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  requirement: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFetchingSuggestions: PropTypes.bool.isRequired,
  request: PropTypes.object,
};

export default withStyles(styles)(ConfirmRequirement);
