import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Calendar from '@material-ui/icons/CalendarToday';
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import makeStyles from '@material-ui/styles/makeStyles';

const DateButton = ({
  value,
  disabled = false,
  onChange,
  selected,
  askTime,
  onlyTime,
  format = 'DD MMM YY',
  selectedClass = '',
  extraClass = '',
  buttonClick,
  shouldDisableDate,
  minDate,
  maxDate,
  disablePast = true,
  disableFuture,
}) => {
  const classes = useStyles();
  let DateComponent = DatePicker;
  if (askTime) {
    DateComponent = DateTimePicker;
  }
  if (onlyTime) {
    DateComponent = TimePicker;
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ButtonBase
        type="button"
        className={clsx(
          classes.dateButton,
          extraClass,
          selected && selectedClass
        )}
        classes={{
          disabled: classes.disabled,
        }}
        disabled={disabled}
        disableRipple
        disableTouchRipple
        onClick={buttonClick}
      >
        {!disabled ? (
          <DateComponent
            disableFuture={disableFuture}
            disablePast={disablePast}
            shouldDisableDate={shouldDisableDate}
            value={value}
            className={classes.datePicker}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        ) : null}
        <Typography className={classes.dateText}>
          {value ? moment(value).format(format) : null}
        </Typography>
        <Calendar className={classes.calendarIcon} />
      </ButtonBase>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  dateButton: {
    color: theme.colors.black,
    fontSize: 14,
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    borderRadius: 15,
    padding: '5px 10px',
    minWidth: 90,
    // marginRight: 10,
    width: '100%',
    position: 'relative',
    '& > div > div > input': {
      cursor: 'pointer !important',
    },
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed',
  },
  datePicker: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
  dateText: {
    color: theme.colors.black,
    fontSize: 14,
    width: '100%',
  },
  calendarIcon: {
    color: theme.colors.textDark,
    width: 16,
    height: 16,
    marginLeft: '10px',
  },
}));

DateButton.propTypes = {
  selected: PropTypes.bool,
  selectedClass: PropTypes.string,
  extraClass: PropTypes.string,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  buttonClick: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  askTime: PropTypes.bool,
  onlyTime: PropTypes.bool,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
};

export default DateButton;
