import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import tipsImg from 'assets/svg/tips.svg';
import lostImg from 'assets/svg/lost.svg';
import whatsAppImg from 'assets/svg/whatsapp.svg';

const Unreachable = ({ request, onSubmit, onDismiss }) => {
  const classes = useStyles();
  const { primaryCustomerData = {} } = request;
  const { whatsAppDialCode = '', whatsApp = '' } = primaryCustomerData;
  const openLink = () => {
    window.open(`https://wa.me/${whatsAppDialCode.replace('+', '')}${whatsApp}`, '_blank');
  };
  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <div className={classes.titleRow}>
          <Typography className={classes.requirementText}>
            {' '}
            <img src={lostImg} alt="icon" className={classes.lostIcon} />
            Customer unreachable
          </Typography>
          <IconButton className={classes.closeButton} onClick={onDismiss}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.body}>
        <Typography className={classes.confirmText}>
          Are you sure this customer is unreachable?
        </Typography>
        <div className={classes.tipsSection}>
          <div className={classes.tipTitle}>
            <img src={tipsImg} alt="tipsImg" className={classes.icon} />
            <Typography className={classes.tipsHead}>TIPS</Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.tipsText}>
              Try contacting him on WhatsApp
            </Typography>
            <IconButton
              className={classes.whatsappBtn}
              onClick={openLink}
            >
              <img src={whatsAppImg} alt="whatsapp" />
            </IconButton>
          </div>
          <Typography className={classes.tipsText}>
            Try calling him after office hours
          </Typography>
        </div>
        <div className={classes.footer}>
          <Button
            aria-label="update"
            className={classes.updtBtn}
            onClick={() => onSubmit({})}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    padding: '20px 0',
    flexDirection: 'column',
    minWidth: 480,
    borderRadius: 10,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px  0 0',
    borderBottom: `1px solid ${theme.colors.underline_1}`,
  },
  requirementText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.colors.textDark,
  },
  closeButton: {
    marginLeft: 'auto',
  },
  titleRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 20,
  },
  body: {
    padding: '20px 40px',
  },
  confirmText: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  tipsSection: {
    margin: '15px 0',
    paddingLeft: 10,
    borderLeft: `2px solid ${theme.colors.underline_1}`,
  },
  tipsWrap: {
    paddingLeft: 20,
  },
  tipsHead: {
    color: '#2C7A77',
    fontSize: '12px',
    letterSpacing: '0.5px',
    lineHeight: '15px',
    fontWeight: 'bold',
    paddingLeft: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  whatsappBtn: {
    marginLeft: 10,
  },
  tipsText: {
    color: theme.colors.textLight,
    fontSize: 14,
    lineHeight: '17px',
    padding: '3px 0px 3px 0px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 26,
  },
  updtBtn: {
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    padding: '0 29px',
    fontWeight: 'bold',
    fontSize: 16,
    width: 140,
    borderRadius: 25,
    letterSpacing: '0.5px',
    textAlign: 'center',
    boxShadow: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  tipTitle: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
  },
  icon: {
    width: 15,
  },
  lostIcon: {
    paddingRight: 10,
    position: 'relative',
    top: 7,
  },
}));

Unreachable.propTypes = {
  request: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default Unreachable;
