import React from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { getDateStr, getTravellerText, uuid } from 'utils/common';
import DiffInput from './diffInput';

const StayDiff = ({ data, oldData }) => {
  const classes = useStyles();
  let newTransferMode = '-';
  let oldTransferMode = '-';
  if (data?.transferMode.items.length) {
    newTransferMode = data.transferMode.items
      .map((m) => m.title)
      .join(', ');
  }
  if (oldData?.transferMode?.items.length) {
    oldTransferMode = oldData.transferMode.items
      .map((m) => m.title)
      .join(', ');
  }
  const renderRoomDetail = (index, newRoom, oldRoom) => {
    return (
      <div className={classes.row} key={uuid()}>
        <Typography className={classes.index}>{index}</Typography>
        <DiffInput
          label=""
          value={newRoom?.roomType.value || '-'}
          oldValue={oldRoom?.roomType.value || '-'}
          extraClass={classes.flexSection}
        />
        <DiffInput
          label=""
          value={newRoom?.occupancy ? getTravellerText(newRoom.occupancy) : '-'}
          oldValue={oldRoom?.occupancy ? getTravellerText(oldRoom.occupancy) : '-'}
          extraClass={classes.middleSection}
        />
        <DiffInput
          label=""
          value={newRoom?.mealTypes.items.map((m) => m.title).join(', ') || '-'}
          oldValue={oldRoom?.mealTypes.items.map((m) => m.title).join(', ') || '-'}
          extraClass={classes.flexSection}
        />
      </div>
    );
  };
  const newRoomLength = data.roomDetails.length;
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DiffInput
          label="Check in - Check out"
          value={getDateStr(data.checkinCheckoutDates)}
          oldValue={getDateStr(oldData.checkinCheckoutDates)}
          extraClass={classes.flexSection}
        />
        <DiffInput
          label="ROOMS"
          value={data.rooms.value}
          oldValue={oldData.rooms.value}
          extraClass={classes.flexSection}
        />
        <DiffInput
          label="STAY TRANSFER"
          value={newTransferMode}
          oldValue={oldTransferMode}
          extraClass={classes.flexSection}
        />
      </div>
      <div className={classes.col}>
        {data.roomDetails.map((room, i) => renderRoomDetail(i + 1, room, oldData.roomDetails[i]))}
        {data.roomDetails.length < oldData.roomDetails.length ? (
          oldData.roomDetails.slice(newRoomLength).map((oldRoom, i) => (
            renderRoomDetail(i + newRoomLength, undefined, oldRoom)
          ))) : null}
      </div>
      <div className={classes.row}>
        <DiffInput
          label="NOTES"
          value={data.notes || '-'}
          oldValue={oldData.notes || '-'}
          extraClass={classes.flexSection}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  imageSection: {
    minWidth: '30%',
    overflowY: 'auto',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    padding: 20,
    marginBottom: 14,
    height: '80%',
  },
  image: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  index: {
    fontSize: 16,
    color: theme.colors.textLight,
    marginRight: 40,
    marginTop: 26,
  },
  flexSection: {
    flex: 1,
  },
  middleSection: {
    flex: 1,
    margin: '0 10px',
  },
}));

StayDiff.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default StayDiff;
