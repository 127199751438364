import {
  SET_SUPPLIER,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAILURE,
  GET_SUPPLIER,
  GET_SUPPLIER_SUCCESS,
  GET_SUPPLIER_FAILURE,
} from '../../actionTypes';

export const setSupplier = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SET_SUPPLIER,
    payload,
  };
};

export const updateSupplier = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_SUPPLIER,
    payload,
  };
};

export const updateSupplierFailure = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPDATE_SUPPLIER_FAILURE,
    payload,
  };
};

export const updateSupplierSuccess = (payload) => {
  return {
    type: UPDATE_SUPPLIER_SUCCESS,
    payload,
  };
};

export const getSupplier = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_SUPPLIER,
    payload,
  };
};

export const getSupplierFailure = (payload) => {
  return {
    type: GET_SUPPLIER_FAILURE,
    payload,
  };
};

export const getSupplierSuccess = (payload) => {
  return {
    type: GET_SUPPLIER_SUCCESS,
    payload,
  };
};
