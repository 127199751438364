import {
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  UPDATE_TOKEN_INIT,
  GET_EXPERT_DETAILS_INIT,
  GET_EXPERT_DETAILS_SUCCESS,
  GET_EXPERT_DETAILS_FAILURE,
  // UPDATE_TOKEN_SUCCESS,
  // UPDATE_TOKEN_FAILURE,
} from '../../actionTypes';

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};

export const login = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: LOGIN_INIT,
    payload,
  };
};

export const logoutSuccess = (data) => {
  return {
    type: LOGOUT_SUCCESS,
    data,
  };
};

export const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    error,
  };
};

export const logout = (params, headers) => {
  const payload = {
    params,
    headers,
  };
  return {
    type: LOGOUT_INIT,
    payload,
  };
};

export const updateToken = (params) => {
  return {
    type: UPDATE_TOKEN_INIT,
    params,
  };
};

export const getExpertDetailsSuccess = (data) => {
  return {
    type: GET_EXPERT_DETAILS_SUCCESS,
    data,
  };
};

export const getExpertDetailsFailure = (error) => {
  return {
    type: GET_EXPERT_DETAILS_FAILURE,
    error,
  };
};

export const getExpertDetails = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_EXPERT_DETAILS_INIT,
    payload,
  };
};
