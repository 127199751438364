import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/styles/makeStyles';

const LabelInput = ({
  label,
  value,
  required = false,
  placeholder = '',
  error,
  onChange,
  children,
  readOnly,
  inputProps = {},
  onFocus,
  onBlur,
  extraClass = '',
  labelClass = '',
  inputClass = '',
  disabledInput = false,
  containerStyle = {},
}) => {
  const classes = useStyles();
  const [focused, toggleFocus] = useState(false);
  const focusHandler = (e) => {
    toggleFocus(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const blurHandler = (e) => {
    toggleFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };
  const isFocusable = focused && !disabledInput && !inputProps.readOnly && !readOnly;
  return (
    <div className={clsx(classes.container, extraClass)} style={containerStyle}>
      <Typography className={clsx(classes.label, labelClass, isFocusable && classes.focusedLabel)}>
        {label}
        {required && <sup className={classes.required}>*</sup>}
      </Typography>
      {children || (
        <InputBase
          value={value}
          disabled={disabledInput}
          autoComplete="off"
          placeholder={placeholder}
          onFocus={focusHandler}
          onBlur={blurHandler}
          classes={{
            input: classes.input,
          }}
          readOnly={readOnly}
          className={clsx(classes.inputRoot, inputClass,
            isFocusable ? classes.focused : classes.blurred,
            error && classes.error)}
          onChange={(e) => onChange(e.target.value)}
          {...inputProps}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  focusedLabel: {
    color: `${theme.colors.primary} !important`,
  },
  input: {
    padding: 0,
    height: '100%',
    fontSize: 16,
    overflowY: 'auto !important',
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  focused: {
    border: `1px solid ${theme.colors.primary}`,
  },
  blurred: {
    border: `1px solid ${theme.colors.border}`,
  },
  error: {
    border: `1px solid ${theme.colors.red}`,
  },
  inputRoot: {
    height: 40,
    padding: '6px 15px',
    borderRadius: 4,
    width: '100%',
  },
  required: {
    verticalAlign: 'top',
    marginLeft: '6px',
    fontWeight: 'bold',
  },
}));

LabelInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  extraClass: PropTypes.string,
  containerStyle: PropTypes.object,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  children: PropTypes.element,
  disabledInput: PropTypes.bool,
  inputProps: PropTypes.object,
  startAdornment: PropTypes.element,
  error: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default LabelInput;
