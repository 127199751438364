import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.global.css';
import App from './app';
import store from './store';
import * as serviceWorker from './serviceWorker';

const app = () => (
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
);

const preventDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

window.addEventListener('drop', preventDrop);
window.addEventListener('dragend', preventDrop);
window.addEventListener('dragover', preventDrop);

ReactDOM.render(app(), document.getElementById('root'));

window.addEventListener('storage', (event) => {
  if (event.key === 'updatedStore') {
    window.isDirty = true;
  }
});

if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
  serviceWorker.register();
}
