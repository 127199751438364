import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import withStyles from '@material-ui/styles/withStyles';
import AutocompleteItem from './autocompleteItem';

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      focused: false,
      selected: -1,
    };
    this.inputRef = React.createRef();
  }

  onBlurHandler = () => {
    this.setState({ isVisible: false, focused: false });
  };

  onFocusHandler = () => {
    const { onChange } = this.props;
    onChange('');
    this.setState({ isVisible: true, focused: true });
    if (this.inputRef.current) {
      this.inputRef.current.children[0].focus();
    }
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e.target.value);
    }
  };

  selectionHandler = (item, index) => {
    const { onChange, onSelected } = this.props;
    this.inputRef.current.focus();
    onChange('');
    onSelected(item, index);
  };

  optionsKeyboardHandler = (e, data) => {
    const { selected } = this.state;
    let newSelected = selected;
    if (e.key === 'ArrowDown') {
      if (selected + 1 < data.length) {
        newSelected++;
      } else {
        newSelected = 0;
      }
    } else if (e.key === 'ArrowUp') {
      if (selected - 1 > 0) {
        newSelected--;
      } else {
        newSelected = data.length - 1;
      }
    } else if (e.key === 'Enter') {
      this.selectionHandler(data[selected], selected);
      return;
    }
    this.setState({ selected: newSelected });
  };

  render() {
    const {
      data = [],
      label = '',
      value,
      error,
      // key,
      uniqueId,
      placeholder = '',
      selections = [],
      classes,
      extraClass = '',
      inputClass = '',
      accessor = '',
      popupClass = '',
      secondaryAccessor,
      removeHandler,
      renderType,
    } = this.props;
    const { isVisible, focused, selected } = this.state;
    let selectionsToRender = selections;
    let extras = false;
    if (selections.length > 2) {
      selectionsToRender = selections.slice(0, 2);
      extras = `+${selections.length - 2}`;
    }
    const rootClass = label ? 'labelRoot' : 'root';
    let dispData = data;
    if (isVisible) {
      const selectionMap = {};
      for (let i = 0; i < selections.length; i++) {
        selectionMap[selections[i][accessor]] = 1;
      }
      dispData = [];
      for (let i = 0; i < data.length; i++) {
        if (!selectionMap[data[i][accessor]]) {
          dispData.push(data[i]);
        }
      }
    }
    const Icon = focused ? KeyboardArrowUp : KeyboardArrowDown;
    const ky = uniqueId || accessor;
    return (
      <div className={clsx(classes.container, extraClass)}>
        {label ? (
          <Typography
            className={clsx(classes.label, focused && classes.focusedLabel)}
          >
            {label}
          </Typography>
        ) : null}
        <ClickAwayListener onClickAway={this.onBlurHandler}>
          <div
            className={clsx(classes[rootClass],
              focused && classes.focusedRoot,
              error && classes.error)}
          >
            <div
              role="button"
              tabIndex="0"
              onClick={this.onFocusHandler}
              className={classes.row}
            >
              {!focused && selectionsToRender.map((selection) => {
                return (
                  <Chip
                    key={selection[accessor]}
                    label={selection[accessor]}
                    onDelete={() => removeHandler(selection)}
                    classes={{
                      root: classes.chip,
                      label: classes.chipLabel,
                      deleteIcon: classes.chipIcon,
                    }}
                    deleteIcon={<CloseIcon />}
                  />
                );
              })}
              {(!focused && extras) ? (
                <Typography className={classes.extras}>{extras}</Typography>
              ) : null}
              <InputBase
                ref={this.inputRef}
                className={clsx(classes.textField, inputClass)}
                value={value}
                placeholder={selections.length === 0 ? placeholder : ''}
                classes={{
                  input: classes.input,
                }}
                autoComplete="off"
                onChange={(e) => this.handleChange(e)}
                onFocus={this.onFocusHandler}
                onKeyDown={(e) => this.optionsKeyboardHandler(e, dispData)}
                endAdornment={(
                  <IconButton
                    className={classes.iconBtn}
                    onClick={this.onFocusHandler}
                  >
                    <Icon />
                  </IconButton>
                )}
              />
            </div>
            {((isVisible && data.length) || (focused && selections.length)) ? (
              <div className={clsx(classes.paper, popupClass)}>
                {selections.length ? (
                  <div className={classes.selectionsRow}>
                    {selections.map((selection) => {
                      return (
                        <Chip
                          key={selection[ky]}
                          label={selection[accessor]}
                          onDelete={() => removeHandler(selection)}
                          classes={{
                            root: classes.popupChip,
                            label: classes.chipLabel,
                            deleteIcon: classes.chipIcon,
                          }}
                          deleteIcon={<CloseIcon />}
                        />
                      );
                    })}
                  </div>
                ) : null}
                {dispData.map((item, index) => {
                  const isSelected = selected === index;
                  return (
                    <div
                      key={item[ky]}
                      role="button"
                      tabIndex="0"
                      className={clsx(classes.item, isSelected && classes.selected)}
                      onClick={() => this.selectionHandler(item, index)}
                    >
                      <AutocompleteItem
                        accessor={accessor}
                        secondaryAccessor={secondaryAccessor}
                        item={item}
                        index={index}
                        renderType={renderType}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  focusedRoot: {
    border: `1px solid ${theme.colors.primary} !important`,
  },
  focusedLabel: {
    color: theme.colors.primary,
  },
  labelRoot: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${theme.colors.border}`,
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  error: {
    border: `1px solid ${theme.colors.red}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: '6px 5px',
    boxSizing: 'border-box',
    outline: 'none',
  },
  selectionsRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '6px 5px',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.colors.border}`,
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.colors.white,
    boxShadow: '0 4px 8px 1px rgba(0,0,0,0.1)',
    zIndex: 1301,
    width: '100%',
    top: 39,
    borderRadius: 4,
    maxHeight: 200,
    overflowY: 'auto',
  },
  textField: {
    cursor: 'pointer',
    width: '100%',
  },
  item: {
    fontSize: 16,
    fontFamily: 'Lato',
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 15px',
    color: theme.colors.black,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  primaryText: {
    margin: 0,
    fontSize: 16,
    color: theme.colors.black,
  },
  secondaryText: {
    margin: '6px 0 0 0',
    fontSize: 14,
    color: theme.colors.textLight,
  },
  selected: {
    backgroundColor: theme.colors.primaryLight,
  },
  input: {
    padding: 0,
    height: '100%',
    fontSize: 16,
    cursor: 'pointer',
  },
  extras: {
    backgroundColor: theme.colors.primaryLight,
    color: theme.colors.black,
    borderRadius: 12.5,
    fontSize: '12px',
    boxSizing: 'border-box',
    height: '25px',
    padding: '4px 8px',
  },
  chip: {
    borderRadius: 12.5,
    color: theme.colors.black,
    height: 25,
    marginRight: 4,
    maxWidth: 120,
    backgroundColor: theme.colors.primaryLight,
  },
  popupChip: {
    borderRadius: 12.5,
    color: theme.colors.black,
    height: 25,
    marginRight: 4,
    backgroundColor: theme.colors.primaryLight,
    marginBottom: 6,
  },
  chipLabel: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
  },
  chipIcon: {
    padding: '0',
    fontSize: '14px',
    color: theme.colors.white,
    backgroundColor: theme.colors.underline_1,
    borderRadius: '50%',
    marginLeft: '-4px',
    width: 14,
    height: 14,
    '&:hover': {
      color: theme.colors.white,
      backgroundColor: theme.colors.textLight,
    },
  },
  iconBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

MultiSelect.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  onSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  selections: PropTypes.array,
  removeHandler: PropTypes.func.isRequired,
  extraClass: PropTypes.string,
  inputClass: PropTypes.string,
  accessor: PropTypes.string.isRequired,
  secondaryAccessor: PropTypes.string,
  uniqueId: PropTypes.string,
  popupClass: PropTypes.string,
  renderType: PropTypes.oneOf(['place']),
};

export default withStyles(styles)(MultiSelect);
