import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/styles/withStyles';
import { CSSTransition } from 'react-transition-group';
import StaySelection from 'components/itineraryMaker/staySelection';
import PreviewDocs from 'components/dashboard/previewDocs';
import Button from 'UI/button';
import Autocomplete from 'UI/autocomplete';
import DotLine from 'UI/dotLine';
import PartActionButton from 'UI/partActionButton';
import Footer from 'UI/footer';
import RouteSection from 'UI/routeSection';
import LabelInput from 'UI/labelInput';
import { CHANGE_TYPES, IMAGE_PLACEHOLDER, ITINERARY_ACTIONS_TYPES } from 'utils/consts';
import {
  getCardClass, getOriginalPart, capitalize,
  getPartsForPreview, getDateStr,
} from 'utils/common';
import StarRating from './starRating';

class StayPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: {},
      showStaySelection: false,
      previewDialog: false,
      previewFiles: [],
      fileDetails: [],
    };
    this.bookingAction = {
      [ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING]: true,
      [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY]: true,
      [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH]: true,
    };
    const { suppliers } = this.props;
    this.suppliers = suppliers.map((s) => s.supplier);
  }

  componentDidMount = () => {
    const { suppliers } = this.props;
    this.suppliers = suppliers.map((s) => s.supplier);
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  addStayHandler = (index) => {
    const { routes } = this.props;
    this.setState({
      activeIndex: {
        route: routes[index],
        routeIndex: index,
        itineraryIndex: -1,
        edit: false,
        mode: 'new',
      },
      showStaySelection: true,
    });
  };

  actionHandler = (action, index, stay, routeIndex) => {
    const { itineraryParts, onUpdate, routes } = this.props;
    if (action === 'edit') {
      this.setState({
        activeIndex: {
          route: routes[routeIndex],
          routeIndex,
          itineraryIndex: index,
          edit: true,
          mode: 'edit',
        },
        showStaySelection: true,
      });
    } else if (action === 'view') {
      console.log('\n*************** actionHandler edit ***************\n');
      console.log(routes, routeIndex);
      this.setState({
        activeIndex: {
          route: routes[routeIndex],
          routeIndex,
          itineraryIndex: index,
          edit: false,
          mode: 'view',
        },
        showStaySelection: true,
      });
    } else if (action === 'remove') {
      onUpdate('remove', index);
    } else if (action === 'save') {
      const { activeIndex: { itineraryIndex, route } } = this.state;
      let supplier = {};
      if (stay.supplier) {
        supplier = stay.supplier;
      }
      const modStay = {
        ...itineraryParts[itineraryIndex],
        ...stay,
        route,
        routeIndex,
        type: 'STAY',
        supplier,
        startTimeStamp: moment(stay.checkInDate).toDate(),
        endTimeStamp: moment(stay.checkOutDate).toDate(),
      };
      const actionType = itineraryIndex === -1 ? 'add' : 'edit';
      onUpdate(actionType, itineraryIndex, modStay);
      this.setState({
        activeIndex: { itineraryIndex: -1 },
        showStaySelection: false,
      });
    }
  };

  handleSupplierSelection = (action, index, value) => {
    const { itineraryParts, onUpdate } = this.props;
    const stay = itineraryParts[index];
    const modStay = { ...stay };
    if (action === 'change') {
      modStay.supplier = {
        value,
        valid: false,
        item: {},
      };
    } else if (action === 'select') {
      modStay.supplier = {
        value: value.name,
        valid: true,
        item: value,
      };
    }
    onUpdate('edit', index, modStay);
  };

  getItineraryStays = () => {
    const { itineraryParts, routes } = this.props;
    const { count, previewParts } = getPartsForPreview(itineraryParts, 'STAY', routes);
    return {
      stays: previewParts,
      stayCount: count,
    };
  };

  handleStayPreview = (stay) => {
    const previewFiles = [];
    const fileDetails = [];
    if (stay.images?.length) {
      for (let i = 0; i < stay.images.length; i++) {
        const image = stay.images[i];
        fileDetails.push({
          type: 'image/jpg',
          name: image.title,
        });
        previewFiles.push(image.url);
      }
    }
    if (stay.videos?.length) {
      for (let i = 0; i < stay.videos.length; i++) {
        const video = stay.videos[i];
        fileDetails.push({
          type: 'video/mp4',
          name: video.title,
        });
        previewFiles.push(video.url);
      }
    }
    this.setState({ previewDialog: true, previewFiles, fileDetails });
  };

  handleStayConfirmationChange = (itineraryPart, value) => {
    const { itineraryParts, onUpdate } = this.props;
    const itineraryPartIndex = itineraryParts.findIndex((iPt) => iPt._id === itineraryPart._id);
    onUpdate('edit', itineraryPartIndex, {
      ...itineraryParts[itineraryPartIndex],
      customerRefNum: value,
    });
  }

  renderStay = (stay, index) => {
    const {
      classes, mode,
      itineraryActionType, version,
    } = this.props;
    let descriptionText = '';
    const {
      checkInDate, checkOutDate,
      stay: sty, supplier, customerRefNum,
    } = stay;
    if (checkInDate && checkOutDate) {
      descriptionText = `${moment(checkInDate).format('DD MMM')} - ${moment(checkOutDate)
        .format('DD MMM')}`;
    }
    let isDeleted = false;
    let extraCardClass = '';
    if (
      itineraryActionType === ITINERARY_ACTIONS_TYPES.APPROVE_REJECT_ITINERARY
      || itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
      extraCardClass = getCardClass(stay.proposedChange.changeType);
      if (stay.proposedChange.changeType === CHANGE_TYPES.DELETE) {
        isDeleted = true;
      }
    }
    const finalImage = sty.images[0] ? sty.images[0].url : IMAGE_PLACEHOLDER;
    const stayTypes = sty.stayTypes.map((s) => capitalize(s)).join(', ');
    return (
      <div className={classes.stayParent} key={sty._id}>
        <div className={clsx(classes.stayCard, classes[extraCardClass])}>
          <button
            type="button"
            className={classes.previewButton}
            onClick={() => this.handleStayPreview(sty)}
          >
            <img alt="" decoding="async" className={classes.stayImage} src={finalImage} />
          </button>
          <div className={classes.description}>
            <Typography className={classes.stayType}>
              {stayTypes}
            </Typography>
            <Typography className={classes.stayName}>{sty.name}</Typography>
            <div className={classes.cardRow}>
              <StarRating rating={sty.starRatingNumber} extraClass={classes.starRating} />
              <Typography
                className={classes.descriptionText}
              >
                {descriptionText}
              </Typography>
              {((!isDeleted && version !== 2)
                || this.bookingAction[itineraryActionType]) ? (
                  <Autocomplete
                    data={this.suppliers}
                    value={supplier.value}
                    extraClass={classes.autocomplete}
                    inputClass={classes.autocompleteInput}
                    accessor="name"
                    onChange={(val) => this.handleSupplierSelection('change', stay.itineraryIndex, val)}
                    onSelected={(item) => this.handleSupplierSelection('select', stay.itineraryIndex, item)}
                  />
                ) : null}
            </div>
          </div>
          {itineraryActionType === 'confirm_booking' || itineraryActionType === 'generate_voucher' || itineraryActionType === 'view_booking' ? (
            <LabelInput
              required
              extraClass={classes.confirmationNumber}
              label="Confirmation No."
              placeholder="Confirmation number"
              disabledInput={itineraryActionType !== 'confirm_booking'}
              value={customerRefNum}
              onChange={(val) => this.handleStayConfirmationChange(stay, val)}
            />
          ) : null}
          <PartActionButton
            actionHandler={this.actionHandler}
            editArgs={[stay.itineraryIndex, {}, index]}
            removeArgs={[stay.itineraryIndex]}
            mode={mode}
            isDeleted={isDeleted}
            itineraryActionType={itineraryActionType}
          />
          {/* {(options && options.length < 2) ? ( // options wont have options field
              <Button
                variant="plain"
                className={classes.actionBtn}
                onClick={() => this.actionHandler('add_option', stay.itineraryIndex)}
              >
                + STAY OPTION
              </Button>
            ) : null} */}
          {/* </div> */}
        </div>
        {/* {options && options
          .map((optionalStay, indx) => this.renderStay(optionalStay, rIndex, sIndex, indx))} */}
      </div>
    );
  };

  render() {
    const {
      classes, nextHandler, itineraryParts, transferModes, isFetchingSuggestions,
      originalParts, isProcessing, suggestions, getSuggestions,
      meta, mode, itineraryActionType, errorMsg, version, requirement, routes,
      minDate,
    } = this.props;
    const {
      showStaySelection, previewDialog, previewFiles, fileDetails,
      activeIndex,
    } = this.state;
    let activeStay;
    let relatedRoute = {};
    if (activeIndex.itineraryIndex !== -1 && showStaySelection) {
      activeStay = itineraryParts[activeIndex.itineraryIndex];
    }
    const originalStay = getOriginalPart(originalParts,
      activeStay, itineraryActionType, showStaySelection || true);
    if (activeIndex.route) {
      relatedRoute = activeIndex.route;
    }
    const { stays, stayCount } = this.getItineraryStays();
    let buttonText = 'Save & continue';
    if (mode === 'view') {
      buttonText = 'Next';
    }
    if (itineraryActionType === 'confirm_booking') {
      buttonText = 'Submit';
    }
    let staySelection;
    if (showStaySelection) {
      staySelection = (
        <StaySelection
          isFetchingSuggestions={isFetchingSuggestions}
          requirement={requirement}
          activeStay={activeStay}
          originalStay={originalStay}
          relatedRoute={relatedRoute}
          suggestions={suggestions}
          getSuggestions={getSuggestions}
          transferModes={transferModes}
          minDate={minDate}
          mode={activeIndex.mode}
          meta={meta}
          version={version}
          itineraryActionType={itineraryActionType}
          onAddStay={(stay) => this.actionHandler('save', activeIndex.itineraryIndex, stay, activeIndex.routeIndex)}
          onDismiss={() => this.handleChange('showStaySelection', false)}
        />
      );
    }
    const dialogClass = previewDialog ? 'vDialog' : 'nvDialog';
    return (
      <div className={classes.container}>
        <div className={classes[dialogClass]}>
          {previewDialog ? (
            <PreviewDocs
              base64Files={previewFiles}
              fileDetails={fileDetails}
              index={0}
              onDismiss={() => this.handleChange('previewDialog', false)}
            />
          ) : null}
        </div>
        {version === 2 ? (
          <Drawer
            anchor="right"
            open={showStaySelection}
            onClose={() => this.handleChange('showStaySelection', false)}
          >
            {staySelection}
          </Drawer>
        )
          : (
            <CSSTransition
              in={showStaySelection}
              timeout={300}
              classNames="overlay"
              unmountOnExit
            >
              <div className={classes.overlayContainer}>
                {staySelection}
              </div>
            </CSSTransition>
          )}
        <div className={classes.routesContainer}>
          {(mode === 'view' && stayCount === 0) ? (
            <Typography className={classes.emptyText}> No stays added</Typography>
          ) : routes.map((route, index) => {
            const isLast = routes.length === index + 1;
            const dateStr = getDateStr(routes[index].dates.from, routes[index].dates.to);
            return (
              <div key={dateStr} className={classes.wrapper}>
                <DotLine isLast={isLast} />
                <div className={classes.routeItem}>
                  <RouteSection route={routes[index]} />
                  {(stays[index] || []).map((stay) => this.renderStay(stay, index))}
                  {(mode === 'edit' && itineraryActionType !== 'generate_voucher') ? (
                    <Button
                      variant="outlined"
                      className={classes.addStayBtn}
                      onClick={() => this.addStayHandler(index)}
                    >
                      + Add stay
                    </Button>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        {nextHandler ? (
          <Footer errorMsg={errorMsg}>
            <Button
              variant="normal"
              className={classes.saveBtn}
              onClick={nextHandler}
              loading={isProcessing}
            >
              {buttonText}
            </Button>
          </Footer>
        ) : null}
      </div>
    );
  }
}

const styles = ((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    maxHeight: '74vh',
    overflowY: 'auto',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  nvDialog: {
    opacity: 0,
    widows: 0,
    height: 0,
    display: 'none',
  },
  vDialog: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1499,
  },
  routesContainer: {
    padding: '20px 40px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  routeItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: 16,
  },
  routeName: {
    color: theme.colors.textLight,
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  addStayBtn: {
    width: 140,
    margin: '0 auto 20px 0',
  },
  overlayContainer: {
    zIndex: 3,
    position: 'absolute',
    top: 64,
    width: '100%',
    height: 'calc(100% - 64px)',
  },
  emptyText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    textAlign: 'center',
  },
  stayParent: {
    width: '100%',
  },
  stayCard: {
    width: '96%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 6,
    marginBottom: 20,
    marginLeft: 16,
    transition: 'box-shadow 400ms',
    '&:hover': {
      boxShadow: theme.extraShadows[1],
    },
  },
  deletedCard: {
    backgroundColor: theme.colors.redLight,
  },
  editedCard: {
    backgroundColor: theme.colors.primarySelected,
  },
  stayImage: {
    width: 220,
    height: 120,
  },
  previewButton: {
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
  },
  description: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  confirmationNumber: {
    marginTop: '10px',
    marginRight: '10px',
  },
  starRating: {
    marginRight: 8,
  },
  stayType: {
    fontSize: 12,
    color: theme.colors.textLight,
  },
  stayName: {
    fontSize: 16,
    color: theme.colors.black,
  },
  descriptionText: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  cardRow: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  autocomplete: {
    height: 36,
    width: 180,
    marginRight: 10,
    marginLeft: 46,
  },
  autocompleteInput: {
    padding: '5px 15px',
  },
  stayImg: {
    width: 92,
    height: 68,
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px 20px 40px',
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
}));

StayPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  suppliers: PropTypes.array.isRequired,
  routes: PropTypes.array.isRequired,
  itineraryParts: PropTypes.array.isRequired,
  nextHandler: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  transferModes: PropTypes.array.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  originalParts: PropTypes.object,
  mode: PropTypes.string,
  errorMsg: PropTypes.string,
  isProcessing: PropTypes.bool,
  version: PropTypes.number,
  requirement: PropTypes.object.isRequired,
  minDate: PropTypes.instanceOf(Date),
  isFetchingSuggestions: PropTypes.bool.isRequired,
};

export default withStyles(styles)(StayPreview);
