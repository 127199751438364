import React from 'react';
import * as PropTypes from 'prop-types';

const Success = ({
  width = 16,
  height = 16,
  className,
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
    >
      <path
        fill={fill}
        d="M13.1 5.333a2 2 0 0 1 2 2.301l-.92 6a2 2 0 0 1-1.992 1.7h-7.52c-.368 0-.667-.3-.667-.667V7.333c0-.093.02-.186.057-.27l2.667-6c.107-.24.346-.396.61-.396C8.806.667 10 1.86 10 3.333v2h3.1zM5.333 14h6.86c.332.004.617-.238.667-.568l.92-6c.03-.193-.028-.4-.156-.537a.67.67 0 0 0-.518-.229H9.333c-.368 0-.667-.298-.667-.667V3.333c0-.595-.4-1.1-.928-1.27L5.333 7.475V14zM4 8H2.667C2.298 8 2 8.298 2 8.667v4.667c0 .368.298.667.667.667H4V8zm.667 7.333h-2a2 2 0 0 1-2-2V8.667a2 2 0 0 1 2-2h2c.368 0 .667.298.667.667v7.333c0 .368-.298.667-.667.667z"
      />
    </svg>
  );
};

Success.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Success;
