import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddOutlined';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withStyles from '@material-ui/styles/withStyles';

class AddAutocomplete extends Component {
  state = {
    focused: false,
    isVisible: false,
  };

  onBlurHandler = () => {
    this.setState({ isVisible: false, focused: false });
  };

  onFocusHandler = () => {
    this.setState({ isVisible: true, focused: true });
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e.target.value);
    }
  };

  selectionHandler = (item, index) => {
    const { onSelected } = this.props;
    this.setState({ isVisible: false, focused: false });
    onSelected(item, index);
  };

  render() {
    const {
      data = [],
      label = '',
      value,
      classes,
      extraClass = '',
      inputClass = '',
      accessor = '',
      popupClass = '',
    } = this.props;
    const { isVisible, focused } = this.state;
    const rootClass = label ? 'labelRoot' : 'root';
    return (
      <div className={clsx(classes.container, extraClass)}>
        {label ? (
          <Typography
            className={clsx(classes.label, focused && classes.focusedLabel)}
          >
            {label}
          </Typography>
        ) : null}
        <ClickAwayListener onClickAway={this.onBlurHandler}>
          <div
            className={clsx(classes[rootClass], focused && classes.focusedRoot)}
          >
            <div className={classes.row}>
              <InputBase
                className={clsx(classes.textField, inputClass)}
                autoComplete="off"
                value={value}
                classes={{
                  input: classes.input,
                }}
                onChange={(e) => this.handleChange(e)}
                onFocus={this.onFocusHandler}
              />
              {focused ? <KeyboardArrowUp className={classes.icon} />
                : <KeyboardArrowDown className={classes.icon} />}
            </div>
            {(isVisible && data.length) ? (
              <div className={clsx(classes.paper, popupClass)}>
                {data.map((item, index) => {
                  if (item.type === 'add_new') {
                    return (
                      <div
                        key={item.type}
                        role="button"
                        tabIndex="0"
                        className={classes.item}
                        onClick={() => this.selectionHandler(item, index)}
                      >
                        <AddIcon className={classes.addIcon} />
                        <Typography className={classes.addText}>
                          {`Add ${value}`}
                        </Typography>
                      </div>
                    );
                  }
                  return (
                    <div
                      key={item[accessor]}
                      role="button"
                      tabIndex="0"
                      className={classes.item}
                      onClick={() => this.selectionHandler(item, index)}
                    >
                      {item[accessor]}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${theme.colors.border}`,
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  focusedRoot: {
    border: `1px solid ${theme.colors.primary} !important`,
  },
  focusedLabel: {
    color: theme.colors.primary,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
  },
  labelRoot: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${theme.colors.border}`,
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.colors.white,
    boxShadow: '0 4px 8px 1px rgba(0,0,0,0.1)',
    zIndex: 1301,
    width: '100%',
    borderRadius: 4,
    maxHeight: 200,
    overflowY: 'auto',
  },
  textField: {
    width: '100%',
    cursor: 'pointer',
    padding: '6px 5px 6px 15px',
    height: 40,
    boxSizing: 'border-box',
  },
  item: {
    fontSize: 16,
    fontFamily: 'Lato',
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 15px',
    color: theme.colors.black,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  input: {
    padding: 0,
    height: '100%',
    fontSize: 16,
  },
  addText: {
    paddingLeft: 6,
    fontSize: 16,
    color: theme.colors.primary,
  },
  addIcon: {
    color: theme.colors.primary,
  },
  icon: {
    paddingRight: 5,
  },
});

AddAutocomplete.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  extraClass: PropTypes.string,
  inputClass: PropTypes.string,
  accessor: PropTypes.string,
  popupClass: PropTypes.string,
};

export default withStyles(styles)(AddAutocomplete);
