import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import makeStyles from '@material-ui/styles/makeStyles';
import { getTravellerText } from 'utils/common';
import Section from 'UI/section';
import DotLine from 'UI/dotLine';
import DiffInput from './diffInput';

const TransfersDiffNew = ({ data, oldData }) => {
  const classes = useStyles();
  const transfersLength = data.transfers.length;
  const renderTransfers = (newTransfers, oldTransfers) => {
    return newTransfers.map((transfer, index) => {
      const isLast = transfersLength === index + 1;
      let label = index === 0 ? 'Start' : `Point ${String.fromCharCode(65 + index - 1)}`;
      if (isLast) {
        label = 'End';
      }
      return (
        <Section
          key={transfer.id}
          type="row"
          align="none"
          extraClass={classes.transferSection}
        >
          <DotLine
            isLast={isLast}
            lineClass={!isLast ? classes.lineClass : undefined}
          />
          <DiffInput
            label={label}
            value={transfer.value}
            oldValue={oldTransfers[index] ? oldTransfers[index].value : '-'}
            extraClass={classes.transferPlace}
          />
        </Section>
      );
    });
  };
  return (
    <div className={classes.container}>
      <div className={classes.transfers}>
        <DiffInput
          value={data.tripType}
          oldValue={oldData.tripType}
          extraClass={classes.tripType}
        />
        {renderTransfers(data.transfers, oldData.transfers)}
      </div>
      <div className={classes.body}>
        <Section
          type="row"
          gutterBottom
        >
          <DiffInput
            label="Transfer date"
            value={moment(data.transferDate).format('DD MMM YY - hh:mm A')}
            oldValue={moment(oldData.transferDate).format('DD MMM YY - hh:mm A')}
            extraClass={classes.input}
          />
          <DiffInput
            label="Mode of transfer"
            value={data.transferMode.value}
            oldValue={oldData.transferMode.value}
            extraClass={classes.input}
          />
        </Section>
        <Section
          type="row"
          gutterBottom
        >
          <DiffInput
            label="No. of travellers"
            value={getTravellerText(data.travellers)}
            oldValue={getTravellerText(oldData.travellers)}
            extraClass={classes.input}
          />
        </Section>
        <Section
          type="row"
          gutterBottom
        >
          <DiffInput
            label="DESCRIPTION"
            value={data.description}
            oldValue={oldData.description}
            extraClass={classes.input}
          />
        </Section>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  transfers: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.primarySelected,
    marginBottom: 30,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 40px',
  },
  input: {
    flex: 1,
  },
  transferPlace: {
    width: 320,
    margin: '-10px 20px 20px 0',
    backgroundColor: theme.colors.primarySelected,
  },
  lineClass: {
    backgroundColor: theme.colors.primaryBackground,
  },
  transferSection: {
    backgroundColor: theme.colors.primarySelected,
  },
  tripType: {
    paddingBottom: 20,
    backgroundColor: theme.colors.primarySelected,
  },
}));

TransfersDiffNew.propTypes = {
  data: PropTypes.object.isRequired,
  oldData: PropTypes.object.isRequired,
};

export default TransfersDiffNew;
