import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import DiffInput from './diffInput';

const InstallmentDiff = ({ data, oldData }) => {
  const classes = useStyles();
  const oldLength = oldData.length;
  const newLength = data.length;
  const renderInstallmentRow = (newInstallment = {}, oldInstallment = {}, index) => {
    return (
      <div
        key={newInstallment.uniqueId || oldInstallment.uniqueId}
        className={classes.row}
      >
        <Typography className={classes.title}>
          {index === 0 ? 'Token' : `Installment ${index}`}
        </Typography>
        <DiffInput
          label=""
          value={newInstallment.amount || '-'}
          oldValue={oldInstallment.amount || '-'}
          extraClass={classes.inputBox}
        />
        <DiffInput
          label=""
          value={newInstallment.dueDate ? moment(newInstallment.dueDate).format('DD MMM YY') : '-'}
          oldValue={oldInstallment.dueDate ? moment(oldInstallment.dueDate)
            .format('DD MMM YY') : '-'}
          extraClass={classes.dateBox}
        />
        {/* <DiffInput
          label=""
          value={newInstallment.paymentMode?.value || '-'}
          oldValue={oldInstallment.paymentMode?.value || '-'}
          extraClass={classes.paymentBox}
        /> */}
      </div>
    );
  };
  const installments = [];
  for (let i = 0; i < data.length; i++) {
    installments.push(renderInstallmentRow(data[i], oldData[i], i));
  }
  return (
    <div className={classes.container}>
      {installments}
      {(oldLength > newLength) ? (
        oldData.slice(newLength).map((oldInstallment, i) => (
          renderInstallmentRow({}, oldInstallment, i + newLength)))
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px 0',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  title: {
    fontSize: 14,
    color: theme.colors.textDark,
    minWidth: 100,
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  inputBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    minWidth: 110,
    marginRight: 10,
  },
  dateBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    minWidth: 110,
    marginRight: 10,
  },
  paymentBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    minWidth: 110,
    marginRight: 10,
  },
}));

InstallmentDiff.propTypes = {
  data: PropTypes.array.isRequired,
  oldData: PropTypes.array.isRequired,
};

export default InstallmentDiff;
