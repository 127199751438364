import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/styles/withStyles';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import SearchIcon from 'assets/icons/search';
import Chip from 'UI/chip';
import Button from 'UI/button';
import Footer from 'UI/footer';
import Header from 'UI/header';
import arrowBack from 'assets/svg/arrow_left.svg';
import tickImg from 'assets/svg/tick.svg';
import { IMAGE_PLACEHOLDER } from 'utils/consts';
import CreateExperience from './createExperience';

class SelectExperience extends Component {
  constructor(props) {
    super(props);
    const { activity } = this.props;
    let changes = {};
    if (activity) {
      changes = this.processExperience(activity);
    }
    this.state = {
      searchVal: '',
      errorMsg: '',
      selectedCategories: {},
      selectedExperience: {},
      showExperienceDetails: false,
      edit: false,
      ...changes,
    };
  }

  componentDidMount = () => {
    this.getSuggestions();
  };

  getSuggestions = () => {
    const { getSuggestions, route } = this.props;
    const { searchVal, selectedCategories } = this.state;
    getSuggestions('experiences', searchVal, {
      placeIds: [route.place.item._id],
      placeRequired: true,
      categories: Object.keys(selectedCategories),
      limit: 100,
    });
  };

  processExperience = (experience) => {
    return {
      selectedExperience: experience.experience,
      showExperienceDetails: true,
      edit: true,
    };
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value }, () => {
      if (field === 'searchVal') {
        this.getSuggestions();
      }
    });
  };

  handleCategorySelection = (category) => {
    const { selectedCategories } = this.state;
    const modCats = { ...selectedCategories };
    if (modCats[category.code]) {
      delete modCats[category.code];
    } else {
      modCats[category.code] = category;
    }
    this.setState({ selectedCategories: modCats }, () => {
      this.getSuggestions();
    });
  };

  dismissPreference = () => {
    const { onDismiss } = this.props;
    const { edit } = this.state;
    if (edit) {
      onDismiss();
    } else {
      this.setState({ showExperienceDetails: false });
    }
  };

  submitHandler = (activityDetails) => {
    const { onSubmit } = this.props;
    const { selectedExperience } = this.state;
    const params = {
      experience: selectedExperience,
      ...activityDetails,
    };
    onSubmit(params);
  };

  render() {
    const {
      classes, onDismiss, isFetchingSuggestions,
      transferModes, activity, experienceCategories = [],
      route, suggestions, itineraryActionType,
      getSuggestions, mode, originalActivity,
      requirement,
    } = this.props;
    const {
      searchVal, errorMsg, selectedCategories,
      showExperienceDetails, selectedExperience,
    } = this.state;
    const placeholderTxt = route.place.item.name;
    return (
      <div className={classes.container}>
        <Header
          className={classes.headerStrip}
          titleClass={classes.titleClass}
          variant="back"
          img={arrowBack}
          title={`Select experience in ${route.place.item.name}`}
          onDismiss={onDismiss}
        />
        <Drawer
          anchor="right"
          open={showExperienceDetails}
          onClose={this.dismissPreference}
        >
          <CreateExperience
            mode={mode}
            requirement={requirement}
            itineraryActionType={itineraryActionType}
            route={route}
            suggestions={suggestions}
            getSuggestions={getSuggestions}
            transferModes={transferModes}
            experience={selectedExperience}
            activity={activity}
            onDismiss={this.dismissPreference}
            onSubmit={this.submitHandler}
            originalActivity={originalActivity}
          />
        </Drawer>
        {isFetchingSuggestions ? <LinearProgress className={classes.loader} /> : null}
        <div className={classes.body}>
          <div className={classes.filterSection}>
            <Typography className={classes.filterText}>Filters</Typography>
            <div className={classes.filters}>
              {experienceCategories.map((cat) => {
                const { code, title } = cat;
                return (
                  <Chip
                    dynamicWidth={false}
                    label={title}
                    key={code}
                    selected={Boolean(selectedCategories[code])}
                    extraClass={classes.chip}
                    onSelected={() => this.handleCategorySelection(cat)}
                  />
                );
              })}
            </div>
          </div>
          <div className={classes.displayBody}>
            <InputBase
              className={classes.searchBox}
              classes={{
                focused: classes.focusedBox,
              }}
              value={searchVal}
              placeholder={`Search experience in ${placeholderTxt}`}
              onChange={(e) => this.handleChange('searchVal', e.target.value)}
              endAdornment={<SearchIcon />}
            />
            {suggestions.experiences.length === 0 ? (
              <>
                <Typography
                  className={classes.noResultTitle}
                >
                  Couldn&apos;t find any matching results
                </Typography>
                <Typography className={classes.noResult}>• Try another search</Typography>
                <Typography className={classes.noResult}>
                  •  Try changing the filters
                </Typography>
              </>
            ) : null}
            <div className={classes.experiences}>
              {suggestions.experiences.map((experience) => {
                const imgLink = experience.images.length ? experience.images[0].url
                  : IMAGE_PLACEHOLDER;
                const isSelectedExperience = experience._id === selectedExperience._id;
                return (
                  <button
                    type="button"
                    key={experience._id}
                    className={clsx(classes.card, isSelectedExperience && classes.selectedCard)}
                    onClick={() => this.handleChange('selectedExperience', experience)}
                  >
                    {isSelectedExperience ? (
                      <img src={tickImg} alt="selected" className={classes.tickImg} />
                    ) : null}
                    <img src={imgLink} alt=" " className={classes.experienceImg} />
                    <Typography className={classes.experienceTitle}>
                      {experience.title}
                    </Typography>
                    <div className={classes.row}>
                      <PlaceIcon className={classes.placeIcon} />
                      <Typography className={classes.experienceLocation}>
                        {_.startCase(experience.address.place.normalizedName)}
                      </Typography>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <Footer errorMsg={errorMsg}>
            <Button
              disabled={!selectedExperience.title}
              onClick={() => this.handleChange('showExperienceDetails', true)}
            >
              Select & Continue
            </Button>
          </Footer>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '70vw',
    borderRadius: 10,
    backgroundColor: theme.colors.white,
    overflowY: 'auto',
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  loader: {
    position: 'absolute',
    top: 40,
    width: '100%',
    left: 0,
    color: theme.colors.primary,
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  displayBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 30px',
  },
  filterSection: {
    flex: 1,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '20%',
    padding: '20px 20px 20px 40px',
    borderRight: `1px solid ${theme.colors.border}`,
  },
  filterText: {
    fontSize: 14,
    color: theme.colors.black,
    marginBottom: 6,
  },
  chip: {
    cursor: 'pointer',
    marginRight: 4,
    marginBottom: 10,
    width: 110,
    '&:hover': {
      backgroundColor: theme.colors.primarySelected,
    },
    '& > p': {
      fontSize: 12,
    },
  },
  searchBox: {
    width: '100%',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    height: '40px',
    boxSizing: 'border-box',
    padding: '10px 20px',
    marginBottom: 20,
    color: theme.colors.black,
    fontSize: 16,
  },
  focusedBox: {
    border: `1px solid ${theme.colors.primary}`,
  },
  createText: {
    color: theme.colors.primary,
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 0.5,
    marginLeft: 6,
  },
  noResultTitle: {
    fontSize: 16,
    color: theme.colors.textLight,
    marginBottom: 15,
  },
  noResult: {
    marginLeft: 30,
    fontSize: 16,
    color: theme.colors.textLight,
    marginBottom: 10,
  },
  experiences: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    width: 210,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  selectedCard: {
    backgroundColor: theme.colors.primaryLight,
  },
  experienceImg: {
    height: 110,
    width: 190,
    borderRadius: 4,
  },
  tickImg: {
    position: 'absolute',
    padding: 6,
    borderRadius: '50%',
    top: -13,
    right: -13,
    boxSizing: 'border-box',
    width: 26,
    height: 26,
    backgroundColor: theme.colors.primary,
    border: `1px solid ${theme.colors.white}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  experienceTitle: {
    fontSize: 14,
    color: theme.colors.black,
    padding: '10px 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  experienceLocation: {
    paddingLeft: 10,
    fontSize: 12,
    color: theme.colors.textLight,
  },
  placeIcon: {
    width: 20,
    height: 20,
    color: theme.colors.primary,
  },
});

SelectExperience.propTypes = {
  classes: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetchingSuggestions: PropTypes.bool.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  activity: PropTypes.object,
  originalActivity: PropTypes.object,
  route: PropTypes.object.isRequired,
  transferModes: PropTypes.array.isRequired,
  mode: PropTypes.string,
  experienceCategories: PropTypes.array.isRequired,
  requirement: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectExperience);
