import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Header from 'UI/header';
import arrowBack from 'assets/svg/arrow_left.svg';
import Autocomplete from 'UI/autocomplete';
import LabelInput from 'UI/labelInput';
import Button from 'UI/button';
import Footer from 'UI/footer';
import TravellerSelection from 'UI/travellerSelection';
import { spacedToCamelStr, getButtonText, shouldShowDiffToggle } from 'utils/common';
import { SUPPLIER_TYPES } from 'utils/consts';
import DocumentCards from 'UI/documentCards';
import ServiceDiff from 'components/itineraryMaker/serviceDiff';

class CreateService extends Component {
  constructor(props) {
    super(props);
    this.services = ['Forex', 'Travel Insurance', 'Visa']
      .map((s) => ({ name: s }));
    const { service = {}, itineraryActionType, originalService } = this.props;
    let prevState = {};
    this.originalService = {};
    if (service?.serviceType) {
      const processedService = this.processServiceData(service);
      prevState = {
        ...processedService,
        valid: true,
        edit: true,
      };
      this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalService);
      if (this.showDiffToggle) {
        this.originalService = this.processServiceData(originalService);
      }
    }
    this.state = {
      activeService: '',
      service: {
        value: '',
        valid: false,
      },
      costPrice: 0,
      sellingPrice: 0,
      supplier: {
        value: '',
        valid: false,
        item: {},
      },
      forexData: {
        targetAmount: 0,
        sourceCurrency: {
          value: '',
          valid: false,
          item: {},
        },
        targetCurrency: {
          value: '',
          valid: false,
          item: {},
        },
      },
      travelInsuranceData: {
        pax: {
          adults: 0,
          children: 0,
          infants: 0,
        },
        description: '',
        documents: [],
      },
      visaData: {
        pax: {
          adults: 0,
          children: 0,
          infants: 0,
        },
        country: {
          value: '',
          valid: false,
          item: {},
        },
      },
      valid: false,
      edit: false,
      showDiff: false,
      ...prevState,
    };
  }

  processServiceData = (service) => {
    const {
      serviceType,
      supplier,
      costPrice,
      sellPrice,
    } = service;
    const normalisedServiceTypes = {
      Forex: 'Forex',
      'Travel Insurance': 'Travel Insurance',
      Visa: 'Visa',
      FOREX: 'Forex',
      VISA: 'Visa',
      TRAVEL_INSURANCE: 'Travel Insurance',
      'TRAVEL INSURANCE': 'Travel Insurance',
    };
    const activeService = `${spacedToCamelStr(normalisedServiceTypes[service.serviceType])}Data`;
    let serviceData = null;
    const data = service[activeService];
    if (activeService === 'forexData') {
      serviceData = {
        targetAmount: data.targetAmount,
        sourceCurrency: {
          value: data.sourceCurrency.code,
          valid: true,
          item: data.sourceCurrency,
        },
        targetCurrency: {
          value: data.targetCurrency.code,
          valid: true,
          item: data.targetCurrency,
        },
      };
    } else if (activeService === 'travelInsuranceData') {
      serviceData = {
        pax: data.pax || {},
        documents: data.documents || [],
        description: data.description || '',
      };
    } else if (activeService === 'visaData') {
      serviceData = {
        country: {
          value: data.country.name,
          valid: true,
          item: data.country,
        },
        pax: data.pax || {},
      };
    }
    return {
      service: {
        value: serviceType,
        valid: true,
      },
      supplier,
      activeService,
      [activeService]: serviceData,
      costPrice: costPrice / 100,
      sellingPrice: sellPrice / 100,
    };
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  handleServiceChange = (value) => {
    this.setState({
      service: {
        value,
        valid: false,
        item: {},
      },
    });
  };

  handleServiceSelection = (item, accessor) => {
    const activeService = `${spacedToCamelStr(item[accessor])}Data`;
    this.setState({
      service: {
        value: item[accessor],
        valid: true,
      },
      activeService,
    });
  };

  handleSupplierChange = (value) => {
    const { activeService } = this.state;
    const { getSuggestions } = this.props;
    let supportedService;
    if (activeService === 'travelInsuranceData') {
      supportedService = SUPPLIER_TYPES.INSURANCE;
    } else if (activeService === 'visaData') {
      supportedService = SUPPLIER_TYPES.VISA;
    } else {
      supportedService = SUPPLIER_TYPES.FOREX;
    }
    getSuggestions('supplier', value, {
      supportedServices: [supportedService],
    });
    this.setState({
      supplier: {
        value,
        valid: false,
        item: {},
      },
    });
  };

  handleSupplierSelection = (item) => {
    this.setState({
      supplier: {
        value: item.name,
        valid: true,
        item,
      },
    }, this.validityChecker);
  };

  handleServiceDataChange = (field, value) => {
    const { getSuggestions } = this.props;
    const { activeService } = this.state;
    if (activeService === 'forexData'
      && (field === 'targetCurrency' || field === 'sourceCurrency')) {
      getSuggestions('currencies', value);
    } else if (activeService === 'visaData' && field === 'country') {
      getSuggestions('destinations', value, { includeAddressTypes: ['country'] });
    }
    const { [activeService]: serviceData } = this.state;
    const modServiceData = { ...serviceData };
    if (modServiceData[field].constructor.name === 'Object' && field !== 'pax') {
      modServiceData[field] = {
        value,
        valid: false,
        item: {},
      };
    } else {
      modServiceData[field] = value;
    }
    this.setState({ [activeService]: modServiceData }, this.validityChecker);
  };

  handleServiceDataSelection = (field, item, accessor) => {
    const { activeService } = this.state;
    const { [activeService]: serviceData } = this.state;
    const modServiceData = { ...serviceData };
    modServiceData[field] = {
      value: item[accessor],
      valid: true,
      item,
    };
    this.setState({ [activeService]: modServiceData }, this.validityChecker);
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value }, this.validityChecker);
  };

  handleUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { travelInsuranceData } = this.state;
      const modData = { ...travelInsuranceData };
      const modDocuments = [...modData.documents];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const { type } = file;
        if (type && type === 'application/pdf') {
          modDocuments.push(file);
        }
      }
      modData.documents = modDocuments;
      this.setState({ travelInsuranceData: modData });
    }
  };

  handleDocumentRemoval = (index) => {
    const { travelInsuranceData } = this.state;
    const modServiceData = { ...travelInsuranceData };
    modServiceData.documents.splice(index, 1);
    this.setState({ travelInsuranceData: modServiceData });
  };

  getServiceDataValidity = () => {
    const {
      activeService, forexData, travelInsuranceData, visaData,
    } = this.state;
    let valid = true;
    if (activeService === 'forexData') {
      const { sourceCurrency, targetAmount, targetCurrency } = forexData;
      if (!sourceCurrency.valid || !targetCurrency.valid || targetAmount === 0) {
        valid = false;
      }
    } else if (activeService === 'travelInsuranceData') {
      const { coverAmount, pax } = travelInsuranceData;
      if (coverAmount === 0 || pax === 0) {
        valid = false;
      }
    } else if (activeService === 'visaData') {
      const { country, pax } = visaData;
      if (!country.valid || pax === 0) {
        valid = false;
      }
    }
    return valid;
  };

  validityChecker = (setError) => {
    const {
      service, costPrice,
      sellingPrice, supplier,
    } = this.state;
    let valid = true;
    let errorMsg = '';
    if (!service.valid) {
      valid = false;
      errorMsg = 'Invalid service selection';
    } else if (!this.getServiceDataValidity()) {
      valid = false;
      errorMsg = 'Invalid service details';
    } else if (costPrice === 0) {
      valid = false;
      errorMsg = 'Cost price cannot be 0';
    } else if (sellingPrice === 0) {
      valid = false;
      errorMsg = 'Selling price cannot be 0';
    } else if (!supplier.valid) {
      valid = false;
      errorMsg = 'Invalid supplier';
    }
    if (setError) {
      this.setState({
        valid,
        errorMsg,
      });
    } else {
      this.setState({ valid });
    }
    return valid;
  };

  getServiceData = () => {
    const { activeService } = this.state;
    const { [activeService]: data } = this.state;
    let serviceData = {};
    if (activeService === 'forexData') {
      serviceData = {
        targetAmount: data.targetAmount,
        sourceCurrency: data.sourceCurrency.item,
        targetCurrency: data.targetCurrency.item,
      };
    } else if (activeService === 'travelInsuranceData') {
      serviceData = {
        pax: data.pax,
        documents: data.documents,
        description: data.description,
      };
    } else if (activeService === 'visaData') {
      serviceData = {
        country: data.country.item,
        pax: data.pax,
      };
    }
    return serviceData;
  };

  onSubmit = () => {
    const {
      onSubmit, service: srv,
      isEdit, onDismiss,
    } = this.props;
    const {
      service, activeService, costPrice,
      sellingPrice, supplier, valid,
    } = this.state;
    if (!isEdit) {
      onDismiss();
      return;
    }
    if (!valid) {
      this.validityChecker(true);
      return;
    }
    const params = {
      ...srv,
      serviceType: service.value,
      [activeService]: this.getServiceData(),
      costPrice: costPrice * 100,
      sellPrice: sellingPrice * 100,
      supplier,
    };
    onSubmit(params);
  };

  render() {
    const {
      classes, onDismiss, version,
      suggestions = {}, itineraryActionType,
    } = this.props;
    const {
      service, costPrice, sellingPrice, supplier, activeService, showDiff,
      visaData, travelInsuranceData, forexData, valid, errorMsg, edit,
    } = this.state;
    return (
      <div className={clsx(classes.container, version === 2 && classes.newContainer)}>
        <Header
          className={classes.headerStrip}
          titleClass={classes.titleClass}
          variant="back"
          img={arrowBack}
          title="Select service"
          onDismiss={onDismiss}
          showDiffToggle={this.showDiffToggle}
          checked={this.showDiffToggle && showDiff}
          label="VIEW CHANGES"
          onToggle={this.toggleDiff}
        />
        {showDiff ? (
          <ServiceDiff oldData={this.originalService} data={this.state} />
        )
          : (
            <div className={classes.body}>
              <Autocomplete
                label="TYPE OF ADD-ON"
                value={service.value}
                data={this.services}
                accessor="name"
                extraClass={classes.addOnContainer}
                inputClass={classes.addOnInput}
                onChange={(val) => this.handleServiceChange(val)}
                onSelected={(item) => this.handleServiceSelection(item, 'name')}
              />
              {activeService === 'forexData' ? (
                <div className={classes.row}>
                  <div className={classes.autoCompleteWrapper}>
                    <Typography
                      className={classes.label}
                    >
                      AMOUNT TO CONVERT
                    </Typography>
                    <div className={classes.toSection}>
                      <Autocomplete
                        extraClass={classes.toSectionAutocomplete}
                        inputClass={classes.addOnInput}
                        data={suggestions.currencies}
                        value={forexData.targetCurrency.value}
                        accessor="code"
                        variant="no-outline"
                        placeholder="USD"
                        popupClass={classes.popupClass}
                        onChange={(val) => this.handleServiceDataChange('targetCurrency', val)}
                        onSelected={(item) => this.handleServiceDataSelection('targetCurrency', item, 'code')}
                      />
                      <InputBase
                        autoFocus
                        placeholder="Amount"
                        value={forexData.targetAmount}
                        type="number"
                        onChange={(e) => this.handleServiceDataChange('targetAmount', e.target.value, '')}
                        classes={{
                          root: classes.inputArea,
                        }}
                      />
                    </div>
                  </div>
                  <Autocomplete
                    data={suggestions.currencies}
                    value={forexData.sourceCurrency.value}
                    label="CONVERT FROM"
                    accessor="code"
                    placeholder="INR"
                    extraClass={classes.fromAutocomplete}
                    inputClass={classes.fromCurrInput}
                    popupClass={classes.popupClass}
                    bodyClass={classes.fromAutocompleteBody}
                    labelClass={classes.autoCompleteLabel}
                    onChange={(val) => this.handleServiceDataChange('sourceCurrency', val)}
                    onSelected={(item) => this.handleServiceDataSelection('sourceCurrency', item, 'code')}
                  />
                </div>
              ) : null}
              {activeService === 'travelInsuranceData' ? (
                <div className={classes.column}>
                  <div className={classes.row}>
                    {/* <LabelInput
                  label="PAX"
                  value={travelInsuranceData.pax}
                  extraClass={classes.left}
                  inputProps={{
                    type: 'number',
                  }}
                  onChange={(val) => this.handleServiceDataChange('pax', val)}
                /> */}
                    <TravellerSelection
                      label="PAX"
                      travellers={travelInsuranceData.pax}
                      extraClass={classes.travellerPax}
                      onChange={(val) => this.handleServiceDataChange('pax', val)}
                    />
                    {/* <LabelInput
                  label="Cover amount"
                  value={travelInsuranceData.coverAmount}
                  extraClass={classes.right}
                  inputProps={{
                    type: 'number',
                  }}
                  onChange={(val) => this.handleServiceDataChange('coverAmount', val)}
                /> */}
                  </div>
                  <div className={classes.row}>
                    <LabelInput
                      label="POLICY DESCRIPTION"
                      extraClass={classes.descriptionWrapper}
                      inputProps={{
                        multiline: true,
                        rows: 3,
                        rowsMax: 3,
                      }}
                      inputClass={classes.description}
                      value={travelInsuranceData.description}
                      placeholder="Details about insurance"
                      onChange={(val) => this.handleServiceDataChange('description', val)}
                    />
                    <div className={classes.dropArea}>
                      <input
                        type="file"
                        accept="application/pdf"
                        ref={this.dropInput}
                        className={classes.dropBox}
                        onChange={this.handleUpload}
                      />
                      <Typography
                        className={classes.uploadText}
                      >
                        Upload insurance documents
                      </Typography>
                      <DocumentCards
                        documents={travelInsuranceData.documents}
                        onRemove={this.handleDocumentRemoval}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {activeService === 'visaData' ? (
                <div className={classes.row}>
                  <TravellerSelection
                    label="PAX"
                    travellers={visaData.pax}
                    extraClass={classes.left}
                    onChange={(val) => this.handleServiceDataChange('pax', val)}
                  />
                  <Autocomplete
                    data={suggestions.destinations}
                    value={visaData.country.value}
                    extraClass={classes.right}
                    inputClass={classes.fromCurrInput}
                    bodyClass={classes.fromAutocompleteBody}
                    labelClass={classes.autoCompleteLabel}
                    label="Country"
                    accessor="name"
                    onChange={(val) => this.handleServiceDataChange('country', val)}
                    onSelected={(item) => this.handleServiceDataSelection('country', item, 'name')}
                  />
                </div>
              ) : null}
              <div className={classes.row}>
                <LabelInput
                  placeholder="Cost price"
                  label="Cost price"
                  inputProps={{
                    type: 'number',
                  }}
                  value={costPrice}
                  extraClass={classes.left}
                  onChange={(val) => this.handleChange('costPrice', val)}
                />
                <LabelInput
                  placeholder="Selling price"
                  label="Selling price"
                  inputProps={{
                    type: 'number',
                  }}
                  value={sellingPrice}
                  extraClass={classes.right}
                  onChange={(val) => this.handleChange('sellingPrice', val)}
                />
              </div>
              <div className={classes.row}>
                <Autocomplete
                  data={suggestions.suppliers}
                  value={supplier.value}
                  label="Supplier"
                  accessor="name"
                  extraClass={classes.fromAutocomplete}
                  inputClass={classes.fromCurrInput}
                  popupClass={classes.popupClass}
                  bodyClass={classes.fromAutocompleteBody}
                  labelClass={classes.autoCompleteLabel}
                  onChange={(val) => this.handleSupplierChange(val)}
                  onSelected={(item) => this.handleSupplierSelection(item)}
                />
              </div>
            </div>
          )}
        <Footer errorMsg={errorMsg}>
          <Button
            className={clsx(classes.createButton, !valid && classes.disabled)}
            onClick={this.onSubmit}
          >
            {getButtonText(itineraryActionType, edit, 'service')}
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 940,
    height: 562,
    maxHeight: 562,
    borderRadius: 10,
    backgroundColor: theme.colors.white,
    overflowY: 'auto',
  },
  newContainer: {
    overflowY: 'auto',
    maxHeight: 'inherit',
    height: 'inherit',
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  body: {
    padding: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  addOnContainer: {
    width: 300,
    maxHeight: 100,
    marginBottom: 16,
  },
  addOnInput: {
    width: '100%',
    height: '100%',
    padding: '5px 15px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,
  },
  autoCompleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
    flex: 1,
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  toSection: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
  },
  inputArea: {
    padding: '10px 12px',
    boxSizing: 'border-box',
    height: 40,
    width: '100%',
  },
  fromAutocomplete: {
    flex: 1,
  },
  fromAutocompleteBody: {
    height: 42,
  },
  fromSection: {
    flex: 1,
    flexDirection: 'column',
    margin: '0 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: 4,
  },
  autoCompleteLabel: {
    marginBottom: 10,
  },
  fromCurrInput: {
    padding: '0 0 0 15px',
    width: '100%',
    height: '100%',
  },
  left: {
    flex: 1,
    display: 'flex',
    marginRight: 10,
  },
  right: {
    flex: 1,
    display: 'flex',
    marginLeft: 10,
  },
  travellerPax: {
    width: 'calc(50% - 10px)',
    display: 'flex',
    marginRight: 10,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
  },
  toSectionAutocomplete: {
    padding: '0',
    height: 40,
    width: 110,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  dropArea: {
    width: '50%',
    height: 100,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    padding: 10,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 0,
    cursor: 'pointer',
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  uploadText: {
    fontSize: 14,
    color: theme.colors.primary,
    alignSelf: 'center',
  },
  description: {
    height: 72,
    padding: '6px 12px',
    marginTop: 'auto',
  },
  descriptionWrapper: {
    flex: 1,
    marginRight: 10,
    // marginBottom: 16,
  },
  createButton: {
    width: 180,
    color: theme.colors.white,
    fontSize: 16,
    borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
});

CreateService.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  service: PropTypes.object,
  originalService: PropTypes.object,
  version: PropTypes.number,
  itineraryActionType: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CreateService);
