import {
  PING_USER_INIT,
  PING_USER_SUCCESS,
  PING_USER_FAILURE,
  GET_PARAMS_META_INIT,
  GET_PARAMS_META_SUCCESS,
  GET_PARAMS_META_FAILURE,
  // TOGGLE_ACTIVE_INIT,
  // TOGGLE_ACTIVE_SUCCESS,
  // TOGGLE_ACTIVE_FAILURE,
  SUGGEST_AUTOCOMPLETE_INIT,
  SUGGEST_AUTOCOMPLETE_SUCCESS,
  SUGGEST_AUTOCOMPLETE_FAILURE,
  GET_CONVERSION_INIT,
  GET_CONVERSION_SUCCESS,
  GET_CONVERSION_FAILURE,
  UPLOAD_DOCS_INIT,
  UPLOAD_DOCS_SUCCESS,
  UPLOAD_DOCS_FAILURE,
  REGISTER_NOTIF_TOKEN_INIT,
  REGISTER_NOTIF_TOKEN_SUCCESS,
  REGISTER_NOTIF_TOKEN_FAILURE,
} from '../../actionTypes';

export const pingUserSuccess = (data) => {
  return {
    type: PING_USER_SUCCESS,
    data,
  };
};

export const pingUserFailure = (error) => {
  return {
    type: PING_USER_FAILURE,
    error,
  };
};

export const pingUser = (params, headers) => {
  const payload = { params, headers };
  return {
    type: PING_USER_INIT,
    payload,
  };
};

export const getParamsSuccess = (data) => {
  return {
    type: GET_PARAMS_META_SUCCESS,
    data,
  };
};

export const getParamsFailure = (error) => {
  return {
    type: GET_PARAMS_META_FAILURE,
    error,
  };
};

export const getParams = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_PARAMS_META_INIT,
    payload,
  };
};

// export const toggleActiveSuccess = (data) => {
//   return {
//     type: TOGGLE_ACTIVE_SUCCESS,
//     data,
//   };
// };

// export const toggleActiveFailure = (error) => {
//   return {
//     type: TOGGLE_ACTIVE_FAILURE,
//     error,
//   };
// };

// export const toggleActive = (params, headers) => {
//   const payload = { params, headers };
//   return {
//     type: TOGGLE_ACTIVE_INIT,
//     payload,
//   };
// };

export const suggestAutocompleteSuccess = (data) => {
  return {
    type: SUGGEST_AUTOCOMPLETE_SUCCESS,
    data,
  };
};

export const suggestAutocompleteFailure = (error) => {
  return {
    type: SUGGEST_AUTOCOMPLETE_FAILURE,
    error,
  };
};

export const suggestAutocomplete = (params, headers) => {
  const payload = { params, headers };
  return {
    type: SUGGEST_AUTOCOMPLETE_INIT,
    payload,
  };
};

export const getConversionSuccess = (data) => {
  return {
    type: GET_CONVERSION_SUCCESS,
    data,
  };
};

export const getConversionFailure = (error) => {
  return {
    type: GET_CONVERSION_FAILURE,
    error,
  };
};

export const getConversion = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_CONVERSION_INIT,
    payload,
  };
};

export const uploadDocsSuccess = (data) => {
  return {
    type: UPLOAD_DOCS_SUCCESS,
    data,
  };
};

export const uploadDocsFailure = (error) => {
  return {
    type: UPLOAD_DOCS_FAILURE,
    error,
  };
};

export const uploadDocs = (params, headers) => {
  const payload = { params, headers };
  return {
    type: UPLOAD_DOCS_INIT,
    payload,
  };
};

export const registerNotifTokenSuccess = (data) => {
  return {
    type: REGISTER_NOTIF_TOKEN_SUCCESS,
    data,
  };
};

export const registerNotifTokenFailure = (error) => {
  return {
    type: REGISTER_NOTIF_TOKEN_FAILURE,
    error,
  };
};

export const registerNotifToken = (params, headers) => {
  const payload = { params, headers };
  return {
    type: REGISTER_NOTIF_TOKEN_INIT,
    payload,
  };
};
