import {
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  UPDATE_TOKEN_INIT,
  GET_EXPERT_DETAILS_INIT,
  GET_EXPERT_DETAILS_SUCCESS,
  GET_EXPERT_DETAILS_FAILURE,
} from '../../actionTypes';

const initialState = {
  isLogging: false,
  loginResp: {},
  loginError: false,
  loginErrorMsg: '',
  isLoggingOut: false,
  logOutResp: {},
  logOutError: false,
  logOutErrorMsg: '',
  getExpertDetailsResp: {},
  getExpertDetailsError: false,
  getExpertDetailsErrorMsg: '',
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_INIT:
      return {
        ...state,
        isLogging: true,
        loginError: false,
        loginErrorMsg: '',
      };

    case LOGIN_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isLogging: false,
        loginResp: data,
      };
    }

    case LOGIN_FAILURE:
      return {
        ...state,
        isLogging: false,
        loginError: true,
        loginErrorMsg: action.error,
      };

    case LOGOUT_INIT:
      return {
        ...state,
        isLoggingOut: true,
        logOutError: false,
        logOutErrorMsg: '',
      };

    case LOGOUT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        loginResp: {},
        isLoggingOut: false,
        logOutResp: data,
        logOutError: false,
        logOutErrorMsg: '',
      };
    }

    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logOutError: true,
        logOutErrorMsg: action.error,
      };

    case UPDATE_TOKEN_INIT:
      return {
        ...state,
        loginResp: action.params,
      };

    case GET_EXPERT_DETAILS_INIT:
      return {
        ...state,
        isLogging: true,
        getExpertDetailsError: false,
      };

    case GET_EXPERT_DETAILS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isLogging: false,
        getExpertDetailsResp: data,
        loginResp: { ...state.loginResp, ...data },
      };
    }

    case GET_EXPERT_DETAILS_FAILURE:
      return {
        ...state,
        isLogging: false,
        getExpertDetailsError: true,
        getExpertDetailsErrorMsg: action.error,
      };

    default:
      return state;
  }
};

export default auth;
