import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/styles/withStyles';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import StayDiffNew from 'components/itineraryMaker/stayDiffNew';
import Header from 'UI/header';
import LabelInput from 'UI/labelInput';
import DateRangeSelection from 'UI/dateRangeSelection';
import Button from 'UI/button';
import Footer from 'UI/footer';
import arrowBack from 'assets/svg/arrow_left.svg';
import {
  capitalize,
  getButtonText,
  getDateStr,
  getTravellerText,
  getValidDates,
  shouldShowDiffToggle,
} from 'utils/common';
import TravellerSelection from 'UI/travellerSelection';
import { IMAGE_PLACEHOLDER } from 'utils/consts';
import RoomSelection from './roomSelection';
import StarRating from './starRating';
import DiffInput from './diffInput';

class StayPreferencesNew extends Component {
  constructor(props) {
    super(props);
    let prevState = {};
    this.originalStay = {};
    const {
      requirement, stay = {}, relatedRoute,
      mode, itineraryActionType, originalStay,
    } = this.props;
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalStay);
    if (stay.stay && stay.roomDetails) {
      prevState = this.processStay(stay);
      if (this.showDiffToggle) {
        this.originalStay = this.processStay(originalStay);
      }
    }
    this.state = {
      errorMsg: '',
      valid: false,
      checkinCheckout: '',
      mealPlanDialog: false,
      transferMode: {
        value: '',
        valid: false,
        items: [],
      },
      travellers: {
        adults: 0,
        children: 0,
        infants: 0,
        ...requirement.travellers,
      },
      roomDetails: [],
      checkinCheckoutDates: {
        from: relatedRoute.dates.from,
      },
      notes: '',
      edit: mode === 'edit',
      showDiff: false,
      roomSelectionDrawer: false,
      stayInfo: false,
      roomEdit: false,
      roomIndex: {},
      ...prevState,
    };
    if (props.minDate) {
      this.disabledDateFn = getValidDates(new Date(Math.max(relatedRoute.dates.from, props.minDate)), relatedRoute.dates.to);
    } else {
      this.disabledDateFn = getValidDates(relatedRoute.dates.from, relatedRoute.dates.to);
    }
  }

  /*
  componentDidMount = () => {
    this.mapEl = document.getElementById('map');
    if (!this.mapEl) {
      const scriptEl = document.createElement('script');
      scriptEl.async = true;
      scriptEl.defer = true;
      scriptEl.id = 'map';
      scriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API}`;
      scriptEl.onload = this.initMap;
      document.head.appendChild(scriptEl);
      this.mapEl = scriptEl;
    } else {
      this.initMap();
    }
  };
  */

  initMap = () => {
    /* eslint-disable */
    const { stay: { stay } } = this.props;
    const { address: { coordinates: { lat, long } }, name } = stay;
    const location = new google.maps.LatLng(lat, long);
    const map = new google.maps.Map(document.getElementById('googleMap'), {
      center: location,
      zoom: 11,
    });
    new google.maps.Marker({
      position: location,
      label: name,
      map: map,
    });
    /* eslint-enable */
  };

  processStay = (stay) => {
    const checkinCheckoutDates = {
      from: moment(stay.checkInDate).toDate(),
      to: moment(stay.checkOutDate).toDate(),
    };
    const checkinCheckout = getDateStr(stay.checkInDate, stay.checkOutDate);
    return {
      transferMode: {
        value: '',
        valid: true,
        items: stay.transferMode,
      },
      travellers: stay.travellers,
      checkinCheckoutDates,
      checkinCheckout,
      roomDetails: stay.roomDetails,
      notes: stay.notes || '',
      valid: true,
      // edit: true,
    };
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  handleDateSelection = (date, dateStr) => {
    const { from, to } = date;
    if (from && to) {
      this.setState({
        checkinCheckout: dateStr,
        checkinCheckoutDates: date,
      }, this.validityChecker);
    }
  };

  handleChange = (field, value) => {
    const { [field]: itm } = this.state;
    if (itm.constructor.name === 'Object') {
      this.setState({
        [field]: {
          value,
          valid: false,
        },
      }, this.validityChecker);
    } else {
      this.setState({ [field]: value }, this.validityChecker);
    }
  };

  handleTransferChange = (value) => {
    this.setState((prevState) => ({
      transferMode: {
        ...prevState.transferMode,
        value,
      },
    }), this.validityChecker);
  };

  handleTransferSelection = (item) => {
    this.setState((prevState) => ({
      transferMode: {
        items: [item, ...prevState.transferMode.items],
        value: '',
        valid: true,
      },
    }), this.validityChecker);
  };

  handleTransferRemoval = (item) => {
    this.setState((prevState) => ({
      transferMode: {
        items: prevState.transferMode.items.filter((i) => i.code !== item.code),
        value: '',
        valid: true,
      },
    }), this.validityChecker);
  };

  validityChecker = (setError) => {
    const {
      roomDetails,
      checkinCheckoutDates: {
        from = '', to = '',
      },
      travellers,
    } = this.state;
    let valid = true;
    let errorMsg = '';
    if (!from || !to) {
      valid = false;
      errorMsg = 'Select travel date to continue';
    }
    // if (transferMode.items.length === 0) {
    //   valid = false;
    //   errorMsg = 'Please select transfer mode';
    // }
    if (roomDetails.length === 0) {
      valid = false;
      errorMsg = 'Please select room(s) for this stay';
    }
    if (travellers.adults === 0 && travellers.children === 0) {
      valid = false;
      errorMsg = 'Adults and/or children not selected';
    }
    if (setError) {
      this.setState({
        valid,
        errorMsg,
      });
    } else {
      this.setState({ valid });
    }
    return valid;
  };

  addStayHandler = () => {
    const { onSelect } = this.props;
    const valid = this.validityChecker(true);
    if (valid) {
      const {
        checkinCheckoutDates,
        roomDetails,
        transferMode,
        notes,
        travellers,
      } = this.state;
      const checkInDate = moment(checkinCheckoutDates.from).toISOString();
      const checkOutDate = moment(checkinCheckoutDates.to).toISOString();
      const params = {
        checkInDate,
        checkOutDate,
        transferMode: transferMode.items,
        travellers,
        roomDetails,
        notes,
      };
      onSelect(params);
    }
  };

  toggleState = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  handleRoomAction = (action, index, data) => {
    const { roomDetails, roomIndex } = this.state;
    if (action === 'delete') {
      const modDetails = [...roomDetails];
      modDetails.splice(index, 1);
      this.setState({ roomDetails: modDetails }, this.validityChecker);
    } else if (action === 'edit') {
      this.setState({
        roomSelectionDrawer: true,
        roomEdit: true,
        roomIndex: index,
      }, this.validityChecker);
    } else if (action === 'update') {
      const modDetails = [...roomDetails];
      modDetails[roomIndex] = data;
      this.setState({
        roomDetails: modDetails,
        roomSelectionDrawer: false,
        roomEdit: false,
        roomIndex: -1,
      }, this.validityChecker);
    } else if (action === 'save') {
      const modDetails = [...roomDetails];
      modDetails.push(...data);
      this.setState({
        roomDetails: modDetails,
        roomSelectionDrawer: false,
        roomEdit: false,
        roomIndex: -1,
      }, this.validityChecker);
    }
  };

  handleTravellerChange = (travellers) => {
    this.setState({ travellers });
  };

  renderStayPreview = () => {
    const { classes, stay: { stay } } = this.props;
    // const { stayInfo } = this.state;
    // const stayInfoClass = stayInfo ? 'stayExpanded' : 'stayCollapsed';
    const {
      name, address = {}, description,
      images = [], stayTypes = [], starRatingNumber,
    } = stay;
    const { addressText = '' } = address;
    // const ArrowIcon = stayInfo ? UpArrowIcon : DownArrowIcon;
    return (
      <div className={classes.infoSection}>
        <div className={classes.previewSection}>
          {images.length ? (
            <Button
              variant="plain"
              className={classes.imagePreviewButton}
              onClick={() => this.toggleState('imagePreviewDialog')}
            >
              <img alt="" src={images[0].url} className={classes.stayImage} />
            </Button>
          ) : null}
          <div className={classes.stayDetails}>
            <div className={classes.stayRow}>
              <StarRating rating={starRatingNumber} stayType=" " />
              <Typography className={classes.stayTypes}>
                {stayTypes.join(',')}
              </Typography>
            </div>
            <Typography className={classes.stayTitle}>{name}</Typography>
            <Typography className={classes.stayAddress}>{addressText}</Typography>
          </div>
        </div>
        {
          description ? (
            <>
              <Typography className={classes.descriptionText}>
                Description
              </Typography>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </>
          ) : null
        }
        {
          /*
          <Button
            variant="plain"
            className={classes.viewToggleButton}
            onClick={() => this.toggleState('stayInfo')}
          >
            {stayInfo ? 'View less' : 'View more'}
            <ArrowIcon className={classes.arrowIcon} />
          </Button>
          <div className={clsx(classes.stayContainer, classes[stayInfoClass])}>
            <Typography className={classes.subTitle}>
              Location
            </Typography>
            <div id="googleMap" className={classes.googleMap} />
          </div>
          */
        }
      </div>
    );
  };

  renderRoomData = (Icon, value, extraClass) => {
    const { classes } = this.props;
    return (
      <div className={clsx(classes.roomDataRow, extraClass)}>
        <Icon className={classes.roomDataIcon} />
        <Typography className={classes.roomDataText}>
          {value}
        </Typography>
      </div>
    );
  };

  handleRoomDetailChange = (index, field, value) => {
    const { roomDetails } = this.state;
    const modDetails = [...roomDetails];
    const modRoom = { ...modDetails[index] };
    modRoom[field] = value;
    modDetails[index] = modRoom;
    this.setState({ roomDetails: modDetails });
  };

  showMealPlanDialog = () => this.setState({ mealPlanDialog: true });

  closeMealPlanDialog = () => this.setState({ mealPlanDialog: false });

  renderSelectedRooms = () => {
    const { classes, mode, itineraryActionType } = this.props;
    const { roomDetails, mealPlanDialog } = this.state;
    return roomDetails.map((room, index) => {
      const {
        room: {
          name, images = [],
          roomType = '', _id,
        },
        mealPlan = {}, occupancy, roomCheckInDate,
        roomCheckOutDate, customerRefNum = '',
      } = room;
      const finalImage = images[0] ? images[0].url : IMAGE_PLACEHOLDER;
      return (
        <div key={_id} className={classes.roomWrapper}>
          <div className={classes.roomContainer}>
            <img
              decoding="async"
              src={finalImage}
              alt={`${name.slice(0, 5)}...`}
              className={classes.roomImage}
            />
            <div className={clsx(classes.roomInfo, classes.roomData)}>
              <Typography className={classes.roomName}>
                {name}
              </Typography>
              <div className={classes.roomInfoRow}>
                <RoomIcon className={classes.roomDataIcon} />
                <Typography className={classes.roomTypeText}>
                  {capitalize(roomType.replace('_', ' '))}
                </Typography>
              </div>
              <div className={classes.mealPlanDialogRow}>
                <Button onClick={this.showMealPlanDialog}>
                  <RestaurantIcon />
                </Button>
                <Dialog
                  open={mealPlanDialog}
                  maxWidth="lg"
                  onClose={this.closeMealPlanDialog}
                  classes={{
                    paper: classes.visiblePaper,
                  }}
                  aria-labelledby="refund payment"
                >
                  <Header
                    title={`Meal plan details: ${mealPlan.title}`}
                    onDismiss={this.closeMealPlanDialog}
                    img={arrowBack}
                    variant="back"
                  />
                  <div>
                    <span className={classes.px20}>
                      {mealPlan.description}
                    </span>
                    <hr />
                    <div className={classes.px20} dangerouslySetInnerHTML={{ __html: mealPlan.longDescription }} />
                  </div>
                </Dialog>
              </div>
              {mode !== 'view' ? (
                <div className={classes.actionArea}>
                  <IconButton
                    onClick={() => this.handleRoomAction('edit', index)}
                    className={classes.actionButton}
                  >
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                  <IconButton
                    className={classes.actionButton}
                    onClick={() => this.handleRoomAction('delete', index)}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </div>
              ) : null}
            </div>
            <div className={classes.roomData}>
              {this.renderRoomData(PersonIcon, getTravellerText(occupancy))}
              {this.renderRoomData(RestaurantIcon, mealPlan.title, classes.mealPlanRow)}
              {this.renderRoomData(CalendarIcon, getDateStr(roomCheckInDate, roomCheckOutDate))}
            </div>
          </div>
          {itineraryActionType === 'confirm_booking' || itineraryActionType === 'generate_voucher' || itineraryActionType === 'view_booking' ? (
            <LabelInput
              extraClass={classes.refInput}
              placeholder="Confirmation number"
              value={customerRefNum}
              disabledInput={itineraryActionType !== 'confirm_booking'}
              onChange={(val) => this.handleRoomDetailChange(index, 'customerRefNum', val)}
            />
          ) : null}
        </div>
      );
    });
  };

  dismissRoomDrawer = () => {
    this.setState({
      roomSelectionDrawer: false,
      roomEdit: false,
      roomIndex: -1,
    });
  };

  render() {
    const {
      classes, onDismiss, itineraryActionType, mode,
      stay, relatedRoute, requirement,
    } = this.props;
    const {
      checkinCheckout,
      checkinCheckoutDates,
      travellers,
      notes,
      errorMsg,
      valid,
      edit,
      showDiff,
      roomDetails,
      roomEdit,
      roomIndex,
      roomSelectionDrawer,
    } = this.state;
    const stayDetailsText = edit ? 'Edit stay details' : 'Stay details';
    console.log('\n***********   render *************');
    console.log('checkinCheckout: ', checkinCheckout);
    console.log('checkinCheckoutDates', checkinCheckoutDates);
    console.log('\n***********************\n');
    return (
      <div className={classes.container}>
        <Drawer
          anchor="right"
          open={roomSelectionDrawer}
          onClose={this.dismissRoomDrawer}
        >
          <RoomSelection
            edit={roomEdit}
            room={roomDetails[roomIndex]}
            requirement={requirement}
            rooms={stay.rooms}
            mealPlan={stay.stay.mealPlans}
            shouldDisableDate={this.disabledDateFn}
            route={relatedRoute}
            stay={stay.stay}
            mealPlans={stay.stay.mealPlans}
            onUpdate={(action, data) => this.handleRoomAction(action, roomIndex, data)}
            onDismiss={this.dismissRoomDrawer}
          />
        </Drawer>
        <Header
          className={classes.headerStrip}
          titleClass={classes.titleClass}
          img={arrowBack}
          variant="back"
          title="Choose stay | Add preferences"
          onDismiss={onDismiss}
          showDiffToggle={this.showDiffToggle}
          checked={showDiff}
          label="VIEW CHANGES"
          onToggle={this.toggleDiff}
        />
        <div className={classes.body}>
          {this.renderStayPreview()}
          {!showDiff ? (
            <div className={classes.displaySection}>
              <Typography className={classes.subTitle}>
                {stayDetailsText}
              </Typography>
              {mode !== 'view'
                ? (
                  <div className={classes.row}>
                    <LabelInput
                      label="Check in - Check out"
                      extraClass={classes.wrapper}
                    >
                      <DateRangeSelection
                        placeholder="Check in - Check out"
                        value={checkinCheckout}
                        dates={checkinCheckoutDates}
                        shouldDisableDate={this.disabledDateFn}
                        onSelected={(date, dateStr) => this.handleDateSelection(date, dateStr)}
                      />
                    </LabelInput>
                    {/*
                    <MultiSelect
                      label="Transfers included"
                      value={transferMode.value}
                      selections={transferMode.items}
                      placeholder="Transfer"
                      extraClass={classes.wrapperMid}
                      inputClass={classes.autoCompleteInput}
                      data={transferModes}
                      accessor="title"
                      removeHandler={(item) => this.handleTransferRemoval(item)}
                      onChange={(val) => this.handleTransferChange(val)}
                      onSelected={(item) => this.handleTransferSelection(item)}
                    />
                    */}
                    <TravellerSelection
                      label="No. of travellers"
                      extraClass={classes.wrapper}
                      travellers={travellers}
                      onChange={(val) => this.handleTravellerChange(val)}
                    />
                  </div>
                )
                : (
                  <div className={classes.row}>
                    <DiffInput
                      label="Check in - Check out"
                      value={checkinCheckout}
                      oldValue={checkinCheckout}
                      extraClass={classes.input}
                    />
                    {/*
                    <DiffInput
                      label="Transfers included"
                      value={transferMode.items.map((t) => t.title).join(', ')}
                      oldValue={transferMode.items.map((t) => t.title).join(', ')}
                      extraClass={classes.input}
                    />
                    */}
                    <DiffInput
                      label="No. of travellers"
                      value={getTravellerText(travellers)}
                      oldValue={getTravellerText(travellers)}
                      extraClass={classes.input}
                    />
                  </div>
                )}
              {this.renderSelectedRooms()}
              {mode !== 'view' ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.toggleState('roomSelectionDrawer');
                  }}
                  className={classes.addRoomButton}
                >
                  Add room
                </Button>
              ) : null}
              <div className={classes.row}>
                {mode !== 'view'
                  ? (
                    <LabelInput
                      value={notes}
                      inputClass={classes.notes}
                      extraClass={classes.notesContainer}
                      label="Notes"
                      placeholder="Stay notes"
                      inputProps={{
                        multiline: true,
                        rows: 3,
                        rowsMax: 6,
                      }}
                      onChange={(val) => this.handleChange('notes', val)}
                    />
                  )
                  : (
                    <DiffInput
                      label="Notes"
                      value={notes}
                      oldValue={notes}
                      extraClass={classes.input}
                    />
                  )}
              </div>
            </div>
          ) : <StayDiffNew oldData={this.originalStay} data={this.state} />}
        </div>
        <Footer
          errorMsg={errorMsg}
          extraClass={classes.footer}
        >
          <Button
            variant="normal"
            className={clsx(classes.addButton, valid && classes.validBtn)}
            onClick={this.addStayHandler}
          >
            {getButtonText(itineraryActionType, edit, 'stay')}
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    backgroundColor: theme.colors.white,
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // maxHeight: 460,
    overflowY: 'auto',
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: 30,
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
  },
  previewSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  imagePreviewButton: {
    height: 'auto',
  },
  stayImage: {
    width: 280,
    height: 160,
    borderRadius: 8,
  },
  // stayRating: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   justifyContent: 'flex-start',
  //   backgroundColor: theme.colors.primary,
  //   borderRadius: 8,
  //   padding: '2px 8px',
  //   fontSize: 10,
  //   color: theme.colors.white,
  // },
  stayDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 30,
  },
  stayRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  stayTitle: {
    fontSize: 16,
    marginBottom: 6,
    marginRight: 6,
  },
  stayAddress: {
    color: theme.colors.textLight,
    fontSize: 12,
  },
  // starImg: {
  //   width: 10,
  //   height: 10,
  //   marginRight: 4,
  // },
  descriptionText: {
    marginTop: 20,
    fontSize: 16,
    color: theme.colors.black,
    marginBottom: 10,
  },
  description: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  stayTypes: {
    fontSize: 12,
    color: theme.colors.textLight,
    letterSpacing: 1,
    paddingLeft: 10,
    fontWeight: 'bold',
  },
  googleMap: {
    width: '100%',
    height: 300,
  },
  stayContainer: {
    height: 0,
    transition: 'height 0.5s ease-in',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  stayExpanded: {
    height: 420,
    maxHeight: 600,
  },
  stayCollapsed: {
    height: 0,
  },
  viewToggleButton: {
    fontSize: 12,
    color: theme.colors.primary,
    marginTop: 10,
    height: 36,
    marginRight: 'auto',
    width: 120,
  },
  subTitle: {
    fontSize: 16,
    color: theme.colors.black,
    margin: '10px 0',
  },
  arrowIcon: {
    width: 16,
    height: 16,
    color: theme.colors.primary,
  },
  roomType: {
    flex: 1,
  },
  refInput: {
    marginTop: 10,
  },
  displaySection: {
    // flex: 7,
    display: 'flex',
    minWidth: 700,
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '20px 40px',
  },
  index: {
    fontSize: 16,
    color: theme.colors.textLight,
    marginRight: 20,
    marginTop: 26,
  },
  occupancy: {
    width: 'auto',
    flex: 1,
    margin: '0 10px',
  },
  stayDates: {
    width: 'auto',
    flex: 1,
    margin: '0 10px',
  },
  datePopup: {
    left: -260,
  },
  input: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
    width: '100%',
  },
  wrapperMid: {
    flex: 1,
    width: '100%',
    margin: '0 20px',
  },
  autoCompleteInput: {
    padding: '10px 5px 10px 15px',
    height: 40,
    width: '100%',
  },
  px20: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  roomWrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    marginBottom: 16,
  },
  roomContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  roomImage: {
    height: 170,
    width: 300,
    borderRadius: 4,
  },
  roomInfo: {
    position: 'relative',
    padding: '15px 20px',
    borderRight: `1px solid ${theme.colors.border}`,
  },
  roomName: {
    color: theme.colors.black,
    fontSize: 16,
    marginBottom: 10,
  },
  roomInfoRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  roomTypeText: {
    fontSize: 12,
    color: theme.colors.black,
  },
  actionArea: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  actionButton: {
    margin: '0 6px',
  },
  editIcon: {
    width: 16,
    height: 16,
    color: theme.colors.primary,
  },
  deleteIcon: {
    width: 16,
    height: 16,
    color: theme.colors.red,
  },
  roomData: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  roomDataRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  mealPlanRow: {
    border: `1px solid ${theme.colors.border}`,
    borderLeft: 'none',
    borderRight: 'none',
  },
  mealPlanDialogRow: {
    alignSelf: 'flex-end',
  },
  label: {
    font: 'bold',
    '&::after': {
      content: '": "',
    },
  },
  roomDataIcon: {
    width: 20,
    height: 20,
    padding: '0 20px',
    color: theme.colors.primary,
  },
  roomDataText: {
    fontSize: 14,
    color: theme.colors.black,
  },
  notesContainer: {
    width: '100%',
  },
  notes: {
    height: 'auto',
  },
  addRoomButton: {
    width: 120,
    borderRadius: 25,
    color: theme.colors.primary,
    border: `1px solid ${theme.colors.primary}`,
    margin: '20px 0',
  },
  footer: {
    boxSizing: 'border-box',
  },
  addButton: {
    width: 180,
    borderRadius: 25,
    backgroundColor: theme.colors.grey,
    fontSize: 16,
    color: theme.colors.white,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
  validBtn: {
    backgroundColor: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
});

StayPreferencesNew.propTypes = {
  classes: PropTypes.object,
  stay: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  mealPlans: PropTypes.array.isRequired,
  relatedRoute: PropTypes.object.isRequired,
  transferModes: PropTypes.array.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  requirement: PropTypes.object.isRequired,
  originalStay: PropTypes.object,
  mode: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
};

export default withStyles(styles)(StayPreferencesNew);
