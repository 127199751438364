// auth
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_INIT = 'LOGOUT_INIT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const UPDATE_TOKEN_INIT = 'UPDATE_TOKEN_INIT';
export const UPDATE_TOKEN_SUCCESS = 'UPDATE_TOKEN_SUCCESS';
export const UPDATE_TOKEN_FAILURE = 'UPDATE_TOKEN_FAILURE';

// dashboard
export const REQUEST_LIST_INIT = 'REQUEST_LIST_INIT';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_LIST_FAILURE = 'REQUEST_LIST_FAILURE';

export const REQUEST_SEARCH_INIT = 'REQUEST_SEARCH_INIT';
export const REQUEST_SEARCH_SUCCESS = 'REQUEST_SEARCH_SUCCESS';
export const REQUEST_SEARCH_FAILURE = 'REQUEST_SEARCH_FAILURE';

export const ADD_REQUEST_INIT = 'ADD_REQUEST_INIT';
export const ADD_REQUEST_SUCCESS = 'ADD_REQUEST_SUCCESS';
export const ADD_REQUEST_FAILURE = 'ADD_REQUEST_FAILURE';

export const UPDATE_REQUEST_DETAILS_INIT = 'UPDATE_REQUEST_DETAILS_INIT';
export const UPDATE_REQUEST_DETAILS_SUCCESS = 'UPDATE_REQUEST_DETAILS_SUCCESS';
export const UPDATE_REQUEST_DETAILS_FAILURE = 'UPDATE_REQUEST_DETAILS_FAILURE';

export const REASSIGN_REQUEST_INIT = 'REASSIGN_REQUEST_INIT';
export const REASSIGN_REQUEST_SUCCESS = 'REASSIGN_REQUEST_SUCCESS';
export const REASSIGN_REQUEST_FAILURE = 'REASSIGN_REQUEST_FAILURE';

export const UPDATE_STATUS_INIT = 'UPDATE_STATUS_INIT';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

export const SEND_ITINERARY_INIT = 'SEND_ITINERARY_INIT';
export const SEND_ITINERARY_SUCCESS = 'SEND_ITINERARY_SUCCESS';
export const SEND_ITINERARY_FAILURE = 'SEND_ITINERARY_FAILURE';

export const DELETE_ITINERARY_INIT = 'DELETE_ITINERARY_INIT';
export const DELETE_ITINERARY_SUCCESS = 'DELETE_ITINERARY_SUCCESS';
export const DELETE_ITINERARY_FAILURE = 'DELETE_ITINERARY_FAILURE';

export const SAVE_AS_TEMPLATE_ITINERARY_INIT = 'SAVE_AS_TEMPLATE_ITINERARY_INIT';
export const SAVE_AS_TEMPLATE_ITINERARY_SUCCESS = 'SAVE_AS_TEMPLATE_ITINERARY_SUCCESS';
export const SAVE_AS_TEMPLATE_ITINERARY_FAILURE = 'SAVE_AS_TEMPLATE_ITINERARY_FAILURE';

export const REFUND_FROM_WALLET_INIT = 'REFUND_FROM_WALLET_INIT';
export const REFUND_FROM_WALLET_SUCCESS = 'REFUND_FROM_WALLET_SUCCESS';
export const REFUND_FROM_WALLET_FAILURE = 'REFUND_FROM_WALLET_FAILURE';

// export const SUBMIT_ITINERARY_DETAILS_INIT = 'SUBMIT_ITINERARY_DETAILS_INIT';
// export const SUBMIT_ITINERARY_DETAILS_SUCCESS = 'SUBMIT_ITINERARY_DETAILS_SUCCESS';
// export const SUBMIT_ITINERARY_DETAILS_FAILURE = 'SUBMIT_ITINERARY_DETAILS_FAILURE';

export const REMARK_UPDATE_INIT = 'REMARK_UPDATE_INIT';
export const REMARK_UPDATE_SUCCESS = 'REMARK_UPDATE_SUCCESS';
export const REMARK_UPDATE_FAILURE = 'REMARK_UPDATE_FAILURE';

export const GET_REQUEST_FILTERS_INIT = 'GET_REQUEST_FILTERS_INIT';
export const GET_REQUEST_FILTERS_SUCCESS = 'GET_REQUEST_FILTERS_SUCCESS';
export const GET_REQUEST_FILTERS_FAILURE = 'GET_REQUEST_FILTERS_FAILURE';

export const STAR_REQUEST_INIT = 'STAR_REQUEST_INIT';
export const STAR_REQUEST_SUCCESS = 'STAR_REQUEST_SUCCESS';
export const STAR_REQUEST_FAILURE = 'STAR_REQUEST_FAILURE';

export const INTERESTED_REQUEST_INIT = 'INTERESTED_REQUEST_INIT';
export const INTERESTED_REQUEST_SUCCESS = 'INTERESTED_REQUEST_SUCCESS';
export const INTERESTED_REQUEST_FAILURE = 'INTERESTED_REQUEST_FAILURE';

export const UPDATE_ORDER_INIT = 'UPDATE_ORDER_INIT';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const MODIFY_INSTALLMENTS_INIT = 'MODIFY_INSTALLMENTS_INIT';
export const MODIFY_INSTALLMENTS_SUCCESS = 'MODIFY_INSTALLMENTS_SUCCESS';
export const MODIFY_INSTALLMENTS_FAILURE = 'MODIFY_INSTALLMENTS_FAILURE';

export const BOOKING_ACTION_INIT = 'BOOKING_ACTION_INIT';
export const BOOKING_ACTION_SUCCESS = 'BOOKING_ACTION_SUCCESS';
export const BOOKING_ACTION_FAILURE = 'BOOKING_ACTION_FAILURE';

export const ACCEPT_PAYMENT_INIT = 'ACCEPT_PAYMENT_INIT';
export const ACCEPT_PAYMENT_SUCCESS = 'ACCEPT_PAYMENT_SUCCESS';
export const ACCEPT_PAYMENT_FAILURE = 'ACCEPT_PAYMENT_FAILURE';

export const GET_REQUEST_BY_ID_INIT = 'GET_REQUEST_BY_ID_INIT';
export const GET_REQUEST_BY_ID_SUCCESS = 'GET_REQUEST_BY_ID_SUCCESS';
export const GET_REQUEST_BY_ID_FAILURE = 'GET_REQUEST_BY_ID_FAILURE';

export const GET_EXPERT_DETAILS_INIT = 'GET_EXPERT_DETAILS_INIT';
export const GET_EXPERT_DETAILS_SUCCESS = 'GET_EXPERT_DETAILS_SUCCESS';
export const GET_EXPERT_DETAILS_FAILURE = 'GET_EXPERT_DETAILS_FAILURE';

export const RESEND_CONFIRMATION_INIT = 'RESEND_CONFIRMATION_INIT';
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_FAILURE = 'RESEND_CONFIRMATION_FAILURE';

export const GENERATE_VOUCHER_INIT = 'GENERATE_VOUCHER_INIT';
export const GENERATE_VOUCHER_SUCCESS = 'GENERATE_VOUCHER_SUCCESS';
export const GENERATE_VOUCHER_FAILURE = 'GENERATE_VOUCHER_FAILURE';

export const REFUND_PAYMENT_INIT = 'REFUND_PAYMENT_INIT';
export const REFUND_PAYMENT_SUCCESS = 'REFUND_PAYMENT_SUCCESS';
export const REFUND_PAYMENT_FAILURE = 'REFUND_PAYMENT_FAILURE';

export const CANCEL_ITINERARY_INIT = 'CANCEL_ITINERARY_INIT';
export const CANCEL_ITINERARY_SUCCESS = 'CANCEL_ITINERARY_SUCCESS';
export const CANCEL_ITINERARY_FAILURE = 'CANCEL_ITINERARY_FAILURE';

export const REFUND_TO_WALLET_INIT = 'REFUND_TO_WALLET_INIT';
export const REFUND_TO_WALLET_SUCCESS = 'REFUND_TO_WALLET_SUCCESS';
export const REFUND_TO_WALLET_FAILURE = 'REFUND_TO_WALLET_FAILURE';

export const CREATE_UPDATE_ITINERARY = 'CREATE_UPDATE_ITINERARY';

export const RESET_DASHBOARD_RESP = 'RESET_DASHBOARD_RESP';

export const SYNC_STORE = 'SYNC_STORE';

export const MARK_BOOKING_TRANSACTION_INIT = 'MARK_BOOKING_TRANSACTION_INIT';
export const MARK_BOOKING_TRANSACTION_SUCCESS = 'MARK_BOOKING_TRANSACTION_SUCCESS';
export const MARK_BOOKING_TRANSACTION_FAILURE = 'MARK_BOOKING_TRANSACTION_FAILURE';

export const CANCEL_BOOKING_INIT = 'CANCEL_BOOKING_INIT';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE';

export const GET_BOOKINGS_INIT = 'GET_BOOKINGS_INIT';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE';

export const GET_B2B_WALLET_BALANCE_INIT = 'GET_B2B_WALLET_BALANCE_INIT';
export const GET_B2B_WALLET_BALANCE_SUCCESS = 'GET_B2B_WALLET_BALANCE_SUCCESS';
export const GET_B2B_WALLET_BALANCE_FAILURE = 'GET_B2B_WALLET_BALANCE_FAILURE';

export const GET_REFUND_DATA_INIT = 'GET_REFUND_DATA_INIT';
export const GET_REFUND_DATA_SUCCESS = 'GET_REFUND_DATA_SUCCESS';
export const GET_REFUND_DATA_FAILURE = 'GET_REFUND_DATA_FAILURE';

export const GET_VIRTUAL_ACCOUNT_DATA_INIT = 'GET_VIRTUAL_ACCOUNT_DATA_INIT';
export const GET_VIRTUAL_ACCOUNT_DATA_SUCCESS = 'GET_VIRTUAL_ACCOUNT_DATA_SUCCESS';
export const GET_VIRTUAL_ACCOUNT_DATA_FAILURE = 'GET_VIRTUAL_ACCOUNT_DATA_FAILURE';

// itineraryMaker

export const SAVE_ITINERARY_DRAFT_INIT = 'SAVE_ITINERARY_DRAFT_INIT';
export const SAVE_ITINERARY_DRAFT_SUCCESS = 'SAVE_ITINERARY_DRAFT_SUCCESS';
export const SAVE_ITINERARY_DRAFT_FAILURE = 'SAVE_ITINERARY_DRAFT_FAILURE';

export const GET_STAY_ROOMS_INIT = 'GET_STAY_ROOMS_INIT';
export const GET_STAY_ROOMS_SUCCESS = 'GET_STAY_ROOMS_SUCCESS';
export const GET_STAY_ROOMS_FAILURE = 'GET_STAY_ROOMS_FAILURE';

export const GET_ITINERARY_PRICE_INIT = 'GET_ITINERARY_PRICE_INIT';
export const GET_ITINERARY_PRICE_SUCCESS = 'GET_ITINERARY_PRICE_SUCCESS';
export const GET_ITINERARY_PRICE_FAILURE = 'GET_ITINERARY_PRICE_FAILURE';

// common
export const PING_USER_INIT = 'PING_USER_INIT';
export const PING_USER_SUCCESS = 'PING_USER_SUCCESS';
export const PING_USER_FAILURE = 'PING_USER_FAILURE';

export const GET_PARAMS_META_INIT = 'GET_PARAMS_META_INIT';
export const GET_PARAMS_META_SUCCESS = 'GET_PARAMS_META_SUCCESS';
export const GET_PARAMS_META_FAILURE = 'GET_PARAMS_META_FAILURE';

export const SUGGEST_AUTOCOMPLETE_INIT = 'SUGGEST_AUTOCOMPLETE_INIT';
export const SUGGEST_AUTOCOMPLETE_SUCCESS = 'SUGGEST_AUTOCOMPLETE_SUCCESS';
export const SUGGEST_AUTOCOMPLETE_FAILURE = 'SUGGEST_AUTOCOMPLETE_FAILURE';

export const GET_CONVERSION_INIT = 'GET_CONVERSION_INIT';
export const GET_CONVERSION_SUCCESS = 'GET_CONVERSION_SUCCESS';
export const GET_CONVERSION_FAILURE = 'GET_CONVERSION_FAILURE';

export const UPLOAD_DOCS_INIT = 'UPLOAD_DOCS_INIT';
export const UPLOAD_DOCS_SUCCESS = 'UPLOAD_DOCS_SUCCESS';
export const UPLOAD_DOCS_FAILURE = 'UPLOAD_DOCS_FAILURE';

export const REGISTER_NOTIF_TOKEN_INIT = 'REGISTER_NOTIF_TOKEN_INIT';
export const REGISTER_NOTIF_TOKEN_SUCCESS = 'REGISTER_NOTIF_TOKEN_SUCCESS';
export const REGISTER_NOTIF_TOKEN_FAILURE = 'REGISTER_NOTIF_TOKEN_FAILURE';

// notification
export const GET_NOTIFICATIONS_INIT = 'GET_NOTIFICATIONS_INIT';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const MARK_NOTIF_READ_INIT = 'MARK_NOTIF_READ_INIT';
export const MARK_NOTIF_READ_SUCCESS = 'MARK_NOTIF_READ_SUCCESS';
export const MARK_NOTIF_READ_FAILURE = 'MARK_NOTIF_READ_FAILURE';

// itineraryMaker
export const CREATE_ITINERARY_INIT = 'CREATE_ITINERARY_INIT';
export const CREATE_ITINERARY_SUCCESS = 'CREATE_ITINERARY_SUCCESS';
export const CREATE_ITINERARY_FAILURE = 'CREATE_ITINERARY_FAILURE';

// stay detail
export const SET_STAY = 'SET_STAY';
export const GET_STAY = 'GET_STAY';
export const GET_STAY_SUCCESS = 'GET_STAY_SUCCESS';
export const GET_STAY_FAILURE = 'GET_STAY_FAILURE';
export const UPDATE_STAY = 'UPDATE_STAY';
export const UPDATE_STAY_SUCCESS = 'UPDATE_STAY_SUCCESS';
export const UPDATE_STAY_FAILURE = 'UPDATE_STAY_FAILURE';
export const TOGGLE_APPROVE = 'TOGGLE_APPROVE';
export const TOGGLE_APPROVE_SUCCESS = 'TOGGLE_APPROVE_SUCCESS';
export const TOGGLE_APPROVE_FAILURE = 'TOGGLE_APPROVE_FAILURE';

// stay list
export const GET_STAYS = 'GET_STAYS';
export const GET_STAYS_SUCCESS = 'GET_STAYS_SUCCESS';
export const GET_STAYS_FAILURE = 'GET_STAYS_FAILURE';
export const ADD_STAY = 'ADD_STAY';
export const ADD_STAY_SUCCESS = 'ADD_STAY_SUCCESS';
export const ADD_STAY_FAILURE = 'ADD_STAY_FAILURE';

// hotel list
export const GET_HOTELS = 'GET_HOTELS';
export const GET_HOTELS_SUCCESS = 'GET_HOTELS_SUCCESS';
export const GET_HOTELS_FAILURE = 'GET_HOTELS_FAILURE';

// hotel detail
export const SET_HOTEL = 'SET_HOTEL';
export const GET_HOTEL = 'GET_HOTEL';
export const GET_HOTEL_SUCCESS = 'GET_HOTEL_SUCCESS';
export const GET_HOTEL_FAILURE = 'GET_HOTEL_FAILURE';
export const UPDATE_HOTEL = 'UPDATE_HOTEL';
export const UPDATE_HOTEL_SUCCESS = 'UPDATE_HOTEL_SUCCESS';
export const UPDATE_HOTEL_FAILURE = 'UPDATE_HOTEL_FAILURE';

// place list
export const GET_PLACES = 'GET_PLACES';
export const GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS';
export const GET_PLACES_FAILURE = 'GET_PLACES_FAILURE';
export const ADD_PLACE = 'ADD_PLACE';
export const ADD_PLACE_SUCCESS = 'ADD_PLACE_SUCCESS';
export const ADD_PLACE_FAILURE = 'ADD_PLACE_FAILURE';

// place detail
export const SET_PLACE = 'SET_PLACE';
export const GET_PLACE = 'GET_PLACE';
export const GET_PLACE_SUCCESS = 'GET_PLACE_SUCCESS';
export const GET_PLACE_FAILURE = 'GET_PLACE_FAILURE';
export const UPDATE_PLACE = 'UPDATE_PLACE';
export const UPDATE_PLACE_SUCCESS = 'UPDATE_PLACE_SUCCESS';
export const UPDATE_PLACE_FAILURE = 'UPDATE_PLACE_FAILURE';

// supplier detail
export const SET_SUPPLIER = 'SET_SUPPLIER';
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_FAILURE = 'GET_SUPPLIER_FAILURE';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
export const UPDATE_SUPPLIER_FAILURE = 'UPDATE_SUPPLIER_FAILURE';

// SUPPLIER list
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIERS_FAILURE = 'GET_SUPPLIERS_FAILURE';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS';
export const ADD_SUPPLIER_FAILURE = 'ADD_SUPPLIER_FAILURE';

// experiences
export const GET_EXPERIENCES = 'GET_EXPERIENCES';
export const GET_EXPERIENCES_SUCCESS = 'GET_EXPERIENCES_SUCCESS';
export const GET_EXPERIENCES_FAILURE = 'GET_EXPERIENCES_FAILURE';

// experience detail
export const SET_EXPERIENCE = 'SET_EXPERIENCE';
export const GET_EXPERIENCE = 'GET_EXPERIENCE';
export const GET_EXPERIENCE_SUCCESS = 'GET_EXPERIENCE_SUCCESS';
export const GET_EXPERIENCE_FAILURE = 'GET_EXPERIENCE_FAILURE';
export const UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE';
export const UPDATE_EXPERIENCE_SUCCESS = 'UPDATE_EXPERIENCE_SUCCESS';
export const UPDATE_EXPERIENCE_FAILURE = 'UPDATE_EXPERIENCE_FAILURE';
