import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import prevArrow from '../../assets/svg/arrow_back.svg';
import nextArrow from '../../assets/svg/arrow_next.svg';
import Close from '../../assets/icons/close';
import RenderPDF from '../../UI/renderPDF';

/* eslint-disable jsx-a11y/media-has-caption */
class PreviewDocs extends Component {
  constructor(props) {
    super(props);
    const { index = 0 } = this.props;
    this.state = { index };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.containerRef.current.focus();
  }

  prevHandler = (e) => {
    e.stopPropagation();
    const { fileDetails } = this.props;
    const { index } = this.state;
    if (index - 1 >= 0) {
      this.setState((prevState) => ({ index: prevState.index - 1 }));
    } else {
      this.setState({ index: fileDetails.length - 1 });
    }
  };

  nextHandler = (e) => {
    e.stopPropagation();
    const { fileDetails } = this.props;
    const { index } = this.state;
    if (index + 1 < fileDetails.length) {
      this.setState((prevState) => ({ index: prevState.index + 1 }));
    } else {
      this.setState({ index: 0 });
    }
  };

  keyboardNavHandler = (e) => {
    if (e.key === 'ArrowRight') {
      this.nextHandler(e);
    } else if (e.key === 'ArrowLeft') {
      this.prevHandler(e);
    } else if (e.key === 'Escape') {
      const { onDismiss } = this.props;
      e.stopPropagation();
      onDismiss();
    }
  };

  renderPreview = () => {
    const {
      fileDetails,
      base64Files,
      classes,
    } = this.props;
    const { index } = this.state;
    const item = fileDetails[index];
    const { type, name } = item;
    if (type === 'application/pdf') {
      return (
        <RenderPDF
          file={fileDetails[index] || {}}
          id="previewPDF"
          preview
          show
        />
      );
    }
    if (type === 'video/mp4') {
      return (
        <video controls className={classes.video}>
          <source src={base64Files[index]} type={type} />
          Your browser does not support video
        </video>
      );
    }
    return (
      <img
        decoding="async"
        className={classes.imgPreview}
        alt={name}
        src={base64Files[index]}
      />
    );
  };

  render() {
    const {
      classes,
      fileDetails,
      onDismiss,
    } = this.props;
    const { index } = this.state;
    const item = fileDetails[index];
    const { name } = item;
    return (
      <div
        ref={this.containerRef}
        tabIndex={0}
        role="menuitem"
        className={classes.container}
        onKeyDown={(e) => this.keyboardNavHandler(e)}
      >
        <div className={classes.header}>
          <Typography className={classes.title}>{name}</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={onDismiss}
          >
            <Close fill="#fff" />
          </IconButton>
          <Typography className={classes.esc}>ESC</Typography>
        </div>
        <div className={classes.lSection}>
          <IconButton
            className={classes.lButton}
            onClick={this.prevHandler}
          >
            <img src={prevArrow} alt="<" className={classes.icon} />
          </IconButton>
        </div>
        <div className={classes.rSection}>
          <IconButton
            className={classes.rButton}
            onClick={this.nextHandler}
          >
            <img src={nextArrow} alt=">" className={classes.icon} />
          </IconButton>
        </div>
        <div className={classes.body}>
          {this.renderPreview()}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: 'rgba(22, 36, 42, 0.8)',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.18)',
    height: 64,
    padding: '30px 20px 20px 20px',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: 20,
    color: theme.colors.white,
    paddingLeft: 20,
  },
  closeButton: {
    marginLeft: 'auto',
    width: 40,
    height: 40,
    border: `1px solid ${theme.colors.white}`,
    backgroundColor: 'rgba(255,255,255,0.15)',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.25)',
    },
  },
  esc: {
    position: 'absolute',
    color: theme.colors.white,
    fontSize: 14,
    right: 28,
    top: 64,
  },
  lSection: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 120,
  },
  rSection: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: 120,
  },
  lButton: {
    position: 'absolute',
    left: 40,
    top: '50%',
    width: 40,
    height: 40,
    transform: 'translateY(-50%)',
  },
  rButton: {
    position: 'absolute',
    right: 40,
    top: '50%',
    width: 40,
    height: 40,
    transform: 'translateY(-50%)',
  },
  icon: {
    width: 18,
    height: 28,
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  body: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  imgPreview: {
    maxHeight: '90vh',
    maxWidth: '90vw',
  },
  video: {
    width: 480,
    maxHeight: 500,
  },
});

PreviewDocs.propTypes = {
  classes: PropTypes.object,
  base64Files: PropTypes.array.isRequired,
  fileDetails: PropTypes.array.isRequired,
  index: PropTypes.number,
  onDismiss: PropTypes.func.isRequired,
};

export default withStyles(styles)(PreviewDocs);
