import React from 'react';
import * as PropTypes from 'prop-types';

const Refresh = ({
  width = 12,
  height = 12,
  className,
  fill = '#000',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
    >
      <path
        fill={fill}
        d="M11.965 6.82a.48.48 0 0 1 .035.182v3c0 .276-.234.5-.522.5s-.522-.224-.522-.5V8.157l-1.53 1.378c-1.266 1.214-3.094 1.72-4.842 1.342s-3.17-1.6-3.767-3.21c-.096-.26.046-.546.318-.638s.57.044.666.305C2.28 8.63 3.417 9.598 4.815 9.9S7.675 9.8 8.7 8.816L10.16 7.5H8.348c-.288 0-.522-.224-.522-.5s.234-.5.522-.5h3.13c.22 0 .407.13.484.313l.003.006zM.035 4.18A.48.48 0 0 1 0 3.998V1C0 .723.234.5.522.5s.522.224.522.5v1.844l1.53-1.378C3.84.25 5.668-.256 7.415.123s3.17 1.6 3.767 3.21c.096.26-.046.546-.318.638s-.57-.044-.666-.305C9.72 2.37 8.583 1.402 7.185 1.1S4.325 1.2 3.3 2.184L1.84 3.5h1.813c.288 0 .522.224.522.5s-.234.5-.522.5H.522c-.22 0-.407-.13-.484-.313L.035 4.18z"
      />
    </svg>
  );
};

Refresh.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Refresh;
