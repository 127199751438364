/* eslint react/prop-types: 0 */
/* eslint no-nested-ternary: 0 */
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import withStyles from '@material-ui/styles/withStyles';
// import moment from 'moment';
// import jpegImg from 'assets/svg/jpeg.svg';
// import pdfImg from 'assets/svg/pdf.svg';
// import pngImg from 'assets/svg/png.svg';
import * as PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import Button from 'UI/button';
import DateButton from 'UI/dateButton';
import Footer from 'UI/footer';
import LabelInput from 'UI/labelInput';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import {
  getRequest,
  postRequest
} from 'utils/apiRequests';
import { ROLES } from 'utils/consts';
import TravellerDocumentUpload from './travellerDocumentUpload';
import FlightDocumentVerification from './flightDocumentVerification';

const uploadUrl = `${baseURL}${endPoints.uploadDocs}`;
const flightUploadDocUrl = `${baseURL}${endPoints.flightUploadDocUrl}`;
const travellerUpdate = `${baseURL}${endPoints.travellerUpdate}`;
const mapTravellersToRequest = `${baseURL}${endPoints.mapTravellersToRequest}`;

const upload = async (signedUrl, file) => {
  try {
    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
    });
    return true;
  } catch (err) {
    return false;
  }
};

const TravellerPassportUpdate = ({
  classes,
  request,
  traveller: travellerProp,
  headers,
  showSnackbar,
  onChange,
  isDomestic,
}) => {
  const [traveller, setTraveller] = useState(travellerProp);
  const [busy, setBusy] = useState(false);
  const [passportExpiryDate, setPassportExpiryDate] = useState(
    traveller.documents
      .filter((doc) => doc.documentType === 'PASSPORT_FRONT')
      .map((doc) => doc.documentExpiryDate)[0]
  )
  const [editable, setEditable] = useState(false);
  const handleDocumentChange = (document) => {
    const newData = { ...traveller };
    const index = newData.documents.findIndex((doc) => doc.documentType === document.documentType)
    if (index !== -1) {
      newData.documents[index] = document;
      setTraveller(newData);
    } else {
      newData.documents.push(document);
      setTraveller(newData);
    }
  }
  const handleNameChange = useCallback((name) => {
    setTraveller({
      ...traveller,
      name,
    });
  }, [traveller, setTraveller]);
  const handleDoBChange = (dob) => {
    setTraveller({
      ...traveller,
      dateOfBirth: dob.toISOString(),
    });
  }
  const handleCancel = () => {
    setTraveller(travellerProp);
    setPassportExpiryDate(travellerProp.documents
      .filter((doc) => doc.documentType === 'PASSPORT_FRONT')
      .map((doc) => doc.documentExpiryDate)[0]);
    setEditable(false);
  }
  const handleRowUpdate = async () => {
    setBusy(true);
    const data = { ...traveller, travellerId: traveller._id, uuId: request.uuId };
    data.documents = data.documents.map((doc) => {
      if (doc.documentType === 'PASSPORT_FRONT' || doc.documentType === 'PASSPORT_BACK') {
        return {
          ...doc,
          documentExpiryDate: passportExpiryDate,
        }
      }
      return doc;
    })
    const newData = await postRequest(travellerUpdate, data, headers);
    if (newData.error) {
      setBusy(false);
      showSnackbar(newData.message || 'Error while updating Traveller', 'error');
      return;
    }
    setTraveller(newData);
    setBusy(false);
    setEditable(false);
    onChange(newData);
    showSnackbar('Traveller updated succesfully', 'success');
  }
  const handleAllowEdit = () => {
    setEditable(true);
  }
  const handlePassportExpiryDate = (expiryDate) => {
    setPassportExpiryDate(expiryDate.toISOString());
  }
  const passportFront = traveller.documents
    .find((document) => document.documentType === 'PASSPORT_FRONT') || { documentType: 'PASSPORT_FRONT' };
  const passportBack = traveller.documents
    .find((document) => document.documentType === 'PASSPORT_BACK') || { documentType: 'PASSPORT_BACK' };
  useEffect(() => {
    if (travellerProp !== traveller) setTraveller(travellerProp);
  }, [travellerProp])
  return (
    <Card
      className={classes.card}
    >
      <div
        className={classes.nameContainer}
      >
        <div
          className={classes.inputBox}
        >
          <LabelInput
            disabledInput={!editable}
            placeholder="Name"
            extraClass={classes.nameInput}
            value={traveller.name}
            onChange={handleNameChange}
          />
        </div>
        <div className={classes.actionBox}>
          {
            editable ? (
              <Button
                variant="plain"
                disabled={busy}
                onClick={handleRowUpdate}
                className={classes.iconButton}
              >
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : (
                    <SaveIcon className={classes.editIcon} />
                  )
                }
                Save
              </Button>
            ) : null
          }
          {
            editable ? (
              <Button
                variant="plain"
                disabled={busy}
                onClick={handleCancel}
                className={classes.iconButton}
              >
                <ClearIcon className={classes.editIcon} />
                Cancel
              </Button>
            ) : null
          }
          {
            !editable ? (
              <Button
                variant="plain"
                onClick={handleAllowEdit}
                className={classes.iconButton}
              >
                <EditIcon className={classes.editIcon} />
                Edit
              </Button>
            ) : null
          }
        </div>
      </div>
      <div className={classes.wrapper}>
        <Typography className={classes.label}>Date of Birth</Typography>
        <DateButton
          disabled={!editable}
          disableFuture
          disablePast={false}
          extraClass={classes.dateButton}
          value={traveller.dateOfBirth}
          onChange={handleDoBChange}
        />
      </div>
      {
        !isDomestic ? (
          <>
            <div className={classes.wrapper}>
              <Typography className={classes.label}>Passport Expiry Date</Typography>
              <DateButton
                onChange={handlePassportExpiryDate}
                disabled={!editable || (passportFront.verificationStatus === 'APPROVED' || passportBack.verificationStatus === 'APPROVED')}
                value={passportExpiryDate}
                disablePast
                extraClass={classes.dateButton}
              />
            </div>
            <div className={classes.documentBox}>
              <TravellerDocumentUpload
                request={request}
                editable={editable}
                classes={classes}
                document={passportFront}
                caption="Front side of passport"
                traveller={traveller}
                documentExpiryDate={passportExpiryDate}
                headers={headers}
                onChange={handleDocumentChange}
                showSnackbar={showSnackbar}
              />
              <TravellerDocumentUpload
                request={request}
                editable={editable}
                classes={classes}
                document={passportBack}
                caption="Back side of passport"
                traveller={traveller}
                documentExpiryDate={passportExpiryDate}
                headers={headers}
                onChange={handleDocumentChange}
                showSnackbar={showSnackbar}
              />
            </div>
          </>
        ) : null
      }
    </Card>
  )
}

const TravellerPanCardUpdate = ({
  request,
  classes,
  traveller: travellerProp,
  headers,
  showSnackbar,
  onChange,
}) => {
  const [traveller, setTraveller] = useState(travellerProp);
  const [busy, setBusy] = useState(false);
  const [panNumber, setPanNumber] = useState(
    traveller.documents
      .filter((doc) => doc.documentType === 'PAN_CARD')
      .map((doc) => doc.documentNumber)[0]
  )
  const [editable, setEditable] = useState(false);
  const handleDocumentChange = (document) => {
    const newData = { ...traveller };
    const index = newData.documents.findIndex((doc) => doc.documentType === document.documentType)
    if (index !== -1) {
      newData.documents[index] = document;
      setTraveller(newData);
    } else {
      newData.documents.push(document);
      setTraveller(newData);
    }
  }
  const handleNameChange = useCallback((name) => {
    setTraveller({
      ...traveller,
      name,
    });
  }, [traveller, setTraveller]);
  const handlePanNumberChange = (pan) => {
    setPanNumber(pan);
  }
  const handleCancel = () => {
    setTraveller(travellerProp);
    setPanNumber(travellerProp.documents
      .filter((doc) => doc.documentType === 'PAN_CARD')
      .map((doc) => doc.documentNumber)[0]);
    setEditable(false);
  }
  const handleRowUpdate = async () => {
    setBusy(true);
    const data = {
      ...traveller,
      travellerId: traveller._id,
      uuId: request.uuId,
      isBillingTraveller: true,
    };
    data.documents = data.documents.map((doc) => {
      if (doc.documentType === 'PAN_CARD') {
        return {
          ...doc,
          documentNumber: panNumber,
        };
      }
      return doc;
    });
    const newData = await postRequest(travellerUpdate, data, headers);
    if (newData.error) {
      setBusy(false);
      showSnackbar(newData.message || 'Error while updating Traveller', 'error');
      return;
    }
    setTraveller(newData);
    setBusy(false);
    setEditable(false);
    onChange(newData);
    showSnackbar('Traveller updated succesfully', 'success');
  }

  const handleAllowEdit = () => {
    setEditable(true);
  }
  const panCard = traveller.documents
    .find((document) => document.documentType === 'PAN_CARD');
  useEffect(() => {
    if (travellerProp !== traveller) setTraveller(travellerProp);
  }, [travellerProp])
  return (
    <Card
      className={classes.card}
    >
      <div
        className={classes.nameContainer}
      >
        <div
          className={classes.inputBox}
        >
          <LabelInput
            disabledInput={!editable}
            placeholder="Name"
            extraClass={classes.nameInput}
            value={traveller.name}
            onChange={handleNameChange}
          />
        </div>
        <div className={classes.actionBox}>
          {
            editable ? (
              <Button
                variant="plain"
                disabled={busy}
                onClick={handleRowUpdate}
                className={classes.iconButton}
              >
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : (
                    <SaveIcon className={classes.editIcon} />
                  )
                }
                Save
              </Button>
            ) : null
          }
          {
            editable ? (
              <Button
                variant="plain"
                disabled={busy}
                onClick={handleCancel}
                className={classes.iconButton}
              >
                <ClearIcon className={classes.editIcon} />
                Cancel
              </Button>
            ) : null
          }
          {
            !editable ? (
              <Button
                variant="plain"
                onClick={handleAllowEdit}
                className={classes.iconButton}
              >
                <EditIcon className={classes.editIcon} />
                Edit
              </Button>
            ) : null
          }
        </div>
      </div>
      <div className={classes.wrapper}>
        <LabelInput
          disabledInput={!editable}
          placeholder="Pan Card Number"
          extraClass={classes.nameInput}
          value={panNumber}
          onChange={handlePanNumberChange}
        />
      </div>
      <div className={classes.documentBox}>
        <TravellerDocumentUpload
          request={request}
          editable={editable}
          classes={classes}
          document={panCard}
          caption="Pan card"
          traveller={traveller}
          headers={headers}
          onChange={handleDocumentChange}
          showSnackbar={showSnackbar}
        />
      </div>
    </Card>
  )
}
const CustomerVerification = ({
  classes,
  expert,
  request,
  showSnackbar,
  headers,
  onClose,
}) => {
  const [travellers, setTravellers] = useState(null);
  const [billingTraveller, setBillingTraveller] = useState(null);
  const [flightData, setFlightData] = useState(null);
  const [busy, setBusy] = useState(false);
  const [newTravellerName, setNewTravellerName] = useState();
  const [panNumber, setPanNumber] = useState('');
  const [openAddTravellerModal, setOpenTravellerModal] = React.useState(false);
  const [openAddBillingTravellerModal, setOpenBillingTravellerModal] = React.useState(false);
  const isDomestic = request.itineraries.every((itinerary) => itinerary.tags.includes('DOMESTIC_TRIP'));
  const handleNameChange = (value) => { setNewTravellerName(value); };
  const handleTravellerModalOpen = () => { setOpenTravellerModal(true); };
  const handleTravellerModalClose = () => { setOpenTravellerModal(false); };

  const handleBillingTravellerModalOpen = () => { setOpenBillingTravellerModal(true); };
  const handleBillingTravellerModalClose = () => { setOpenBillingTravellerModal(false); };

  const handlePanChange = (value) => { setPanNumber(value); }

  const fetchDocuments = async () => {
    const url = `${baseURL}${endPoints.requestTraveller}/?uuId=${request.uuId}`;
    const response = await getRequest(url, {}, headers);
    if (response.error) {
      showSnackbar(response.message || 'Failed to fetch', 'error');
      onClose();
      return;
    }
    setTravellers(response.travellers);
    setBillingTraveller(response.billingTraveller);
  }
  const fetchFlightDocuments = async () => {
    const url = `${baseURL}${endPoints.getRequestFlightDocuments}/?uuId=${request.uuId}`;
    const response = await getRequest(url, {}, headers);
    if (response.error) {
      showSnackbar(response.message || 'Failed to fetch', 'error');
      onClose();
      return;
    }
    setFlightData(response);
  }
  const nextHandler = () => { onClose() }
  useEffect(() => {
    fetchDocuments();
    fetchFlightDocuments();
  }, [])
  if (travellers === null) {
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <LinearProgress />
        </div>
      </div>
    );
  }
  const handleChange = (traveller) => {
    const index = travellers.findIndex((tvlr) => tvlr._id === traveller._id);
    const newData = [...travellers];
    newData[index] = traveller;
    setTravellers(newData);
    if (billingTraveller?._id === traveller._id) {
      if (billingTraveller.name && (traveller.name !== billingTraveller.name)) {
        showSnackbar('Updated the name but its inconsistent', 'error');
      }
    }
  }
  const handleBillingDetailChange = (traveller) => {
    setBillingTraveller(traveller);
    const index = travellers.findIndex((tvlr) => tvlr._id === traveller._id);
    if (index !== -1) {
      const newData = [...travellers];
      if (newData[index].name && (newData[index].name !== traveller.name)) {
        showSnackbar('Updated the name but its inconsistent', 'error');
      }
      newData[index].name = traveller.name;
      setTravellers(newData);
    }
  }
  const handleFlightDataChange = (document) => {
    const index = flightData.findIndex((flightDoc) => flightDoc.document === document.document);
    const newData = [...flightData];
    newData[index] = document;
    setFlightData(newData);
  }

  const addTraveller = async () => {
    setBusy(true);
    const traveller = await postRequest(travellerUpdate, {
      uuId: request.uuId,
      name: newTravellerName,
      primaryCustomer: request.primaryCustomer._id,
    }, headers);
    if (traveller.error) {
      showSnackbar(traveller.message || 'Unable to create traveller', 'error');
      setBusy(false);
      return;
    }
    const mapped = await postRequest(mapTravellersToRequest, {
      uuId: request.uuId,
      travellerIds: [...travellers.map((tv) => tv._id), traveller._id],
    }, headers);
    if (mapped.error) {
      showSnackbar(mapped.message || 'Unable to map traveller', 'error');
      setBusy(false);
      return;
    }
    showSnackbar(traveller.message || 'Created traveller', 'success');
    setTravellers([...travellers, traveller]);
    setOpenTravellerModal(false);
    setNewTravellerName('');
    setBusy(false);
  }

  const addBillingTraveller = async () => {
    setBusy(true);
    const traveller = await postRequest(travellerUpdate, {
      isBillingTraveller: true,
      documents: [{ documentType: 'PAN_CARD', documentNumber: panNumber }],
      uuId: request.uuId,
      name: newTravellerName,
      primaryCustomer: request.primaryCustomer._id,
      travellerType: 'ADULT',
    }, headers);
    if (traveller.error) {
      showSnackbar(traveller.message || 'Unable to create traveller', 'error');
      setBusy(false);
      return;
    }
    showSnackbar(traveller.message || 'Created traveller', 'success');
    setBillingTraveller(traveller);
    setOpenBillingTravellerModal(false);
    setNewTravellerName('');
    setBusy(false);
  }

  const handleFlightUpload = async (event) => {
    const filelist = event.target.files;
    const files = [...filelist];
    setBusy(true);
    const uploadParams = {
      files: files.map((file) => ({
        fileName: file.name,
        fileCategory: 'USER_DOCUMENTS',
        ttlMinutes: 60,
        entityId: request._id,
        entityType: 'USER_DOCUMENT',
        extension: file.name.match(/\w+$/g)[0],
      })),
    };
    try {
      const uploadEntries = await postRequest(uploadUrl, uploadParams, headers);
      if (uploadEntries.error) throw uploadEntries;
      const awsUpload = await Promise.all(
        uploadEntries.urls.map(async (uploadEntry, index) => {
          const file = files[index];
          const isUploaded = await upload(uploadEntry.signedUrl, file);
          if (!isUploaded) {
            throw new Error();
          }
          return uploadEntry;
        })
      );
      const data = awsUpload
        .filter((entry) => !!entry)
        .map((entry) => ({
          url: entry.signedUrl.substr(0, entry.signedUrl.indexOf('?')),
          title: entry.fileName,
          document: entry.document,
        }));
      await postRequest(`${flightUploadDocUrl}`, {
        documentIds: [...(flightData || []).map((document) => document.document), ...data.map((flightDoc) => flightDoc.document)],
        uuId: request.uuId,
      }, headers);
    } catch (ex) {
      console.error(ex);
    }
    await fetchFlightDocuments();
    setBusy(false);
  }

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <Paper
          className={classes.paper}
          variant="outlined"
          elevation={3}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Traveller Details
          </Typography>
          {
            travellers.map((traveller) => {
              return (
                <TravellerPassportUpdate
                  isDomestic={isDomestic}
                  classes={classes}
                  request={request}
                  traveller={traveller}
                  headers={headers}
                  onChange={handleChange}
                  showSnackbar={showSnackbar}
                />
              )
            })
          }
          {
            expert.roles.includes(ROLES.EXPERT) || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.FELLOW) ? (
              <Button
                variant="outlined"
                disabled={busy}
                onClick={handleTravellerModalOpen}
              >
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : null
                }
                Add Traveller
              </Button>
            ) : null
          }
          <Dialog open={openAddTravellerModal} onClose={handleTravellerModalClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Traveller</DialogTitle>
            <DialogContent>
              <LabelInput
                placeholder="Name"
                extraClass={classes.nameInput}
                value={newTravellerName}
                onChange={handleNameChange}
              />
            </DialogContent>
            <DialogActions>
              <Button disabled={busy} onClick={handleTravellerModalClose} color="secondary">
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : null
                }
                Cancel
              </Button>
              <Button disabled={busy} onClick={addTraveller} color="primary">
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : null
                }
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
        <Paper
          className={classes.paper}
          variant="outlined"
          elevation={3}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Billing Details
          </Typography>
          {
            billingTraveller ? (
              <TravellerPanCardUpdate
                classes={classes}
                request={request}
                traveller={billingTraveller}
                headers={headers}
                onChange={handleBillingDetailChange}
                showSnackbar={showSnackbar}
              />
            ) : (
              expert.roles.includes(ROLES.EXPERT) || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.FELLOW) ? (
                <Button
                  variant="outlined"
                  disabled={busy}
                  onClick={handleBillingTravellerModalOpen}
                >
                  {
                    busy ? (
                      <CircularProgress size={25} className={classes.editIcon} />
                    ) : null
                  }
                  Add Billing Identity
                </Button>
              ) : null
            )
          }
          <Dialog open={openAddBillingTravellerModal} onClose={handleBillingTravellerModalClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Billing Identity</DialogTitle>
            <DialogContent>
              <LabelInput
                placeholder="Billing Name"
                extraClass={classes.nameInput}
                value={newTravellerName}
                onChange={handleNameChange}
              />
              <LabelInput
                placeholder="Pan Card"
                extraClass={classes.nameInput}
                value={panNumber}
                onChange={handlePanChange}
              />
            </DialogContent>
            <DialogActions>
              <Button disabled={busy} onClick={handleBillingTravellerModalClose} color="secondary">
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : null
                }
                Cancel
              </Button>
              <Button disabled={busy} onClick={addBillingTraveller} color="primary">
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : null
                }
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
        <Paper
          className={classes.paper}
          variant="outlined"
          elevation={3}
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Flight Data
          </Typography>
          {
            flightData ? flightData.map((flightDocument) => {
              return (
                <FlightDocumentVerification
                  classes={classes}
                  request={request}
                  document={flightDocument}
                  headers={headers}
                  onChange={handleFlightDataChange}
                  showSnackbar={showSnackbar}
                />
              )
            }) : <LinearProgress />
          }
          {
            expert.roles.includes(ROLES.EXPERT) || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.FELLOW) ? (
              <Button
                variant="outlined"
                disabled={busy}
              >
                <input
                  type="file"
                  accept="application/pdf,image/png,image/jpeg"
                  multiple
                  className={classes.dropBox}
                  onChange={handleFlightUpload}
                />
                {
                  busy ? (
                    <CircularProgress size={25} className={classes.editIcon} />
                  ) : null
                }
                Add Flight Document
              </Button>
            ) : null
          }
        </Paper>
      </div>
      <Footer>
        <Button
          className={classes.createButton}
          onClick={nextHandler}
        >
          Close
        </Button>
      </Footer>
    </div>
  );
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    minWidth: 600,
    padding: '20px 40px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  paper: {
    padding: 10,
  },
  iconButton: {
    color: theme.colors.primary,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 15px',
  },
  spacing: {
    height: 20,
    padding: 10,
    width: 1,
    backgroundColor: theme.colors.white,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  documentRow: {
    marginLeft: 40,
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
    width: 120,
    textAlign: 'right',
  },
  travellerLabel: {
    fontSize: 14,
    marginBottom: 10,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  subHeading: {
    fontSize: 12,
    marginBottom: 10,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    marginBottom: '10px',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  inputBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  nameInput: {
    minWidth: '250px',
  },
  verificationIcon: {
    alignSelf: 'center',
  },
  index: {
    width: 32,
    marginRight: 10,
    fontSize: 12,
    color: theme.colors.textLight,
    fontWeight: 'bold',
  },
  busy: {
    position: 'absolute',
    left: 0,
  },
  fullName: {
    width: '100%',
  },
  caption: {
    marginRight: 5,
  },
  uploadSection: {
    cursor: 'pointer',
    borderRadius: 4,
    marginRight: 10,
    width: 160,
    height: 80,
    position: 'relative',
    backgroundColor: theme.colors.primarySelected,
    padding: '5px 10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  uploadButton: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  uploadText: {
    fontWeight: 'bold',
    letterSpacing: 0.5,
    fontSize: 12,
    color: theme.colors.primaryBackground,
  },
  document: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 6,
    position: 'absolute',
    width: '90%',
    marginTop: 20,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
    backgroundColor: theme.colors.white,
  },
  flexCol: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
  docImage: {
    width: 16,
    padding: 4,
    border: `2px solid ${theme.colors.border}`,
    borderRadius: 4,
    height: 26,
  },
  documentName: {
    fontSize: 12,
    padding: '0 6px',
    color: theme.colors.textDark,
    maxWidth: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    width: 16,
    height: 16,
  },
  rejectButton: {
    color: theme.colors.red,
  },
  tooltip: {
    outline: 'none',
  },
  createButton: {
    width: 180,
    color: theme.colors.white,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
    marginLeft: 10,
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  dateButton: {
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padding: '5px 10px',
    height: '40px',
    width: '120px',
    margin: '10px',
  },
  tags: {
    color: '#369f9b',
    marginLeft: 15,
  },
  w160: {
    width: 160,
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '250px',
    marginRight: 40,
    marginBottom: 6,
    padding: 6,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
  },
  captionContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  docName: {
    fontSize: 14,
    padding: '0 6px',
    color: theme.colors.textDark,
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 140,
  },
  popperClass: {
    borderRadius: 4,
    marginTop: 6,
    marginLeft: -40,
  },
  deleteButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    color: theme.colors.red,
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  visibleButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  optionsText: {
    width: '80%',
    margin: '0 auto',
    fontSize: 14,
    color: theme.colors.textDark,
  },
  deleteText: {
    color: theme.colors.red,
  },
  moreIcon: {
    marginLeft: 'auto',
    padding: 1,
  },
  deleteIcon: {
    width: 16,
    height: 16,
    color: theme.colors.textLight,
  },
  approvedIcon: {
    color: 'green',
  },
  filePreview: {
    width: '100%',
    height: 110,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  dropText: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.colors.textDark_1,
  },
  dropMsgOR: {
    margin: '6px 0',
    textAlign: 'center',
    fontSize: 12,
    color: theme.colors.textLight_1,
  },
  dropMsgAdd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.colors.primaryBackground,
  },
  documentWrapper: {
    backgroundColor: theme.colors.background,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'absolute',
    right: 10,
    top: -2,
    borderRadius: 8,
  },
  documentBox: {
    display: 'flex',
  },
});

CustomerVerification.propTypes = {
  classes: PropTypes.object,
  request: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomerVerification);
