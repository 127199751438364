import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ButtonBase from '@material-ui/core/ButtonBase';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Autocomplete from 'UI/autocomplete';
import LabelInput from 'UI/labelInput';
import Header from 'UI/header';
import Button from 'UI/button';
import TravellerSelection from 'UI/travellerSelection';
import DateButton from 'UI/dateButton';
import { shouldShowDiffToggle, uuid, getTravellerText } from 'utils/common';
import arrowBack from 'assets/svg/arrow_left.svg';
import Footer from 'UI/footer';
import RadioOptions from 'UI/radioOptions';
import DotLine from 'UI/dotLine';
import DiffTransfer from './transfersDiffNew';
import DiffInput from './diffInput';

class CreateTransfer extends Component {
  constructor(props) {
    super(props);
    const {
      transfer, itineraryActionType, travellers,
      originalTransfer, route,
    } = this.props;
    let prevState = {};
    if (transfer) {
      prevState = {
        ...this.processTransferData(transfer),
        valid: true,
        edit: true,
      };
    }
    this.originalTransfer = {};
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalTransfer);
    if (this.showDiffToggle) {
      this.originalTransfer = this.processTransferData(originalTransfer);
    }
    this.routeDates = {
      from: moment(route.dates.from).unix(),
      to: moment(route.dates.to).unix(),
    };
    this.state = {
      tripType: 'One way',
      transfers: [{
        id: uuid(),
        value: '',
        valid: false,
        item: {},
      }, {
        id: uuid(),
        value: '',
        valid: false,
        item: {},
      }],
      transferDate: new Date(route.dates.from),
      travellers,
      transferMode: {
        value: '',
        valid: false,
      },
      description: '',
      valid: false,
      errorMsg: '',
      // edit: false,
      showDiff: false,
      ...prevState,
    };
  }

  processTransferData = (transfer) => {
    const {
      transfers, transferMode, travellers,
      transferDate, description, tripType,
    } = transfer;
    return {
      tripType,
      transfers: transfers.map((t) => ({
        id: uuid(),
        value: t.name,
        valid: true,
        item: t,
      })),
      travellers,
      transferDate: moment(transferDate).toDate(),
      transferMode: {
        value: transferMode,
        valid: true,
      },
      description,
    };
  };

  handleChange = (field, value) => {
    const { [field]: itm } = this.state;
    if (itm.constructor.name === 'Object' && field === 'transferMode') {
      this.setState({
        [field]: {
          value,
          valid: false,
        },
      });
    } else if (field === 'tripType' && value === 'Round trip') {
      const { transfers } = this.state;
      const modTransfers = [...transfers];
      if (modTransfers[0].item._id !== modTransfers[modTransfers.length - 1].item._id) {
        modTransfers.push(modTransfers[0]);
      }
      this.setState({ [field]: value, transfers: modTransfers }, this.validityChecker);
    } else {
      this.setState({ [field]: value }, this.validityChecker);
    }
  };

  handleTransferChange = (index, value) => {
    const { getSuggestions } = this.props;
    const { transfers } = this.state;
    getSuggestions('destinations', value, {
      // includeAddressTypes: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2', 'airport', 'lodging'],
    });
    const modTransfers = [...transfers];
    modTransfers[index] = {
      ...modTransfers[index],
      valid: false,
      value,
    };
    this.setState({ transfers: modTransfers });
  };

  handleTransferSelection = (index, item) => {
    const { transfers } = this.state;
    const modTransfers = [...transfers];
    modTransfers[index] = {
      value: item.name,
      valid: true,
      item,
    };
    this.setState({ transfers: modTransfers });
  };

  handleTransferRemoval = (index) => {
    const { transfers } = this.state;
    let modTransfers = [...transfers];
    modTransfers.splice(index, 1);
    if (modTransfers.length === 1) {
      modTransfers = [{
        id: uuid(),
        value: '',
        valid: false,
        item: {},
      }];
    }
    this.setState({ transfers: modTransfers });
  };

  handleTransferAddition = () => {
    const { transfers } = this.state;
    const modTransfers = [...transfers];
    modTransfers.push({
      id: uuid(),
      value: '',
      valid: false,
      item: {},
    });
    this.setState({ transfers: modTransfers });
  };

  handleSelection = (field, item, accessor) => {
    this.setState({
      [field]: {
        value: item[accessor],
        valid: true,
        item,
      },
    }, this.validityChecker);
  };

  validityChecker = (setError) => {
    const {
      transfers, transferMode,
      transferDate, travellers,
    } = this.state;
    let valid = true;
    let errorMsg = '';
    if (!transferMode.valid) {
      valid = false;
      errorMsg = 'Invalid transfer mode';
    }
    if (travellers.adults === 0) {
      valid = false;
      errorMsg = 'Invalid travellers seelcted';
    }
    const transferTime = moment(transferDate).unix();
    if (transferTime < this.routeDates.from || transferTime > this.routeDates.to) {
      valid = false;
      errorMsg = 'Selected transfer date falls outside selected route date';
    }
    if (transfers.length < 2) {
      valid = false;
      errorMsg = 'Atleast 2 transfers are required';
    }
    for (let i = 0; i < transfers.length; i++) {
      if (!transfers[i].valid) {
        valid = false;
        errorMsg = `Invalid transfer place at stop number ${i + 1}`;
        break;
      }
    }
    if (setError) {
      this.setState({
        valid,
        errorMsg,
      });
    } else {
      this.setState({ valid });
    }
    return valid;
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    const {
      transfers, transferMode, travellers,
      transferDate, description, valid, tripType,
    } = this.state;
    if (!valid) {
      this.validityChecker(true);
      return;
    }
    const params = {
      transfers: transfers.map((t) => t.item),
      transferMode: transferMode.value,
      transferDate: moment(transferDate).toISOString(),
      description,
      travellers,
      tripType,
    };
    onSubmit(params);
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  getTransferLabel = (index, transferLength) => {
    if (index === 0) {
      return 'Start';
    }
    if ((index + 1) === transferLength) {
      return 'End';
    }
    return `Point ${String.fromCharCode(65 + index - 1)}`;
  };

  renderTransferView = () => {
    const { classes } = this.props;
    const { transfers } = this.state;
    const transferLength = transfers.length;
    return transfers.map((transfer, index) => {
      const { id, value } = transfer;
      const isLast = index + 1 === transferLength;
      return (
        <div
          className={classes.transferRow}
          key={id}
        >
          <DotLine isLast={isLast} lineClass={!isLast ? classes.lineClass : undefined} />
          <div className={classes.transferRow}>
            <DiffInput
              value={value}
              oldValue={value}
              extraClass={classes.transferPlace}
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const {
      classes, onDismiss, mode,
      suggestions, transferModes,
    } = this.props;
    const {
      transfers, transferMode, travellers, showDiff,
      description, transferDate, errorMsg, tripType,
    } = this.state;
    const transferLength = transfers.length;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.container}>
          <Header
            className={classes.headerStrip}
            titleClass={classes.titleClass}
            variant="back"
            img={arrowBack}
            title="Create transfer"
            onDismiss={onDismiss}
            label="VIEW CHANGES"
            showDiffToggle={this.showDiffToggle}
            checked={this.showDiffToggle && showDiff}
            onToggle={this.toggleDiff}
          />
          {!showDiff ? (
            <div className={classes.body}>
              <div className={classes.transfersSection}>
                {mode !== 'view' ? (
                  <RadioOptions
                    defaultValue={tripType}
                    selected={tripType}
                    options={['One way', 'Round trip']}
                    onChange={(e) => this.handleChange('tripType', e.target.value)}
                  />
                )
                  : (
                    <DiffInput
                      value={tripType}
                      oldValue={tripType}
                      extraClass={classes.transferPlace}
                    />
                  )}
                {mode !== 'view' ? transfers.map((transfer, index) => {
                  const { id, value } = transfer;
                  const isLast = transferLength === (index + 1);
                  const transferLabel = this.getTransferLabel(index, transferLength);
                  return (
                    <div
                      className={classes.transferRow}
                      key={id}
                    >
                      <DotLine
                        isLast={isLast}
                        lineClass={!isLast ? classes.lineClass : undefined}
                      />
                      <div className={classes.transferRow}>
                        <Autocomplete
                          label={transferLabel}
                          value={value}
                          data={value ? suggestions.destinations : []}
                          accessor="name"
                          extraClass={classes.transferAutocomplete}
                          inputClass={classes.autocompleteInput}
                          onChange={(val) => this.handleTransferChange(index, val)}
                          onSelected={(item) => this.handleTransferSelection(index, item)}
                          renderType="place"
                        />
                        <IconButton
                          className={classes.deleteButton}
                          onClick={() => this.handleTransferRemoval(index)}
                        >
                          <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                        {isLast ? (
                          <ButtonBase
                            className={classes.addButton}
                            onClick={this.handleTransferAddition}
                          >
                            Add stop
                            <AddIcon className={classes.addIcon} />
                          </ButtonBase>
                        ) : null}
                      </div>
                    </div>
                  );
                }) : this.renderTransferView()}
              </div>
              {mode !== 'view' ? (
                <div className={classes.row}>
                  <LabelInput
                    label="TRANSFER DATE"
                    extraClass={classes.autocompleteContainerL}
                  >
                    <DateButton
                      format="DD MMM YY"
                      value={transferDate}
                      extraClass={classes.transferDate}
                      onChange={(date) => this.handleChange('transferDate', date)}
                    />
                  </LabelInput>
                  <Autocomplete
                    label="MODE OF TRANSFER"
                    value={transferMode.value}
                    data={transferModes}
                    accessor="title"
                    extraClass={classes.autocompleteContainerR}
                    inputClass={classes.autocompleteInput}
                    onChange={(val) => this.handleChange('transferMode', val)}
                    onSelected={(item) => this.handleSelection('transferMode', item, 'title')}
                  />
                </div>
              )
                : (
                  <div className={classes.row}>
                    <DiffInput
                      label="Transfer date"
                      value={moment(transferDate).format('DD MMM YY - hh:mm A')}
                      oldValue={moment(transferDate).format('DD MMM YY - hh:mm A')}
                      extraClass={classes.input}
                    />
                    <DiffInput
                      label="Mode of transfer"
                      value={transferMode.value}
                      oldValue={transferMode.value}
                      extraClass={classes.input}
                    />
                  </div>
                )}
              <div className={classes.row}>
                {mode !== 'view' ? (
                  <TravellerSelection
                    label="No. of travellers"
                    placeholder="Add travellers"
                    extraClass={classes.autocompleteContainerL}
                    travellers={travellers}
                    onChange={(val) => this.handleChange('travellers', val)}
                  />
                )
                  : (
                    <DiffInput
                      label="No. of travellers"
                      value={getTravellerText(travellers)}
                      oldValue={getTravellerText(travellers)}
                      extraClass={classes.input}
                    />
                  )}
              </div>
              <div className={classes.row}>
                {mode !== 'view' ? (
                  <LabelInput
                    value={description}
                    inputClass={classes.description}
                    extraClass={classes.description}
                    label="DESCRIPTION"
                    placeholder="Transfer description"
                    inputProps={{
                      multiline: true,
                      rows: 3,
                      rowsMax: 3,
                    }}
                    onChange={(val) => this.handleChange('description', val)}
                  />
                )
                  : (
                    <DiffInput
                      label="DESCRIPTION"
                      value={description}
                      oldValue={description}
                      extraClass={classes.input}
                    />
                  )}
              </div>
            </div>
          )
            : (
              <DiffTransfer
                oldData={this.originalTransfer}
                data={this.state}
              />
            )}
          <Footer errorMsg={errorMsg}>
            <Button
              onClick={this.onSubmit}
            >
              Save & Add
            </Button>
          </Footer>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '70vw',
    borderRadius: 10,
    backgroundColor: theme.colors.white,
    overflowY: 'auto',
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  transfersSection: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.primarySelected,
    marginBottom: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    padding: '0 30px',
  },
  transferRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  transferBody: {
    padding: '0 0 18px 30px',
  },
  deleteButton: {
    marginLeft: 10,
    marginTop: 32,
    padding: 4,
    height: 30,
  },
  deleteIcon: {
    height: 20,
    width: 20,
    color: theme.colors.red,
  },
  addIcon: {
    marginLeft: 6,
    height: 20,
    width: 20,
    color: theme.colors.primary,
  },
  addButton: {
    marginTop: 26,
    height: 40,
    marginLeft: 20,
    padding: '5px 10px',
    color: theme.colors.primary,
    fontWeight: 'bold',
  },
  lineClass: {
    backgroundColor: theme.colors.primaryBackground,
  },
  transferAutocomplete: {
    width: 600,
    marginRight: 20,
    marginBottom: 16,
  },
  autocompleteContainerL: {
    flex: 1,
    marginRight: 10,
    maxWidth: '49%',
  },
  autocompleteContainerR: {
    flex: 1,
    marginLeft: 10,
    maxWidth: '50%',
  },
  autocompleteInput: {
    width: '100%',
    height: '100%',
    padding: '5px 15px',
  },
  labelDateContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: 10,
  },
  transferDate: {
    border: `1px solid ${theme.colors.border}`,
    borderRadius: '4px',
    height: '40px',
  },
  description: {
    height: 'auto',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  transferPlace: {
    width: 320,
    margin: '-10px 20px 20px 0',
    backgroundColor: theme.colors.primarySelected,
  },
  createButton: {
    width: 180,
    color: theme.colors.white,
    fontSize: 16,
    borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
});

CreateTransfer.propTypes = {
  classes: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  transferModes: PropTypes.array.isRequired,
  transfer: PropTypes.object,
  originalTransfer: PropTypes.object,
  route: PropTypes.object.isRequired,
  travellers: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default withStyles(styles)(CreateTransfer);
