import {
  GET_SUPPLIERS,
  GET_SUPPLIERS_SUCCESS,
  GET_SUPPLIERS_FAILURE,
  ADD_SUPPLIER,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAILURE,
} from '../../actionTypes';

export const getSuppliers = (params, headers) => {
  const payload = { params, headers };
  return {
    type: GET_SUPPLIERS,
    payload,
  };
};

export const getSuppliersFailure = (payload) => {
  return {
    type: GET_SUPPLIERS_FAILURE,
    payload,
  };
};

export const getSuppliersSuccess = (payload) => {
  return {
    type: GET_SUPPLIERS_SUCCESS,
    payload,
  };
};

export const addSupplier = (params, headers) => {
  const payload = { params, headers };
  return {
    type: ADD_SUPPLIER,
    payload,
  };
};

export const addSupplierFailure = (params, headers) => {
  const payload = { params, headers };
  return {
    type: ADD_SUPPLIER_FAILURE,
    payload,
  };
};

export const addSupplierSuccess = (payload) => {
  return {
    type: ADD_SUPPLIER_SUCCESS,
    payload,
  };
};
