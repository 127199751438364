import { put, call, takeLatest } from 'redux-saga/effects';
import { LOGIN_INIT, LOGOUT_INIT, GET_EXPERT_DETAILS_INIT } from '../../actionTypes';
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  getExpertDetailsSuccess,
  getExpertDetailsFailure,
} from './authActions';
import { baseURL, endPoints } from '../../utils/apiEndpoints';
import { postRequest, getRequest } from '../../utils/apiRequests';

function* login(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.login}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.token) {
      yield put(loginSuccess(data));
    } else {
      yield put(loginFailure(data.message));
    }
  } catch (err) {
    yield put(loginFailure(err.message));
  }
}

export function* loginSaga() {
  yield takeLatest(LOGIN_INIT, login);
}

function* logout(action) {
  try {
    const { params = {}, headers } = action.payload;
    const url = `${baseURL}${endPoints.logOut}`;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(logoutSuccess(data));
    } else {
      yield put(logoutFailure(data.message));
    }
  } catch (err) {
    yield put(logoutFailure(err.message));
  }
}

export function* logoutSaga() {
  yield takeLatest(LOGOUT_INIT, logout);
}

function* getExpertDetails(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.expertDetails}`;
    const data = yield call(getRequest, url, params, headers);
    if (!data.error) {
      yield put(getExpertDetailsSuccess(data));
    } else {
      yield put(getExpertDetailsFailure(data.message));
    }
  } catch (err) {
    yield put(getExpertDetailsFailure(err.message));
  }
}

export function* getExpertDetailsSaga() {
  yield takeLatest(GET_EXPERT_DETAILS_INIT, getExpertDetails);
}
