import { put, call, takeLatest } from 'redux-saga/effects';
import { postRequest } from 'utils/apiRequests';
import { baseURL, endPoints } from 'utils/apiEndpoints';
import {
  UPDATE_PLACE,
  GET_PLACE,
} from '../../actionTypes';
import {
  updatePlaceSuccess,
  updatePlaceFailure,
  getPlaceSuccess,
  getPlaceFailure,
} from './placeDetailActions';

function* updatePlace(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.updatePlace}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.success) {
      yield put(updatePlaceSuccess());
    } else {
      yield put(updatePlaceFailure(data.message));
    }
  } catch (err) {
    yield put(updatePlaceFailure(err.message));
  }
}

function* getPlace(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getPlaceForEdit}`;
    const data = yield call(postRequest, url, params, headers);
    if (data) {
      yield put(getPlaceSuccess({ place: data, editAllowed: true }));
    } else {
      yield put(getPlaceFailure(data.message));
    }
  } catch (err) {
    yield put(getPlaceFailure(err.message));
  }
}

export function* updatePlaceSaga() {
  yield takeLatest(UPDATE_PLACE, updatePlace);
}

export function* getPlaceSaga() {
  yield takeLatest(GET_PLACE, getPlace);
}
